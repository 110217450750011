import { Global } from "src/lib/api";
import serverLogActionTypes from "../actionTypes/serverLogActionTypes";

export const fetchLogs = async (dispatch, limit) => {
  dispatch({ type: serverLogActionTypes.FETCH_LOGS_REQUEST });
  const response = await Global.serverlog(limit);
  dispatch({ type: serverLogActionTypes.FETCH_LOGS_SUCCESS, payload: response });
}

export const fetchOldLogs = async (dispatch, limit) => {
  dispatch({ type: serverLogActionTypes.FETCH_LOGS_REQUEST });
  const response = await Global.serveroldlog(limit);
  dispatch({ type: serverLogActionTypes.FETCH_LOGS_SUCCESS, payload: response });
}