import React from 'react';
import './ServerLog.css';
const LogMessage = ({ log }) => {
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const formatCaller = (caller) => {
    if (!caller) return '';
    
    // Maneja varias posibilidades:
    
    // 1. Función anónima con ruta Unix: IncomingMessage.<anonymous> (/app/routes/global.js:801:12)
    const unixFuncMatch = caller.match(/\(\/app\/(.+?)\)/);
    if (unixFuncMatch) return unixFuncMatch[1];
    
    // 2. Ruta Unix simple: /app/routes/global.js:776:10
    const unixPathMatch = caller.match(/\/app\/(.+)/);
    if (unixPathMatch) return unixPathMatch[1];
    
    // 3. Función anónima con ruta Windows: IncomingMessage.<anonymous> (C:\app\routes\global.js:801:12)
    // 4. Ruta Windows simple: C:\app\routes\global.js:776:10
    // Combinamos ambos casos de Windows
    const windowsMatch = caller.match(/[A-Z]:\\api\\(.+?)(?:\)|$)/i);
    if (windowsMatch) {
      // Reemplaza backslashes por forward slashes para consistencia
      return windowsMatch[1].replace(/\\/g, '/');
    }
    
    // Si no coincide con ningún patrón conocido, devolvemos el caller original
    return caller;
  };

  const getLevelColor = (level) => {
    const defaultColor = '#d4d4d4'
    const options = {
      10: '#6b7280', // trace
      20: '#3b82f6', // debug
      30: '#10b981', // info
      40: '#fbbf24', // warn
      50: '#ef4444', // error
      60: '#8b5cf6', // fatal
    }
    return options[level] ?? defaultColor
  };

  const getLevelName = (level) => {
    const defaultLevel = 'UNKNW';
    const options = {
      10: 'TRACE',
      20: 'DEBUG',
      30: 'INFO',
      40: 'WARN',
      50: 'ERROR',
      60: 'FATAL',
    }
    return options[level] ?? defaultLevel
  };

  if (log.isDiscontinuityWarning) {
    return (
      <div 
        key={`warning-${log.time}`} 
        className="server-logs__discontinuity-warning"
      >
        {log.message}
      </div>
    );
  }

  return (
    <div className='server-logs__message-container'>
      <div className='server-logs__log-entry'>
        <span style={{ color: '#888888' }}>
          {formatDate(log.time)}
        </span>
        <span style={{ color: getLevelColor(log.level) }}>
          {`[${getLevelName(log.level)}]`}
        </span>
        <span style={{ color: '#d4d4d4' }}>
          {log.msg}
        </span>
        <span style={{ color: '#666666' }}>
          {`(${formatCaller(log.caller)})`}
        </span>
        <span style={{ color: '#666666' }}>
          {`pid:${log.pid}`}
        </span>
        <span style={{ color: '#666666' }}>
          {`host:${log.hostname}`}
        </span>
      </div>
    </div>
  );
};

export default LogMessage;