import React from "react";
import Input from "src/modules/components/common/Input/Input";

// {value, label} (normal)
const RelationalEnum = ({ option, onChange }) => {
  return (
    <>
      <Input
        name="value"
        label="Valor:"
        value={option.value}
        onChange={onChange}
      />
      <Input
        name="label"
        label="Etiqueta:"
        value={option.label}
        onChange={onChange}
      />
    </>
  );
};

export default RelationalEnum;
