import React, { useContext, useEffect } from 'react';
import './Analysis.css';
import { useAppContext } from 'src/modules/contexts/AppContextProvider';
import { UserActivityContext } from 'src/modules/contexts/UserActivityContextProvider';
import { fetchAnalysis } from 'src/modules/actions/userActivityActions';
function Analysis() {
    const { email } = useAppContext()
    const { state, dispatch } = useContext(UserActivityContext)
    const { nerrors, ndossiers, nflows } = state.analysis
    useEffect(() => {
        fetchAnalysis(dispatch, email)
    }, [email, dispatch])

    return (
        <div className="user-activity-dashboard__analysis">
            <div className="analysis__title">Tu análisis</div>
            <div className="analysis__analytics">
                <div className="analysis__analytic">
                    <div className="analytic__value">{nerrors?.total}</div>
                    <div className="analytic__name"><a href ="/view/dmyerrors">errores pendientes</a>.</div>
                </div>
                <div className="analysis__analytic">
                    <div className="analytic__value">{nflows?.total}</div>
                    <div className="analytic__name"><a href ="/view/dmytasks">Tareas activas</a>.</div>
                </div>
                <div className="analysis__analytic">
                    <div className="analytic__value">{ndossiers?.total}</div>
                    <div className="analytic__name">dossiers asignados.</div>
                </div>
                <div className="analysis__analytic">
                    <div className="analytic__value">{nflows?.total}</div>
                    <div className="analytic__name">flujos de trabajo.</div>
                </div>
            </div>
        </div>
    );
}

export default Analysis;

