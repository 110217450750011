// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.etiquetas-gruposContables,
.etiquetas-formasPago,
.etiquetas-servicios,
.etiquetas-grupos {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 4px;
  margin: 0 auto;
}
.movimientos-etiqueta {
  color: white;
  font-size: 1rem;
  font-family: "Ping LCG";
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(62, 59, 68, 0.7);
  padding: 4px 8px;
}

.movimientosRightTabs {
  display: flex;
  gap: 5px;
  justify-content: space-between;
  background-color: rgba(62, 59, 68, 0.7);
  margin-bottom: 10px;
}

.tab-element {
  color: white;
  font-size: 1rem;
  font-family: "Ping LCG";
  cursor: pointer;
  border: 2px solid transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  flex: auto;
}

.tab-element.selected {
  background-color: rgba(62, 59, 68, 1); /* color más oscuro */
}

.movimientosTab-editFields {
  display: flex;
  flex-direction: column;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/Movimientos/Movimientos.css"],"names":[],"mappings":"AAAA;;;;EAIE,aAAa;EACb,WAAW;EACX,eAAe;EACf,QAAQ;EACR,cAAc;AAChB;AACA;EACE,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,eAAe;EACf,6BAA6B;EAC7B,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,uCAAuC;EACvC,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,QAAQ;EACR,8BAA8B;EAC9B,uCAAuC;EACvC,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,uBAAuB;EACvB,eAAe;EACf,6BAA6B;EAC7B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,qCAAqC,EAAE,qBAAqB;AAC9D;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB","sourcesContent":[".etiquetas-gruposContables,\r\n.etiquetas-formasPago,\r\n.etiquetas-servicios,\r\n.etiquetas-grupos {\r\n  display: flex;\r\n  width: 100%;\r\n  flex-wrap: wrap;\r\n  gap: 4px;\r\n  margin: 0 auto;\r\n}\r\n.movimientos-etiqueta {\r\n  color: white;\r\n  font-size: 1rem;\r\n  font-family: \"Ping LCG\";\r\n  cursor: pointer;\r\n  border: 2px solid transparent;\r\n  border-radius: 4px;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  background-color: rgba(62, 59, 68, 0.7);\r\n  padding: 4px 8px;\r\n}\r\n\r\n.movimientosRightTabs {\r\n  display: flex;\r\n  gap: 5px;\r\n  justify-content: space-between;\r\n  background-color: rgba(62, 59, 68, 0.7);\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.tab-element {\r\n  color: white;\r\n  font-size: 1rem;\r\n  font-family: \"Ping LCG\";\r\n  cursor: pointer;\r\n  border: 2px solid transparent;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  padding: 4px 8px;\r\n  flex: auto;\r\n}\r\n\r\n.tab-element.selected {\r\n  background-color: rgba(62, 59, 68, 1); /* color más oscuro */\r\n}\r\n\r\n.movimientosTab-editFields {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
