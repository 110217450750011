import { useState, useEffect, useCallback } from "react";
import { Bank as BankAPI, Setup as SetupAPI } from "src/lib/api";
import { generateUUID } from "../utils/generateUUID";

export const useBankManagement = (setupInfo, setSetupInfo) => {
  const [institutions, setInstitutions] = useState([]);
  const [isFetchingInstitutions, setIsFetchingInstitutions] = useState(false);
  const [boxNewBank, setBoxNewBank] = useState(false);
  const [boxPeriodicityMovements, setBoxPeriodicityMovements] = useState(false);
  const [selectedBank, setSelectedBank] = useState({});
  const [peridocity, setPeridocity] = useState("");
  const [isGocardlessAuthorized, setIsGocardlessAuthorized] = useState(true);

  // Verificar credenciales de GoCardless
  useEffect(() => {
    async function checkCredentials() {
      const result = await BankAPI.checkCredentials();
      if (!result.valid) {
        setIsGocardlessAuthorized(false);
      }
    }
    checkCredentials();
  }, []);

  const fetchInstitutions = async (country) => {
    setIsFetchingInstitutions(true);
    const fetchedInstitutions = await BankAPI.bankList({ country });
    setInstitutions(fetchedInstitutions);
    setIsFetchingInstitutions(false);
  };

  const addBank = useCallback(() => {
    setBoxNewBank(true);
  }, []);

  const saveNewBank = async () => {
    if (!selectedBank.bank || !selectedBank.country) return;
    const bankFound = institutions.find(
      (institution) => institution.id === selectedBank.bank
    );
    const newAccount = {
      bank: bankFound?.name,
      institutionid: bankFound?.id,
      bic: bankFound?.bic,
      countries: selectedBank.country,
      logo: bankFound?.logo,
      transactiontotaldays: bankFound?.transaction_total_days,
      active: false,
      status: "CR",
      id: generateUUID(),
    };
    const setupTemp = {
      ...setupInfo,
      bank: {
        ...setupInfo.bank,
        accounts: [...setupInfo.bank.accounts, newAccount],
      },
    };
    const resultSetupAPI = await SetupAPI.write(setupTemp);
    setSetupInfo(resultSetupAPI);
    return resultSetupAPI;
  };

  const openPeriodicityModal = useCallback(() => {
    setBoxPeriodicityMovements(true);
  }, []);

  const savePeriodicity = async () => {
    setBoxPeriodicityMovements(false);
    const setupTemp = {
      ...setupInfo,
      bank: {
        ...setupInfo.bank,
        periodicity: peridocity,
      },
    };
    await SetupAPI.write(setupTemp);
    setSetupInfo(setupTemp);
  };

  const deleteBank = async (row) => {
    const newSetup = { ...setupInfo };
    newSetup.bank.accounts = newSetup.bank.accounts.filter(
      (account) => account.id !== row.id
    );
    if (row.name) {
      await BankAPI.bankDelete(row.id);
    }
    setSetupInfo(newSetup);
    await SetupAPI.write(newSetup);
  };

  const updateAccount = async (row) => {
    const newSetup = { ...setupInfo };
    newSetup.bank.accounts = newSetup.bank.accounts.filter(
      (account) => account.id !== row.id
    );
    BankAPI.transactions(row.accountid).then(() => {
      newSetup.bank.accounts = newSetup.bank.accounts.filter(
        (account) => account.id !== row.id
      );
    });
  };

  return {
    institutions,
    setInstitutions,
    isFetchingInstitutions,
    boxNewBank,
    setBoxNewBank,
    boxPeriodicityMovements,
    setBoxPeriodicityMovements,
    selectedBank,
    setSelectedBank,
    peridocity,
    setPeridocity,
    isGocardlessAuthorized,
    fetchInstitutions,
    addBank,
    saveNewBank,
    openPeriodicityModal,
    savePeriodicity,
    deleteBank,
    updateAccount,
  };
};
