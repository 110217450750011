import React, { useState } from "react";
import PropTypes from "prop-types";
import "./Chat.css";
import Button from "src/modules/components/common/Button/Button";
import Icon from "@mui/material/Icon";

/**
 * Chat component renders a chat interface with an input field, a list of messages,
 * and control buttons to send messages, clear the chat, or close the chat.
 *
 * The accepted roles for messages are:
 * - **agent**: Represents the messages sent by the chat agent.
 * - **user**: Represents the messages sent by the user.
 *
 * @component
 * @param {Object} props - Component props.
 * @param {Array.<{text: string, role: ("agent"|"user")}>} props.messages - Array of messages for the conversation.
 * @param {function} props.onSubmitMessage
 * @param {boolean} props.loadingAgentMessage - Boolean state to manage loading agent message.
 * @param {string} [props.chatTitle] - Title to assign to the chat.
 * @param {function} props.onClose - Callback function invoked to close the chat.
 * @param {function} props.onClearChat - Callback function invoked to clear the chat.
 * @returns {JSX.Element} The rendered chat component.
 */
const Chat = ({
  messages = [],
  onSubmitMessage,
  loadingAgentMessage = false,
  chatTitle,
  onClose,
  onClearChat,
}) => {
  const [inputText, setInputText] = useState("");

  const handleUserInput = (e) => {
    setInputText(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleOnCloseChat = () => {
    onClose();
  };

  const handleSendMessage = () => {
    if (loadingAgentMessage) return;
    if (!inputText) return;
    onSubmitMessage(inputText);
    setInputText("");
  };

  return (
    <div className="container-chat">
      <div className="container-chat-header">
        <h2>{chatTitle ? chatTitle : ""}</h2>
      </div>
      {onClose && (
        <div className="chat-close-button-div" onClick={handleOnCloseChat}>
          <Icon>close</Icon>
        </div>
      )}

      <div className="chat-app">
        <div className="chat-window">
          {messages.map((message, index) => (
            <div
              key={index}
              className={
                message.role === "user" ? "user-message" : "agent-message"
              }
            >
              {message.text}
            </div>
          ))}
          {loadingAgentMessage && (
            <div className="loading-animation">
              <span>.</span>
              <span>.</span>
              <span>.</span>
            </div>
          )}
        </div>
      </div>

      <div className="input-container-chat">
        <input
          type="text"
          value={inputText}
          onChange={handleUserInput}
          placeholder="Type your message..."
          onKeyDown={handleKeyDown}
          autoFocus
          className="input-container-chat-input"
        />
        <div className="input-container-chat-buttons">
          {onClearChat && (
            <Button
              onClick={onClearChat}
              content={<Icon>comments_disabled_icon</Icon>}
              style={{ minWidth: "20px", marginLeft: "10px" }}
              title="Clear chat"
            />
          )}
          <Button
            onClick={handleSendMessage}
            content={<Icon>send</Icon>}
            style={{ minWidth: "20px" }}
            title="Send"
          />
        </div>
      </div>
    </div>
  );
};

Chat.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      role: PropTypes.oneOf(["agent", "user"]).isRequired,
    })
  ).isRequired,
  onSubmitMessage: PropTypes.func.isRequired,
  loadingAgentMessage: PropTypes.boolean,
  chatTitle: PropTypes.string,
  onClose: PropTypes.func,
  onClearChat: PropTypes.func,
};

export default Chat;
