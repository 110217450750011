import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Bank {
  /**
   * Obtiene la lista de bancos públicos según el código de país.
   * @param {object} params - Parámetros de la solicitud.
   * @param {string} params.country - Código de país basado en ISO 3166-1 alpha-2.
   * @returns {Promise<any>} - Respuesta de la solicitud.
   */
  static async bankList({ country }) {
    const response = await HttpMethods.request_get(
      `/v1/bank/publiclist/${country}`
    );
    if (response.error || !response) {
      addErrorMessageGlobal("Error al obtener los bancos");
      return [];
    }
    return response;
  }

  /**
   * Obtiene las requisiciones de bancos.
   * @returns {Promise<any>} - Respuesta de la solicitud.
   */
  static async bankRequisitions() {
    const response = await HttpMethods.request_get(`/v1/bank/requisitions`);
    if (!response) addErrorMessageGlobal("Error al actualizar requisiciones");
    return response;
  }

  /**
   * Realiza la revalidación de una requisición específica.
   * @param {string} requisitionid - ID de la requisición a revalidar.
   * @returns {Promise<any>} - Respuesta de la solicitud.
   */
  static async bankRevalidation(requisitionid) {
    const response = await HttpMethods.request_get(
      `/v1/bank/revalidate/${requisitionid}`
    );
    if (!response) addErrorMessageGlobal("Error al realizar la revalidación");
    return response;
  }

  /**
   * Elimina una requisición específica.
   * @param {string} requisitionid - ID de la requisición a eliminar.
   * @returns {Promise<any>} - Respuesta de la solicitud.
   */
  static async bankDelete(requisitionid) {
    const response = await HttpMethods.request_delete(
      `/v1/bank/delete`,
      requisitionid
    );
    if (!response) addErrorMessageGlobal("Error al eliminar la requisición");
    return response;
  }
  /**
   * Comprueba si las claves de gocardless esta autorizadas.
   * @returns {Promise<any>} - Respuesta de la solicitud.
   */
  static async checkCredentials() {
    const response = await HttpMethods.request_get(`/v1/bank/credentials`);
    if (!response) addErrorMessageGlobal("Error al comprobar las credenciales");
    return response;
  }

  static async transactions(accountid) {
    const response = await HttpMethods.request_get(
      `/v1/bank/transactions/${accountid}/-/-`
    );
    if (!response) addErrorMessageGlobal("Error al comprobar las credenciales");
    return response;
  }
}
