import React, { useState, useEffect } from "react";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";

const MovimientosType = ({
  movementData,
  setMovementData,
  selectedItem,
  clearSelectedItem,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [item, setItem] = useState({ code: "", description: "" });
  const isEditing =
    selectedItem.category === "type" && selectedItem.index !== null;

  useEffect(() => {
    if (isEditing) {
      const existingItem = movementData.type[selectedItem.index];
      setItem(existingItem);
      setShowForm(true);
    }
  }, [selectedItem]);

  const handleInputChange = (e) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (isEditing) {
      const updatedArray = [...movementData.type];
      updatedArray[selectedItem.index] = item;
      setMovementData({ ...movementData, type: updatedArray });
    } else {
      setMovementData({ ...movementData, type: [...movementData.type, item] });
    }
    setShowForm(false);
    clearSelectedItem();
    setItem({ code: "", description: "" });
  };

  const handleDelete = () => {
    if (isEditing) {
      const updatedArray = movementData.type.filter(
        (_, idx) => idx !== selectedItem.index
      );
      setMovementData({ ...movementData, type: updatedArray });
      setShowForm(false);
      clearSelectedItem();
      setItem({ code: "", description: "" });
    }
  };

  return (
    <div className="movimientosTab">
      <Button
        icon="add"
        onClick={() => {
          setShowForm(true);
          setItem({ code: "", description: "" });
        }}
      />
      {showForm && (
        <>
          <div className="movimientosTab-editFields">
            <Input
              name="code"
              type="text"
              placeholder="Código"
              onChange={handleInputChange}
              value={item.code}
              label="Code"
            />
            <Input
              name="description"
              type="text"
              placeholder="Descripción"
              onChange={handleInputChange}
              value={item.description}
              label="Descripción"
            />
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button icon="save" onClick={handleSave} />
            {isEditing && <Button icon="delete" onClick={handleDelete} />}
          </div>
        </>
      )}
    </div>
  );
};

export default MovimientosType;
