import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Error {
  static async list(framecode, first = 0, pagination = 100) {
    const response = await HttpMethods.request_get(
      `/v1/error/list?format=flat`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener los errores");
    }
    return response;
  }

  static async listByUser(email, first = 0, pagination = 100) {
    const response = await HttpMethods.request_get(
      `/v1/error/user/${email}?format=flat`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener los errores");
    }
    return response;
  }

  static async dossier(dossiercode) {
    const response = await HttpMethods.request_get(
      `/v1/error/dossier/${dossiercode}`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener los errores");
    }
    return response;
  }

  static async clean() {
    const response = await HttpMethods.request_get(`/v1/error/clean`);
    if (!response) {
      addErrorMessageGlobal("Error al limpiar los errores");
    }
    return response;
  }
}
