import React, { useState, useEffect } from "react";
import "./Disconnection.css";
const logo = "/logointro.png";

function Disconnection() {
  const [counter, setCounter] = useState(60);

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    } else {
      window.location.reload();
    }
  }, [counter]);

  return (
    <div className="disconnection-container">
      <div>
        <img className="disconnection-image" src={logo} alt="logointro" />
      </div>
      <div>
        <h1>
          Lo sentimos, ha ocurrido un error.
        </h1>
        <h3>Nuestros técnicos estan trabajando para solucionar el problema</h3>
        <h3>Reintentando en {counter} segundos...</h3>
      </div>
    </div>
  );
}

export default Disconnection;
