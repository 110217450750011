import React, { useState, useEffect } from "react";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";

const MovimientosGroups = ({
  movementData,
  setMovementData,
  selectedItem,
  clearSelectedItem,
}) => {
  const [showForm, setShowForm] = useState(false);
  // Se actualiza el estado para trabajar con 'subgroups' como array
  const [group, setGroup] = useState({ name: "", subgroups: [] });
  const isEditing =
    selectedItem.category === "group" && selectedItem.index !== null;

  useEffect(() => {
    if (isEditing) {
      const existingGroup = movementData.group[selectedItem.index];
      setGroup({
        name: existingGroup.name,
        subgroups: existingGroup.subgroup, // se asume que la estructura original es { name, subgroup: [] }
      });
      setShowForm(true);
    }
  }, [selectedItem]);

  const handleInputChange = (e) => {
    setGroup({ ...group, [e.target.name]: e.target.value });
  };

  // Actualiza el valor de un subgrupo en el índice indicado
  const handleSubgroupChange = (index, value) => {
    const updatedSubgroups = group.subgroups.map((sub, idx) =>
      idx === index ? value : sub
    );
    setGroup({ ...group, subgroups: updatedSubgroups });
  };

  // Agrega un nuevo subgrupo vacío
  const handleAddSubgroup = () => {
    setGroup({ ...group, subgroups: [...group.subgroups, ""] });
  };

  // Elimina un subgrupo por su índice
  const handleRemoveSubgroup = (index) => {
    const updatedSubgroups = group.subgroups.filter((_, idx) => idx !== index);
    setGroup({ ...group, subgroups: updatedSubgroups });
  };

  const handleSave = () => {
    const groupData = {
      name: group.name,
      // Se filtran los subgrupos vacíos antes de guardar
      subgroup: group.subgroups.filter((s) => s.trim() !== ""),
    };

    if (isEditing) {
      const updatedArray = [...movementData.group];
      updatedArray[selectedItem.index] = groupData;
      setMovementData({ ...movementData, group: updatedArray });
    } else {
      setMovementData({
        ...movementData,
        group: [...movementData.group, groupData],
      });
    }
    setShowForm(false);
    clearSelectedItem();
    setGroup({ name: "", subgroups: [] });
  };

  const handleDelete = () => {
    if (isEditing) {
      const updatedArray = movementData.group.filter(
        (_, idx) => idx !== selectedItem.index
      );
      setMovementData({ ...movementData, group: updatedArray });
      setShowForm(false);
      clearSelectedItem();
      setGroup({ name: "", subgroups: [] });
    }
  };

  return (
    <div className="movimientosTab">
      <Button
        icon="add"
        onClick={() => {
          setShowForm(true);
          setGroup({ name: "", subgroups: [] });
        }}
      />
      {showForm && (
        <>
          <div className="movimientosTab-editFields">
            <Input
              name="name"
              type="text"
              placeholder="Nombre del grupo"
              onChange={handleInputChange}
              value={group.name}
              label="Nombre"
            />
            <div>
              <h4>Subgrupos</h4>
              {group.subgroups.map((sub, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    marginBottom: "5px",
                  }}
                >
                  <Input
                    name={`subgroup-${index}`}
                    type="text"
                    placeholder="Nombre del subgrupo"
                    onChange={(e) =>
                      handleSubgroupChange(index, e.target.value)
                    }
                    value={sub}
                    label={`Subgrupo ${index + 1}`}
                  />
                  <Button
                    icon="delete"
                    onClick={() => handleRemoveSubgroup(index)}
                  />
                </div>
              ))}
              <Button icon="add" onClick={handleAddSubgroup} />
            </div>
          </div>
          <div style={{ display: "flex", gap: "5px", marginTop: "10px" }}>
            <Button icon="save" onClick={handleSave} />
            {isEditing && <Button icon="delete" onClick={handleDelete} />}
          </div>
        </>
      )}
    </div>
  );
};

export default MovimientosGroups;
