import React, {
  useContext,
  useEffect,
  lazy,
  useState,
  Suspense,
  useRef,
} from "react";
import { ViewContext } from "src/modules/contexts/ViewContextProvider";
import { fetchView, editView } from "src/modules/actions/viewActions";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import ListFrame from "src/modules/components/frame/ListFrame/ListFrame";
import "./View.css";
import MetaWindow from "../../modules/components/common/MetaWindow/MetaWindow";
import LoadingSpinner from "src/modules/components/common/LoadingSpinner/LoadingSpinner";
import ShowFrame from "src/modules/components/frame/ShowFrame/ShowFrame";
import TemplateFrame from "src/modules/components/frame/TemplateFrame/TemplateFrame";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
import EditView from "./EditView";
import CanvaFrame from "src/modules/components/frame/CanvaFrame/CanvaFrame";
import { useSearchParams } from "react-router-dom";

const renderFrame = (frame, view) => {
  switch (frame.type) {
    case "list": {
      return <ListFrame key={frame._id} frame={frame} />;
    }
    case "show": {
      return <ShowFrame key={`show_${frame._id}`} frame={frame} />;
    }
    case "canvas": {
      return (
        <CanvaFrame key={`canvas_${frame._id}`} frame={frame} view={view} />
      );
    }
    case "template":
      return <TemplateFrame key={`template_${frame._id}`} frame={frame} />;
    default: {
      return <h1>Frame tipo {frame.type} no soportado</h1>;
    }
  }
};

const DossierDashboard = lazy(() =>
  import(
    "../../modules/components/common/MetaWindow/DossierDashboard/DossierDashboard"
  )
);

let unmountCount = 0;

const View = ({ vid }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramMenuMetawindow = searchParams.get("_mtw") || false;

  const [isOpen, setIsOpen] = useState(!!paramMenuMetawindow);
  const [metaWindowMenuPosition, setMetaWindowMenuPosition] = useState(() => {
    const mtwParam = searchParams.get("_mtw");
    return mtwParam
      ? mtwParam.charAt(0).toUpperCase() + mtwParam.slice(1).toLowerCase()
      : "Flows";
  });

  const [showDropDown, setShowDropDown] = useState(false);
  const { state, dispatch } = useContext(ViewContext);
  const { role, setNavBarTitle } = useAppContext();
  const [shouldRender, setShouldRender] = useState(isOpen);
  const [animateIn, setAnimateIn] = useState(false);

  const containerRef = useRef(null);
  const shouldShowMetaWindow = Array.isArray(state.view?.frames)
    ? state.view.frames.some(
        (item) => item.scope === "dossier" && item.type === "show"
      )
    : Object.values(state.data || {}).some(
        (item) => item.scope === "dossier" && item.type === "show"
      );

  useEffect(() => {
    if (state.view?.id !== vid && !state.loading) {
      fetchView(vid, dispatch);
    }
  }, [vid, state.view?.id, dispatch]);

  useEffect(() => {
    let title = state.view?.title;
    setNavBarTitle(title);
  }, [state.view?.title, setNavBarTitle]);

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
      setTimeout(() => {
        setAnimateIn(true);
      }, 0);
    } else {
      setAnimateIn(false);
      const timer = setTimeout(() => setShouldRender(false), 300);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (containerRef.current) {
      if (isOpen) {
        containerRef.current.classList.remove("container--with-margin");
      } else {
        if (shouldShowMetaWindow) {
          containerRef.current.classList.add("container--with-margin");
        } else {
          containerRef.current.classList.remove("container--with-margin");
        }
      }
    }
  }, [isOpen, shouldShowMetaWindow]);

  if (!state.view?.frames) {
    return (
      <div className="view-container__loader">
        <span className="view-spinner-container">
          Cargando frames <LoadingSpinner />
        </span>
      </div>
    );
  }

  const framesCount = state.view?.frames.length;

  const onClick = (id) => {
    setMetaWindowMenuPosition("Tareas");
    setIsOpen(!isOpen);
  };

  const onClickError = () => {
    setMetaWindowMenuPosition("Errores");
    setIsOpen(!isOpen);
  };

  const isMetaWindowClosed = isOpen || !shouldShowMetaWindow;

  if (framesCount === 1) {
    const frame = state.view?.frames[0];
    return (
      <div className={`container`} ref={containerRef}>
        {role === "developer" && (
          <>
            <Icon
              title="Haga click para editar el view."
              className="view-edit-icon"
              style={{
                color: "orange",
                border: "2px solid blue",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowDropDown(true);
              }}
            >
              edit
            </Icon>
            {showDropDown && (
              <EditView
                viewData={state.view}
                showDropDown={showDropDown}
                setShowDropDown={setShowDropDown}
                updateView={editView}
              />
            )}
          </>
        )}

        {frame.type === "list" && renderFrame(frame)}

        {frame.type === "canvas" && renderFrame(frame, state.view)}

        {/* {frame.type === "show" && renderFrame(frame)} */}

        {frame.type === "show" && (
          <PanelGroup direction="horizontal">
            <Panel className="" id={`frame-panel-${frame._id}`} order={1}>
              {renderFrame(frame)}
            </Panel>
            {shouldRender && (
              <>
                <PanelResizeHandle className="panel-resize-handle-horizontal" />
                <Panel
                  id="dashboard-panel"
                  order={2}
                  className={`panel panel-scroll dashboard-panel-container`}
                  style={{ width: "300px" }}
                >
                  <div
                    className={`dashboard-panel ${
                      animateIn ? "open" : "closed"
                    }`}
                  >
                    <Suspense>
                      <DossierDashboard
                        onClose={() => setIsOpen(false)}
                        menuPosition={metaWindowMenuPosition}
                      />
                    </Suspense>
                  </div>
                </Panel>
              </>
            )}
          </PanelGroup>
        )}

        {frame.type === "template" && (
          <div className="template-container">{renderFrame(frame)}</div>
        )}

        {!isOpen && shouldShowMetaWindow && (
          <MetaWindow onClick={onClick} onClickError={onClickError} />
        )}
      </div>
    );
  }

  // // Manejo de múltiples frames
  // let topFrame = null;
  // let remainingFrames = state.view.frames;

  // if (framesCount % 2 !== 0) {
  //   topFrame = state.view.frames[0];
  //   remainingFrames = state.view.frames.slice(1);
  // }

  // const halfIndex = Math.ceil(remainingFrames.length / 2);
  // const firstHalf = remainingFrames.slice(0, halfIndex);
  // const secondHalf = remainingFrames.slice(halfIndex);

  // return (
  //   <div
  //     className={`container ${
  //       isMetaWindowClosed ? "container--with-margin" : ""
  //     }`}
  //   >
  //     {showDropDown && (
  //       <EditView
  //         viewData={state.view}
  //         showDropDown={showDropDown}
  //         setShowDropDown={setShowDropDown}
  //         updateView={editView}
  //       />
  //     )}

  //     <PanelGroup direction="vertical">
  //       {topFrame && (
  //         <>
  //           {/* Panel para el topFrame */}
  //           <Panel
  //             id={`frame-panel-${topFrame._id}`}
  //             order={1}
  //             className="panel panel-scroll"
  //             style={{ height: "40%" }}
  //           >
  //             {renderFrame(topFrame)}
  //           </Panel>
  //           <PanelResizeHandle className="panel-resize-handle-vertical" />
  //         </>
  //       )}
  //       <Panel style={{ flex: 1 }}>
  //         <PanelGroup direction="horizontal">
  //           {/* Primer grupo de Panels verticales */}
  //           <Panel className="panel panel-scroll">
  //             <PanelGroup direction="vertical">
  //               {firstHalf.map((frame, index) => (
  //                 <React.Fragment key={frame._id}>
  //                   <Panel
  //                     id={`frame-panel-${frame._id}`}
  //                     order={index + 1}
  //                     className="panel panel-scroll"
  //                   >
  //                     {renderFrame(frame)}
  //                   </Panel>
  //                   {index < firstHalf.length - 1 && (
  //                     <PanelResizeHandle className="panel-resize-handle-vertical" />
  //                   )}
  //                 </React.Fragment>
  //               ))}
  //             </PanelGroup>
  //           </Panel>
  //           <PanelResizeHandle className="panel-resize-handle-horizontal" />
  //           {/* Segundo grupo de Panels verticales */}
  //           <Panel className="panel panel-scroll">
  //             <PanelGroup direction="vertical">
  //               {secondHalf.map((frame, index) => (
  //                 <React.Fragment key={frame._id}>
  //                   <Panel
  //                     id={`frame-panel-${frame._id}`}
  //                     order={index + 1}
  //                     className="panel panel-scroll"
  //                   >
  //                     {renderFrame(frame)}
  //                   </Panel>
  //                   {index < secondHalf.length - 1 && (
  //                     <PanelResizeHandle className="panel-resize-handle-vertical" />
  //                   )}
  //                 </React.Fragment>
  //               ))}
  //             </PanelGroup>
  //           </Panel>
  //           {/* Panel de DossierDashboard con animación */}
  //           {shouldRender && (
  //             <Panel
  //               id="dashboard-panel"
  //               order={framesCount + 1}
  //               className={`panel panel-scroll dashboard-panel-container`}
  //               style={{ width: "300px" }}
  //             >
  //               {/* Wrapper div para aplicar la animación */}
  //               <div
  //                 className={`dashboard-panel ${isOpen ? "open" : "closed"}`}
  //               >
  //                 <Suspense
  //                   fallback={
  //                     <div>
  //                       Cargando <LoadingSpinner />
  //                     </div>
  //                   }
  //                 >
  //                   <DossierDashboard
  //                     isOpen={isOpen}
  //                     onClose={() => setIsOpen(false)}
  //                     scope={state.selectedFrame?.scope || ""}
  //                     datacode={state.selectedFrame?.datacode || ""}
  //                     item={state.selectedFrame}
  //                     menuPosition={metaWindowMenuPosition}
  //                   />
  //                 </Suspense>
  //               </div>
  //             </Panel>
  //           )}
  //           <PanelResizeHandle className="panel-resize-handle-horizontal" />
  //         </PanelGroup>
  //       </Panel>
  //     </PanelGroup>
  //     {!isOpen && shouldShowMetaWindow && (
  //       <MetaWindow onClick={onClick} onClickError={onClickError} />
  //     )}
  //   </div>
  // );
};

View.propTypes = {
  vid: PropTypes.string,
};

export default View;
