import React, {
  createContext,
  useReducer,
  useContext,
  useState,
  useEffect
} from 'react';
import PropTypes from 'prop-types';
import { viewReducer } from '../reducers/viewReducer';

const initialState = {
  view: {},
  data: {},
  metaWindowData: {},
  users: [],
  selectedTask: '',
  loading: false,
  error: null,
};

export const ViewContext = createContext(initialState);

export const ViewContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(viewReducer, initialState);
  const [dossierCreated, setDossierCreated] = useState(false);

  useEffect(() => {
    if (dossierCreated) {
      const timer = setTimeout(() => {
        setDossierCreated(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [dossierCreated]);

  const value = {
    state,
    dispatch,
    dossierCreated,
    setDossierCreated,
  };

  return <ViewContext.Provider value={value}>{children}</ViewContext.Provider>;
};

ViewContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useViewContext = () => useContext(ViewContext);