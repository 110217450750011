import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types"; // Asegúrate de tener PropTypes importado
import "./TextArea.css";

const TextArea = ({
  name,
  type,
  placeholder,
  onChange,
  value,
  onBlur,
  disabled,
  defaultValue,
  label,
  styleTextArea,
  disableResizing = false,
  ...props
}) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current && !disableResizing) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight + 15
      }px`;
    }
  }, [value]);

  return (
    <div className="input-container">
      {label && <label className="input-label">{label}</label>}
      <textarea
        name={name}
        type={type || "text"}
        placeholder={placeholder || "text"}
        onChange={onChange}
        className="text-area-style"
        value={value}
        onBlur={onBlur}
        ref={textareaRef}
        disabled={disabled}
        defaultValue={defaultValue}
        style={styleTextArea}
        {...props}
      />
    </div>
  );
};

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  styleTextArea: PropTypes.object,
  disableResizing: PropTypes.bool,
};

export default TextArea;
