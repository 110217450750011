import React, { useEffect, useRef } from "react";
import "./RemovableItemList.css";
import PropTypes from "prop-types";

const RemovableItemList = ({ items, deleteItem }) => {
  const containerRef = useRef(null);

  // Cada vez que cambien las items, hacemos scroll hasta el final
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [items]);

  return (
    <div className="item-labels" ref={containerRef}>
      {items.map((item, index) => (
        <div key={index} className="item-item">
          <span className="item-remove" onClick={() => deleteItem(index)}>
            x
          </span>
          <span className="item-text" title={item.name}>
            {item.name}
          </span>
        </div>
      ))}
    </div>
  );
};

RemovableItemList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ),
  deleteItem: PropTypes.func,
};
export default RemovableItemList;
