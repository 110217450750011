// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divlink {
  cursor: pointer;
  text-decoration: underline;
}

.table-bancos {
  width: 100%;
}

.bancos-container-box-float {
  .box-float-footer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
}
.editable-input {
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  padding: 6px;
  font-family: Ping LCG;
  color: var(--font-color);
  font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Setup/Grupos/Bancos/BancosStyles.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE;IACE,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB;AACF;AACA;EACE,WAAW;EACX,sBAAsB;EACtB,6BAA6B;EAC7B,YAAY;EACZ,YAAY;EACZ,qBAAqB;EACrB,wBAAwB;EACxB,eAAe;AACjB","sourcesContent":[".divlink {\r\n  cursor: pointer;\r\n  text-decoration: underline;\r\n}\r\n\r\n.table-bancos {\r\n  width: 100%;\r\n}\r\n\r\n.bancos-container-box-float {\r\n  .box-float-footer {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-top: 10px;\r\n  }\r\n}\r\n.editable-input {\r\n  width: 100%;\r\n  box-sizing: border-box;\r\n  background-color: transparent;\r\n  border: none;\r\n  padding: 6px;\r\n  font-family: Ping LCG;\r\n  color: var(--font-color);\r\n  font-size: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
