const menuCanvaActionTypes = {
  SET_BACKUP_LOAD_ONCE: "SET_BACKUP_LOAD_ONCE",
  SET_MENU_DATA: "SET_MENU_DATA",
  SET_BACKUP_MENU_DATA: "SET_BACKUP_MENU_DATA",
  SET_LOADING_MENU_CANVA: "SET_LOADING_MENU_CANVA",
  SET_LIST_DATA: "SET_LIST_DATA",
  SET_CARD_DATA: "SET_CARD_DATA",
  SET_MODAL_VARIABLE: "SET_MODAL_VARIABLE",
  SET_ROLES_OPTIONS: "SET_ROLES_OPTIONS",
  UPDATE_MENU_TO_SETUP: "UPDATE_MENU_TO_SETUP",
};

export default menuCanvaActionTypes;
