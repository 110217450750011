import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./ExecutiveEdit.css";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import Input from "src/modules/components/common/Input/Input";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import RemovableItemList from "./RemovableItemList/RemovableItemList";
import DragFileBox from "src/modules/components/common/DragFileBox/DragFileBox";
import { Executive as ExecutiveAPI } from "src/lib/api";
import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider";
import Button from "src/modules/components/common/Button/Button";

const ExecutiveEdit = () => {
  const { executiveid } = useParams();
  const { setNavBarTitle } = useAppContext();
  const isFirstLoad = useRef(true);
  const initialData = useRef(null);

  const [data, setData] = useState({
    urls: [],
    documents: [],
    executiveprompt: "",
  });

  const [urlInputValue, setUrlInputValue] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const prevDataRef = useRef(data);
  const navigate = useNavigate();
  const returnToExecutives = () => setTimeout(() => navigate(-1), 500);

  useEffect(() => {
    ExecutiveAPI.read(executiveid).then((response) => {
      if (response) {
        setData(response.executive);
        setNavBarTitle(response.executive.name);
        initialData.current = response.executive;
        isFirstLoad.current = false;
      }
    });
  }, [executiveid]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isFirstLoad.current) {
        if (
          initialData.current &&
          JSON.stringify(initialData.current) !== JSON.stringify(data) &&
          JSON.stringify(prevDataRef.current) !== JSON.stringify(data)
        ) {
          ExecutiveAPI.create(data)
            .then(() => {
              prevDataRef.current = data;
            })
            .catch((error) =>
              addErrorMessageGlobal("Error al guardar: " + error)
            );
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [data, executiveid]);

  const addUrl = (newUrl) => {
    setErrorMsg("");
    setData((prev) => {
      if (prev.urls.some((urlObj) => urlObj.url === newUrl)) {
        setErrorMsg("La URL ya está en la lista.");
        return prev;
      }
      return {
        ...prev,
        urls: [...prev.urls, { url: newUrl, type: "page" }],
      };
    });
  };

  const removeUrl = (indexToRemove) => {
    setData((prev) => ({
      ...prev,
      urls: prev.urls.filter((_, i) => i !== indexToRemove),
    }));
  };

  const isValidUrl = (str) => {
    try {
      new URL(str);
      return true;
    } catch {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMsg("");
    // Verificar si la cadena ya incluye un protocolo (http:// o https://)
    let urlToAdd = urlInputValue.trim();
    if (!/^(?:f|ht)tps?:\/\//i.test(urlToAdd)) {
      urlToAdd = "http://" + urlToAdd;
    }
    if (isValidUrl(urlToAdd)) {
      addUrl(urlToAdd);
      setUrlInputValue("");
    } else {
      setErrorMsg("Debe introducir una URL válida.");
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const newFile = e.target.files[0];
    if (newFile) {
      setData((prev) => ({
        ...prev,
        documents: [...prev.documents, newFile],
      }));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      setData((prev) => ({
        ...prev,
        documents: [...prev.documents, droppedFile],
      }));
    }
  };

  const removeDocument = (indexToRemove) => {
    setData((prev) => ({
      ...prev,
      documents: prev.documents.filter((_, i) => i !== indexToRemove),
    }));
  };

  return (
    <div className="executive-edit">
      <div className="executive-edit__content">
        <div className="executive-edit__left-sidebar">
          <Button onClick={returnToExecutives} icon="arrow_back" />
        </div>
        <div className="executive-edit__editor">
          <div className="executive-edit__editor-title">Executiveprompt</div>
          <TextArea
            name={"Executiveprompt"}
            styleTextArea={{ height: "100%", resize: "none" }}
            disableResizing={true}
            placeholder="Introduzca aquí el executiveprompt para definir el comportamiento del agente"
            value={data.executiveprompt}
            onChange={(e) =>
              setData((prev) => ({ ...prev, executiveprompt: e.target.value }))
            }
          />
        </div>

        <div className="executive-edit__sidebar">
          <div className="executive-edit__sidebar-section executive-edit__sidebar-section--urls">
            <div className="executive-edit__sidebar-section-title">Webs</div>
            <div className="executive-edit__sidebar-section-content">
              <form
                onSubmit={handleSubmit}
                style={{ display: "flex", gap: 10 }}
              >
                <Input
                  type="text"
                  onChange={(e) => setUrlInputValue(e.target.value)}
                  value={urlInputValue || ""}
                  placeholder="Ingrese una URL y presione Enter"
                />
                <input type="submit" style={{ display: "none" }} />
                <Button
                  content={"Agregar"}
                  onClick={handleSubmit}
                  style={{ minWidth: "auto" }}
                />
              </form>

              {errorMsg && (
                <div className="executive-edit__sidebar-section-content-errormessage">
                  {errorMsg}
                </div>
              )}

              <RemovableItemList
                items={data.urls.map(({ url }) => ({ name: url }))}
                deleteItem={removeUrl}
              />
            </div>
          </div>

          <div className="executive-edit__sidebar-section executive-edit__sidebar-section--files">
            <div className="executive-edit__sidebar-section-title">
              Documents
            </div>
            <div className="executive-edit__sidebar-section-content">
              <DragFileBox
                handleFileChange={handleFileChange}
                handleDragOver={handleDragOver}
                handleDrop={handleDrop}
                style={{ minHeight: "auto" }}
              />

              <RemovableItemList
                items={data.documents.map((file) => ({ name: file.name }))}
                deleteItem={removeDocument}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExecutiveEdit;
