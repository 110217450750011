import React, { useEffect, useState } from "react";
import "./Setup.css";
import Button from "src/modules/components/common/Button/Button";
import Tabs from "src/modules/components/header/Tabs/Tabs";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { Setup as SetupAPI, View as ViewAPI } from "src/lib/api";
import { Icon } from "@mui/material";
import Bancos from "./Grupos/Bancos/Bancos";
import Credentials from "./Grupos/Bancos/Credenciales";
import ApiTab from "./Grupos/ApiTab/ApiTab";
import Agentes from "./Grupos/Agentes/Agentes";
import App from "./Grupos/App/App";
import General from "./Grupos/General/General";
import Aspecto from "./Grupos/Aspecto/Aspecto";
import Login from "./Grupos/Login/Login";
import Asistentes from "./Grupos/Asistentes/Asistentes";
import Roles from "./Grupos/Roles/Roles";
import FileSync from "./Grupos/FileSync/FileSync";
import { AspectoContextProvider } from "./Grupos/Aspecto/contexts/AspectoContextProvider";
import MenuCanva from "./Grupos/MenuCanva/MenuCanva";
import { MenuCanvaContextProvider } from "src/modules/contexts/MenuCanvaContextProvider";

const Setup = () => {
  const {
    setPublicSetup,
    setupData: setupInfo,
    setSetupData: setSetupInfo,
  } = useAppContext();

  const [isAccountSet, setIsAccountSet] = useState(false);
  const [viewsOptions, setViewsOptions] = useState([]);
  useEffect(() => {
    const fetchViews = async () => {
      let viewsRead = await ViewAPI.list();
      viewsRead = viewsRead.map((view) => {
        if (view.view) return view.view;
        else return view;
      });
      viewsRead = viewsRead.map((view) => {
        return {
          label: view.name,
          value: view.viewcode,
          public: view.public || false,
        };
      });
      setViewsOptions(viewsRead);
    };
    const readSetup = async () => {
      const setupRead = await SetupAPI.read();
      if (setupRead) setSetupInfo(setupRead);
    };
    readSetup();
    fetchViews();
  }, [setSetupInfo]);

  useEffect(() => {
    if (setupInfo.bank?.bankconfig === true) {
      setIsAccountSet(true);
    }
  }, [setupInfo.bank?.bankconfig]);

  const writeSetup = async () => {
    const setupNormalized = normalizeSetupInfo(setupInfo);
    const setupRead = await SetupAPI.write(setupNormalized);
    if (setupRead) {
      alert("Setup grabado correctamente");
      setSetupInfo(setupRead);
    }
    const readPublicSetup = await SetupAPI.public();
    if (readPublicSetup) setPublicSetup(readPublicSetup);
    setIsAccountSet(true);
  };

  const normalizeSetupInfo = (setup) => {
    const newSetup = {
      ...setup,
      login: setup.login.map((el) => {
        delete el.id;
        return el;
      }),
    };
    //delete ID from login

    return newSetup;
  };
  const tabsData = [
    {
      name: "General",
      component: (
        <div className="tab-distributer">
          <div className="setup-title" style={{ maxWidth: "50%" }}>
            General
            <Icon
              className="info-icon"
              title="Mas información"
              onClick={() => {
                window.open(
                  "https://valeria.moodrobotics.com/docs/setup/general",
                  "_blank"
                );
              }}
            >
              info_icon
            </Icon>
          </div>
          <General viewsOptions={viewsOptions} />
        </div>
      ),
      id: "general",
    },
    {
      name: "App",
      component: (
        <div className="tab-distributer">
          <div className="setup-title" style={{ maxWidth: "50%" }}>
            App
            <Icon
              className="info-icon"
              title="Mas información"
              onClick={() => {
                window.open(
                  "https://valeria.moodrobotics.com/docs/setup/app",
                  "_blank"
                );
              }}
            >
              info_icon
            </Icon>
          </div>
          <App setupInfo={setupInfo} setSetupInfo={setSetupInfo} />
        </div>
      ),
      id: "app",
    },
    {
      name: "Aspecto",
      component: (
        <AspectoContextProvider>
          <div className="tab-distributer">
            <div className="setup-title" style={{ maxWidth: "50%" }}>
              Aspecto
              <Icon
                className="info-icon"
                title="Mas información"
                onClick={() => {
                  window.open(
                    "https://valeria.moodrobotics.com/docs/setup/aspecto",
                    "_blank"
                  );
                }}
              >
                info_icon
              </Icon>
            </div>
            <Aspecto />
          </div>
        </AspectoContextProvider>
      ),
      id: "aspect",
    },
    {
      name: "Login",
      component: (
        <div className="tab-distributer">
          <div className="setup-title">
            Login
            <Icon
              className="info-icon"
              title="Mas información"
              onClick={() => {
                window.open(
                  "https://valeria.moodrobotics.com/docs/setup/login",
                  "_blank"
                );
              }}
            >
              info_icon
            </Icon>
          </div>
          <Login />
        </div>
      ),
      id: "login",
    },
    {
      name: "Menú",
      component: (
        <div className="tab-distributer">
          <div className="setup-title">
            Menú
            <Icon
              className="info-icon"
              title="Mas información"
              onClick={() => {
                window.open(
                  "https://valeria.moodrobotics.com/docs/setup/menu",
                  "_blank"
                );
              }}
            >
              info_icon
            </Icon>
          </div>
          <MenuCanvaContextProvider>
            <MenuCanva />
          </MenuCanvaContextProvider>
        </div>
      ),
      id: "menu",
    },
    {
      name: "Roles",
      component: (
        <div className="tab-distributer">
          <div className="setup-title">
            Roles
            <Icon
              className="info-icon"
              title="Mas información"
              onClick={() => {
                window.open(
                  "https://valeria.moodrobotics.com/docs/setup/roles",
                  "_blank"
                );
              }}
            >
              info_icon
            </Icon>
          </div>
          <Roles />
        </div>
      ),
      id: "roles",
    },
    {
      name: "Asistentes",
      component: (
        <div className="tab-distributer">
          <div className="setup-title">
            Asistentes
            <Icon
              className="info-icon"
              title="Mas información"
              onClick={() => {
                window.open(
                  "https://valeria.moodrobotics.com/docs/setup/asistentes",
                  "_blank"
                );
              }}
            >
              info_icon
            </Icon>
          </div>
          <Asistentes />
        </div>
      ),
      id: "asistentes",
    },
    {
      name: "Agentes",
      component: (
        <div className="tab-distributer">
          <div className="setup-title">
            Agentes
            <Icon
              className="info-icon"
              title="Mas información"
              onClick={() => {
                window.open(
                  "https://valeria.moodrobotics.com/docs/setup/agentes",
                  "_blank"
                );
              }}
            >
              info_icon
            </Icon>
          </div>
          <Agentes />
        </div>
      ),
      id: "agentes",
    },
    {
      name: "Bancos",
      component: (
        <div className="tab-distributer">
          <div className="setup-title">
            Bancos
            <Icon
              className="info-icon"
              title="Mas información"
              onClick={() => {
                window.open(
                  "https://valeria.moodrobotics.com/docs/setup/bancos",
                  "_blank"
                );
              }}
            >
              info_icon
            </Icon>
          </div>

          {isAccountSet ? <Bancos /> : <Credentials />}
        </div>
      ),
      id: "bancos",
    },
    {
      name: "API",
      component: (
        <div className="tab-distributer">
          <div className="setup-title">
            API
            <Icon
              className="info-icon"
              title="Mas información"
              onClick={() => {
                window.open(
                  "https://valeria.moodrobotics.com/docs/setup/api",
                  "_blank"
                );
              }}
            >
              info_icon
            </Icon>
          </div>
          <ApiTab />
        </div>
      ),
      id: "api",
    },
    {
      name: "FileSync",
      component: (
        <div className="tab-distributer">
          <div className="setup-title">
            FileSync
            {/* <Icon
              className="info-icon"
              title="Mas información"
              onClick={() => {
                window.open(
                  "https://valeria.moodrobotics.com/docs/setup/api",
                  "_blank"
                );
              }}
            >
              info_icon
            </Icon> */}
          </div>
          <FileSync />
        </div>
      ),
      id: "filesync",
    },
  ];

  return (
    <div className="setup-page">
      <div className="groupSetup">
        <div className="setup-container">
          <Tabs
            tabsData={tabsData}
            vertical
            saveButton={
              <Button
                onClick={writeSetup}
                className={"navbutton-save"}
                icon="save"
              />
            }
          />
          <div></div>
        </div>
        <br />
      </div>
      <br />
    </div>
  );
};

export default Setup;
