import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Movement {
  static async list(framecode, datacode, first = 0, pagination = 100) {
    const skip = 0;
    if (datacode && datacode !== "null") {
      datacode = `&dossiercode=${datacode}`;
    } else {
      datacode = "";
    }
    let response = await HttpMethods.request_get(
      // `/v1/movement/list/-/${framecode}/${first}/${pagination}?format=flat${datacode}`
      `/v1/movement/list/-/${framecode}/${skip}/${first}?format=flat${datacode}`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener los movements");
      response = [];
    }
    return response;
  }

  static async read(movementCode) {
    const response = await HttpMethods.request_get(
      `/v1/movement/read/${movementCode}`
    );
    return response;
  }

  static async update(movementCode, movementData) {
    const response = await HttpMethods.request_post(
      `/v1/movement/update/${movementCode}`,
      movementData
    );
    return response;
  }

  static async train_group(movementId, group, subgroup) {
    let response = await HttpMethods.request_get(
      `/v1/movement/group/${movementId}/${group}/${subgroup}`
    );
    if (response.error) {
      addErrorMessageGlobal(response.message);
      response = {};
    }
    return response;
  }
}
