import React, { useContext, useEffect, useState } from "react";
import ExecutivesLayout from "src/routes/Executives/Executives";
import { fetchListDataCanva } from "src/modules/actions/viewActions";
import { ViewContext } from "src/modules/contexts/ViewContextProvider";
import Input from "src/modules/components/common/Input/Input";
import { useMustache } from "src/modules/hooks/useMustache";
import { useLocation, useNavigate } from "react-router";
import Button from "src/modules/components/common/Button/Button";
import EditFrame from "../EditFrame";

const CanvaFrame = ({ frame, view }) => {
  const { state, dispatch } = useContext(ViewContext);
  const [searchText, setSearchText] = useState("");

  const mustache = useMustache();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const limit = 10_000;
    fetchListDataCanva(frame, limit, dispatch);
  }, [frame, dispatch]);

  if (state.loading) return <div>Loading...</div>;

  const data = state.data[frame._id];
  if (!data) return <div>No data found</div>;

  const filterExecutives = (data, searchText) => {
    if (!searchText) return data;
    const filteredExecutives = data.executives.filter((executive) =>
      executive.name.toLowerCase().includes(searchText.toLowerCase())
    );
    return { ...data, executives: filteredExecutives };
  };

  const filteredData = filterExecutives(data, searchText);

  const clickButton = (url) => {
    navigate(url);
  };

  const setView = async (view) => {
    fetchListDataCanva(frame, 10_000, dispatch).catch((err) => {
      if (err?.response?.status === 404 || err?.response?.status === 403) {
        alert(
          `Error al obtener la lista.\n Message: ${err.response.message.message} \n Código: ${err.response.status}`
        );
      }
    });
  };

  return (
    <>
      <EditFrame view={state.view} setView={setView} frame={frame} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        {frame.elements
          .filter((el) => el.type === "Button" && el.label === "Añadir")
          .map((element, index) => (
            <div
              key={`button-${index}`}
              className="row-element"
              style={{ width: "unset" }}
            >
              <Button
                key={`button-${index}`}
                onClick={() =>
                  clickButton(
                    mustache.replaceMustache(
                      element.link,
                      state.data[frame._id],
                      queryParams
                    )
                  )
                }
                icon={element.icon}
                content={element.label}
              />
            </div>
          ))}
        <Input
          onChange={(e) => setSearchText(e.target.value)}
          value={searchText}
          placeholder="Buscar..."
          name="search"
          label=""
          type="search"
        />
      </div>

      <ExecutivesLayout
        data={filteredData}
        frame={frame}
        view={view}
        setView={setView}
      />
    </>
  );
};

export default CanvaFrame;
