import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import constants from "src/definitions/constants";

const General = ({ viewsOptions }) => {
  const { setupData, setSetupData } = useAppContext();
  const [demoExample, setDemoExample] = useState("");
  const [numeroConvinaciones, setNumeroConvinaciones] = useState(0);
  const loggingOptions = constants.loggingOptions;
  // Function to initialize setup info
  const initializeSetupInfo = () => {
    const general = setupData.general || {};
    general.suffix = general.suffix ?? "#";
    general.slength = general.slength ?? "5";
    general.prefix = general.prefix ?? "";

    setSetupData({ ...setupData, general });
  };

  // Calculate demo example and combinations
  const calculateDemoAndCombinations = (general) => {
    const slength = general.slength ?? "5";
    const nCeros = "0".repeat(Number(slength));
    const formulaDemo =
      (general.prefix ?? "") + nCeros + (general.suffix ?? "#");
    setDemoExample(formulaDemo);

    const length = Number(slength);
    let convinaciones = isNaN(length) ? 1 : Math.pow(35, length);
    setNumeroConvinaciones(convinaciones);
  };

  useEffect(() => {
    if (!setupData.general) {
      initializeSetupInfo();
    } else {
      calculateDemoAndCombinations(setupData.general);
    }
  }, [setupData.general]);

  const handleInputChangeGeneral = (event) => {
    const { name, value } = event.target;

    if (value.length !== 0) {
      if (name === "suffix" && value && !/^[^+]{1}$/.test(value)) return;
      if (name === "prefix" && value && !/^[^+]{1,3}$/.test(value)) return;
      if (name === "slength" && value && !/^[3-9]$/.test(value)) return;
    }

    const newGeneral = {
      ...setupData.general,
      [name]: value,
    };

    setSetupData((prevValues) => ({
      ...prevValues,
      general: newGeneral,
    }));

    calculateDemoAndCombinations(newGeneral);
  };

  const onSelectChangeSingleDefaultView = (event) => {
    const values = event?.value;
    setSetupData((prev) => ({
      ...prev,
      general: {
        ...prev.general,
        defaultview: values,
      },
    }));
  };

  const onSelectChangeSingleLoggedDefaultView = (event) => {
    const values = event?.value;
    setSetupData((prev) => ({
      ...prev,
      general: {
        ...prev.general,
        loggeddefaultview: values,
      },
    }));
  };

  const defaultLoggedViewOptions = () => {
    const views = viewsOptions;
    const activityView = {
      value: "useractivity",
      label: "Actividad",
      public: false,
    };
    views.unshift(activityView);
    return views;
  };

  const options = defaultLoggedViewOptions();

  const onSelectChangeMinimalLogging = (event) => {
    const value = event?.value;

    setSetupData((prev) => {
      return {
        ...prev,
        general: {
          ...prev.general,
          minimallogging: value,
        },
      };
    });
  };

  return (
    <>
      <div className="tab-container tab-container-section">
        <Input
          name="companyname"
          type="text"
          placeholder="MoodRob"
          value={setupData.general?.companyname || ""}
          onChange={handleInputChangeGeneral}
          label="Nombre de la compañía:"
        />
        <Select
          isSearchable={true}
          isClearable={false}
          name="defaultView"
          options={viewsOptions.filter((view) => view.public === true)}
          placeholder=""
          onChange={onSelectChangeSingleDefaultView}
          value={viewsOptions.find(
            (item) => item.value === setupData.general?.defaultview
          )}
          label="Página por defecto:"
        />
        <Select
          isSearchable={true}
          isClearable={false}
          name="loggeddefaultview"
          options={options}
          placeholder=""
          onChange={onSelectChangeSingleLoggedDefaultView}
          value={options.find(
            (item) => item.value === setupData.general?.loggeddefaultview
          )}
          label="Página por defecto con sesión iniciada:"
        />
        <Input
          name="developmode"
          type="checkbox"
          checked={setupData.general?.developmode || false}
          onChange={() => {
            const newGeneral = {
              ...setupData.general,
              developmode: !setupData.general.developmode,
            };
            setSetupData((prev) => ({
              ...prev,
              general: newGeneral,
            }));
            calculateDemoAndCombinations(newGeneral);
          }}
          label="Modo de desarrollo:"
        />
        <Input
          name="debugmode"
          type="checkbox"
          checked={setupData.general?.debugmode || false}
          onChange={() => {
            const newGeneral = {
              ...setupData.general,
              debugmode: !setupData.general.debugmode,
            };
            setSetupData((prev) => ({
              ...prev,
              general: newGeneral,
            }));
            calculateDemoAndCombinations(newGeneral);
          }}
          label="Modo de debug:"
        />
        <Select
          isSearchable={true}
          isClearable={false}
          name="minimallogging"
          options={loggingOptions}
          placeholder=""
          onChange={onSelectChangeMinimalLogging}
          value={loggingOptions.find(
            (item) => item.value === setupData.general?.minimallogging
          )}
          label="Registro minimo"
        />
      </div>
      <div className="tab-container-general-filetypes">
        <div className="tab-container-general-filetypes-title">Scode</div>
        <div className="tab-container-general">
          <div className="tab-container-general-form">
            <Input
              name="prefix"
              type="text"
              placeholder=""
              value={setupData.general?.prefix}
              onChange={handleInputChangeGeneral}
              label="prefix:"
              tooltip="Se requiere al menos un caracter, se excluye el simbolo +"
            />
            <Input
              name="suffix"
              type="text"
              placeholder=""
              value={setupData.general?.suffix}
              onChange={handleInputChangeGeneral}
              label="Suffix:"
              tooltip="Se requiere al menos un caracter, se excluye el simbolo +"
            />
            <Input
              name="slength"
              type="text"
              placeholder=""
              value={setupData.general?.slength}
              onChange={handleInputChangeGeneral}
              label="SLength:"
              tooltip="Se acepta solo un número, que debe de estar entre 3-9"
            />
          </div>
          <div className="tab-container-general-demo">
            <div>
              <Input
                name="demo"
                type="text"
                placeholder={demoExample}
                disabled={true}
              />
              <p>Cantidad de combinaciones: {numeroConvinaciones}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// Validación de prop-types
General.propTypes = {
  viewsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      public: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export default General;
