import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Setup } from 'src/lib/api';
import { useSessionStorage } from "src/modules/hooks/useSessionStorage";
import { useCookie } from "src/modules/hooks/useCookie";
import setupDefault from 'src/_publicfiles/setup.json';
import { ViewContextProvider } from "./ViewContextProvider";

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
  const [userSession, setUserSession] = useCookie('user_session', { role: "guest", token: null });

  const [name, setName] = useSessionStorage('name', "");
  const [email, setEmail] = useSessionStorage('email', "");
  const [controlUserActivity, setControlUserActivity] = useSessionStorage('controlUserActivity', null);

  const [publicSetup, setPublicSetup] = useState({});
  const [setupData, setSetupData] = useState({});
  const [isServerUp, setIsServerUp] = useState(true);
  const [navBarTitle, setNavBarTitle] = useState("titulo");

  useEffect(() => {
    async function fetchSetup() {
      try {
        let response = await Setup.public();
        response = fillUndefinedProps(response, setupDefault);
        setPublicSetup(response);
      } catch (error) {
        setIsServerUp(false);
        alert('Error al conectar al servidor');
      }
    }
    fetchSetup();
    document.title = publicSetup.title ? publicSetup.title : "ValerIA";
  }, [userSession.token]);

  const refreshSetup = async () => {
    try {
      let response = await Setup.public();
      response = fillUndefinedProps(response, setupDefault);
      setPublicSetup(response);
      setIsServerUp(true);
    } catch (error) {
      setIsServerUp(false);
      alert('Error al conectar al servidor');
    }
  };

  const logout = () => {
    sessionStorage.clear();
    setUserSession({ role: "guest", token: null });
  };

  return (
    <AppContext.Provider value={{
      name, setName, email, setEmail, role: userSession.role,
      token: userSession.token, publicSetup, setPublicSetup,
      isServerUp, setIsServerUp, navBarTitle, setNavBarTitle,
      setupData, setSetupData, refreshSetup, controlUserActivity,
      setControlUserActivity, logout, userSession, setUserSession,
    }}>
      <ViewContextProvider>
        {children}
      </ViewContextProvider>
    </AppContext.Provider>
  );
};


AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

function fillUndefinedProps(response, setupDefault) {
  const itemToReturn = {};
  for (let key in setupDefault) {
    itemToReturn[key] = response[key] || setupDefault[key];
  }
  return { ...itemToReturn, ...response };
}

export const useAppContext = () => useContext(AppContext);
