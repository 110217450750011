// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container-executive-title {
  display: flex;
  align-items: center;
  /* flex-basis: 156px; */
  padding-top: 4px;
  margin-right: 24px;
  min-width: 150px;
  max-width: 150px;
}
`, "",{"version":3,"sources":["webpack://./src/routes/ExecutiveManagement/ExecutiveManagement.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".input-container-executive-title {\r\n  display: flex;\r\n  align-items: center;\r\n  /* flex-basis: 156px; */\r\n  padding-top: 4px;\r\n  margin-right: 24px;\r\n  min-width: 150px;\r\n  max-width: 150px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
