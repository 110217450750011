import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types"; // Importar PropTypes para la validación
import "./ColumnFilter.css";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import searchTextHelper from "src/modules/helpers/searchTextHelper";

function ColumnFilter({
  column,
  data,
  activeFilters,
  setActiveFilters,
  selectedValues,
  setSelectedValues,
}) {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];

  const valueCounts = useMemo(() => {
    if (!data || data.length === 0) return {};
  
    return data.reduce((counts, item) => {
      let value = item[removeMustache(column.variable)];
      
      if (value === null || value === undefined || value === "") {
        value = "-";
      }
  
      let displayValue = value;
      const dataType = getDataType(data, column.variable);
  
      if (dataType === "fecha" && value !== "-") {
        const date = new Date(value);
        const month = months[date.getMonth()];
        const year = date.getFullYear().toString();
        displayValue = `${month} ${year}`;
      }
  
      if (!counts[displayValue]) {
        counts[displayValue] = { value, count: 0 };
      }
      counts[displayValue].count += 1;
  
      return counts;
    }, {});
  }, [data, column.variable]);
  
  

  const uniqueValues = useMemo(() => {
    return Object.keys(valueCounts).sort(
      (a, b) => valueCounts[b].count - valueCounts[a].count
    );
  }, [valueCounts]);
  

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchText, setSearchText] = useState("");

  // Maneja el width y height del filtro
  const filterContentRef = useRef(null);
  const heightRef = useRef(null);
  const widthRef = useRef(null);

  useEffect(() => {
    let resizeTimeout;
    const setFilterContentDimensions = () => {
      if (resizeTimeout) cancelAnimationFrame(resizeTimeout);
      resizeTimeout = requestAnimationFrame(() => {
        if (filterContentRef.current) {
          heightRef.current = filterContentRef.current.offsetHeight;
          widthRef.current = filterContentRef.current.offsetWidth;
          filterContentRef.current.style.minHeight = `${heightRef.current}px`;
          filterContentRef.current.style.minWidth = `${widthRef.current}px`;
        }
      });
    };
  
    setFilterContentDimensions();
    window.addEventListener("resize", setFilterContentDimensions);
    return () => window.removeEventListener("resize", setFilterContentDimensions);
  }, [isFilterOpen]);
  

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleOptionClick = (selectedValue) => {
    let selectedValueString = selectedValue.value.toString();
    if (selectedValueString === "-") {
      selectedValueString = "";
    }
    setSelectedValues((prevSelectedValues) => {
      const updatedSelectedValues = [...prevSelectedValues];
      const index = updatedSelectedValues.findIndex(
        (item) => item.name === column.variable
      );
  
      if (index === -1) {
        updatedSelectedValues.push({
          name: column.variable,
          checks: [selectedValueString],
        });
      } else {
        const checks = updatedSelectedValues[index].checks;
        const checkIndex = checks.indexOf(selectedValueString);
  
        checkIndex !== -1 ? checks.splice(checkIndex, 1) : checks.push(selectedValueString);
  
        if (checks.length === 0) updatedSelectedValues.splice(index, 1);
      }
  
      setActiveFilters((prevActiveFilters) => {
        const updatedFilters = { ...prevActiveFilters };
  
        if (updatedSelectedValues.length > 0) {
          updatedFilters[column.variable] = updatedSelectedValues.find(
            (item) => item.name === column.variable
          )?.checks ?? [];
        } else {
          delete updatedFilters[column.variable];
        }
  
        return updatedFilters;
      });
  
      return updatedSelectedValues;
    });
  };
  
  
  
  const toggleFilterMenu = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const closeFilterMenu = () => {
    setIsFilterOpen(false);
  };

  const isActive = selectedValues.some(
    (item) => item.name === column.variable && item.checks.length > 0
  );

  return (
    <div className={`column-filter`}>
      <div className="filter-button-container">
        <Button
          onClick={toggleFilterMenu}
          icon="Filter Alt Outlined"
          className={`button-filter${isActive ? "--active" : ""}`}
        />
        {isFilterOpen && (
          <div className="filter-menu" onMouseLeave={closeFilterMenu}>
            {uniqueValues.length > 10 && (
              <div className="filter-searchBar">
                <Input
                  onChange={handleSearchChange}
                  value={searchText}
                  placeholder="Buscar..."
                  name="search"
                  label=""
                  type="search"
                />
              </div>
            )}
            <div className="filter-content" ref={filterContentRef}>
              {searchTextHelper(uniqueValues, searchText).map((value) => (
                <div
                  key={value}
                  className="filter-option"
                  onClick={() => handleOptionClick(valueCounts[value])}
                >
                  <div className="filter-option-checkbox">
                    <input
                      type="checkbox"
                      
                      checked={selectedValues.some(
                        (item) =>
                          item.name === column.variable &&
                          item.checks.includes(
                            value === "-" ? "" : value.toString()
                          )
                      )}
                      readOnly
                    />
                  </div>
                  <div className="filter-option-row" title={value}>
                    {value}
                  </div>
                  <div className="filter-option-count">
                    ({valueCounts[value].count})
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const getDataType = (data, prop) => {
  // Función auxiliar para verificar si un dato es de tipo fecha
  const isDate = (value) => {
    //1970-01-01T00:00:00.000Z,  1970-01-01,  1970/01/01, 01/01/1970
    const dateFormats = [
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/,
      /^\d{4}-\d{2}-\d{2}$/,
      /^\d{4}\/\d{2}\/\d{2}$/,
      /^\d{2}\/\d{2}\/\d{4}$/,
    ];

    for (const format of dateFormats) {
      if (format.test(value)) {
        return true;
      }
    }

    return false;
  };

  if (!data || data.length === 0) return "general";

  for (const item of data) {
    const value = item[removeMustache(prop)];
    if (value !== undefined) return isDate(value) ? "fecha" : "general";
  }

  return "general";
};

const removeMustache = (str) => {
  if (!str || typeof str !== "string") return "";
  return str.slice(2, -2);
};

// Agregar validación de PropTypes
ColumnFilter.propTypes = {
  column: PropTypes.shape({
    variable: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeFilters: PropTypes.object.isRequired,
  setActiveFilters: PropTypes.func.isRequired,
  selectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      checks: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  setSelectedValues: PropTypes.func.isRequired,
};

export default ColumnFilter;
