import React, { useState } from "react";
import { Icon } from "@mui/material";
import "../Input.css";

const PasswordInput = ({ ...inputProps }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  
  const handleToggle = () => setPasswordShown((prev) => !prev);

  return (
    <>
      <input {...inputProps} type={passwordShown ? "text" : "password"} />
      <Icon onClick={handleToggle} className="eye" size={25}>
        {passwordShown ? "visibility" : "visibility_off"}
      </Icon>
    </>
  );
};

export default PasswordInput;
