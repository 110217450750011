import React, { useContext } from "react";
import PropTypes from "prop-types";
import Button from "src/modules/components/common/Button/Button";
import { MenuCanvaContext } from "src/modules/contexts/MenuCanvaContextProvider";
import menuCanvaConstants from "../MenuCanvaConstants";
import {
  deleteMenuCardAtList,
  setMenuCardData,
  setMenuListData,
  setupDragAndDropCards,
  setVariableModal,
} from "src/modules/actions/menuCanvaActions";

const MenuCanvaCard = ({ data, idMenuList, indexMenuCard }) => {
  const { VAR_EDIT_MENUCARD } = menuCanvaConstants;
  const { state, dispatch } = useContext(MenuCanvaContext);
  const { menuData } = state;
  const idMenuCard = data.id;
  const listData = menuData.find((el) => el.id === idMenuList);
  return (
    <div
      className="menu-card__container"
      draggable
      data-dragdrop-index={indexMenuCard} // Añadimos el índice para identificafFr la tarjeta
      data-dragdrop-object={`${idMenuList}-${data.id}`} // Identificamos la tarjeta
      onDragStart={() => setupDragAndDropCards(dispatch, menuData)}
    >
      <div className="menu-card__container-title">{data.title}</div>
      <div className="menu-card__container-buttons">
        <Button
          onClick={() => {
            setMenuListData(dispatch, listData);
            setMenuCardData(dispatch, data);
            setVariableModal(dispatch, VAR_EDIT_MENUCARD);
          }}
          icon="edit"
          style={{ minWidth: "0px" }}
        />
        <Button
          onClick={() =>
            deleteMenuCardAtList(dispatch, menuData, idMenuList, idMenuCard)
          }
          icon="delete"
          style={{ minWidth: "0px" }}
        />
      </div>
    </div>
  );
};

export default MenuCanvaCard;

MenuCanvaCard.propTypes = {
  data: PropTypes.object.isRequired,
  setupDragAndDropCards: PropTypes.func.isRequired,
  indexMenuList: PropTypes.number.isRequired,
  indexMenuCard: PropTypes.number.isRequired,
  idMenuList: PropTypes.string.isRequired,
  handleDeleteMenuCard: PropTypes.func.isRequired,
  handleEditMenuCard: PropTypes.func.isRequired,
};
