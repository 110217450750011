// useSetNavbarTitle.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const useSetNavbarTitle = (title = null) => {
  const location = useLocation();
  const { setNavBarTitle, publicSetup, setupData } = useAppContext();

  useEffect(() => {
    // Se actualiza el título solo si la URL no contiene parámetros (query strings)
    if (!location.search) {
      if (title) {
        // Si se pasó un título explícito, se utiliza ese
        setNavBarTitle(title);
      } else {
        // Primero se busca en publicSetup.menu
        let foundItem;
        if (publicSetup && Array.isArray(publicSetup.menu)) {
          foundItem = publicSetup.menu.find(
            (item) => item.url === location.pathname
          );
        }
        // Si no se encontró en publicSetup, se busca en setupData.menu
        if (!foundItem && setupData && Array.isArray(setupData.menu)) {
          foundItem = setupData.menu.find(
            (item) => item.url === location.pathname
          );
        }
        // Si se encontró el elemento, se actualiza el título
        if (foundItem) {
          setNavBarTitle(foundItem.title);
        }
      }
    }
  }, [location, title, setNavBarTitle, publicSetup, setupData]);
};

export default useSetNavbarTitle;
