import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "src/modules/components/common/Table/Table";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import FormGenerator from "src/modules/components/FormGenerator/FormGenerator";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { Setup as SetupAPI } from "src/lib/api";
import Button from "src/modules/components/common/Button/Button";
const varAddRole = "addRole";
const varEditRole = "editRole";
const rolDefaultValues = {
  name: "",
  security: [], //"owner", "gestor",
  filter: [],
};
const securityOptions = [
  { value: "owner", label: "owner" },
  { value: "gestor", label: "gestor" },
  { value: "fullacces", label: "fullacces" },
];
const filterOptions = [
  { value: "read", label: "read" },
  { value: "write", label: "write" },
  { value: "delete", label: "delete" },
];

const StructureMenuRoles = ({ editedItems, dispatch }) => {
  const { setupData, setSetupData } = useAppContext();
  const [rolesSetup, setRolesSetup] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [rolesFileTypes, setRolesFiletypes] = useState([]);
  const [roleEditingValues, setRoleEditingValues] = useState(null); //{}

  const rolesOptions = rolesSetup?.map((rol) => {
    return { label: rol.name, value: rol.name };
  });

  useEffect(() => {
    const result = editedItems.filetype.header.roles.map((el) => {
      return { ...el, id: crypto.randomUUID() };
    });
    setRolesFiletypes(result);
  }, [editedItems.filetype.header.roles]);

  useEffect(() => {
    if (!setupData.roles) {
      SetupAPI.read().then((res) => {
        setSetupData(res);
      });
    }
  }, []);
  useEffect(() => {
    setRolesSetup(setupData.roles);
  }, [setupData]);

  const updateHeaderFileType = (key, value) => {
    const newHeader = {
      ...editedItems.filetype.header,
      [key]: value,
    };
    dispatch({
      type: "UPDATE_HEADER",
      payload: newHeader,
    });
  };
  const onSubmitNewRole = (e) => {
    if (!e["name"] || e["security"].length === 0 || e["filter"].lenth === 0) {
      alert("Complete todos los campos");
      return;
    }
    const newRoles = [...rolesFileTypes, e];
    updateHeaderFileType("roles", newRoles);
    setShowModal(false);
  };

  const onSubmitEditRole = (e) => {
    if (!e["name"] || e["security"].length === 0 || e["filter"].lenth === 0) {
      alert("Complete todos los campos");
      return;
    }
    const copyRoles = rolesFileTypes;
    const indexTargetRole = copyRoles.findIndex((el) => el.id === e.id);
    copyRoles[indexTargetRole] = e;
    updateHeaderFileType("roles", copyRoles);
    setShowModal(false);
  };
  const deleteRow = (e) => {
    const idElement = e.id;
    const newRolesFileTypes = rolesFileTypes.filter((el) => {
      el.id !== idElement;
    });
    updateHeaderFileType("roles", newRolesFileTypes);
  };

  const editRow = (e) => {
    setShowModal(varEditRole);
    setRoleEditingValues(e);
  };

  const columns = [
    {
      label: "Nombre",
      key: "name",
      type: "text",
    },
    {
      label: "Seguridad",
      key: "security",
      type: "text",
    },
    {
      label: "Filter",
      key: "filter",
      type: "text",
    },
    {
      label: "Acciones",
      key: "table_actions",
      type: "actions",
      buttons: [
        {
          icon: "EditIcon",
          onClick: (e) => {
            editRow(e);
          },
        },
        {
          icon: "DeleteIcon",
          onClick: (e) => {
            deleteRow(e);
          },
        },
      ],
    },
  ];

  const elements = [
    {
      name: "name",
      component: "select",
      type: "text",
      label: "Nombre",
      key: "name",
      placeholder: "",
      options: rolesOptions,
    },
    {
      name: "security",
      component: "multiselect",
      type: "text",
      label: "Seguridad",
      key: "security",
      placeholder: "",
      options: securityOptions,
    },
    {
      name: "filter",
      component: "multiselect",
      type: "text",
      label: "Filtro",
      key: "filter",
      placeholder: "",
      options: filterOptions,
    },
  ];

  return (
    <div className="dossier-dashboard__section-container">
      <h3>Roles</h3>
      <p>Indica que roles pueden acceder al filetype y con que permisos</p>
      <div className="structure-body__value">
        <div
          className="structure-body-buttons__value"
          style={{
            display: "flex",
            gap: "20px",
            justifyContent: "flex-end",
          }}
        >
          <Button
            content="Añadir nuevo rol"
            onClick={() => setShowModal(varAddRole)}
            tooltip="Añadir nuevo rol"
          />
        </div>
        <hr></hr>
        {showModal === varAddRole && (
          <BoxFloat
            title="Agregar nuevo rol"
            onClickExit={() => setShowModal(null)}
            onClose={() => setShowModal(null)}
          >
            <FormGenerator
              form={{ elements: elements }}
              onSubmit={onSubmitNewRole}
              initialValues={rolDefaultValues}
            />
          </BoxFloat>
        )}
        {showModal === varEditRole && (
          <BoxFloat
            title="Editando rol"
            onClickExit={() => setShowModal(null)}
            onClose={() => setShowModal(null)}
          >
            <FormGenerator
              form={{ elements: elements }}
              onSubmit={onSubmitEditRole}
              initialValues={roleEditingValues}
            />
          </BoxFloat>
        )}
        <Table columns={columns} data={rolesFileTypes} />
      </div>
    </div>
  );
};

// Validación de props
StructureMenuRoles.propTypes = {
  editedItems: PropTypes.shape({
    filetype: PropTypes.shape({
      header: PropTypes.shape({
        roles: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            security: PropTypes.arrayOf(PropTypes.string).isRequired,
            filter: PropTypes.arrayOf(PropTypes.string).isRequired,
          })
        ),
      }),
      structure: PropTypes.array,
    }),
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default StructureMenuRoles;
