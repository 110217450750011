import { API_URL } from "../../config.js";

export class SSEClient {
    static eventSource = null;
    static isConnected = false;
    static isConnecting = false;
    static subscribers = [];

    static subscribe({ next, error, complete }) {
        if (this.isConnecting) {
            console.warn("SSEClient: Conexión en proceso. Esperando para agregar suscriptores.");
            return () => {}; // Retorna no-op mientras se establece la conexión
        }

        if (this.isConnected) {
            console.warn("SSEClient: Ya existe una conexión activa. Agregando suscriptor.");
        } else {
            this.connect(); // Inicia conexión si no está activa
        }

        const subscriber = { next, error, complete };
        this.subscribers.push(subscriber);
        console.log('SSEClient: Suscriptor añadido. Total suscriptores:', this.subscribers.length);

        return () => this.unsubscribe(subscriber); // Retorna función de desuscripción
    }

    static unsubscribe(subscriber) {
        this.subscribers = this.subscribers.filter(sub => sub !== subscriber);
        console.log('SSEClient: Suscriptor eliminado. Total suscriptores:', this.subscribers.length);

        if (this.subscribers.length === 0) {
            this.disconnect(); // Cierra conexión si no hay más suscriptores
        }
    }

    static connect() {
        if (this.isConnected || this.isConnecting) {
            console.warn("SSEClient: Ya existe una conexión o está en proceso de establecerse.");
            return;
        }

        const token = this.getToken();
        if (!token) {
            console.error("SSEClient: No se encontró un token válido. Conexión cancelada.");
            return;
        }

        const sseUrl = `${API_URL}/v1/global/notifications/?TOKEN=${encodeURIComponent(token)}`;
        this.isConnecting = true; // Marca como conexión en proceso

        this.eventSource = new EventSource(sseUrl);

        this.eventSource.onopen = () => {
            this.isConnected = true;
            this.isConnecting = false;
            console.log("SSEClient: Conexión SSE establecida.");
        };

        this.eventSource.onmessage = (event) => {
            const data = JSON.parse(event.data);
            this.subscribers.forEach(sub => sub.next && sub.next(data));
        };

        this.eventSource.onerror = (event) => {
            console.error("SSEClient: Error en la conexión SSE.", event);
            this.isConnected = false;
            this.isConnecting = false;
            this.subscribers.forEach(sub => sub.error && sub.error(new Error("Error en la conexión SSE.")));
            this.disconnect();
        };

        this.eventSource.onclose = () => {
            this.isConnected = false;
            this.isConnecting = false;
            this.subscribers.forEach(sub => sub.complete && sub.complete());
            console.log("SSEClient: Conexión SSE cerrada.");
        };
    }

    static disconnect() {
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = null;
            this.isConnected = false;
            this.isConnecting = false;
            console.log("SSEClient: Conexión SSE cerrada.");
        }
    }

    static getToken() {
        const nameEQ = "user_session=";
        const ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);

            if (c.indexOf(nameEQ) === 0) {
                try {
                    const userSession = JSON.parse(c.substring(nameEQ.length, c.length));
                    return userSession.token ? userSession.token : null;
                } catch (error) {
                    return null;
                }
            }
        }

        return null;
    }
}
