import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Select from "src/modules/components/common/Select/Select";
import Button from "src/modules/components/common/Button/Button";

const NewBankModal = ({
  countryOptions,
  institutions,
  isFetchingInstitutions,
  selectedBank,
  onCountryChange,
  onBankChange,
  onClose,
  onSave,
}) => {
  const [validationLink, setValidationLink] = useState(null);
  const [secondsLeft, setSecondsLeft] = useState(10);

  useEffect(() => {
    if (validationLink) {
      setSecondsLeft(10);
      const intervalId = setInterval(() => {
        setSecondsLeft((prevSeconds) => {
          if (prevSeconds <= 1) {
            clearInterval(intervalId);
            window.location.href = validationLink;
            return 0;
          }
          return prevSeconds - 1;
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [validationLink]);

  const handleSave = async () => {
    const result = await onSave();
    const accounts = result?.bank?.accounts;
    if (accounts && accounts.length > 0) {
      const lastAccount = accounts[accounts.length - 1];
      setValidationLink(lastAccount.validationlink);
    }
  };

  const handleRedirect = () => {
    window.location.href = validationLink;
  };

  return (
    <BoxFloat
      title="Añadir Banco"
      onClickExit={onClose}
      footer={
        <>
          {!validationLink && selectedBank.bank && (
            <Button onClick={handleSave} content="Buscar cuenta" />
          )}
          {validationLink && (
            <div
              style={{
                marginTop: "10px",
                textAlign: "center",
                maxWidth: "600px",
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <p>
                Para continuar, lo redirigiremos de forma segura a GoCardless,
                donde podrá ingresar sus credenciales bancarias para vincular su
                cuenta con nuestra aplicación.
              </p>
              <p>
                {`Será redirigido automáticamente en ${secondsLeft} 
                ${secondsLeft === 1 ? "segundo" : "segundos"}, o haga clic en
                "Redirigir Ahora" para continuar de inmediato.`}
              </p>
              <Button onClick={handleRedirect} content="Redirigir Ahora" />
            </div>
          )}
        </>
      }
    >
      <Select
        isSearchable
        name="country"
        options={countryOptions}
        placeholder="Spain"
        onChange={onCountryChange}
        label="País"
      />
      {selectedBank.country &&
        !isFetchingInstitutions &&
        institutions.length === 0 && (
          <div style={{ marginTop: "8px", fontSize: "14px", color: "red" }}>
            No existen bancos para este país.
          </div>
        )}
      {institutions.length > 0 && (
        <Select
          isSearchable
          name="banks"
          options={institutions.map((institution) => ({
            value: institution.id,
            label: institution.name,
          }))}
          placeholder="Banco"
          onChange={onBankChange}
          label="Bancos"
        />
      )}
    </BoxFloat>
  );
};

NewBankModal.propTypes = {
  countryOptions: PropTypes.array.isRequired,
  institutions: PropTypes.array.isRequired,
  isFetchingInstitutions: PropTypes.bool.isRequired,
  selectedBank: PropTypes.object.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  onBankChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default NewBankModal;
