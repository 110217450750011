import React, { useEffect, useState } from "react";
import MovimientosLeftTab from "./MovimientosLeftTab";
import MovimientosRighTab from "./MovimientosRighTab";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const initialMovData = {
  way: [],
  type: [],
  service: [],
  group: [],
};

const Movimientos = () => {
  const [movementData, setMovementData] = useState(initialMovData);
  const { setupData, setSetupData } = useAppContext();

  useEffect(() => {
    if (setupData) {
      const { way, type, service, group } =
        setupData.movement || initialMovData;
      setMovementData({ way, type, service, group });
    }
  }, []);
  useEffect(() => {
    setSetupData((prev) => ({
      ...prev,
      movement: {
        way: movementData.way,
        type: movementData.type,
        service: movementData.service,
        group: movementData.group,
      },
    }));
  }, [movementData]);
  const [selectedItem, setSelectedItem] = useState({
    category: null,
    index: null,
  });
  const [selectedTabId, setSelectedTabId] = useState("way");

  const handleSelectItem = (category, index) => {
    setSelectedItem({ category, index });
    // Cambiar la pestaña según la categoría
    switch (category) {
      case "way":
        setSelectedTabId("way");
        break;
      case "type":
        setSelectedTabId("formas");
        break;
      case "service":
        setSelectedTabId("servicios");
        break;
      case "group":
        setSelectedTabId("grupos");
        break;
      default:
        break;
    }
  };

  return (
    <div className="movimientos-container" style={{ display: "flex" }}>
      <div className="movimientos-left" style={{ flex: 1, padding: "1rem" }}>
        <MovimientosLeftTab
          movementData={movementData}
          onSelectItem={handleSelectItem}
        />
      </div>
      <div
        className="movimientos-right"
        style={{ flex: 1, padding: "1rem", borderLeft: "1px solid #ccc" }}
      >
        <MovimientosRighTab
          movementData={movementData}
          setMovementData={setMovementData}
          selectedItem={selectedItem}
          selectedTabId={selectedTabId}
          setSelectedTabId={setSelectedTabId}
          clearSelectedItem={() =>
            setSelectedItem({ category: null, index: null })
          }
        />
      </div>
    </div>
  );
};

export default Movimientos;
