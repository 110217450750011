import React, { useEffect, useState } from "react";
import "../Agentes/Agentes.css";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Select from "src/modules/components/common/Select/Select";
import constants from "src/definitions/constants";
import Icon from "@mui/material/Icon";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const tiposProveedores = Object.entries(constants.loginProviders).map(
  ([key, value]) => ({
    value: value.value,
    label: value.label,
  })
);

const Asistentes = () => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();
  const [assistants, setAssistants] = useState(setupInfo?.assistants || []);
  // const [assistants, setAssistants] = useState(initialValues);
  const [selectedAssistant, setSelectedAssistant] = useState(null);
  const [openBox, setOpenBox] = useState(false);
  const [editedAssistant, setEditedAssistant] = useState(null);
  const [newAssistant, setNewAssistant] = useState(false);

  useEffect(() => {
    setSetupInfo((prev) => {
      return { ...prev, assistants: assistants };
    });
  }, [setAssistants, setSetupInfo, assistants]);

  useEffect(() => {
    if (editedAssistant?.provider === "google") {
      setEditedAssistant((prev) => ({
        ...prev,
        scope:
          "https://www.googleapis.com/auth/userinfo.email https://mail.google.com",
      }));
    } else {
      setEditedAssistant((prev) => ({
        ...prev,
        scope: "",
      }));
    }
  }, [editedAssistant?.provider]);
  const handleAssistantClick = (index) => {
    setSelectedAssistant(assistants[index]);
    setEditedAssistant({ ...assistants[index] });
    setOpenBox(true);
  };

  const handleEditAssistant = (e) => {
    setEditedAssistant((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleEditClientID = (e) => {
    e.target.value = e.target.value.trim();
    handleEditAssistant(e);
  };

  const agregarElemento = () => {
    const item = {
      id: crypto.randomUUID(),
      name: "",
      description: "",
      email: "",
      avatar: "",
      provider: "",
      clientID: "",
      secretID: "",
      redirect_uri: "",
      scope: "",
      // code: "",
      // token: "",
      // refreshToken: "",
    };
    setEditedAssistant(item);
    setSelectedAssistant(item);
    setNewAssistant(true);
    setOpenBox(true);
  };

  const handlSaveAssistant = () => {
    const assistantToSave = { ...editedAssistant };

    delete assistantToSave.code;
    delete assistantToSave.token;
    delete assistantToSave.refreshToken;

    if (newAssistant) {
      setAssistants((prev) => [...prev, assistantToSave]);
    } else {
      const updatedAssistants = assistants.map((assistant) =>
        assistant === selectedAssistant ? assistantToSave : assistant
      );
      setAssistants(updatedAssistants);
    }

    setOpenBox(false);
    setSelectedAssistant(null);
    setEditedAssistant(null);
    setNewAssistant(false);
  };
  const handleDeleteAssistant = () => {
    const filteredassistants = assistants.filter(
      (loginType) => loginType !== selectedAssistant
    );
    setAssistants(filteredassistants);
    setOpenBox(false);
    setSelectedAssistant(null);
    setEditedAssistant(null);
  };

  const onClickExit = () => {
    setOpenBox(false);
    setEditedAssistant(null);
    setNewAssistant(false);
    setSelectedAssistant(null);
  };

  function onSelectChangeSingle(event, setValues, property) {
    const values = event?.value;
    setValues((prev) => {
      return { ...prev, [property]: values };
    });
  }

  const footerBoxFloat = (
    <div className="agente-container-buttons">
      <Button icon="save" onClick={handlSaveAssistant} />
      {!newAssistant && (
        <Button icon="delete" onClick={handleDeleteAssistant} />
      )}
    </div>
  );
  return (
    <div className="agentes-container">
      {openBox && (selectedAssistant || newAssistant) && (
        <BoxFloat
          title={selectedAssistant ? "Editar Assistente" : "Agregar Assistente"}
          onClickExit={onClickExit}
          onClose={selectedAssistant ? onClickExit : undefined}
          footer={footerBoxFloat}
        >
          <Input
            name="name"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["name"] : ""}
            label="Nombre"
          />
          <Input
            name="description"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["description"] : ""}
            label="Descripcion"
          />
          <Input
            name="email"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["email"] : ""}
            label="Email"
          />
          <Input
            name="avatar"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["avatar"] : ""}
            label="Avatar URL"
          />
          <div className="agentes-container-provider">
            <Select
              name="provider"
              options={tiposProveedores}
              onChange={(event) =>
                onSelectChangeSingle(event, setEditedAssistant, "provider")
              }
              label="Proveedor"
              value={tiposProveedores.find(
                (tipo) => tipo.value === editedAssistant["provider"]
              )}
            />
            {editedAssistant?.provider === "google" && (
              <div
                className="angentes-container-provider-icon"
                onClick={() => {
                  window.open(
                    "https://console.cloud.google.com/apis/credentials",
                    "_blank"
                  );
                }}
              >
                <Icon title="Mas información">info</Icon>
              </div>
            )}
          </div>
          <Input
            name="clientID"
            type="text"
            placeholder=""
            onChange={handleEditClientID}
            value={editedAssistant ? editedAssistant["clientID"] : ""}
            label="Client ID"
          />
          <Input
            name="secretID"
            type="password"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["secretID"] : ""}
            label="Secret ID"
          />
          <Input
            name="redirect_uri"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["redirect_uri"] : ""}
            label="Redirección URI"
          />
          <Input
            name="scope"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["scope"] : ""}
            label="Scope"
            disabled={editedAssistant?.provider === "google" ? true : false}
          />
          <Input
            name="validated"
            type="checkbox"
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["validated"] : ""}
            label="Validado"
            disabled={true}
          />
          {/* <Input
            name="code"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["code"] : ""}
            label="Codigo autorizacion"
          />
          <Input
            name="token"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["token"] : ""}
            label="Token"
          />
          <Input
            name="refreshToken"
            type="text"
            placeholder=""
            onChange={handleEditAssistant}
            value={editedAssistant ? editedAssistant["refreshToken"] : ""}
            label="Token de refresco"
          /> */}
        </BoxFloat>
      )}
      <div className="display-agentes">
        {assistants?.map((el, index) => (
          <div
            key={index}
            className="agente-item"
            onClick={() => handleAssistantClick(index)}
          >
            <div className="agente-item-title">{el.name}</div>
            <div className="agente-item-description">{el.description}</div>
            <div
              className="assistant-item-chatIcon"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Icon>google-plus-box</Icon>
            </div>
            <a href={`${el.linkurl}`}>
              <img src={el.avatar} alt="avatar" />
            </a>
          </div>
        ))}
        <div
          className="agente-item agente-item-add-last"
          onClick={agregarElemento}
        >
          <Icon>add</Icon>
        </div>
      </div>
    </div>
  );
};

export default Asistentes;
