import React from 'react'
import Input from 'src/modules/components/common/Input/Input'

// Array de strings
const SimpleEnum = ({option, onChange}) => {
  return (
      <Input
        name={option}
        label="Valor: "
        value={option.value}
        onChange={onChange}
      />
  )
}

export default SimpleEnum