import React from "react";
import PropTypes from 'prop-types'; // Añadido para la validación de props
import "../planner.css";
import Button from "../../Button/Button";

const Sidebar = ({ children, onCloseEditor, onDelete }) => {
  return (
    <aside className="sidebar">
      <div style={{ display: "flex", justifyContent: "flex-end", gap: '10px' }}>
        <Button icon='delete' onClick={onDelete}/>
        <Button icon='close' onClick={onCloseEditor}/>
      </div>
      {children}
    </aside>
  );
};

// Añadido para la validación de props
Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  onCloseEditor: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Sidebar;
