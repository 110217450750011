const viewActionTypes = {
    FETCH_VIEW_REQUEST: "FETCH_VIEW_REQUEST",
    FETCH_VIEW_SUCCESS: "FETCH_VIEW_SUCCESS",
    FETCH_VIEW_FAILURE: "FETCH_VIEW_FAILURE",
    FETCH_DATA_REQUEST: "FETCH_DATA_REQUEST",
    FETCH_DATA_SUCCESS: "FETCH_DATA_SUCCESS",
    FETCH_DATA_FAILURE: "FETCH_DATA_FAILURE",
    FETCH_SHOW_REQUEST: "FETCH_SHOW_REQUEST",
    FETCH_SHOW_SUCCESS: "FETCH_SHOW_SUCCESS",
    FETCH_SHOW_FAILURE: "FETCH_SHOW_FAILURE",
    UPDATE_VIEW_REQUEST: "UPDATE_VIEW_REQUEST",
    UPDATE_VIEW_SUCCESS: "UPDATE_VIEW_SUCCESS",
    UPDATE_VIEW_FAILURE: "UPDATE_VIEW_FAILURE",
    FETCH_PATCH_REQUEST: "FETCH_PATCH_REQUEST",
    FETCH_PATCH_SUCCESS: "FETCH_PATCH_SUCCESS",
    FETCH_PATCH_FAILURE: "FETCH_PATCH_FAILURE",
    //METAWINDOW
    SET_META_LOADING: "SET_META_LOADING",
    SET_META_ERROR: "SET_META_ERROR",
    SET_META_DOSSIER: "SET_META_DOSSIER",
    SET_META_ARCHIVES: "SET_META_ARCHIVES",
    SET_META_COMMENTS: "SET_META_COMMENTS",
    SET_META_USERS: "SET_META_USERS",
    SET_META_DOCS: "SET_META_DOCS",
    SET_META_ERRORS: "SET_META_ERRORS",
    SET_META_FLOWS: "SET_META_FLOWS",
    SET_META_LOGS: "SET_META_LOGS",
    SET_META_TRANSACTIONS: "SET_META_TRANSACTIONS",
    SET_META_TASKDOCS: "SET_META_TASKDOCS",
    SET_SELECTED_TASK: "SET_SELECTED_TASK",
    FETCH_ERASEDOSSIER_SUCCESS: "FETCH_ERASEDOSSIER_SUCCESS"
};

export default viewActionTypes;