import serverLogActionTypes from "../actionTypes/serverLogActionTypes";

export const serverLogReducer = (state, action) => {
  switch (action.type) {
    case serverLogActionTypes.FETCH_LOGS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case serverLogActionTypes.FETCH_LOGS_SUCCESS: {
      if (!Array.isArray(action.payload)) {
        return {
          ...state,
          lastFetch: new Date(),
          loading: false,
        };
      }
      const filteredNewLogs = removeMatchingTimes(state.logs, action.payload);

      // Comprobar si hay discontinuidad en los datos
      let updatedLogs = [...state.logs];
      if (
        state.logs.length > 0 &&
        filteredNewLogs.length > 0 &&
        action.payload.length > 0 &&
        filteredNewLogs.length === action.payload.length
      ) {
        // Si todos los logs son nuevos, probablemente se perdieron datos
        const discontinuityLog = {
          level: 60, // Nivel FATAL para que se muestre en rojo
          message:
            "⚠️ POSIBLES LOGS PERDIDOS ⚠️ No hay coincidencia con los logs anteriores. Aumente la frecuencia de actualización de los logs.",
          time: new Date().toISOString(),
          isDiscontinuityWarning: true, // Marca especial para identificar advertencias
        };
        updatedLogs = [...updatedLogs, discontinuityLog, ...filteredNewLogs];
      } else {
        updatedLogs = [...updatedLogs, ...filteredNewLogs];
      }

      return {
        ...state,
        lastFetch: new Date(),
        loading: false,
        logs: updatedLogs,
      };
    }
    default:
      return state;
  }
};

function removeMatchingTimes(array1, array2) {
  const timesSet = new Set(array1.map((item) => item.time));
  return array2.filter((item) => !timesSet.has(item.time));
}
