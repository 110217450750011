import React from "react";
import "../Input.css";

const CheckboxInput = ({ ...inputProps }) => {
  return (
    <label className="custom-checkbox">
      <input {...inputProps} type="checkbox" />
    </label>
  );
};

export default CheckboxInput;
