import { React, createContext, useReducer } from "react";
import PropTypes from "prop-types";
import { serverLogReducer } from "../reducers/serverLogReducer";

const initialState = {
  logs: [],
  loading: false,
  lastFetch: null,
};

export const ServerLogContext = createContext(initialState);

export const ServerLogContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(serverLogReducer, initialState);

  return (
    <ServerLogContext.Provider value={{ state, dispatch }}>
      {children}
    </ServerLogContext.Provider>
  );
};

ServerLogContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
