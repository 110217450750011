import React, { useContext, useMemo, useCallback } from "react";
import Button from "src/modules/components/common/Button/Button";
import PropTypes from "prop-types";
import "./ExecutiveCard.css";
import EditElement from "src/modules/components/frame/EditElement";
import { ViewContext } from "src/modules/contexts/ViewContextProvider";
import { editView } from "src/modules/actions/viewActions";

const getPropertyKey = (variable) => {
  const regex = /\{\{\s*executive\.(\w+)\s*\}\}/;
  const match = variable.match(regex);
  return match ? match[1] : null;
};

export const resolveLink = (link, data) => {
  return link.replace(
    /\{\{\s*executive\.(\w+)\s*\}\}/g,
    (_, key) => data[key] || ""
  );
};

const ExecutiveCard = React.memo(
  ({ cardData, handleClick, view, frame, setView }) => {
    const { state, dispatch } = useContext(ViewContext);

    const handleNavigation = useCallback(
      (url) => {
        handleClick(url);
      },
      [handleClick]
    );

    const buttonElements = useMemo(
      () =>
        frame?.elements.filter(
          (element) =>
            (element.type || "").toLowerCase() === "button" &&
            element.icon !== "add_circle"
        ),
      [frame.elements]
    );

    const otherElements = useMemo(
      () =>
        frame?.elements.filter(
          (element) => (element.type || "").toLowerCase() !== "button"
        ),
      [frame.elements]
    );

    const newProperty = useCallback(() => {
      try {
        const updatedFrame = {
          ...frame,
          elements: [
            ...frame.elements,
            {
              variable: "Nuevo Elemento",
              label: "Nuevo Elemento",
              link: "",
              icon: "",
            },
          ],
        };
        const newFrames = state.view.frames.map((item) =>
          item._id === frame._id ? updatedFrame : item
        );
        const newView = { ...state.view, frames: newFrames };
        editView(newView, dispatch);
      } catch (error) {
        console.log(error);
      }
    }, [frame, state.view.frames, dispatch]);

    const deleteProperty = useCallback(() => {
      try {
        const updatedFrame = {
          ...frame,
          elements: frame.elements.filter(
            (item) => item.variable !== "Nuevo Elemento"
          ),
        };

        const newFrames = state.view.frames.map((item) =>
          item._id === frame._id ? updatedFrame : item
        );
        const newView = { ...state.view, frames: newFrames };
        editView(newView, dispatch);
      } catch (error) {
        console.log(error);
      }
    }, [frame, state.view.frames, dispatch]);

    const reorderNonButtonElements = (dragIndex, dropIndex) => {
      const nonButtonElements = frame.elements.filter(
        (el) => (el.type || "").toLowerCase() !== "button"
      );

      const movedElement = nonButtonElements.splice(dragIndex, 1)[0];
      nonButtonElements.splice(dropIndex, 0, movedElement);

      const newElements = [...frame.elements];
      const nonButtonIndices = [];
      frame.elements.forEach((el, index) => {
        if ((el.type || "").toLowerCase() !== "button") {
          nonButtonIndices.push(index);
        }
      });
      nonButtonIndices.forEach((origIndex, orderIndex) => {
        newElements[origIndex] = nonButtonElements[orderIndex];
      });

      const newFrame = { ...frame, elements: newElements };
      const newFrames = state.view.frames.map((f) =>
        f._id === frame._id ? newFrame : f
      );
      const newView = { ...state.view, frames: newFrames };
      editView(newView, dispatch);
    };

    // Manejador de arrastre: almacena el índice del elemento arrastrado
    const handleDragStart = (e, index) => {
      e.dataTransfer.setData("text/plain", index);
    };

    // Permite el drop sobre el elemento
    const handleDragOver = (e) => {
      e.preventDefault();
    };

    // Manejador al soltar: obtiene el índice de origen y lo usa junto al índice destino para reordenar
    const handleDrop = (e, dropIndex) => {
      e.preventDefault();
      const dragIndex = parseInt(e.dataTransfer.getData("text/plain"), 10);
      if (!isNaN(dragIndex)) {
        reorderNonButtonElements(dragIndex, dropIndex);
      }
    };

    return (
      <div className="executive_card">
        {/* Botones de acción (arriba a la derecha) */}
        <div className="executive_card-actions">
          {frame?.elements &&
            buttonElements.map((element, index) => (
              <React.Fragment key={element._id + index}>
                <EditElement
                  view={view}
                  setView={setView}
                  element={element}
                  frame={frame}
                  erase={deleteProperty}
                />
                <Button
                  icon={element.icon}
                  onClick={() =>
                    handleNavigation(resolveLink(element.link, cardData))
                  }
                />
              </React.Fragment>
            ))}
        </div>

        {/* Botón de arrastre (arriba a la izquierda) */}
        <div className="executive_card-drag-handle">
          <Button icon="open_with_icon" onClick={() => {}} />
        </div>

        {/* Contenido de la tarjeta en el orden original */}
        <div className="executive_card-content">
          {otherElements.map((element, index) => {
            const key = getPropertyKey(element.variable);
            const value = cardData[key];
            if ((value === undefined || value === null) && !element.label)
              return null;

            const type = (element.type || "").toLowerCase();
            return (
              <div
                key={element._id + index}
                className="executive_card-element"
                data-dragdrop-index={index}
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, index)}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <EditElement
                  view={view}
                  setView={setView}
                  element={element}
                  frame={frame}
                  erase={deleteProperty}
                />
                {type === "image" ? (
                  value ? (
                    <img
                      src={value}
                      alt={cardData.name || "Image"}
                      className="executive_card-image"
                    />
                  ) : (
                    <div className="executive_card-placeholder">No Image</div>
                  )
                ) : (
                  <>
                    {element.label && <strong>{element.label}: </strong>}
                    {type === "link" ? (
                      <a href={value} target="_blank" rel="noopener noreferrer">
                        {value}
                      </a>
                    ) : (
                      <span>{value}</span>
                    )}
                  </>
                )}
              </div>
            );
          })}
        </div>
        <Button
          name="addProperty"
          icon={"add_circle"}
          onClick={newProperty}
          className="add-column-button"
        />
      </div>
    );
  }
);

ExecutiveCard.propTypes = {
  cardData: PropTypes.object.isRequired,
  frame: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
};

export default ExecutiveCard;
