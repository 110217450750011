import React, { useState } from "react";
import PropTypes from "prop-types"; // Importa PropTypes
import Sidebar from "../Sidebar/Sidebar";
import { ReactFlow } from "@xyflow/react";
import Button from "../../Button/Button";
import constants from "src/definitions/constants";
import PopoverMenu from "../Nodes/NotesNode/NoteEditor/PopoverMenu/PopoverMenu";

const NodeViewer = ({
  nodes,
  edges,
  onNodesChange,
  onEdgesChange,
  onConnect,
  onConnectStart,
  onConnectEnd,
  onNodeClick,
  hasSidebar,
  renderSidebar,
  canCreateNodes,
  createNewNode,
  onCloseEditor,
  nodeTypes,
  edgeTypes,
  onNodesDelete,
  onClickDelete
}) => {
  const proOptions = { hideAttribution: true };
  const fitViewOptions = {
    padding: 3,
  };

  const options = Object.values(constants.nodeTypes);

  const [openEditor, setOpenEditor] = useState(false);
  const [openSelector, setOpenSelector] = useState(false);

  const onClickNode = (event, node) => {
    if (node.type !== constants.nodeByType.plannerNode) return;
    setOpenEditor(true);
    onNodeClick(event, node);
  };

  const closeEditor = () => {
    setOpenEditor(false);
    onCloseEditor();
  };

  const onDelete = () => {
    setOpenEditor(false);
    onNodesDelete();
  };

  const onSelectOption = (event, nodeType) => {
    createNewNode(event, null, nodeType);
    setOpenSelector(false);
  };

  const onClickButtonDelete = () => {
    setOpenEditor(false);
    onClickDelete();
  }

  return (
    <>
      {canCreateNodes && (
        <div className="add-node-button-container">
          <Button
            icon="add"
            name="add-node"
            onClick={() => setOpenSelector((prev) => !prev)}
          />
          <PopoverMenu
            style={{
              position: "relative",
              top: 0,
              marginTop: "5px",
            }}
            isOpen={openSelector}
          >
            {options.map((option) => (
              <PopoverMenu.Option
                key={option.value}
                onClick={(e) => onSelectOption(e, option.value)}
              >
                {option.label}
              </PopoverMenu.Option>
            ))}
          </PopoverMenu>
        </div>
      )}
      <div style={{ display: "flex", flexDirection: "row-reverse" }}>
        {openEditor && hasSidebar && (
          <Sidebar onCloseEditor={closeEditor} onDelete={onClickButtonDelete}>{renderSidebar()}</Sidebar>
        )}

        <div className="wrapper">
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            fitView
            fitViewOptions={fitViewOptions}
            proOptions={proOptions}
            onNodeClick={onClickNode}
            onNodesDelete={onDelete}
          />
        </div>
      </div>
    </>
  );
};

// Validación de propiedades
NodeViewer.propTypes = {
  nodes: PropTypes.array.isRequired,
  edges: PropTypes.array.isRequired,
  onNodesChange: PropTypes.func.isRequired,
  onEdgesChange: PropTypes.func.isRequired,
  onConnect: PropTypes.func.isRequired,
  onConnectStart: PropTypes.func.isRequired,
  onConnectEnd: PropTypes.func.isRequired,
  onNodeClick: PropTypes.func.isRequired,
  hasSidebar: PropTypes.bool.isRequired,
  renderSidebar: PropTypes.func.isRequired,
  canCreateNodes: PropTypes.bool.isRequired,
  createNewNode: PropTypes.func.isRequired,
  onCloseEditor: PropTypes.func.isRequired,
  nodeTypes: PropTypes.object.isRequired,
  edgeTypes: PropTypes.object.isRequired,
  onNodesDelete: PropTypes.func.isRequired,
};

export default NodeViewer;
