import React from "react";
import Input from "src/modules/components/common/Input/Input";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const Credentials = () => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();
  return (
    <div className="credential-box" style={{ maxWidth: "60%" }}>
      <div style={{ display: "flex", margin: "22px", fontSize: "32px" }}>
        Credenciales GoCardless
      </div>
      <div
        className="info-credenciales"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          fontSize: "16px",
          marginBottom: "20px",
        }}
      >
        <div>
          Unifique los datos de sus cuentas bancarias y consolidelos con los
          movimientos.
        </div>
        <div>
          Necesitaras cuenta en GoCardless&nbsp;
          <a
            href="https://valeria.moodrobotics.com/docs/movimientos/bancos/"
            target="_blank"
            rel="noreferrer"
          >
            ayuda
          </a>
          .
        </div>
        <div>
          Al introducir el secret id y secret key, debera guardar el setup.
        </div>
      </div>
      <div className="credentials-input-box" style={{ fontSize: "16px" }}>
        <Input
          label="Secret ID"
          value={setupInfo?.bank?.secretid}
          onChange={(e) =>
            setSetupInfo({
              ...setupInfo,
              bank: { ...setupInfo.bank, secretid: e.target.value },
            })
          }
          placeholder="Inserte el Secret ID"
        />
        <Input
          label="Secret Key"
          value={setupInfo?.bank?.secretkey}
          onChange={(e) =>
            setSetupInfo({
              ...setupInfo,
              bank: { ...setupInfo.bank, secretkey: e.target.value },
            })
          }
          placeholder="Inserte el Secret Key"
        />
      </div>
    </div>
  );
};

export default Credentials;
