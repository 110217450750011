// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container___EnumEditor {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  gap: 10px
}
.buttons___EnumEditor {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.option-container___EnumEditor {
  display: flex;
  align-items: center;
  gap: 10px;
}

.option-container___EnumEditor > div > label {
  display: block;
  align-items: unset;
  max-width: unset;
  min-width: unset;
}

.option-icon___EnumEditor {
  width: 40px !important;
  height: 40px !important;
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/routes/EnumEditor/EnumEditor.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb;AACF;AACA;EACE,aAAa;EACb,SAAS;EACT,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,sCAAsC;EACtC,kBAAkB;EAClB,wBAAwB;EACxB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".container___EnumEditor {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100%;\r\n  padding: 20px;\r\n  gap: 10px\r\n}\r\n.buttons___EnumEditor {\r\n  display: flex;\r\n  gap: 10px;\r\n  justify-content: space-between;\r\n}\r\n\r\n.option-container___EnumEditor {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 10px;\r\n}\r\n\r\n.option-container___EnumEditor > div > label {\r\n  display: block;\r\n  align-items: unset;\r\n  max-width: unset;\r\n  min-width: unset;\r\n}\r\n\r\n.option-icon___EnumEditor {\r\n  width: 40px !important;\r\n  height: 40px !important;\r\n  border: 2px solid var(--primary-color);\r\n  border-radius: 8px;\r\n  display: flex !important;\r\n  align-items: center;\r\n  justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
