import menuCanvaActionTypes from "../actionTypes/menuCanvaActionTypes";

export const menuCanvaReducer = (state, action) => {
  switch (action.type) {
    case menuCanvaActionTypes.SET_MENU_DATA:
      return {
        ...state,
        menuData: action.payload,
      };
    case menuCanvaActionTypes.SET_BACKUP_LOAD_ONCE:
      return {
        ...state,
        hasBackupMenuDataBeenLoadedOnce: action.payload,
      };
    case menuCanvaActionTypes.SET_LOADING_MENU_CANVA:
      return {
        ...state,
        loading: false,
      };
    case menuCanvaActionTypes.SET_BACKUP_MENU_DATA:
      return {
        ...state,
        backupMenuData: action.payload,
      };
    case menuCanvaActionTypes.SET_ROLES_OPTIONS:
      return {
        ...state,
        rolesOptions: action.payload,
      };
    case menuCanvaActionTypes.SET_LIST_DATA:
      return {
        ...state,
        listData: action.payload,
      };
    case menuCanvaActionTypes.SET_CARD_DATA:
      return {
        ...state,
        cardData: action.payload,
      };

    case menuCanvaActionTypes.SET_MODAL_VARIABLE:
      return {
        ...state,
        modalVariable: action.payload,
      };
    case menuCanvaActionTypes.UPDATE_MENU_TO_SETUP:
      return {
        ...state,
        updateMenuToSetup: action.payload,
      };

    default:
      return state;
  }
};
