import viewActionTypes from "../actionTypes/viewActionTypes";

export const viewReducer = (state, action) => {
  switch (action.type) {
    case viewActionTypes.FETCH_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case viewActionTypes.FETCH_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        view: action.payload,
        error: null,
      };
    case viewActionTypes.FETCH_VIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case viewActionTypes.FETCH_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case viewActionTypes.FETCH_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, [action.payload.frameId]: action.payload.data },
        error: null,
      };
    case viewActionTypes.FETCH_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case viewActionTypes.UPDATE_VIEW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case viewActionTypes.UPDATE_VIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        view: action.payload,
        error: null,
      };
    case viewActionTypes.UPDATE_VIEW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case viewActionTypes.FETCH_SHOW_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case viewActionTypes.FETCH_SHOW_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, [action.payload.frameId]: action.payload.data },
        error: null,
      };
    case viewActionTypes.FETCH_SHOW_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case viewActionTypes.FETCH_PATCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case viewActionTypes.FETCH_PATCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: { ...state.data, [action.payload.header.code]: action.payload },
        error: null,
      };
    case viewActionTypes.FETCH_PATCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // METAWINDOW

    case viewActionTypes.SET_META_LOADING:
      return {
        ...state,
        metaWindowData: {
          ...state.metaWindowData,
          [action.payload.id]: {
            ...state.metaWindowData[action.payload.id],
            isLoading: action.payload.isLoading,
          },
        },
      };

    case viewActionTypes.SET_META_ERROR:
      return {
        ...state,
        metaWindowData: {
          ...state.metaWindowData,
          error: action.payload
        }
      };

    case viewActionTypes.SET_META_DOSSIER:
      return {
        ...state,
        metaWindowData: {
          ...state.metaWindowData, [action.payload]: {
            flows: [],
            errors: [],
            comments: [],
            documents: [],
            archives: [],
            logs: [],
            transactions: [],
            docDetail: {},
            graphs: {}, // guardar gráficos juntos de developer, docs, flows y tasks. algunos se repiten
            isLoading: false,
            error: null
          }
        }
      };

    case viewActionTypes.SET_META_ARCHIVES:
      return {
        ...state,
        metaWindowData: {
          ...state.metaWindowData,
          [action.payload.id]: {
            ...state.metaWindowData[action.payload.id],
            archives: action.payload.archives
          }
        }
      };

    case viewActionTypes.SET_META_COMMENTS:
      return {
        ...state,
        metaWindowData: {
          ...state.metaWindowData,
          [action.payload.id]: {
            ...state.metaWindowData[action.payload.id],
            comments: action.payload.comments
          }
        }
      };

    case viewActionTypes.SET_META_USERS:
      return {
        ...state,
        users: action.payload
      };

    case viewActionTypes.SET_META_DOCS:
      return {
        ...state,
        metaWindowData: {
          ...state.metaWindowData,
          [action.payload.id]: {
            ...state.metaWindowData[action.payload.id],
            documents: action.payload.data
          }
        }
      };

    case viewActionTypes.SET_META_ERRORS:
      return {
        ...state,
        metaWindowData: {
          ...state.metaWindowData,
          [action.payload.id]: {
            ...state.metaWindowData[action.payload.id],
            errors: action.payload.errors
          }
        }
      };

    case viewActionTypes.SET_META_FLOWS:
      return {
        ...state,
        metaWindowData: {
          ...state.metaWindowData,
          [action.payload.id]: {
            ...state.metaWindowData[action.payload.id],
            flows: action.payload.flows
          }
        }
      };

    case viewActionTypes.SET_META_LOGS:
      return {
        ...state,
        metaWindowData: {
          ...state.metaWindowData,
          [action.payload.id]: {
            ...state.metaWindowData[action.payload.id],
            logs: action.payload.logs
          }
        }
      };

    case viewActionTypes.SET_META_TRANSACTIONS:
      return {
        ...state,
        metaWindowData: {
          ...state.metaWindowData,
          [action.payload.id]: {
            ...state.metaWindowData[action.payload.id],
            transactions: action.payload.transactions
          }
        }
      };

    case viewActionTypes.SET_META_TASKDOCDETAIL:
      return {
        ...state,
        metaWindowData: {
          ...state.metaWindowData,
          [action.payload.id]: {
            ...state.metaWindowData[action.payload.id],
            docDetail: action.payload.docDetail
          }
        }
      };

    case viewActionTypes.SET_SELECTED_TASK:
      return {
        ...state,
        selectedTask: action.payload
      };

    // borrar primero del frame y luego el original
    case viewActionTypes.FETCH_ERASEDOSSIER_SUCCESS:
      const updatedData = { ...state.data };
      delete updatedData[action.payload]; 
      return {
        ...state,
        data: updatedData 
      };


    default:
      return state;
  }
};