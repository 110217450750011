import React, { useState, useCallback, useEffect } from "react";
import "../Agentes/Agentes.css";
import "./Grupos.css";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { Icon } from "@mui/material";

const Grupos = () => {
  const { setupData, setSetupData } = useAppContext();
  const [grupos, setGrupos] = useState(setupData?.groups || []);
  const [selectedGrupo, setSelectedGrupo] = useState(null);
  const [editedGrupo, setEditedGrupo] = useState(null);
  const [openBox, setOpenBox] = useState(false);
  const [newGrupo, setNewGrupo] = useState(false);

  useEffect(() => {
    console.log(setupData.groups);
  }, [setupData]);
  const agregarGrupo = useCallback(() => {
    const newItem = {
      id: crypto.randomUUID(),
      name: "",
      description: "",
    };
    setEditedGrupo(newItem);
    setSelectedGrupo(newItem);
    setNewGrupo(true);
    setOpenBox(true);
  }, []);

  const handleEditGrupo = useCallback((e) => {
    const { name, value } = e.target;
    setEditedGrupo((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSaveGrupo = useCallback(() => {
    const updatedGrupos = newGrupo
      ? [...grupos, editedGrupo]
      : grupos.map((grupo) => (grupo === selectedGrupo ? editedGrupo : grupo));

    setGrupos(updatedGrupos);
    setSetupData((prev) => ({ ...prev, groups: updatedGrupos }));

    setOpenBox(false);
    resetEditState();
  }, [newGrupo, editedGrupo, selectedGrupo, grupos, setSetupData]);

  const handleDeleteGrupo = useCallback(() => {
    const updatedGrupos = grupos.filter((grupo) => grupo !== selectedGrupo);
    setGrupos(updatedGrupos);
    setSetupData((prev) => ({ ...prev, groups: updatedGrupos }));

    setOpenBox(false);
    resetEditState();
  }, [selectedGrupo, grupos, setSetupData]);

  const resetEditState = useCallback(() => {
    setSelectedGrupo(null);
    setEditedGrupo(null);
    setNewGrupo(false);
  }, []);

  const onClickExit = useCallback(() => {
    setOpenBox(false);
    resetEditState();
  }, [resetEditState]);

  return (
    <div className="agentes-container">
      {openBox && (selectedGrupo || newGrupo) && (
        <BoxFloat
          title={selectedGrupo ? "Editar Grupo" : "Agregar Grupo"}
          onClickExit={onClickExit}
          footer={
            <div className="agente-container-buttons">
              <Button icon="save" onClick={handleSaveGrupo} />
              {!newGrupo && (
                <Button icon="delete" onClick={handleDeleteGrupo} />
              )}
            </div>
          }
        >
          <Input
            name="name"
            type="text"
            onChange={handleEditGrupo}
            value={editedGrupo?.name || ""}
            label="Nombre"
            placeholder=""
          />
          <Input
            name="description"
            type="text"
            onChange={handleEditGrupo}
            value={editedGrupo?.description || ""}
            label="Descripción"
            placeholder=""
          />
        </BoxFloat>
      )}
      <div className="display-agentes">
        {grupos.map((grupo, index) => (
          <div
            key={index}
            className="agente-item"
            onClick={() => {
              setSelectedGrupo(grupo);
              setEditedGrupo({ ...grupo });
              setOpenBox(true);
            }}
          >
            <div className="agente-item-title">{grupo.name}</div>
            <div className="agente-item-description">{grupo.description}</div>
          </div>
        ))}
        <div
          className="agente-item agente-item-add-last"
          onClick={agregarGrupo}
        >
          <Icon>add</Icon>
        </div>
      </div>
    </div>
  );
};

export default Grupos;
