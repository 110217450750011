import React, { useContext } from "react";
import MenuCanvaCard from "../MenuCanvaCard/MenuCanvaCard";
import PropTypes from "prop-types";
import Button from "src/modules/components/common/Button/Button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteMenuListAtMenu,
  setMenuCardData,
  setMenuListData,
  setupDragAndDropLists,
  setVariableModal,
} from "src/modules/actions/menuCanvaActions";
import { MenuCanvaContext } from "src/modules/contexts/MenuCanvaContextProvider";
import menuCanvaConstants from "../MenuCanvaConstants";

const MenuCanvaList = ({ data, idMenuList, indexMenuList }) => {
  const { VAR_EDIT_MENULIST, VAR_ADD_MENUCARD } = menuCanvaConstants;
  const { state, dispatch } = useContext(MenuCanvaContext);
  const { menuData } = state;

  return (
    <div className={`menu-list__container ${data.id}`} data-droppable>
      <div
        className="menu-list-header"
        draggable
        data-dragdrop-index={indexMenuList} // Añadimos el índice para identificafFr la tarjeta
        data-dragdrop-object={`${data.id}`} // Identificamos la tarjeta
        onDragStart={() => setupDragAndDropLists(dispatch, menuData)}
      >
        <div className="menu-list-header__title">
          {data.title || "Nuevo Menú"}
        </div>
        <div className="menu-list-header__actions">
          <Button
            content={<EditIcon />}
            style={{ minWidth: "0px", fontSize: "0.7rem !important" }}
            onClick={() => {
              setMenuListData(dispatch, data);
              setVariableModal(dispatch, VAR_EDIT_MENULIST);
            }}
          />
          <Button
            content={<DeleteIcon />}
            style={{ minWidth: "0px", fontSize: "0.7rem !important" }}
            onClick={() => deleteMenuListAtMenu(dispatch, data, menuData)}
          />
        </div>
      </div>
      <div className="menu-list-cards">
        {data.submenus.map((submenu, index) => (
          <MenuCanvaCard
            key={submenu.id}
            data={submenu}
            idMenuList={idMenuList}
            indexMenuCard={index}
          />
        ))}
        <div
          className="menu-card__container menu__add-button"
          onClick={() => {
            setMenuListData(dispatch, data);
            setMenuCardData(dispatch, {});
            setVariableModal(dispatch, VAR_ADD_MENUCARD);
          }}
        >
          +
        </div>
      </div>
    </div>
  );
};

export default MenuCanvaList;

MenuCanvaList.propTypes = {
  data: PropTypes.object.isRequired,
  idMenuList: PropTypes.string,
  indexListId: PropTypes.number,
  indexMenuList: PropTypes.number,
  setupDragAndDropCards: PropTypes.func.isRequired,
  setupDragAndDropLists: PropTypes.func.isRequired,
  addMenuCard: PropTypes.func.isRequired,
  handleEditMenuCard: PropTypes.func.isRequired,
  handleDeleteMenuCard: PropTypes.func.isRequired,
};
