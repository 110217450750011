import React from "react";
import MovimientosWay from "./Tabs/MovimientosWay";
import MovimientosType from "./Tabs/MovimientosType";
import MovimientosServices from "./Tabs/MovimientosServices";
import MovimientosGroups from "./Tabs/MovimientosGroups";

const MovimientosRighTab = ({
  movementData,
  setMovementData,
  selectedItem,
  selectedTabId,
  setSelectedTabId,
  clearSelectedItem,
}) => {
  const tabs = [
    {
      id: "way",
      label: "Formas de pago",
      component: (
        <MovimientosWay
          movementData={movementData}
          setMovementData={setMovementData}
          selectedItem={selectedItem}
          clearSelectedItem={clearSelectedItem}
        />
      ),
    },
    {
      id: "formas",
      label: "Grupos contables",
      component: (
        <MovimientosType
          movementData={movementData}
          setMovementData={setMovementData}
          selectedItem={selectedItem}
          clearSelectedItem={clearSelectedItem}
        />
      ),
    },
    {
      id: "servicios",
      label: "Servicios",
      component: (
        <MovimientosServices
          movementData={movementData}
          setMovementData={setMovementData}
          selectedItem={selectedItem}
          clearSelectedItem={clearSelectedItem}
        />
      ),
    },
    {
      id: "grupos",
      label: "Grupos",
      component: (
        <MovimientosGroups
          movementData={movementData}
          setMovementData={setMovementData}
          selectedItem={selectedItem}
          clearSelectedItem={clearSelectedItem}
        />
      ),
    },
  ];

  const renderContent = () => {
    const selectedTab = tabs.find((tab) => tab.id === selectedTabId);
    return selectedTab ? selectedTab.component : null;
  };

  return (
    <div>
      <div className="movimientosRightTabs">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab-element ${
              selectedTabId === tab.id ? "selected" : ""
            }`}
            onClick={() => setSelectedTabId(tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div className="tab-content">{renderContent()}</div>
    </div>
  );
};

export default MovimientosRighTab;
