// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Contenedor con tamaño fijo y scroll suave */
.item-labels {
  overflow-y: auto;
  scroll-behavior: smooth;
  background-color: #222;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #444;
  border-radius: 8px;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .item-labels {
    height: 400px;
  }
}
/* Cada elemento de la lista */
.item-item {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 4px 8px;
  overflow: hidden;
}

/* Botón/ícono para eliminar */
.item-remove {
  margin-right: 8px;
  cursor: pointer;
}

/* Texto de la item */
.item-text {
  display: inline-block;
  word-break: break-word; /* Para que la item salte de línea si es muy larga */
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Scrollbar personalizada */
.item-labels::-webkit-scrollbar {
  width: 6px;
}

.item-labels::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.item-labels::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}
`, "",{"version":3,"sources":["webpack://./src/routes/ExecutiveEdit/RemovableItemList/RemovableItemList.css"],"names":[],"mappings":"AAAA,8CAA8C;AAC9C;EACE,gBAAgB;EAChB,uBAAuB;EACvB,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;AACd;AACA;EACE;IACE,aAAa;EACf;AACF;AACA,8BAA8B;AAC9B;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,8BAA8B;EAC9B,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA,8BAA8B;AAC9B;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA,qBAAqB;AACrB;EACE,qBAAqB;EACrB,sBAAsB,EAAE,oDAAoD;EAC5E,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA,4BAA4B;AAC5B;EACE,UAAU;AACZ;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;AACpB","sourcesContent":["/* Contenedor con tamaño fijo y scroll suave */\r\n.item-labels {\r\n  overflow-y: auto;\r\n  scroll-behavior: smooth;\r\n  background-color: #222;\r\n  padding: 8px;\r\n  box-sizing: border-box;\r\n  border: 1px solid #444;\r\n  border-radius: 8px;\r\n  height: 100%;\r\n}\r\n@media screen and (max-width: 768px) {\r\n  .item-labels {\r\n    height: 400px;\r\n  }\r\n}\r\n/* Cada elemento de la lista */\r\n.item-item {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-bottom: 6px;\r\n  background: rgba(0, 0, 0, 0.3);\r\n  border-radius: 8px;\r\n  padding: 4px 8px;\r\n  overflow: hidden;\r\n}\r\n\r\n/* Botón/ícono para eliminar */\r\n.item-remove {\r\n  margin-right: 8px;\r\n  cursor: pointer;\r\n}\r\n\r\n/* Texto de la item */\r\n.item-text {\r\n  display: inline-block;\r\n  word-break: break-word; /* Para que la item salte de línea si es muy larga */\r\n  text-overflow: ellipsis;\r\n  white-space: nowrap;\r\n}\r\n\r\n/* Scrollbar personalizada */\r\n.item-labels::-webkit-scrollbar {\r\n  width: 6px;\r\n}\r\n\r\n.item-labels::-webkit-scrollbar-track {\r\n  background: rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.item-labels::-webkit-scrollbar-thumb {\r\n  background: rgba(255, 255, 255, 0.3);\r\n  border-radius: 3px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
