import React from "react";
import { icons } from "src/lib/icons";
import Select from "./Select/Select";

const IconSelect = ({name, label, value, onChange}) => {
  return (
    <Select
      name={name}
      label={label}
      value={icons.find((icon) => icon.value === value)}
      onChange={onChange}
      options={icons}
      filterOption={(candidate, input) => {
        return candidate.data.tags.some((t) => t.includes(input)) ||candidate.value.includes(input);
      }}
    />
  );
};

export default IconSelect;
