/*eslint no-undef: "error"*/
import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Agent {
  static async question(agentid, messages, threadid) {
    const data = {
      agentid: agentid,
      messages: messages,
    };
    if (threadid) data.threadid = threadid;
    const response = await HttpMethods.request_post("/v1/agent/question", data);
    if (!response)
      addErrorMessageGlobal("Error al obtener la respuesta del agente");
    return response;
  }
  static async list() {
    const response = await HttpMethods.request_get("/v1/agent/list");
    if (!response) addErrorMessageGlobal("Error al obtener los agentes");
    return response?.agents;
  }
  static async listflat(/**first = 0, pagination = 100*/) {
    let response = await HttpMethods.request_get(`/v1/agent/list?format=flat`);
    if (!response) {
      addErrorMessageGlobal("Error al obtener los agentes");
      response = [];
    }
    return response;
  }

  static async models(serverUrl) {
    const encodedServerUrl = encodeURIComponent(serverUrl);
    const response = await HttpMethods.request_get(
      `/v1/agent/models/${encodedServerUrl}`
    );
    if (response.error) {
      addErrorMessageGlobal(
        `Error: ${response.error} Message: ${response.message}`
      );
    }

    if (!response)
      addErrorMessageGlobal("Error al obtener los modelos del agente");
    return response;
  }
}
