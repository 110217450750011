import "./App.css";
import NavBar from "src/modules/components/header/NavBar/NavBar";
import Disconnection from "src/modules/components/common/Disconnection/Disconnection";
import { Route, Routes, BrowserRouter, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import Login from "src/routes/Login/Login";
import Setup from "src/routes/Setup/Setup";
import User from "src/routes/Users/User";
import Info from "src/routes/Info/Info";
import Cartridge from "src/routes/Cartridges/Cartridge";
import Developer from "src/routes/Developer/Developer";
import View from "./routes/View/View";
import Form from "src/routes/Form/Form";
import Check from "./routes/Checks/Check";
import Template from "./routes/Templates/Template";
import TemplateEdit from "./routes/Templates/TemplateEdit";
import Import from "./routes/Import/Import";
import Update from "./routes/Update/Update";
import ViewCreate from "./routes/ViewCreate/ViewCreate";
import { DataProvider } from "./routes/ViewCreate/ViewCreateContext.js";
import CreateNewMenuByFiletype from "./modules/components/common/CreateNewMenyByFiletype/CreateNewMenuByFiletype";
import BankRevalidate from "./routes/Setup/BankRevalidate";
import alertControlledByDebugMode from "src/modules/helpers/alertControlledByDebugMode";
import FiletypeEditor from "./routes/FiletypeEditor/FiletypeEditor";
import Structure from "./routes/Structure/Structure";
import Export from "./routes/Export/Export";
import ActionPage from "./routes/ActionPage/ActionPage";
import Assign from "./routes/Assign/Assign";
import OAuth2 from "./routes/OAuth2/OAuth2";
import Archives from "./routes/Archives/Archives";
import Status from "./routes/Status/Status";
import { initEditor } from "./MermaidLanguage";
import { useMonaco } from "@monaco-editor/react";
import Logout from "./routes/Logout/Logout";
import UserActivity from "./routes/UserActivity/UserActivity";
import Profile from "./routes/Profile/Profile"; // Importar el nuevo componente Profile
import { MessageProvider } from "./modules/contexts/MessageContextProvider";
import ExecutiveManagement from "./routes/ExecutiveManagement/ExecutiveManagement";
import ExecutivesLayout from "./routes/Executives/Executives";
import ExecutiveEdit from "./routes/ExecutiveEdit/ExecutiveEdit";
import ExecutiveChat from "./routes/ExecutiveChat/ExecutiveChat";
import ServerLog from "./routes/ServerLog/ServerLog";
import { ServerLogContextProvider } from "./modules/contexts/ServerLogContextProvider";
import EditMovement from "./routes/Setup/Grupos/Bancos/EditMovement";
import EditResume from "./routes/EditResume/EditResume";
import EnumEditor from "./routes/EnumEditor/EnumEditor";

function App() {
  const monaco = useMonaco();
  const { publicSetup, isServerUp, role } = useAppContext();
  const setupStyles = {
    "--bg-color": publicSetup.backcolor,
    "--primary-color": publicSetup.primarycolor,
    "--detail-color": publicSetup.detailcolor,
    "--error-color": publicSetup.errorcolor,
    "--warning-color": publicSetup.warningcolor,
    "--font-color": publicSetup.fontcolor,
    color: publicSetup.fontcolor,
    fontFamily: publicSetup.fontfamily,
  };

  useEffect(() => {
    if (!monaco) return;
    initEditor(monaco);
  }, [monaco]);
  // Función para obtener el parámetro vid
  function ViewWithParams() {
    let { vid } = useParams(); // Obtén el valor de vid de la URL

    if (!vid) {
      vid = role !== 'guest' ? publicSetup?.loggeddefaultview : publicSetup?.defaultview;
    }
    if (vid === "useractivity") {
      return <UserActivity />;
    }
    return <View vid={vid} />; // Pasa vid como prop a View2
  }

  function createRouteByRol(path, component, ContextProvider = null) {
    const element = ContextProvider ? (
      <ContextProvider>{component}</ContextProvider>
    ) : (
      component
    );
    return <Route path={path} element={element} />;
  }

  // Sobrescribimos el metodo alert para que solo se muestre en modo debug
  if (publicSetup.debugmode) {
    alertControlledByDebugMode();
    console.log(
      "¡¡Modo debug activado, solo se mostrarán alertas en modo debug!!"
    );
  }

  if (!isServerUp)
    return (
      <div className="server-error">
        <Disconnection />
      </div>
    );

  return (
    publicSetup.menu && (
      <div style={setupStyles}>
        <MessageProvider>
          <BrowserRouter>
            <NavBar />
            <div className="page-container">
              <Routes>
                {createRouteByRol("", <ViewWithParams />)}
                {createRouteByRol("login", <Login />)}
                {createRouteByRol("/oauth2", <OAuth2 />)}
                {createRouteByRol("form", <Form />)}
                {createRouteByRol("check", <Check />)}
                {createRouteByRol("setup", <Setup />)}
                {createRouteByRol("setup/bank-revalidate", <BankRevalidate />)}
                {createRouteByRol("user", <User />)}
                {createRouteByRol("info", <Info />)}
                {createRouteByRol("cartridge", <Cartridge />)}
                {createRouteByRol("template", <Template />)}
                {createRouteByRol("template/edit", <TemplateEdit />)}
                {createRouteByRol("developer", <Developer />)}
                {createRouteByRol('enum', <EnumEditor />)}
                {/* Pasa el parámetro vid a View2 */}
                {createRouteByRol("/view/:vid", <ViewWithParams />)}
                {createRouteByRol("export", <Export />)}
                {createRouteByRol("import", <Import />)}
                {createRouteByRol("/update", <Update />)}
                {createRouteByRol(
                  "/createmenu/:filetype",
                  <CreateNewMenuByFiletype />
                )}
                {createRouteByRol(
                  "/view/structure/:filetypeName",
                  <Structure />
                )}
                {createRouteByRol("/view/edit", <ViewCreate />, DataProvider)}
                <Route
                  path="/view/edit/:vid"
                  element={
                    <DataProvider>
                      <ViewCreate />
                    </DataProvider>
                  }
                />
                {createRouteByRol("/filetype/:filetype?", <FiletypeEditor />)}
                {createRouteByRol("/action/:actionName", <ActionPage />)}
                {createRouteByRol(
                  "/assign/:filetype?/:ownerEmail?",
                  <Assign />
                )}
                {createRouteByRol("/view/archives", <Archives />)}
                {createRouteByRol("/serverlog", <ServerLog />, ServerLogContextProvider)}
                {createRouteByRol("/status", <Status />)}
                {createRouteByRol("/useractivity", <UserActivity />)}
                {createRouteByRol("/logout", <Logout />)}
                {createRouteByRol("/profile", <Profile />)}
                {createRouteByRol("/executives", <ExecutivesLayout />)}
                {createRouteByRol("/executive", <ExecutiveManagement />)}
                {createRouteByRol(
                  "/executive/:executiveid",
                  <ExecutiveManagement />
                )}
                {createRouteByRol(
                  "/executiveedit/:executiveid",
                  <ExecutiveEdit />
                )}
                {createRouteByRol(
                  "/executivechat/:executiveid",
                  <ExecutiveChat />
                )}
                {createRouteByRol(
                  "/movement/:movementcode",
                  <EditMovement />
                )}
                {createRouteByRol(
                  "/resume/:resumecode",
                  <EditResume />
                )}
                {createRouteByRol(
                  "/resume",
                  <EditResume />
                )}
              </Routes>
            </div>
          </BrowserRouter>
        </MessageProvider>
      </div>
    )
  );
}

export default App;
