import React, { useContext } from "react";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import FormGenerator from "src/modules/components/FormGenerator/FormGenerator";
import { MenuCanvaContext } from "src/modules/contexts/MenuCanvaContextProvider";
import menuCanvaConstants from "../MenuCanvaConstants";
import {
  addCardToMenuList,
  modifyCardToMenuList,
  setMenuCardData,
  setMenuListData,
  setVariableModal,
} from "src/modules/actions/menuCanvaActions";
import { generateUniqueId } from "../helpers";
import { cardElements, defaultValue } from "./cardElements";

const fillUndefinedProps = (data) => {
  return {
    ...defaultValue,
    ...data,
  };
};
const MenuCanvaModalCard = () => {
  const { VAR_EDIT_MENUCARD, VAR_ADD_MENUCARD } = menuCanvaConstants;
  const { state, dispatch } = useContext(MenuCanvaContext);
  const { menuData, listData, cardData, rolesOptions, modalVariable } = state;

  const updateDataValues = (e) => {
    const filledProps = fillUndefinedProps(e);
    const submenuId = filledProps.id ? filledProps.id : generateUniqueId();
    const menuCardFilled = {
      ...filledProps,
      id: submenuId,
      pathmenu: `/${listData.id}/${submenuId}`,
    };
    if (modalVariable === VAR_EDIT_MENUCARD) {
      modifyCardToMenuList(dispatch, menuData, listData.id, menuCardFilled);
    } else if (modalVariable === VAR_ADD_MENUCARD) {
      addCardToMenuList(dispatch, menuData, listData.id, menuCardFilled);
    }
    setVariableModal(dispatch, null);
    setMenuListData(dispatch, null);
    setMenuCardData(dispatch, null);
  };
  const elements = cardElements;
  const elementRolesTargetIndex = cardElements.findIndex(
    (el) => el.name === "roles"
  );
  elements[elementRolesTargetIndex].options = rolesOptions;

  return (
    <BoxFloat
      title="Nuevo Submenú"
      onClickExit={() => setVariableModal(dispatch, null)}
      onClose={() => setVariableModal(dispatch, null)}
    >
      <FormGenerator
        form={{ elements: elements }}
        onSubmit={updateDataValues}
        initialValues={cardData}
      />
    </BoxFloat>
  );
};

export default MenuCanvaModalCard;
