import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import DropDownBox from "src/modules/components/common/DropDownBox/DropDownBox";
import Icon from "@mui/material/Icon";
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import { ViewContext } from "src/modules/contexts/ViewContextProvider";
import { useNavigate } from "react-router-dom";
import { Filetype, View as Views } from "src/lib/api.js";
import LazyLoad from "src/routes/Structure/LazyLoad/LazyLoad";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const EditView = ({
  viewData,
  showDropDown,
  setShowDropDown,
  updateView,
  ...props
}) => {
  const [data, setData] = useState(viewData);
  const { dispatch } = useContext(ViewContext);
  const { role, publicSetup } = useAppContext();
  const navigate = useNavigate();

  const [filetypeOptions, setFiletypeOptions] = useState([]);

  const onClose = () => {
    updateView(data, dispatch);
    setShowDropDown(!showDropDown);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await Filetype.list();
        const filetypesOptions = data.map((fileType) => {
          const value = fileType.name ?? "null";
          const label= value.charAt(0).toUpperCase() + value.slice(1)
          return ({
            label,
            value,
          })
        });
        setFiletypeOptions(filetypesOptions);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      }
    };
    loadData();
  }, []);

  const onChangeInput = (e, prop) => {
    let name = "";
    let value = "";
    if (!e.target) {
      name = prop;
      value = e.value;
      e.target = { name, value };
    } else {
      name = e.target.name;
      value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
    }
    const newData = {
      ...data,
      [name]: value,
    };
    setData(newData);
  };

  const deleteView = async (viewcode) => {
    try {
      await Views.delete(viewcode);
      navigate(`/`);
    } catch (error) {
      console.error("Error al eliminar la vista:", error);
    }
  };

  return (
    <LazyLoad>
      {publicSetup.developmode &&
        (role === "developer" || role === "superadmin") && (
          <div
            className="editViewModal"
            title="Haga click para editar la vista."
          >
            {showDropDown && (
              <DropDownBox
                title="Editor de Vista"
                setOpen={() => setShowDropDown(false)}
                onClosed={onClose}
              >
                <div
                  className="edit-view-container"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="edit-view-header">
                    <div
                      className="edit-view-buttons"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div className="edit-view-button-edit">
                        <Icon
                          title="Haz click para editar la vista"
                          onClick={() =>
                            navigate(`/view/edit?viewcode=${data.viewcode}`)
                          }
                        >
                          edit
                        </Icon>
                      </div>
                      <div className="edit-view-button-delete">
                        <Icon
                          title="Haz click para eliminar la vista"
                          onClick={() => deleteView(data.viewcode)}
                        >
                          delete
                        </Icon>
                      </div>
                    </div>
                  </div>
                  <div className="edit-view-fields">
                    <Input
                      label="Nombre"
                      name="name"
                      value={data.name}
                      onChange={(e) => onChangeInput(e)}
                      placeholder="Lista de clientes"
                      onBlur={() => {}}
                    />
                    <Input
                      label="Titulo"
                      name="title"
                      value={data.title}
                      onChange={(e) => onChangeInput(e)}
                      placeholder="Clientes"
                      onBlur={() => {}}
                    />

                    <Select
                      onChange={(e) => onChangeInput(e, "filetype")}
                      options={filetypeOptions}
                      label={"Filetype"}
                      name={"filetype"}
                      value={filetypeOptions.find(
                        (opt) => data.filetype === opt.value
                      )}
                    />

                    <Input
                      label="Publico"
                      name="public"
                      checked={data.public}
                      onChange={(e) => onChangeInput(e)}
                      type="checkbox"
                    />
                    <Input
                      label="System"
                      name="system"
                      checked={data.system}
                      onChange={(e) => onChangeInput(e)}
                      type="checkbox"
                    />
                    <Input
                      label="Activo"
                      name="active"
                      checked={data.active}
                      onChange={(e) => onChangeInput(e)}
                      type="checkbox"
                    />
                  </div>
                </div>
              </DropDownBox>
            )}
          </div>
        )}
    </LazyLoad>
  );
};

EditView.propTypes = {
  viewData: PropTypes.shape({
    viewcode: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    public: PropTypes.bool,
    system: PropTypes.bool,
    active: PropTypes.bool,
  }).isRequired,
  showDropDown: PropTypes.bool.isRequired,
  setShowDropDown: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
};

export default EditView;
