import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Enums {
  static async read(enumName) {
    const response = await HttpMethods.request_get(
      `/v1/enum/read/${enumName}`
    );
    if (!response) {
      addErrorMessageGlobal("Error al leer el enum");
    }
    return response?.enum;
  }

  static async list(framecode, first, limit, props) {
    if(!props || !props.format) {
      props = { format: "normal" }
    }
    const response = await HttpMethods.request_get(`/v1/enum/list?format=${props.format}`);
    if (!response) {
      addErrorMessageGlobal("Error al leer los enums");
    }
    return response;
  }

  static async auto(enumName, filetype, fileVariable, description) {
    const response = await HttpMethods.request_get(`/v1/enum/auto/${enumName}/${filetype}/${fileVariable}/${description}`);
    if (!response || response.error) {
      addErrorMessageGlobal("Error al leer el enum");
    }
    return response;
  }

  static async fullauto(filetype) {
    const response = await HttpMethods.request_get(`/v1/enum/fullauto/${filetype}`);
    if (!response) {
      addErrorMessageGlobal("Error al leer el enum");
    }
    return response
  } 

  static async create(enumObj) {
    const response = await HttpMethods.request_post(`/v1/enum/create`, enumObj);
    if (!response) {
      addErrorMessageGlobal("Error al crear el enum");
    }
    return response;
  }

  static async update(enumObj) {
    const response = await HttpMethods.request_post(`/v1/enum/update`, enumObj);
    if (!response) {
      addErrorMessageGlobal("Error al actualizar el enum");
    }
    return response;
  }

  static async crupdate(enumObj) {
    const response = await HttpMethods.request_post(`/v1/enum/crupdate`, enumObj);
    if (!response) {
      addErrorMessageGlobal("Error al crear o actualizar el enum");
    }
    return response;
  }

  static async export(enumName) {
    const response = await HttpMethods.request_get(`/v1/enum/export/${enumName}`);
    if (!response) {
      addErrorMessageGlobal("Error al exportar el enum");
    }
    return response;
  }

  static async delete(enumName) {
    const response = await HttpMethods.request_post(`/v1/enum/delete`, { name: enumName });
    if (!response) {
      addErrorMessageGlobal("Error al eliminar el enum");
    }
    return response;
  }
}