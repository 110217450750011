import React, { useContext, useEffect, useRef, useState } from "react";
import { fetchLogs, fetchOldLogs } from "src/modules/actions/serverLogActions";
import Title from "src/modules/components/common/Title/Title";
import { ServerLogContext } from "src/modules/contexts/ServerLogContextProvider";
import LogMessage from "./LogMessage";
import "./ServerLog.css";
import Select from "src/modules/components/common/Select/Select";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";

const ServerLog = () => {
  const refreshTimeSecondsOptions = [
    { label: "1s", value: 1 },
    { label: "5s", value: 5 },
    { label: "10s", value: 10 },
    { label: "30s", value: 30 },
  ];
  const levelOptions = [
    { label: "TRACE", value: 10 },
    { label: "DEBUG", value: 20 },
    { label: "INFO", value: 30 },
    { label: "WARN", value: 40 },
    { label: "ERROR", value: 50 },
    { label: "FATAL", value: 60 },
  ];

  const { state, dispatch } = useContext(ServerLogContext);
  const [refreshTimeSeconds, setRefreshTimeSeconds] = useState(
    refreshTimeSecondsOptions[1]
  );
  const [autoScroll, setAutoScroll] = useState(true);
  const [selectedLevels, setSelectedLevels] = useState([]);
  const [oldLogsActive, setOldLogsActive] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchInitialLogs = async () => {
      await fetchLogs(dispatch, 1000);
    };
    fetchInitialLogs();
  }, []);

  useEffect(() => {
    if(refreshTimeSeconds.value <= 0) return
    const interval = setInterval(async () => {
      await fetchLogs(dispatch, 100);
    }, refreshTimeSeconds.value * 1000);
    return () => clearInterval(interval);
  }, [refreshTimeSeconds]);

  useEffect(() => {
    if (autoScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [state.logs.length, autoScroll]);

  const onSelectRefreshTime = (e) => {
    setRefreshTimeSeconds(e);
  };

  // Aplicamos el filtro de nivel solo a los logs regulares
  const filteredLogs = selectedLevels.length === 0
    ? state.logs
    : state.logs.filter(log => selectedLevels.includes(log.level));

  const handleLevelChange = (selectedOptions) => {
    const newSelectedLevels = selectedOptions.map((option) => option.value);
    setSelectedLevels(newSelectedLevels);
  };

  const onChangeOldLogs = async (e) => {
    e.preventDefault()
    setOldLogsActive(true)
    await fetchOldLogs(dispatch, 100);
  }

  if (state.logs.length === 0 && state.loading) {
    return <Title text="Cargando..." />;
  }

  if (state.logs.length === 0 && !state.loading) {
    return <Title>No hay logs para mostrar</Title>;
  }
  
  return (
    <div className="server-logs">
      <div className="server-logs__controls">
        <Button
          disabled={oldLogsActive}
          onClick={onChangeOldLogs}
          content="Ver logs antiguos"
        />
        <Input
          disabled={oldLogsActive}
          type="checkbox"
          value={oldLogsActive ? false : autoScroll}
          onChange={() => setAutoScroll(!autoScroll)}
          label="Auto-Scroll"
        />
        <Select
          disabled={oldLogsActive}
          isMulti={true}
          name="levelFilter"
          placeholder="Filtrar por niveles de log..."
          options={levelOptions}
          onChange={handleLevelChange}
          value={levelOptions.filter((option) =>
            selectedLevels.includes(option.value)
          )}
          label="Niveles"
        />
        <Select
          disabled={oldLogsActive}
          options={refreshTimeSecondsOptions}
          value={refreshTimeSeconds}
          label="Actualizar cada:"
          isClearable={false}
          isSearchable={false}
          name="refreshTimeSeconds"
          onChange={onSelectRefreshTime}
        />
      </div>
      <div className="server-logs__messages-container">
        {filteredLogs.length === 0 ? (
          <div className="server-logs__no-results">
            No hay logs para los filtros seleccionados
          </div>
        ) : (
          filteredLogs.map((log, index) => {
            let a
            return (<LogMessage key={`log-${index}`} log={log} />)
          })
        )}
        <div ref={messagesEndRef} />
      </div>
    </div>
  );
};

export default ServerLog;