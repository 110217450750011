import React from "react";
import PropTypes from "prop-types";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Select from "src/modules/components/common/Select/Select";
import Button from "src/modules/components/common/Button/Button";

const PeriodicityModal = ({
  periodicityOptions,
  onPeriodicityChange,
  onClose,
  onSave,
}) => {
  return (
    <BoxFloat
      title="Periodicidad de los movimientos"
      onClickExit={onClose}
      footer={<Button icon="save" onClick={onSave} />}
    >
      <Select
        name="peridocity"
        options={periodicityOptions}
        placeholder="Ninguno"
        onChange={onPeriodicityChange}
        label="Tiempo"
      />
    </BoxFloat>
  );
};

PeriodicityModal.propTypes = {
  periodicityOptions: PropTypes.array.isRequired,
  onPeriodicityChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default PeriodicityModal;
