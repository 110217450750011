import React, { createContext, useReducer, useState, useContext, useEffect, useCallback, useRef } from "react";
import PropTypes from 'prop-types';
import { viewReducer } from "../reducers/viewReducer";
import { SSEClient } from 'src/lib/api/SSEClient';
import { useAppContext } from './AppContextProvider';

const initialState = {
    view: {},
    data: {},
    metaWindowData: {},
    users: [],
    selectedTask: "",
    loading: false,
    error: null
};

export const ViewContext = createContext(initialState);

export const ViewContextProvider = ({ children }) => {
    const { userSession } = useAppContext();
    const [state, dispatch] = useReducer(viewReducer, initialState);

    const [realTimeData, setRealTimeData] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [isReconnecting, setIsReconnecting] = useState(false);
    const [error, setError] = useState(null);

    const retryCountRef = useRef(0);
    const reconnectTimeoutRef = useRef(null);

    const handleMessage = useCallback((data) => {
        setRealTimeData(data);
        console.log('Datos actualizados ViewContextProvider:', data);
    }, []);

    const handleError = useCallback((err) => {
        setIsConnected(false);
        setError(err.message || 'Error en la conexión de datos en tiempo real.');
        console.error("Error en ViewContextProvider:", err);
        attemptReconnect();
    }, []);

    const handleComplete = useCallback(() => {
        setIsConnected(false);
        setError('La conexión SSE se ha cerrado.');
        console.log("Conexión SSE cerrada en ViewContextProvider.");
        attemptReconnect();
    }, []);

    const subscribe = useCallback(() => {
        if (SSEClient.isConnected || SSEClient.isConnecting) {
            console.warn('ViewContextProvider: Conexión ya activa o en proceso. Evitando doble suscripción.');
            return () => { };
        }

        const unsubscribe = SSEClient.subscribe({
            next: handleMessage,
            error: handleError,
            complete: handleComplete,
        });

        setIsConnected(true);
        console.log('ViewContextProvider: Suscripción SSE establecida.');
        return unsubscribe;
    }, [handleMessage, handleError, handleComplete]);


    const attemptReconnect = useCallback(() => {
        if (isReconnecting || SSEClient.isConnecting || SSEClient.isConnected) {
            console.log('ViewContextProvider: Reconexión ya en proceso o conexión activa. Evitando múltiples intentos.');
            return;
        }

        setIsReconnecting(true);

        if (retryCountRef.current < 5) {
            const retryDelay = Math.pow(2, retryCountRef.current) * 1000;
            console.log(`ViewContextProvider: Intentando reconexión en ${retryDelay / 1000} segundos...`);

            reconnectTimeoutRef.current = setTimeout(() => {
                if (userSession.token) {
                    console.log('ViewContextProvider: Intentando nueva suscripción...');
                    subscribe();
                    setError(null);
                    retryCountRef.current += 1;
                }
                setIsReconnecting(false);
            }, retryDelay);
        } else {
            console.error('ViewContextProvider: Máximo de intentos de reconexión alcanzado.');
            setError('No se pudo reconectar con el servidor. Por favor, intenta de nuevo más tarde.');
            setIsReconnecting(false);
        }
    }, [subscribe, userSession.token, isReconnecting]);


    useEffect(() => {
        if (!userSession.token) {
            console.warn('ViewContextProvider: No se encontró un token válido. No se puede suscribir a SSE.');
            return;
        }

        const unsubscribe = subscribe();

        retryCountRef.current = 0;

        return () => {
            if (unsubscribe) {
                unsubscribe();
                console.log('ViewContextProvider: Suscripción SSE desuscrita.');
            }
            if (reconnectTimeoutRef.current) {
                clearTimeout(reconnectTimeoutRef.current);
                reconnectTimeoutRef.current = null;
            }
            setIsConnected(false);
            console.log('ViewContextProvider: Hook desmontado, suscripción cerrada.');
        };
    }, [userSession.token, subscribe]);

    const value = {
        state,
        dispatch,
        error,
        isConnected,
        realTimeData
    };

    return (
        <ViewContext.Provider value={value}>
            {children}
        </ViewContext.Provider>
    );
};

ViewContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useViewContext = () => useContext(ViewContext);
