import { HttpMethods } from "./HttpMethods.js";

export class Log {
  static async dossier(code) {
    const response = await HttpMethods.request_get(`/v1/log/dossier/${code}`);
    return response;
  }

  static async list(first = 0, pagination = 100) {
    let response = await HttpMethods.request_get(`/v1/log/list?format=flat`);
    if (!response || response.error) {
      addErrorMessageGlobal("Error al obtener los usuarios");
      response = [];
    }
    return response;
  }
}
