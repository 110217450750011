import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Enums } from "src/lib/api";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import Select from "src/modules/components/common/Select/Select";
import EnumOption from "./ListEditorByType/EnumOption";
import "./EnumEditor.css";

const initialEnum = {
  header: {
    name: "",
    description: "",
    help: "",
    type: "",
  },
  list: [],
};

const enumTypes = [
  { value: "simple", label: "Simple" },
  { value: "relational", label: "Relacional" },
  { value: "color", label: "Color" },
  { value: "icons", label: "Iconos" },
];

const EnumEditor = () => {
  const [searchParams] = useSearchParams();
  const enumName = searchParams.get("name");
  const [enumData, setEnumData] = useState(initialEnum);

  useEffect(() => {
    const fetchData = async () => {
      if (enumName) {
        const response = await Enums.read(enumName);
        if (response && !response.error) {
          setEnumData(response);
        }
      }
    };
    fetchData();
  }, [enumName]);

  const saveEnum = async () => {
    await Enums.crupdate(enumData);
  };

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/view/denums");
  };

  const onEditList = (newList) => {
    setEnumData({
      ...enumData,
      list: newList,
    });
  };

  const addOption = () => {
    const type = enumData.header.type || "relational";
    const newEnumOption = type === "simple" ? "" : { value: "", label: "" };
    onEditList([...enumData.list, newEnumOption]);
  };

  const onDeleteOption = (index) => {
    const newList = [...enumData.list];
    newList.splice(index, 1);
    onEditList(newList);
  };

  return (
    <div className="container___EnumEditor">
      <div className="buttons___EnumEditor">
        <Button icon="arrow_back" onClick={goBack} />
        <Button icon="save" onClick={saveEnum} />
      </div>
      <h2>Cabecera</h2>
      <Input
        name="header.name"
        label="Nombre"
        value={enumData.header.name}
        onChange={(e) =>
          setEnumData({
            ...enumData,
            header: { ...enumData.header, name: e.target.value },
          })
        }
      />

      <Select
        name="header.type"
        label="Tipo"
        value={enumTypes.find((e) => e.value === enumData.header.type)}
        options={enumTypes}
        onChange={(e) =>
          setEnumData({
            ...enumData,
            header: { ...enumData.header, type: e.value },
          })
        }
      />

      <TextArea
        name="header.description"
        label="Descripción"
        value={enumData.header.description}
        onChange={(e) =>
          setEnumData({
            ...enumData,
            header: { ...enumData.header, description: e.target.value },
          })
        }
      />
      <Input
        name="header.help"
        label="Link de ayuda"
        value={enumData.header.help}
        onChange={(e) =>
          setEnumData({
            ...enumData,
            header: { ...enumData.header, help: e.target.value },
          })
        }
        type="url"
      />

      {enumData.header.type && (
        <>
          <h2>Opciones</h2>

          <Button icon="add" onClick={addOption} />

          {enumData.list.map((item, index) => (
            <div className="option-container___EnumEditor" key={`enum_option-${index}`}>
              <EnumOption
                option={item}
                index={index}
                enumData={enumData}
                onEditList={onEditList}
              />
              <Button onClick={() => onDeleteOption(index)} icon="delete" />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default EnumEditor;
