export const listElements = [
  {
    name: "title",
    component: "input",
    type: "text",
    label: "Titulo",
    key: "title",
    placeholder: "Nuevo Menú",
  },
  {
    name: "pathmenu",
    component: "input",
    type: "text",
    label: "Path Menu",
    key: "pathmenu",
    disabled: true,
  },
  {
    name: "id",
    component: "input",
    type: "text",
    label: "ID",
    key: "id",
    placeholder: "nuevomenu2",
    disabled: true,
  },
  {
    name: "url",
    component: "input",
    type: "text",
    label: "URL",
    key: "url",
    placeholder: "/nuevomenu2",
  },
  {
    name: "outwindow",
    component: "input",
    type: "checkbox",
    label: "Out Window",
    key: "outwindow",
    checked: false,
  },
  {
    name: "roles",
    component: "multiselect",
    type: "text",
    label: "Roles",
    key: "roles",
    options: null,
  },
  {
    name: "active",
    component: "input",
    type: "checkbox",
    label: "Activo",
    key: "active",
    checked: true,
  },
];

export const defaultValue = {
  title: "Nuevo Menú",
  pathmenu: "",
  id: null,
  url: "/",
  outwindow: false,
  roles: [],
  active: true,
  submenus: [],
};
