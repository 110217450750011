import React from "react";
import Input from "src/modules/components/common/Input/Input";
import ColorPicker from "src/modules/components/common/ColorPicker/ColorPicker";

// Label con selector de color
const ColorEnum = ({ option, onChange }) => {
  return (
    <>
      <Input
        name="value"
        label="Valor:"
        value={option.value}
        onChange={onChange}
      />
      <ColorPicker
        name="label"
        label="Color de fondo:"
        value={option.label}
        onChange={onChange}
      />
    </>
  );
};

export default ColorEnum;
