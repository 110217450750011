import React, { useState, useEffect } from "react";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";

const MovimientosServices = ({
  movementData,
  setMovementData,
  selectedItem,
  clearSelectedItem,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [service, setService] = useState("");
  const isEditing =
    selectedItem.category === "service" && selectedItem.index !== null;

  useEffect(() => {
    if (isEditing) {
      const existingService = movementData.service[selectedItem.index];
      setService(existingService);
      setShowForm(true);
    }
  }, [selectedItem]);

  const handleInputChange = (e) => {
    setService(e.target.value);
  };

  const handleSave = () => {
    if (isEditing) {
      const updatedArray = [...movementData.service];
      updatedArray[selectedItem.index] = service;
      setMovementData({ ...movementData, service: updatedArray });
    } else {
      setMovementData({
        ...movementData,
        service: [...movementData.service, service],
      });
    }
    setShowForm(false);
    clearSelectedItem();
    setService("");
  };

  const handleDelete = () => {
    if (isEditing) {
      const updatedArray = movementData.service.filter(
        (_, idx) => idx !== selectedItem.index
      );
      setMovementData({ ...movementData, service: updatedArray });
      setShowForm(false);
      clearSelectedItem();
      setService("");
    }
  };

  return (
    <div className="movimientosTab">
      <Button
        icon="add"
        onClick={() => {
          setShowForm(true);
          setService("");
        }}
      />
      {showForm && (
        <>
          <div className="movimientosTab-editFields">
            <Input
              name="service"
              type="text"
              placeholder="Nombre del servicio"
              onChange={handleInputChange}
              value={service}
              label="Servicio"
            />
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button icon="save" onClick={handleSave} />
            {isEditing && <Button icon="delete" onClick={handleDelete} />}
          </div>
        </>
      )}
    </div>
  );
};

export default MovimientosServices;
