
  import { Icon } from '@mui/material';
  import React from 'react';

  export const icons = [
    {
      value: "10k",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>10k</Icon> 10k
      </div>),
      tags: ["10000", "10K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "10mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>10mp</Icon> 10mp
      </div>),
      tags: ["10", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "11mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>11mp</Icon> 11mp
      </div>),
      tags: ["11", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "123",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>123</Icon> 123
      </div>),
      tags: ["1", "2", "3", "digit", "numbers", "symbol"]
    },
		{
      value: "12mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>12mp</Icon> 12mp
      </div>),
      tags: ["12", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "13mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>13mp</Icon> 13mp
      </div>),
      tags: ["13", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "14mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>14mp</Icon> 14mp
      </div>),
      tags: ["14", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "15mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>15mp</Icon> 15mp
      </div>),
      tags: ["15", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "16mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>16mp</Icon> 16mp
      </div>),
      tags: ["16", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "17mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>17mp</Icon> 17mp
      </div>),
      tags: ["17", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "18_up_rating",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>18_up_rating</Icon> 18_up_rating
      </div>),
      tags: []
    },
		{
      value: "18mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>18mp</Icon> 18mp
      </div>),
      tags: ["18", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "19mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>19mp</Icon> 19mp
      </div>),
      tags: ["19", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "1k",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>1k</Icon> 1k
      </div>),
      tags: ["1000", "1K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "1k_plus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>1k_plus</Icon> 1k_plus
      </div>),
      tags: ["+", "1000", "1K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "plus", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "1x_mobiledata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>1x_mobiledata</Icon> 1x_mobiledata
      </div>),
      tags: ["1x", "alphabet", "cellular", "character", "digit", "font", "letters", "mobile", "mobiledata", "network", "numbers", "phone", "signal", "speed", "symbol", "text", "type", "wifi"]
    },
		{
      value: "20mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>20mp</Icon> 20mp
      </div>),
      tags: ["20", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "21mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>21mp</Icon> 21mp
      </div>),
      tags: ["21", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "22mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>22mp</Icon> 22mp
      </div>),
      tags: ["22", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "23mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>23mp</Icon> 23mp
      </div>),
      tags: ["23", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "24mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>24mp</Icon> 24mp
      </div>),
      tags: ["24", "camera", "digits", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "2k",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>2k</Icon> 2k
      </div>),
      tags: ["2000", "2K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "2k_plus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>2k_plus</Icon> 2k_plus
      </div>),
      tags: ["+", "2k", "alphabet", "character", "digit", "font", "letters", "numbers", "plus", "symbol", "text", "type"]
    },
		{
      value: "2mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>2mp</Icon> 2mp
      </div>),
      tags: ["2", "camera", "digit", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "30fps",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>30fps</Icon> 30fps
      </div>),
      tags: ["30fps", "alphabet", "camera", "character", "digit", "font", "fps", "frames", "letters", "numbers", "symbol", "text", "type", "video"]
    },
		{
      value: "30fps_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>30fps_select</Icon> 30fps_select
      </div>),
      tags: ["30", "camera", "digits", "fps", "frame", "frequency", "image", "numbers", "per", "rate", "second", "seconds", "select", "video"]
    },
		{
      value: "360",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>360</Icon> 360
      </div>),
      tags: ["360", "arrow", "av", "camera", "direction", "rotate", "rotation", "virtual reality", "vr"]
    },
		{
      value: "3d_rotation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>3d_rotation</Icon> 3d_rotation
      </div>),
      tags: ["3", "3d", "D", "alphabet", "arrow", "arrows", "av", "camera", "character", "digit", "font", "letters", "numbers", "rotation", "symbol", "text", "type", "virtual_reality", "vr"]
    },
		{
      value: "3g_mobiledata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>3g_mobiledata</Icon> 3g_mobiledata
      </div>),
      tags: ["3g", "alphabet", "cellular", "character", "digit", "font", "letters", "mobile", "mobiledata", "network", "numbers", "phone", "signal", "speed", "symbol", "text", "type", "wifi"]
    },
		{
      value: "3k",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>3k</Icon> 3k
      </div>),
      tags: ["3000", "3K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "3k_plus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>3k_plus</Icon> 3k_plus
      </div>),
      tags: ["+", "3000", "3K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "plus", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "3mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>3mp</Icon> 3mp
      </div>),
      tags: ["3", "camera", "digit", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "3p",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>3p</Icon> 3p
      </div>),
      tags: ["3", "3p", "account", "avatar", "bubble", "chat", "comment", "communicate", "face", "human", "message", "party", "people", "person", "profile", "speech", "user"]
    },
		{
      value: "4g_mobiledata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>4g_mobiledata</Icon> 4g_mobiledata
      </div>),
      tags: ["4g", "alphabet", "cellular", "character", "digit", "font", "letters", "mobile", "mobiledata", "network", "numbers", "phone", "signal", "speed", "symbol", "text", "type", "wifi"]
    },
		{
      value: "4g_plus_mobiledata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>4g_plus_mobiledata</Icon> 4g_plus_mobiledata
      </div>),
      tags: ["4g", "alphabet", "cellular", "character", "digit", "font", "letters", "mobile", "mobiledata", "network", "numbers", "phone", "plus", "signal", "speed", "symbol", "text", "type", "wifi"]
    },
		{
      value: "4k",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>4k</Icon> 4k
      </div>),
      tags: ["4000", "4K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "4k_plus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>4k_plus</Icon> 4k_plus
      </div>),
      tags: ["+", "4000", "4K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "plus", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "4mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>4mp</Icon> 4mp
      </div>),
      tags: ["4", "camera", "digit", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "5g",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>5g</Icon> 5g
      </div>),
      tags: ["5g", "alphabet", "cellular", "character", "data", "digit", "font", "letters", "mobile", "network", "numbers", "phone", "signal", "speed", "symbol", "text", "type", "wifi"]
    },
		{
      value: "5k",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>5k</Icon> 5k
      </div>),
      tags: ["5000", "5K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "5k_plus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>5k_plus</Icon> 5k_plus
      </div>),
      tags: ["+", "5000", "5K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "plus", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "5mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>5mp</Icon> 5mp
      </div>),
      tags: ["5", "camera", "digit", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "60fps",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>60fps</Icon> 60fps
      </div>),
      tags: ["60fps", "camera", "digit", "fps", "frames", "numbers", "symbol", "video"]
    },
		{
      value: "60fps_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>60fps_select</Icon> 60fps_select
      </div>),
      tags: ["60", "camera", "digits", "fps", "frame", "frequency", "numbers", "per", "rate", "second", "seconds", "select", "video"]
    },
		{
      value: "6_ft_apart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>6_ft_apart</Icon> 6_ft_apart
      </div>),
      tags: ["6", "apart", "body", "covid", "distance", "feet", "ft", "human", "people", "person", "social"]
    },
		{
      value: "6k",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>6k</Icon> 6k
      </div>),
      tags: ["6000", "6K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "6k_plus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>6k_plus</Icon> 6k_plus
      </div>),
      tags: ["+", "6000", "6K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "plus", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "6mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>6mp</Icon> 6mp
      </div>),
      tags: ["6", "camera", "digit", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "7k",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>7k</Icon> 7k
      </div>),
      tags: ["7000", "7K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "7k_plus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>7k_plus</Icon> 7k_plus
      </div>),
      tags: ["+", "7000", "7K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "plus", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "7mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>7mp</Icon> 7mp
      </div>),
      tags: ["7", "camera", "digit", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "8k",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>8k</Icon> 8k
      </div>),
      tags: ["8000", "8K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "8k_plus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>8k_plus</Icon> 8k_plus
      </div>),
      tags: ["+", "7000", "8K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "plus", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "8mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>8mp</Icon> 8mp
      </div>),
      tags: ["8", "camera", "digit", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "9k",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>9k</Icon> 9k
      </div>),
      tags: ["9000", "9K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "9k_plus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>9k_plus</Icon> 9k_plus
      </div>),
      tags: ["+", "9000", "9K", "alphabet", "character", "digit", "display", "font", "letters", "numbers", "pixel", "pixels", "plus", "resolution", "symbol", "text", "type", "video"]
    },
		{
      value: "9mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>9mp</Icon> 9mp
      </div>),
      tags: ["9", "camera", "digit", "font", "image", "letters", "megapixel", "megapixels", "mp", "numbers", "pixel", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "abc",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>abc</Icon> abc
      </div>),
      tags: ["alphabet", "character", "font", "letters", "symbol", "text", "type"]
    },
		{
      value: "ac_unit",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ac_unit</Icon> ac_unit
      </div>),
      tags: ["ac", "air", "cold", "conditioner", "flake", "snow", "snowflake", "temperature", "unit", "weather", "winter"]
    },
		{
      value: "access_alarm",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>access_alarm</Icon> access_alarm
      </div>),
      tags: []
    },
		{
      value: "access_alarms",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>access_alarms</Icon> access_alarms
      </div>),
      tags: []
    },
		{
      value: "access_time",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>access_time</Icon> access_time
      </div>),
      tags: []
    },
		{
      value: "access_time_filled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>access_time_filled</Icon> access_time_filled
      </div>),
      tags: []
    },
		{
      value: "accessibility",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>accessibility</Icon> accessibility
      </div>),
      tags: ["accessibility", "accessible", "body", "handicap", "help", "human", "people", "person"]
    },
		{
      value: "accessibility_new",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>accessibility_new</Icon> accessibility_new
      </div>),
      tags: ["accessibility", "accessible", "body", "handicap", "help", "human", "new", "people", "person"]
    },
		{
      value: "accessible",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>accessible</Icon> accessible
      </div>),
      tags: ["accessibility", "accessible", "body", "handicap", "help", "human", "people", "person", "wheelchair"]
    },
		{
      value: "accessible_forward",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>accessible_forward</Icon> accessible_forward
      </div>),
      tags: ["accessibility", "accessible", "body", "forward", "handicap", "help", "human", "people", "person", "wheelchair"]
    },
		{
      value: "account_balance",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>account_balance</Icon> account_balance
      </div>),
      tags: ["account", "balance", "bank", "bill", "building", "card", "cash", "coin", "commerce", "credit", "currency", "dollars", "finance", "government", "money", "online", "pay", "payment"]
    },
		{
      value: "account_balance_wallet",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>account_balance_wallet</Icon> account_balance_wallet
      </div>),
      tags: ["account", "balance", "bank", "bill", "card", "cash", "coin", "commerce", "credit", "currency", "dollars", "finance", "money", "online", "pay", "payment", "wallet"]
    },
		{
      value: "account_box",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>account_box</Icon> account_box
      </div>),
      tags: ["account", "avatar", "box", "face", "human", "people", "person", "profile", "square", "thumbnail", "user"]
    },
		{
      value: "account_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>account_circle</Icon> account_circle
      </div>),
      tags: ["account", "avatar", "circle", "face", "human", "people", "person", "profile", "thumbnail", "user"]
    },
		{
      value: "account_tree",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>account_tree</Icon> account_tree
      </div>),
      tags: ["account", "analytics", "chart", "connect", "data", "diagram", "flow", "graph", "infographic", "measure", "metrics", "process", "square", "statistics", "structure", "tracking", "tree"]
    },
		{
      value: "ad_units",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ad_units</Icon> ad_units
      </div>),
      tags: ["Android", "OS", "ad", "banner", "cell", "device", "hardware", "iOS", "mobile", "notification", "notifications", "phone", "tablet", "top", "units"]
    },
		{
      value: "adb",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>adb</Icon> adb
      </div>),
      tags: ["adb", "android", "bridge", "debug"]
    },
		{
      value: "add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add</Icon> add
      </div>),
      tags: ["+", "add", "new symbol", "plus", "symbol"]
    },
		{
      value: "add_a_photo",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_a_photo</Icon> add_a_photo
      </div>),
      tags: ["+", "a photo", "add", "camera", "lens", "new", "photography", "picture", "plus", "symbol"]
    },
		{
      value: "add_alarm",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_alarm</Icon> add_alarm
      </div>),
      tags: []
    },
		{
      value: "add_alert",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_alert</Icon> add_alert
      </div>),
      tags: ["+", "active", "add", "alarm", "alert", "bell", "chime", "new", "notifications", "notify", "plus", "reminder", "ring", "sound", "symbol"]
    },
		{
      value: "add_box",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_box</Icon> add_box
      </div>),
      tags: ["add", "box", "new square", "plus", "symbol"]
    },
		{
      value: "add_business",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_business</Icon> add_business
      </div>),
      tags: ["+", "add", "bill", "building", "business", "card", "cash", "coin", "commerce", "company", "credit", "currency", "dollars", "market", "money", "new", "online", "pay", "payment", "plus", "shop", "shopping", "store", "storefront", "symbol"]
    },
		{
      value: "add_card",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_card</Icon> add_card
      </div>),
      tags: ["+", "add", "bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollars", "finance", "money", "new", "online", "pay", "payment", "plus", "price", "shopping", "symbol"]
    },
		{
      value: "add_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_chart</Icon> add_chart
      </div>),
      tags: ["+", "add", "analytics", "bar", "bars", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "new", "plus", "statistics", "symbol", "tracking"]
    },
		{
      value: "add_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_circle</Icon> add_circle
      </div>),
      tags: ["+", "add", "circle", "counter", "create", "new", "plus"]
    },
		{
      value: "add_circle_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_circle_outline</Icon> add_circle_outline
      </div>),
      tags: ["+", "add", "circle", "create", "new", "outline", "plus"]
    },
		{
      value: "add_comment",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_comment</Icon> add_comment
      </div>),
      tags: ["+", "add", "bubble", "chat", "comment", "communicate", "feedback", "message", "new", "plus", "speech", "symbol"]
    },
		{
      value: "add_home",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_home</Icon> add_home
      </div>),
      tags: []
    },
		{
      value: "add_home_work",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_home_work</Icon> add_home_work
      </div>),
      tags: []
    },
		{
      value: "add_ic_call",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_ic_call</Icon> add_ic_call
      </div>),
      tags: ["+", "add", "call", "cell", "contact", "device", "hardware", "mobile", "new", "phone", "plus", "symbol", "telephone"]
    },
		{
      value: "add_link",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_link</Icon> add_link
      </div>),
      tags: ["add", "attach", "clip", "link", "new", "plus", "symbol"]
    },
		{
      value: "add_location",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_location</Icon> add_location
      </div>),
      tags: ["+", "add", "destination", "direction", "location", "maps", "new", "pin", "place", "plus", "stop", "symbol"]
    },
		{
      value: "add_location_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_location_alt</Icon> add_location_alt
      </div>),
      tags: ["+", "add", "alt", "destination", "direction", "location", "maps", "new", "pin", "place", "plus", "stop", "symbol"]
    },
		{
      value: "add_moderator",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_moderator</Icon> add_moderator
      </div>),
      tags: ["+", "add", "certified", "moderator", "new", "plus", "privacy", "private", "protect", "protection", "security", "shield", "symbol", "verified"]
    },
		{
      value: "add_photo_alternate",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_photo_alternate</Icon> add_photo_alternate
      </div>),
      tags: ["+", "add", "alternate", "image", "landscape", "mountain", "mountains", "new", "photo", "photography", "picture", "plus", "symbol"]
    },
		{
      value: "add_reaction",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_reaction</Icon> add_reaction
      </div>),
      tags: ["+", "add", "emoji", "emotions", "expressions", "face", "feelings", "glad", "happiness", "happy", "icon", "icons", "insert", "like", "mood", "new", "person", "pleased", "plus", "smile", "smiling", "social", "survey", "symbol"]
    },
		{
      value: "add_road",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_road</Icon> add_road
      </div>),
      tags: ["+", "add", "destination", "direction", "highway", "maps", "new", "plus", "road", "stop", "street", "symbol", "traffic"]
    },
		{
      value: "add_shopping_cart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_shopping_cart</Icon> add_shopping_cart
      </div>),
      tags: ["add", "card", "cart", "cash", "checkout", "coin", "commerce", "credit", "currency", "dollars", "money", "online", "pay", "payment", "plus", "shopping"]
    },
		{
      value: "add_task",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_task</Icon> add_task
      </div>),
      tags: ["+", "add", "approve", "check", "circle", "completed", "increase", "mark", "ok", "plus", "select", "task", "tick", "yes"]
    },
		{
      value: "add_to_drive",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_to_drive</Icon> add_to_drive
      </div>),
      tags: ["add", "app", "application", "backup", "cloud", "drive", "files", "folders", "gdrive", "google", "recovery", "shortcut", "storage"]
    },
		{
      value: "add_to_home_screen",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_to_home_screen</Icon> add_to_home_screen
      </div>),
      tags: ["Android", "OS", "add to", "arrow", "cell", "device", "hardware", "home", "iOS", "mobile", "phone", "screen", "tablet", "up"]
    },
		{
      value: "add_to_photos",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_to_photos</Icon> add_to_photos
      </div>),
      tags: ["add", "collection", "image", "landscape", "mountain", "mountains", "photo", "photography", "photos", "picture", "plus", "to"]
    },
		{
      value: "add_to_queue",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>add_to_queue</Icon> add_to_queue
      </div>),
      tags: ["+", "Android", "OS", "add", "chrome", "desktop", "device", "display", "hardware", "iOS", "mac", "monitor", "new", "plus", "queue", "screen", "symbol", "to", "web", "window"]
    },
		{
      value: "addchart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>addchart</Icon> addchart
      </div>),
      tags: ["+", "addchart", "analytics", "bar", "bars", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "new", "plus", "statistics", "symbol", "tracking"]
    },
		{
      value: "adf_scanner",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>adf_scanner</Icon> adf_scanner
      </div>),
      tags: ["adf", "document", "feeder", "machine", "office", "scan", "scanner"]
    },
		{
      value: "adjust",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>adjust</Icon> adjust
      </div>),
      tags: ["adjust", "alter", "auto click", "center", "circle", "circles", "dot", "fix", "focus", "image", "move", "target"]
    },
		{
      value: "admin_panel_settings",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>admin_panel_settings</Icon> admin_panel_settings
      </div>),
      tags: ["account", "admin", "avatar", "certified", "face", "human", "panel", "people", "person", "privacy", "private", "profile", "protect", "protection", "security", "settings", "shield", "user", "verified"]
    },
		{
      value: "ads_click",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ads_click</Icon> ads_click
      </div>),
      tags: ["ads", "browser", "click", "clicks", "cursor", "internet", "target", "traffic", "web"]
    },
		{
      value: "agriculture",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>agriculture</Icon> agriculture
      </div>),
      tags: ["agriculture", "automobile", "car", "cars", "cultivation", "farm", "harvest", "maps", "tractor", "transport", "travel", "truck", "vehicle"]
    },
		{
      value: "air",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>air</Icon> air
      </div>),
      tags: ["air", "blowing", "breeze", "flow", "wave", "weather", "wind"]
    },
		{
      value: "airline_seat_flat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airline_seat_flat</Icon> airline_seat_flat
      </div>),
      tags: ["airline", "body", "business", "class", "first", "flat", "human", "people", "person", "rest", "seat", "sleep", "travel"]
    },
		{
      value: "airline_seat_flat_angled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airline_seat_flat_angled</Icon> airline_seat_flat_angled
      </div>),
      tags: ["airline", "angled", "body", "business", "class", "first", "flat", "human", "people", "person", "rest", "seat", "sleep", "travel"]
    },
		{
      value: "airline_seat_individual_suite",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airline_seat_individual_suite</Icon> airline_seat_individual_suite
      </div>),
      tags: ["airline", "body", "business", "class", "first", "human", "individual", "people", "person", "rest", "seat", "sleep", "suite", "travel"]
    },
		{
      value: "airline_seat_legroom_extra",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airline_seat_legroom_extra</Icon> airline_seat_legroom_extra
      </div>),
      tags: ["airline", "body", "extra", "feet", "human", "leg", "legroom", "people", "person", "seat", "sitting", "space", "travel"]
    },
		{
      value: "airline_seat_legroom_normal",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airline_seat_legroom_normal</Icon> airline_seat_legroom_normal
      </div>),
      tags: ["airline", "body", "feet", "human", "leg", "legroom", "normal", "people", "person", "seat", "sitting", "space", "travel"]
    },
		{
      value: "airline_seat_legroom_reduced",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airline_seat_legroom_reduced</Icon> airline_seat_legroom_reduced
      </div>),
      tags: ["airline", "body", "feet", "human", "leg", "legroom", "people", "person", "reduced", "seat", "sitting", "space", "travel"]
    },
		{
      value: "airline_seat_recline_extra",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airline_seat_recline_extra</Icon> airline_seat_recline_extra
      </div>),
      tags: ["airline", "body", "extra", "feet", "human", "leg", "legroom", "people", "person", "seat", "sitting", "space", "travel"]
    },
		{
      value: "airline_seat_recline_normal",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airline_seat_recline_normal</Icon> airline_seat_recline_normal
      </div>),
      tags: ["airline", "body", "extra", "feet", "human", "leg", "legroom", "normal", "people", "person", "recline", "seat", "sitting", "space", "travel"]
    },
		{
      value: "airline_stops",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airline_stops</Icon> airline_stops
      </div>),
      tags: ["airline", "arrow", "destination", "direction", "layover", "location", "maps", "place", "stops", "transportation", "travel", "trip"]
    },
		{
      value: "airlines",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airlines</Icon> airlines
      </div>),
      tags: ["air", "aircraft", "airplane", "airplanes", "airport", "flight", "flights", "fly", "flying", "mode", "on", "plane", "planes", "signal", "transportation", "travel", "trip"]
    },
		{
      value: "airplane_ticket",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airplane_ticket</Icon> airplane_ticket
      </div>),
      tags: ["air", "aircraft", "airplane", "airplanes", "airport", "boarding", "boarding pass", "flight", "flights", "fly", "flying", "maps", "pass", "plane", "planes", "signal", "ticket", "transportation", "travel", "trip"]
    },
		{
      value: "airplanemode_active",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airplanemode_active</Icon> airplanemode_active
      </div>),
      tags: ["air", "aircraft", "airplane", "airplanes", "airport", "flight", "flights", "fly", "flying", "mode", "on", "plane", "planes", "signal", "transportation", "travel", "trip"]
    },
		{
      value: "airplanemode_inactive",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airplanemode_inactive</Icon> airplanemode_inactive
      </div>),
      tags: ["air", "aircraft", "airplane", "airplanes", "airport", "disabled", "enabled", "flight", "flights", "fly", "flying", "inactive", "maps", "mode", "off", "offline", "on", "plane", "planes", "signal", "slash", "transportation", "travel", "trip"]
    },
		{
      value: "airplay",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airplay</Icon> airplay
      </div>),
      tags: ["airplay", "arrow", "connect", "control", "desktop", "device", "display", "monitor", "screen", "signal"]
    },
		{
      value: "airport_shuttle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>airport_shuttle</Icon> airport_shuttle
      </div>),
      tags: ["airport", "automobile", "car", "cars", "commercial", "delivery", "direction", "maps", "mini", "public", "shuttle", "transport", "transportation", "travel", "truck", "van", "vehicle"]
    },
		{
      value: "alarm",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>alarm</Icon> alarm
      </div>),
      tags: ["alarm", "alert", "bell", "clock", "countdown", "date", "notification", "schedule", "time"]
    },
		{
      value: "alarm_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>alarm_add</Icon> alarm_add
      </div>),
      tags: ["+", "add", "alarm", "alert", "bell", "clock", "countdown", "date", "new", "notification", "plus", "schedule", "symbol", "time"]
    },
		{
      value: "alarm_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>alarm_off</Icon> alarm_off
      </div>),
      tags: ["alarm", "alert", "bell", "check", "checkmark", "clock", "disabled", "duration", "enabled", "notification", "off", "on", "slash", "time", "timer", "watch"]
    },
		{
      value: "alarm_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>alarm_on</Icon> alarm_on
      </div>),
      tags: ["alarm", "alert", "bell", "check", "checkmark", "clock", "duration", "enabled", "notification", "on", "time", "timer", "watch"]
    },
		{
      value: "album",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>album</Icon> album
      </div>),
      tags: ["album", "artist", "audio", "bvb", "cd", "computer", "data", "disk", "file", "music", "record", "sound", "storage", "track"]
    },
		{
      value: "align_horizontal_center",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>align_horizontal_center</Icon> align_horizontal_center
      </div>),
      tags: ["align", "alignment", "center", "format", "horizontal", "layout", "lines", "paragraph", "rule", "rules", "style", "text"]
    },
		{
      value: "align_horizontal_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>align_horizontal_left</Icon> align_horizontal_left
      </div>),
      tags: ["align", "alignment", "format", "horizontal", "layout", "left", "lines", "paragraph", "rule", "rules", "style", "text"]
    },
		{
      value: "align_horizontal_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>align_horizontal_right</Icon> align_horizontal_right
      </div>),
      tags: ["align", "alignment", "format", "horizontal", "layout", "lines", "paragraph", "right", "rule", "rules", "style", "text"]
    },
		{
      value: "align_vertical_bottom",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>align_vertical_bottom</Icon> align_vertical_bottom
      </div>),
      tags: ["align", "alignment", "bottom", "format", "layout", "lines", "paragraph", "rule", "rules", "style", "text", "vertical"]
    },
		{
      value: "align_vertical_center",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>align_vertical_center</Icon> align_vertical_center
      </div>),
      tags: ["align", "alignment", "center", "format", "layout", "lines", "paragraph", "rule", "rules", "style", "text", "vertical"]
    },
		{
      value: "align_vertical_top",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>align_vertical_top</Icon> align_vertical_top
      </div>),
      tags: ["align", "alignment", "format", "layout", "lines", "paragraph", "rule", "rules", "style", "text", "top", "vertical"]
    },
		{
      value: "all_inbox",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>all_inbox</Icon> all_inbox
      </div>),
      tags: ["Inbox", "all", "delivered", "delivery", "email", "mail", "message", "send"]
    },
		{
      value: "all_inclusive",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>all_inclusive</Icon> all_inclusive
      </div>),
      tags: ["all", "endless", "forever", "inclusive", "infinity", "loop", "mobius", "neverending", "strip", "sustainability", "sustainable"]
    },
		{
      value: "all_out",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>all_out</Icon> all_out
      </div>),
      tags: ["all", "circle", "out", "shape"]
    },
		{
      value: "alt_route",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>alt_route</Icon> alt_route
      </div>),
      tags: ["alt", "alternate", "alternative", "arrows", "dash", "dashed", "direction", "maps", "navigation", "options", "other", "route", "routes", "split", "symbol"]
    },
		{
      value: "alternate_email",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>alternate_email</Icon> alternate_email
      </div>),
      tags: ["@", "address", "alternate", "contact", "email", "tag"]
    },
		{
      value: "analytics",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>analytics</Icon> analytics
      </div>),
      tags: ["analytics", "assessment", "bar", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "statistics", "tracking"]
    },
		{
      value: "anchor",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>anchor</Icon> anchor
      </div>),
      tags: ["anchor", "google", "logo"]
    },
		{
      value: "android",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>android</Icon> android
      </div>),
      tags: ["android", "character", "logo", "mascot", "toy"]
    },
		{
      value: "animation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>animation</Icon> animation
      </div>),
      tags: ["animation", "circles", "film", "motion", "movement", "sequence", "video"]
    },
		{
      value: "announcement",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>announcement</Icon> announcement
      </div>),
      tags: ["!", "alert", "announcement", "attention", "bubble", "caution", "chat", "comment", "communicate", "danger", "error", "exclamation", "feedback", "important", "mark", "message", "notification", "speech", "symbol", "warning"]
    },
		{
      value: "aod",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>aod</Icon> aod
      </div>),
      tags: ["Android", "OS", "always", "aod", "device", "display", "hardware", "homescreen", "iOS", "mobile", "on", "phone", "tablet"]
    },
		{
      value: "apartment",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>apartment</Icon> apartment
      </div>),
      tags: ["accommodation", "apartment", "architecture", "building", "city", "company", "estate", "flat", "home", "house", "office", "places", "real", "residence", "residential", "shelter", "units", "workplace"]
    },
		{
      value: "api",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>api</Icon> api
      </div>),
      tags: ["api", "developer", "development", "enterprise", "software"]
    },
		{
      value: "app_blocking",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>app_blocking</Icon> app_blocking
      </div>),
      tags: ["Android", "OS", "app", "application", "block", "blocking", "cancel", "cell", "device", "hardware", "iOS", "mobile", "phone", "stop", "stopped", "tablet"]
    },
		{
      value: "app_registration",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>app_registration</Icon> app_registration
      </div>),
      tags: ["app", "apps", "edit", "pencil", "register", "registration"]
    },
		{
      value: "app_settings_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>app_settings_alt</Icon> app_settings_alt
      </div>),
      tags: ["Android", "OS", "app", "applications", "cell", "device", "gear", "hardware", "iOS", "mobile", "phone", "setting", "settings", "tablet"]
    },
		{
      value: "app_shortcut",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>app_shortcut</Icon> app_shortcut
      </div>),
      tags: ["app", "bookmarked", "favorite", "highlight", "important", "marked", "mobile", "save", "saved", "shortcut", "software", "special", "star"]
    },
		{
      value: "approval",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>approval</Icon> approval
      </div>),
      tags: ["apply", "approval", "approvals", "approve", "certificate", "certification", "disapproval", "drive", "file", "impression", "ink", "mark", "postage", "stamp"]
    },
		{
      value: "apps",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>apps</Icon> apps
      </div>),
      tags: ["all", "applications", "apps", "circles", "collection", "components", "dots", "grid", "interface", "squares", "ui", "ux"]
    },
		{
      value: "apps_outage",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>apps_outage</Icon> apps_outage
      </div>),
      tags: ["all", "applications", "apps", "circles", "collection", "components", "dots", "grid", "interface", "outage", "squares", "ui", "ux"]
    },
		{
      value: "architecture",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>architecture</Icon> architecture
      </div>),
      tags: ["architecture", "art", "compass", "design", "draw", "drawing", "engineering", "geometric", "tool"]
    },
		{
      value: "archive",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>archive</Icon> archive
      </div>),
      tags: ["archive", "inbox", "mail", "store"]
    },
		{
      value: "area_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>area_chart</Icon> area_chart
      </div>),
      tags: ["analytics", "area", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "statistics", "tracking"]
    },
		{
      value: "arrow_back",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_back</Icon> arrow_back
      </div>),
      tags: ["DISABLE_IOS", "app", "application", "arrow", "back", "components", "direction", "disable_ios", "interface", "left", "navigation", "previous", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "arrow_back_ios",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_back_ios</Icon> arrow_back_ios
      </div>),
      tags: ["DISABLE_IOS", "app", "application", "arrow", "back", "chevron", "components", "direction", "disable_ios", "interface", "ios", "left", "navigation", "previous", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "arrow_back_ios_new",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_back_ios_new</Icon> arrow_back_ios_new
      </div>),
      tags: ["DISABLE_IOS", "app", "application", "arrow", "back", "chevron", "components", "direction", "disable_ios", "interface", "ios", "left", "navigation", "previous", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "arrow_circle_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_circle_down</Icon> arrow_circle_down
      </div>),
      tags: ["arrow", "circle", "direction", "down", "navigation"]
    },
		{
      value: "arrow_circle_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_circle_left</Icon> arrow_circle_left
      </div>),
      tags: ["arrow", "circle", "direction", "left", "navigation"]
    },
		{
      value: "arrow_circle_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_circle_right</Icon> arrow_circle_right
      </div>),
      tags: ["arrow", "circle", "direction", "navigation", "right"]
    },
		{
      value: "arrow_circle_up",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_circle_up</Icon> arrow_circle_up
      </div>),
      tags: ["arrow", "circle", "direction", "navigation", "up"]
    },
		{
      value: "arrow_downward",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_downward</Icon> arrow_downward
      </div>),
      tags: ["app", "application", "arrow", "components", "direction", "down", "downward", "interface", "navigation", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "arrow_drop_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_drop_down</Icon> arrow_drop_down
      </div>),
      tags: ["app", "application", "arrow", "components", "direction", "down", "drop", "interface", "navigation", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "arrow_drop_down_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_drop_down_circle</Icon> arrow_drop_down_circle
      </div>),
      tags: ["app", "application", "arrow", "circle", "components", "direction", "down", "drop", "interface", "navigation", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "arrow_drop_up",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_drop_up</Icon> arrow_drop_up
      </div>),
      tags: ["app", "application", "arrow", "components", "direction", "drop", "interface", "navigation", "screen", "site", "ui", "up", "ux", "web", "website"]
    },
		{
      value: "arrow_forward",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_forward</Icon> arrow_forward
      </div>),
      tags: ["app", "application", "arrow", "arrows", "components", "direction", "forward", "interface", "navigation", "right", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "arrow_forward_ios",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_forward_ios</Icon> arrow_forward_ios
      </div>),
      tags: ["app", "application", "arrow", "chevron", "components", "direction", "forward", "interface", "ios", "navigation", "next", "right", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "arrow_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_left</Icon> arrow_left
      </div>),
      tags: ["app", "application", "arrow", "components", "direction", "interface", "left", "navigation", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "arrow_outward",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_outward</Icon> arrow_outward
      </div>),
      tags: ["app", "application", "arrow", "arrows", "components", "direction", "forward", "interface", "navigation", "right", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "arrow_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_right</Icon> arrow_right
      </div>),
      tags: ["app", "application", "arrow", "components", "direction", "interface", "navigation", "right", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "arrow_right_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_right_alt</Icon> arrow_right_alt
      </div>),
      tags: ["alt", "arrow", "arrows", "direction", "east", "navigation", "pointing", "right"]
    },
		{
      value: "arrow_upward",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>arrow_upward</Icon> arrow_upward
      </div>),
      tags: ["app", "application", "arrow", "components", "direction", "interface", "navigation", "screen", "site", "ui", "up", "upward", "ux", "web", "website"]
    },
		{
      value: "art_track",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>art_track</Icon> art_track
      </div>),
      tags: ["album", "art", "artist", "audio", "image", "music", "photo", "photography", "picture", "sound", "track", "tracks"]
    },
		{
      value: "article",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>article</Icon> article
      </div>),
      tags: ["article", "doc", "document", "file", "page", "paper", "text", "writing"]
    },
		{
      value: "aspect_ratio",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>aspect_ratio</Icon> aspect_ratio
      </div>),
      tags: ["aspect", "dash", "dashed", "expand", "image", "ratio", "resize", "scale", "size", "square"]
    },
		{
      value: "assessment",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assessment</Icon> assessment
      </div>),
      tags: ["analytics", "assessment", "bar", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "statistics", "tracking"]
    },
		{
      value: "assignment",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assignment</Icon> assignment
      </div>),
      tags: ["assignment", "clipboard", "doc", "document", "text", "writing"]
    },
		{
      value: "assignment_ind",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assignment_ind</Icon> assignment_ind
      </div>),
      tags: ["account", "assignment", "clipboard", "doc", "document", "face", "ind", "people", "person", "profile", "user"]
    },
		{
      value: "assignment_late",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assignment_late</Icon> assignment_late
      </div>),
      tags: ["!", "alert", "assignment", "attention", "caution", "clipboard", "danger", "doc", "document", "error", "exclamation", "important", "late", "mark", "notification", "symbol", "warning"]
    },
		{
      value: "assignment_return",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assignment_return</Icon> assignment_return
      </div>),
      tags: ["arrow", "assignment", "back", "clipboard", "doc", "document", "left", "retun"]
    },
		{
      value: "assignment_returned",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assignment_returned</Icon> assignment_returned
      </div>),
      tags: ["arrow", "assignment", "clipboard", "doc", "document", "down", "returned"]
    },
		{
      value: "assignment_turned_in",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assignment_turned_in</Icon> assignment_turned_in
      </div>),
      tags: ["approve", "assignment", "check", "clipboard", "complete", "doc", "document", "done", "in", "mark", "ok", "select", "tick", "turn", "validate", "verified", "yes"]
    },
		{
      value: "assist_walker",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assist_walker</Icon> assist_walker
      </div>),
      tags: ["accessibility", "accessible", "assist", "body", "disability", "handicap", "help", "human", "injured", "injury", "mobility", "person", "walk", "walker"]
    },
		{
      value: "assistant",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assistant</Icon> assistant
      </div>),
      tags: ["ai", "artificial", "assistant", "automatic", "automation", "bubble", "chat", "comment", "communicate", "custom", "feedback", "genai", "intelligence", "magic", "message", "recommendation", "smart", "spark", "sparkle", "speech", "star", "suggestion", "twinkle"]
    },
		{
      value: "assistant_direction",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assistant_direction</Icon> assistant_direction
      </div>),
      tags: ["assistant", "destination", "direction", "location", "maps", "navigate", "navigation", "pin", "place", "right", "stop"]
    },
		{
      value: "assistant_photo",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assistant_photo</Icon> assistant_photo
      </div>),
      tags: ["assistant", "flag", "photo", "recommendation", "smart", "star", "suggestion"]
    },
		{
      value: "assured_workload",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>assured_workload</Icon> assured_workload
      </div>),
      tags: ["account", "assured", "balance", "bank", "bill", "building", "card", "cash", "coin", "commerce", "compliance", "confidential", "credit", "currency", "dollars", "federal", "finance", "government", "money", "online", "pay", "payment", "secure", "sensitive regulatory", "workload"]
    },
		{
      value: "atm",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>atm</Icon> atm
      </div>),
      tags: ["alphabet", "atm", "automated", "bill", "card", "cart", "cash", "character", "coin", "commerce", "credit", "currency", "dollars", "font", "letters", "machine", "money", "online", "pay", "payment", "shopping", "symbol", "teller", "text", "type"]
    },
		{
      value: "attach_email",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>attach_email</Icon> attach_email
      </div>),
      tags: ["attach", "attachment", "clip", "compose", "email", "envelop", "letters", "link", "mail", "message", "paperclip", "send"]
    },
		{
      value: "attach_file",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>attach_file</Icon> attach_file
      </div>),
      tags: ["add", "attach", "attachment", "clip", "file", "link", "mail", "media", "paperclip"]
    },
		{
      value: "attach_money",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>attach_money</Icon> attach_money
      </div>),
      tags: ["bill", "card", "cash", "circle", "coin", "commerce", "cost", "credit", "currency", "dollars", "finance", "monetization", "money", "on", "online", "pay", "payment", "shopping", "symbol"]
    },
		{
      value: "attachment",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>attachment</Icon> attachment
      </div>),
      tags: ["attach", "attachment", "clip", "compose", "file", "image", "link"]
    },
		{
      value: "attractions",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>attractions</Icon> attractions
      </div>),
      tags: ["amusement", "attractions", "entertainment", "ferris", "fun", "maps", "park", "places", "wheel"]
    },
		{
      value: "attribution",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>attribution</Icon> attribution
      </div>),
      tags: ["account", "attribute", "attribution", "body", "circle", "copyright", "copywriter", "human", "people", "person", "profile", "user", "youtube"]
    },
		{
      value: "audio_file",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>audio_file</Icon> audio_file
      </div>),
      tags: ["audio", "doc", "document", "key", "music", "note", "sound", "track"]
    },
		{
      value: "audiotrack",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>audiotrack</Icon> audiotrack
      </div>),
      tags: ["audio", "audiotrack", "key", "music", "note", "sound", "track"]
    },
		{
      value: "auto_awesome",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>auto_awesome</Icon> auto_awesome
      </div>),
      tags: ["adjust", "ai", "artificial", "automatic", "automation", "custom", "edit", "editing", "enhance", "genai", "intelligence", "magic", "smart", "spark", "sparkle", "star", "stars"]
    },
		{
      value: "auto_awesome_mosaic",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>auto_awesome_mosaic</Icon> auto_awesome_mosaic
      </div>),
      tags: ["adjust", "auto", "awesome", "collage", "edit", "editing", "enhance", "image", "mosaic", "photo"]
    },
		{
      value: "auto_awesome_motion",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>auto_awesome_motion</Icon> auto_awesome_motion
      </div>),
      tags: ["adjust", "auto", "awesome", "collage", "edit", "editing", "enhance", "image", "motion", "photo", "video"]
    },
		{
      value: "auto_delete",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>auto_delete</Icon> auto_delete
      </div>),
      tags: ["auto", "bin", "can", "clock", "date", "delete", "garbage", "remove", "schedule", "time", "trash"]
    },
		{
      value: "auto_fix_high",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>auto_fix_high</Icon> auto_fix_high
      </div>),
      tags: ["adjust", "ai", "artificial", "auto", "automatic", "automation", "custom", "edit", "editing", "enhance", "erase", "fix", "genai", "high", "intelligence", "magic", "modify", "pen", "smart", "spark", "sparkle", "star", "tool", "wand"]
    },
		{
      value: "auto_fix_normal",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>auto_fix_normal</Icon> auto_fix_normal
      </div>),
      tags: ["ai", "artificial", "auto", "automatic", "automation", "custom", "edit", "erase", "fix", "genai", "intelligence", "magic", "modify", "smart", "spark", "sparkle", "star", "wand"]
    },
		{
      value: "auto_fix_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>auto_fix_off</Icon> auto_fix_off
      </div>),
      tags: ["ai", "artificial", "auto", "automatic", "automation", "custom", "disabled", "edit", "enabled", "erase", "fix", "genai", "intelligence", "magic", "modify", "off", "on", "slash", "smart", "spark", "sparkle", "star", "wand"]
    },
		{
      value: "auto_graph",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>auto_graph</Icon> auto_graph
      </div>),
      tags: ["analytics", "auto", "chart", "data", "diagram", "graph", "infographic", "line", "measure", "metrics", "stars", "statistics", "tracking"]
    },
		{
      value: "auto_mode",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>auto_mode</Icon> auto_mode
      </div>),
      tags: ["ai", "around", "arrow", "arrows", "artificial", "auto", "automatic", "automation", "custom", "direction", "genai", "inprogress", "intelligence", "load", "loading refresh", "magic", "mode", "navigation", "nest", "renew", "rotate", "smart", "spark", "sparkle", "star", "turn"]
    },
		{
      value: "auto_stories",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>auto_stories</Icon> auto_stories
      </div>),
      tags: ["auto", "book", "flipping", "pages", "stories"]
    },
		{
      value: "autofps_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>autofps_select</Icon> autofps_select
      </div>),
      tags: ["A", "alphabet", "auto", "character", "font", "fps", "frame", "frequency", "letters", "per", "rate", "second", "seconds", "select", "symbol", "text", "type"]
    },
		{
      value: "autorenew",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>autorenew</Icon> autorenew
      </div>),
      tags: ["around", "arrow", "arrows", "autorenew", "cache", "cached", "direction", "inprogress", "load", "loading refresh", "navigation", "renew", "rotate", "turn"]
    },
		{
      value: "av_timer",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>av_timer</Icon> av_timer
      </div>),
      tags: ["av", "clock", "countdown", "duration", "minutes", "seconds", "time", "timer", "watch"]
    },
		{
      value: "baby_changing_station",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>baby_changing_station</Icon> baby_changing_station
      </div>),
      tags: ["babies", "baby", "bathroom", "body", "changing", "child", "children", "father", "human", "infant", "kids", "mother", "newborn", "people", "person", "station", "toddler", "wc", "young"]
    },
		{
      value: "back_hand",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>back_hand</Icon> back_hand
      </div>),
      tags: ["back", "fingers", "gesture", "hand", "raised"]
    },
		{
      value: "backpack",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>backpack</Icon> backpack
      </div>),
      tags: ["back", "backpack", "bag", "book", "bookbag", "knapsack", "pack", "storage", "travel"]
    },
		{
      value: "backspace",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>backspace</Icon> backspace
      </div>),
      tags: ["arrow", "back", "backspace", "cancel", "clear", "correct", "delete", "erase", "remove"]
    },
		{
      value: "backup",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>backup</Icon> backup
      </div>),
      tags: ["arrow", "backup", "cloud", "data", "drive", "files folders", "storage", "up", "upload"]
    },
		{
      value: "backup_table",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>backup_table</Icon> backup_table
      </div>),
      tags: ["backup", "drive", "files folders", "format", "layout", "stack", "storage", "table"]
    },
		{
      value: "badge",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>badge</Icon> badge
      </div>),
      tags: ["account", "avatar", "badge", "card", "certified", "employee", "face", "human", "id", "id card", "identification", "name", "people", "person", "profile", "security", "user", "work"]
    },
		{
      value: "bakery_dining",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bakery_dining</Icon> bakery_dining
      </div>),
      tags: ["bakery", "bread", "breakfast", "brunch", "croissant", "dining", "food"]
    },
		{
      value: "balance",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>balance</Icon> balance
      </div>),
      tags: ["balance", "equal", "equity", "impartiality", "justice", "parity", "stability. equilibrium", "steadiness", "symmetry"]
    },
		{
      value: "balcony",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>balcony</Icon> balcony
      </div>),
      tags: ["architecture", "balcony", "doors", "estate", "home", "house", "maps", "out", "outside", "place", "real", "residence", "residential", "stay", "terrace", "window"]
    },
		{
      value: "ballot",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ballot</Icon> ballot
      </div>),
      tags: ["ballot", "bullet", "election", "list", "point", "poll", "vote"]
    },
		{
      value: "bar_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bar_chart</Icon> bar_chart
      </div>),
      tags: ["analytics", "bar", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "statistics", "tracking"]
    },
		{
      value: "batch_prediction",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>batch_prediction</Icon> batch_prediction
      </div>),
      tags: ["batch", "bulb", "idea", "light", "prediction"]
    },
		{
      value: "bathroom",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bathroom</Icon> bathroom
      </div>),
      tags: ["bath", "bathroom", "closet", "home", "house", "place", "plumbing", "room", "shower", "sprinkler", "wash", "water", "wc"]
    },
		{
      value: "bathtub",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bathtub</Icon> bathtub
      </div>),
      tags: ["bath", "bathing", "bathroom", "bathtub", "home", "hotel", "human", "person", "shower", "travel", "tub"]
    },
		{
      value: "battery_0_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_0_bar</Icon> battery_0_bar
      </div>),
      tags: ["0", "bar", "battery", "cell", "charge", "full", "mobile", "power"]
    },
		{
      value: "battery_1_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_1_bar</Icon> battery_1_bar
      </div>),
      tags: ["1", "bar", "battery", "cell", "charge", "mobile", "power"]
    },
		{
      value: "battery_2_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_2_bar</Icon> battery_2_bar
      </div>),
      tags: ["2", "bar", "battery", "cell", "charge", "mobile", "power"]
    },
		{
      value: "battery_3_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_3_bar</Icon> battery_3_bar
      </div>),
      tags: ["3", "bar", "battery", "cell", "charge", "mobile", "power"]
    },
		{
      value: "battery_4_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_4_bar</Icon> battery_4_bar
      </div>),
      tags: ["4", "bar", "battery", "cell", "charge", "mobile", "power"]
    },
		{
      value: "battery_5_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_5_bar</Icon> battery_5_bar
      </div>),
      tags: ["5", "bar", "battery", "cell", "charge", "mobile", "power"]
    },
		{
      value: "battery_6_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_6_bar</Icon> battery_6_bar
      </div>),
      tags: ["6", "bar", "battery", "cell", "charge", "mobile", "power"]
    },
		{
      value: "battery_alert",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_alert</Icon> battery_alert
      </div>),
      tags: ["!", "alert", "attention", "battery", "caution", "cell", "charge", "danger", "error", "exclamation", "important", "mark", "mobile", "notification", "power", "symbol", "warning"]
    },
		{
      value: "battery_charging_full",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_charging_full</Icon> battery_charging_full
      </div>),
      tags: ["battery", "bolt", "cell", "charge", "charging", "electric", "energy", "full", "instant", "lightening", "mobile", "power", "thunderbolt"]
    },
		{
      value: "battery_full",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_full</Icon> battery_full
      </div>),
      tags: ["0", "bar", "battery", "cell", "charge", "full", "mobile", "power"]
    },
		{
      value: "battery_saver",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_saver</Icon> battery_saver
      </div>),
      tags: ["+", "add", "battery", "charge", "charging", "new", "plus", "power", "saver", "symbol"]
    },
		{
      value: "battery_std",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_std</Icon> battery_std
      </div>),
      tags: ["battery", "cell", "charge", "mobile", "plus", "power", "standard", "std"]
    },
		{
      value: "battery_unknown",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>battery_unknown</Icon> battery_unknown
      </div>),
      tags: ["?", "assistance", "battery", "cell", "charge", "help", "info", "information", "mobile", "power", "punctuation", "question mark", "support", "symbol", "unknown"]
    },
		{
      value: "beach_access",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>beach_access</Icon> beach_access
      </div>),
      tags: ["access", "beach", "places", "summer", "sunny", "umbrella"]
    },
		{
      value: "bed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bed</Icon> bed
      </div>),
      tags: ["bed", "bedroom", "double", "full", "furniture", "home", "hotel", "house", "king", "night", "pillows", "queen", "rest", "room", "size", "sleep"]
    },
		{
      value: "bedroom_baby",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bedroom_baby</Icon> bedroom_baby
      </div>),
      tags: ["babies", "baby", "bedroom", "child", "children", "home", "horse", "house", "infant", "kid", "newborn", "rocking", "room", "toddler", "young"]
    },
		{
      value: "bedroom_child",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bedroom_child</Icon> bedroom_child
      </div>),
      tags: ["bed", "bedroom", "child", "children", "furniture", "home", "hotel", "house", "kid", "night", "pillows", "rest", "room", "size", "sleep", "twin", "young"]
    },
		{
      value: "bedroom_parent",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bedroom_parent</Icon> bedroom_parent
      </div>),
      tags: ["bed", "bedroom", "double", "full", "furniture", "home", "hotel", "house", "king", "night", "parent", "pillows", "queen", "rest", "room", "sizem master", "sleep"]
    },
		{
      value: "bedtime",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bedtime</Icon> bedtime
      </div>),
      tags: ["bedtime", "clear", "climate", "home", "lunar", "moon", "nest", "night", "nightime", "quiet", "security", "sleep", "thermostat", "time", "weather"]
    },
		{
      value: "bedtime_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bedtime_off</Icon> bedtime_off
      </div>),
      tags: ["active", "bedtime", "clear", "climate", "disabled", "home", "lunar", "moon", "nest", "night", "nightime", "off", "offline", "quiet", "security", "slash", "sleep", "thermostat", "time", "weather"]
    },
		{
      value: "beenhere",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>beenhere</Icon> beenhere
      </div>),
      tags: ["approve", "archive", "beenhere", "bookmark", "check", "complete", "done", "favorite", "label", "library", "mark", "ok", "read", "reading", "remember", "ribbon", "save", "select", "tag", "tick", "validate", "verified", "yes"]
    },
		{
      value: "bento",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bento</Icon> bento
      </div>),
      tags: ["bento", "box", "dinner", "food", "lunch", "meal", "restaurant", "takeout"]
    },
		{
      value: "bike_scooter",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bike_scooter</Icon> bike_scooter
      </div>),
      tags: ["automobile", "bike", "car", "cars", "maps", "scooter", "transportation", "vehicle", "vespa"]
    },
		{
      value: "biotech",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>biotech</Icon> biotech
      </div>),
      tags: ["biotech", "chemistry", "laboratory", "microscope", "research", "science", "technology"]
    },
		{
      value: "blender",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>blender</Icon> blender
      </div>),
      tags: ["appliance", "blender", "cook", "cooking", "electric", "juicer", "kitchen", "machine", "vitamix"]
    },
		{
      value: "blind",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>blind</Icon> blind
      </div>),
      tags: ["accessibility", "accessible", "assist", "blind", "body", "cane", "disability", "handicap", "help", "human", "mobility", "person", "walk", "walker"]
    },
		{
      value: "blinds",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>blinds</Icon> blinds
      </div>),
      tags: ["blinds", "cover", "curtains", "nest", "open", "shade", "shutter", "sunshade"]
    },
		{
      value: "blinds_closed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>blinds_closed</Icon> blinds_closed
      </div>),
      tags: ["blinds", "closed", "cover", "curtains", "nest", "shade", "shutter", "sunshade"]
    },
		{
      value: "block",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>block</Icon> block
      </div>),
      tags: ["avoid", "block", "cancel", "close", "disturb", "do not disturb", "entry", "exit", "no", "prohibited", "quit", "remove", "stop"]
    },
		{
      value: "bloodtype",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bloodtype</Icon> bloodtype
      </div>),
      tags: ["blood", "bloodtype", "donate", "droplet", "emergency", "hospital", "medicine", "negative", "positive", "type", "water"]
    },
		{
      value: "bluetooth",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bluetooth</Icon> bluetooth
      </div>),
      tags: ["bluetooth", "cast", "connect", "connection", "device", "paring", "streaming", "symbol", "wireless"]
    },
		{
      value: "bluetooth_audio",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bluetooth_audio</Icon> bluetooth_audio
      </div>),
      tags: ["audio", "bluetooth", "connect", "connection", "device", "music", "signal", "sound", "symbol"]
    },
		{
      value: "bluetooth_connected",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bluetooth_connected</Icon> bluetooth_connected
      </div>),
      tags: ["bluetooth", "cast", "connect", "connection", "device", "paring", "streaming", "symbol", "wireless"]
    },
		{
      value: "bluetooth_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bluetooth_disabled</Icon> bluetooth_disabled
      </div>),
      tags: ["bluetooth", "cast", "connect", "connection", "device", "disabled", "enabled", "off", "offline", "on", "paring", "slash", "streaming", "symbol", "wireless"]
    },
		{
      value: "bluetooth_drive",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bluetooth_drive</Icon> bluetooth_drive
      </div>),
      tags: ["automobile", "bluetooth", "car", "cars", "cast", "connect", "connection", "device", "drive", "maps", "paring", "streaming", "symbol", "transportation", "travel", "vehicle", "wireless"]
    },
		{
      value: "bluetooth_searching",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bluetooth_searching</Icon> bluetooth_searching
      </div>),
      tags: ["bluetooth", "connection", "device", "paring", "search", "searching", "symbol"]
    },
		{
      value: "blur_circular",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>blur_circular</Icon> blur_circular
      </div>),
      tags: ["blur", "circle", "circular", "dots", "edit", "editing", "effect", "enhance", "filter"]
    },
		{
      value: "blur_linear",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>blur_linear</Icon> blur_linear
      </div>),
      tags: ["blur", "dots", "edit", "editing", "effect", "enhance", "filter", "linear"]
    },
		{
      value: "blur_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>blur_off</Icon> blur_off
      </div>),
      tags: ["blur", "disabled", "dots", "edit", "editing", "effect", "enabled", "enhance", "off", "on", "slash"]
    },
		{
      value: "blur_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>blur_on</Icon> blur_on
      </div>),
      tags: ["blur", "disabled", "dots", "edit", "editing", "effect", "enabled", "enhance", "filter", "off", "on", "slash"]
    },
		{
      value: "bolt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bolt</Icon> bolt
      </div>),
      tags: ["bolt", "electric", "energy", "fast", "flash", "instant", "lightning", "power", "thunderbolt"]
    },
		{
      value: "book",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>book</Icon> book
      </div>),
      tags: ["book", "bookmark", "favorite", "label", "library", "read", "reading", "remember", "ribbon", "save", "tag"]
    },
		{
      value: "book_online",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>book_online</Icon> book_online
      </div>),
      tags: ["Android", "OS", "admission", "appointment", "book", "cell", "device", "event", "hardware", "iOS", "mobile", "online", "pass", "phone", "reservation", "tablet", "ticket"]
    },
		{
      value: "bookmark",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bookmark</Icon> bookmark
      </div>),
      tags: ["archive", "bookmark", "favorite", "label", "library", "read", "reading", "remember", "ribbon", "save", "tag"]
    },
		{
      value: "bookmark_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bookmark_add</Icon> bookmark_add
      </div>),
      tags: ["+", "add", "bookmark", "favorite", "plus", "remember", "ribbon", "save", "symbol"]
    },
		{
      value: "bookmark_added",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bookmark_added</Icon> bookmark_added
      </div>),
      tags: ["added", "approve", "bookmark", "check", "complete", "done", "favorite", "mark", "ok", "remember", "save", "select", "tick", "validate", "verified", "yes"]
    },
		{
      value: "bookmark_border",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bookmark_border</Icon> bookmark_border
      </div>),
      tags: ["archive", "bookmark", "border", "favorite", "label", "library", "read", "reading", "remember", "ribbon", "save", "tag"]
    },
		{
      value: "bookmark_remove",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bookmark_remove</Icon> bookmark_remove
      </div>),
      tags: ["bookmark", "delete", "favorite", "minus", "remember", "remove", "ribbon", "save", "subtract"]
    },
		{
      value: "bookmarks",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bookmarks</Icon> bookmarks
      </div>),
      tags: ["bookmark", "bookmarks", "favorite", "label", "layers", "library", "multiple", "read", "reading", "remember", "ribbon", "save", "stack", "tag"]
    },
		{
      value: "border_all",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_all</Icon> border_all
      </div>),
      tags: ["all", "border", "doc", "edit", "editing", "editor", "sheet", "spreadsheet", "stroke", "text", "type", "writing"]
    },
		{
      value: "border_bottom",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_bottom</Icon> border_bottom
      </div>),
      tags: ["border", "bottom", "dash", "dashed", "doc", "edit", "editing", "editor", "sheet", "spreadsheet", "stroke", "text", "type", "writing"]
    },
		{
      value: "border_clear",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_clear</Icon> border_clear
      </div>),
      tags: ["border", "clear", "dash", "dashed", "doc", "edit", "editing", "editor", "sheet", "spreadsheet", "stroke", "text", "type", "writing"]
    },
		{
      value: "border_color",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_color</Icon> border_color
      </div>),
      tags: ["all", "border", "doc", "edit", "editing", "editor", "pen", "pencil", "sheet", "spreadsheet", "stroke", "text", "type", "writing"]
    },
		{
      value: "border_horizontal",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_horizontal</Icon> border_horizontal
      </div>),
      tags: ["border", "dash", "dashed", "doc", "edit", "editing", "editor", "horizontal", "sheet", "spreadsheet", "stroke", "text", "type", "writing"]
    },
		{
      value: "border_inner",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_inner</Icon> border_inner
      </div>),
      tags: ["border", "dash", "dashed", "doc", "edit", "editing", "editor", "inner", "sheet", "spreadsheet", "stroke", "text", "type", "writing"]
    },
		{
      value: "border_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_left</Icon> border_left
      </div>),
      tags: ["border", "dash", "dashed", "doc", "edit", "editing", "editor", "left", "sheet", "spreadsheet", "stroke", "text", "type", "writing"]
    },
		{
      value: "border_outer",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_outer</Icon> border_outer
      </div>),
      tags: ["border", "dash", "dashed", "doc", "edit", "editing", "editor", "outer", "sheet", "spreadsheet", "stroke", "text", "type", "writing"]
    },
		{
      value: "border_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_right</Icon> border_right
      </div>),
      tags: ["border", "dash", "dashed", "doc", "edit", "editing", "editor", "right", "sheet", "spreadsheet", "stroke", "text", "type", "writing"]
    },
		{
      value: "border_style",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_style</Icon> border_style
      </div>),
      tags: ["border", "color", "dash", "dashed", "doc", "edit", "editing", "editor", "sheet", "spreadsheet", "stroke", "style", "text", "type", "writing"]
    },
		{
      value: "border_top",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_top</Icon> border_top
      </div>),
      tags: ["border", "dash", "dashed", "doc", "edit", "editing", "editor", "sheet", "spreadsheet", "stroke", "text", "top", "type", "writing"]
    },
		{
      value: "border_vertical",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>border_vertical</Icon> border_vertical
      </div>),
      tags: ["border", "dash", "dashed", "doc", "edit", "editing", "editor", "sheet", "spreadsheet", "stroke", "text", "type", "vertical", "writing"]
    },
		{
      value: "boy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>boy</Icon> boy
      </div>),
      tags: ["body", "boy", "gender", "human", "male", "man", "people", "person", "social", "symbol"]
    },
		{
      value: "branding_watermark",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>branding_watermark</Icon> branding_watermark
      </div>),
      tags: ["branding", "components", "copyright", "design", "emblem", "format", "identity", "interface", "layout", "logo", "screen", "site", "stamp", "ui", "ux", "watermark", "web", "website", "window"]
    },
		{
      value: "breakfast_dining",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>breakfast_dining</Icon> breakfast_dining
      </div>),
      tags: ["bakery", "bread", "breakfast", "butter", "dining", "food", "toast"]
    },
		{
      value: "brightness_1",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brightness_1</Icon> brightness_1
      </div>),
      tags: ["1", "brightness", "circle", "control", "crescent", "level", "moon", "screen"]
    },
		{
      value: "brightness_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brightness_2</Icon> brightness_2
      </div>),
      tags: ["2", "brightness", "circle", "control", "crescent", "level", "moon", "screen"]
    },
		{
      value: "brightness_3",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brightness_3</Icon> brightness_3
      </div>),
      tags: ["3", "brightness", "circle", "control", "crescent", "level", "moon", "screen"]
    },
		{
      value: "brightness_4",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brightness_4</Icon> brightness_4
      </div>),
      tags: ["4", "brightness", "circle", "control", "crescent", "level", "moon", "screen", "sun"]
    },
		{
      value: "brightness_5",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brightness_5</Icon> brightness_5
      </div>),
      tags: ["5", "brightness", "circle", "control", "crescent", "level", "moon", "screen", "sun"]
    },
		{
      value: "brightness_6",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brightness_6</Icon> brightness_6
      </div>),
      tags: ["6", "brightness", "circle", "control", "crescent", "level", "moon", "screen", "sun"]
    },
		{
      value: "brightness_7",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brightness_7</Icon> brightness_7
      </div>),
      tags: ["7", "brightness", "circle", "control", "crescent", "level", "moon", "screen", "sun"]
    },
		{
      value: "brightness_auto",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brightness_auto</Icon> brightness_auto
      </div>),
      tags: ["A", "auto", "brightness", "control", "display", "level", "mobile", "monitor", "phone", "screen", "sun"]
    },
		{
      value: "brightness_high",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brightness_high</Icon> brightness_high
      </div>),
      tags: ["auto", "brightness", "control", "high", "mobile", "monitor", "phone", "sun"]
    },
		{
      value: "brightness_low",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brightness_low</Icon> brightness_low
      </div>),
      tags: ["auto", "brightness", "control", "low", "mobile", "monitor", "phone", "sun"]
    },
		{
      value: "brightness_medium",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brightness_medium</Icon> brightness_medium
      </div>),
      tags: ["auto", "brightness", "control", "medium", "mobile", "monitor", "phone", "sun"]
    },
		{
      value: "broadcast_on_home",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>broadcast_on_home</Icon> broadcast_on_home
      </div>),
      tags: []
    },
		{
      value: "broadcast_on_personal",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>broadcast_on_personal</Icon> broadcast_on_personal
      </div>),
      tags: []
    },
		{
      value: "broken_image",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>broken_image</Icon> broken_image
      </div>),
      tags: ["broken", "corrupt", "error", "image", "landscape", "mountain", "mountains", "photo", "photography", "picture", "torn"]
    },
		{
      value: "browse_gallery",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>browse_gallery</Icon> browse_gallery
      </div>),
      tags: ["clock", "collection", "gallery", "library", "stack", "watch"]
    },
		{
      value: "browser_not_supported",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>browser_not_supported</Icon> browser_not_supported
      </div>),
      tags: ["browser", "disabled", "enabled", "internet", "not", "off", "on", "page", "screen", "site", "slash", "supported", "web", "website", "www"]
    },
		{
      value: "browser_updated",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>browser_updated</Icon> browser_updated
      </div>),
      tags: ["Android", "OS", "arrow", "browser", "chrome", "desktop", "device", "display", "download", "hardware", "iOS", "mac", "monitor", "screen", "updated", "web", "window"]
    },
		{
      value: "brunch_dining",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brunch_dining</Icon> brunch_dining
      </div>),
      tags: ["breakfast", "brunch", "champagne", "dining", "drink", "food", "lunch", "meal"]
    },
		{
      value: "brush",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>brush</Icon> brush
      </div>),
      tags: ["art", "brush", "design", "draw", "edit", "editing", "paint", "painting", "tool"]
    },
		{
      value: "bubble_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bubble_chart</Icon> bubble_chart
      </div>),
      tags: ["analytics", "bar", "bars", "bubble", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "statistics", "tracking"]
    },
		{
      value: "bug_report",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bug_report</Icon> bug_report
      </div>),
      tags: ["animal", "bug", "fix", "insect", "issue", "problem", "report", "testing", "virus", "warning"]
    },
		{
      value: "build",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>build</Icon> build
      </div>),
      tags: ["adjust", "build", "fix", "home", "nest", "repair", "tool", "tools", "wrench"]
    },
		{
      value: "build_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>build_circle</Icon> build_circle
      </div>),
      tags: ["adjust", "build", "circle", "fix", "repair", "tool", "wrench"]
    },
		{
      value: "bungalow",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bungalow</Icon> bungalow
      </div>),
      tags: ["architecture", "bungalow", "cottage", "estate", "home", "house", "maps", "place", "real", "residence", "residential", "stay", "traveling"]
    },
		{
      value: "burst_mode",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>burst_mode</Icon> burst_mode
      </div>),
      tags: ["burst", "image", "landscape", "mode", "mountain", "mountains", "multiple", "photo", "photography", "picture"]
    },
		{
      value: "bus_alert",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>bus_alert</Icon> bus_alert
      </div>),
      tags: ["!", "alert", "attention", "automobile", "bus", "car", "cars", "caution", "danger", "error", "exclamation", "important", "maps", "mark", "notification", "symbol", "transportation", "vehicle", "warning"]
    },
		{
      value: "business",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>business</Icon> business
      </div>),
      tags: ["apartment", "architecture", "building", "business", "company", "estate", "home", "place", "real", "residence", "residential", "shelter"]
    },
		{
      value: "business_center",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>business_center</Icon> business_center
      </div>),
      tags: ["bag", "baggage", "briefcase", "business", "case", "center", "places", "purse", "suitcase", "work"]
    },
		{
      value: "cabin",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cabin</Icon> cabin
      </div>),
      tags: ["architecture", "cabin", "camping", "cottage", "estate", "home", "house", "log", "maps", "place", "real", "residence", "residential", "stay", "traveling", "wood"]
    },
		{
      value: "cable",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cable</Icon> cable
      </div>),
      tags: ["cable", "connect", "connection", "device", "electronics", "usb", "wire"]
    },
		{
      value: "cached",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cached</Icon> cached
      </div>),
      tags: ["around", "arrows", "cache", "cached", "inprogress", "load", "loading refresh", "renew", "rotate"]
    },
		{
      value: "cake",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cake</Icon> cake
      </div>),
      tags: ["add", "baked", "birthday", "cake", "candles", "celebration", "dessert", "food", "frosting", "new", "party", "pastries", "pastry", "plus", "social", "sweet", "symbol"]
    },
		{
      value: "calculate",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>calculate</Icon> calculate
      </div>),
      tags: ["+", "-", "=", "calculate", "count", "finance calculator", "math"]
    },
		{
      value: "calendar_month",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>calendar_month</Icon> calendar_month
      </div>),
      tags: ["calendar", "date", "day", "event", "month", "schedule", "today"]
    },
		{
      value: "calendar_today",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>calendar_today</Icon> calendar_today
      </div>),
      tags: ["calendar", "date", "day", "event", "month", "schedule", "today"]
    },
		{
      value: "calendar_view_day",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>calendar_view_day</Icon> calendar_view_day
      </div>),
      tags: ["calendar", "date", "day", "event", "format", "grid", "layout", "month", "schedule", "today", "view", "week"]
    },
		{
      value: "calendar_view_month",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>calendar_view_month</Icon> calendar_view_month
      </div>),
      tags: ["calendar", "date", "day", "event", "format", "grid", "layout", "month", "schedule", "today", "view"]
    },
		{
      value: "calendar_view_week",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>calendar_view_week</Icon> calendar_view_week
      </div>),
      tags: ["calendar", "date", "day", "event", "format", "grid", "layout", "month", "schedule", "today", "view", "week"]
    },
		{
      value: "call",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>call</Icon> call
      </div>),
      tags: ["call", "cell", "contact", "device", "hardware", "mobile", "phone", "telephone"]
    },
		{
      value: "call_end",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>call_end</Icon> call_end
      </div>),
      tags: ["call", "cell", "contact", "device", "end", "hardware", "mobile", "phone", "telephone"]
    },
		{
      value: "call_made",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>call_made</Icon> call_made
      </div>),
      tags: ["arrow", "call", "device", "made", "mobile"]
    },
		{
      value: "call_merge",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>call_merge</Icon> call_merge
      </div>),
      tags: ["arrow", "call", "device", "merge", "mobile"]
    },
		{
      value: "call_missed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>call_missed</Icon> call_missed
      </div>),
      tags: ["arrow", "call", "device", "missed", "mobile"]
    },
		{
      value: "call_missed_outgoing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>call_missed_outgoing</Icon> call_missed_outgoing
      </div>),
      tags: ["arrow", "call", "device", "missed", "mobile", "outgoing"]
    },
		{
      value: "call_received",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>call_received</Icon> call_received
      </div>),
      tags: ["arrow", "call", "device", "mobile", "received"]
    },
		{
      value: "call_split",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>call_split</Icon> call_split
      </div>),
      tags: ["arrow", "call", "device", "mobile", "split"]
    },
		{
      value: "call_to_action",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>call_to_action</Icon> call_to_action
      </div>),
      tags: ["action", "alert", "bar", "call", "components", "cta", "design", "info", "information", "interface", "layout", "message", "notification", "screen", "site", "to", "ui", "ux", "web", "website", "window"]
    },
		{
      value: "camera",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>camera</Icon> camera
      </div>),
      tags: ["aperture", "camera", "lens", "photo", "photography", "picture", "shutter"]
    },
		{
      value: "camera_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>camera_alt</Icon> camera_alt
      </div>),
      tags: ["alt", "camera", "image", "photo", "photography", "picture"]
    },
		{
      value: "camera_enhance",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>camera_enhance</Icon> camera_enhance
      </div>),
      tags: ["ai", "artificial", "automatic", "automation", "camera", "custom", "enhance", "genai", "important", "intelligence", "lens", "magic", "photo", "photography", "picture", "quality", "smart", "spark", "sparkle", "special", "star"]
    },
		{
      value: "camera_front",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>camera_front</Icon> camera_front
      </div>),
      tags: ["body", "camera", "front", "human", "lens", "mobile", "person", "phone", "photography", "portrait", "selfie"]
    },
		{
      value: "camera_indoor",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>camera_indoor</Icon> camera_indoor
      </div>),
      tags: ["architecture", "building", "camera", "estate", "film", "filming", "home", "house", "image", "indoor", "inside", "motion", "nest", "picture", "place", "real", "residence", "residential", "shelter", "video", "videography"]
    },
		{
      value: "camera_outdoor",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>camera_outdoor</Icon> camera_outdoor
      </div>),
      tags: ["architecture", "building", "camera", "estate", "film", "filming", "home", "house", "image", "motion", "nest", "outdoor", "outside", "picture", "place", "real", "residence", "residential", "shelter", "video", "videography"]
    },
		{
      value: "camera_rear",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>camera_rear</Icon> camera_rear
      </div>),
      tags: ["camera", "front", "lens", "mobile", "phone", "photo", "photography", "picture", "portrait", "rear", "selfie"]
    },
		{
      value: "camera_roll",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>camera_roll</Icon> camera_roll
      </div>),
      tags: ["camera", "film", "image", "library", "photo", "photography", "roll"]
    },
		{
      value: "cameraswitch",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cameraswitch</Icon> cameraswitch
      </div>),
      tags: ["arrows", "camera", "cameraswitch", "flip", "rotate", "swap", "switch", "view"]
    },
		{
      value: "campaign",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>campaign</Icon> campaign
      </div>),
      tags: ["alert", "announcement", "campaign", "loud", "megaphone", "microphone", "notification", "speaker"]
    },
		{
      value: "cancel",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cancel</Icon> cancel
      </div>),
      tags: ["cancel", "circle", "clear", "close", "exit", "remove", "stop", "x"]
    },
		{
      value: "cancel_presentation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cancel_presentation</Icon> cancel_presentation
      </div>),
      tags: ["cancel", "clear", "close", "device", "exit", "no", "present", "presentation", "quit", "remove", "screen", "slide", "stop", "website", "window", "x"]
    },
		{
      value: "cancel_schedule_send",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cancel_schedule_send</Icon> cancel_schedule_send
      </div>),
      tags: ["cancel", "clear", "email", "mail", "no", "quit", "remove", "schedule", "send", "share", "stop", "x"]
    },
		{
      value: "candlestick_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>candlestick_chart</Icon> candlestick_chart
      </div>),
      tags: ["analytics", "candlestick", "chart", "data", "diagram", "finance", "graph", "infographic", "measure", "metrics", "statistics", "tracking"]
    },
		{
      value: "car_crash",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>car_crash</Icon> car_crash
      </div>),
      tags: ["accident", "automobile", "car", "cars", "collision", "crash", "direction", "maps", "public", "transportation", "vehicle"]
    },
		{
      value: "car_rental",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>car_rental</Icon> car_rental
      </div>),
      tags: ["access", "automobile", "car", "cars", "entry", "key", "lock", "maps", "password", "rental", "transportation", "unlock", "vehicle"]
    },
		{
      value: "car_repair",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>car_repair</Icon> car_repair
      </div>),
      tags: ["automobile", "car", "cars", "maps", "repair", "transportation", "vehicle"]
    },
		{
      value: "card_giftcard",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>card_giftcard</Icon> card_giftcard
      </div>),
      tags: ["account", "balance", "bill", "card", "cart", "cash", "certificate", "coin", "commerce", "credit", "currency", "dollars", "gift", "giftcard", "money", "online", "pay", "payment", "present", "shopping"]
    },
		{
      value: "card_membership",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>card_membership</Icon> card_membership
      </div>),
      tags: ["bill", "bookmark", "card", "cash", "certificate", "coin", "commerce", "cost", "credit", "currency", "dollars", "finance", "loyalty", "membership", "money", "online", "pay", "payment", "shopping", "subscription"]
    },
		{
      value: "card_travel",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>card_travel</Icon> card_travel
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollars", "finance", "membership", "miles", "money", "online", "pay", "payment", "travel", "trip"]
    },
		{
      value: "carpenter",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>carpenter</Icon> carpenter
      </div>),
      tags: ["building", "carpenter", "construction", "cutting", "handyman", "repair", "saw", "tool"]
    },
		{
      value: "cases",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cases</Icon> cases
      </div>),
      tags: ["bag", "baggage", "briefcase", "business", "case", "cases", "purse", "suitcase"]
    },
		{
      value: "casino",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>casino</Icon> casino
      </div>),
      tags: ["casino", "casino chip", "chips", "dice", "dots", "entertainment", "gamble", "gambling", "game", "games", "luck", "places", "tokens"]
    },
		{
      value: "cast",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cast</Icon> cast
      </div>),
      tags: ["Android", "OS", "airplay", "cast", "chrome", "connect", "desktop", "device", "display", "hardware", "iOS", "mac", "monitor", "screen", "screencast", "streaming", "television", "tv", "web", "window", "wireless"]
    },
		{
      value: "cast_connected",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cast_connected</Icon> cast_connected
      </div>),
      tags: ["Android", "OS", "airplay", "cast", "chrome", "connect", "connected", "desktop", "device", "display", "hardware", "iOS", "mac", "monitor", "screen", "screencast", "streaming", "television", "tv", "web", "window", "wireless"]
    },
		{
      value: "cast_for_education",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cast_for_education</Icon> cast_for_education
      </div>),
      tags: ["Android", "OS", "airplay", "cast", "chrome", "connect", "desktop", "device", "display", "education", "for", "hardware", "iOS", "learning", "lessons teaching", "mac", "monitor", "screen", "screencast", "streaming", "television", "tv", "web", "window", "wireless"]
    },
		{
      value: "castle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>castle</Icon> castle
      </div>),
      tags: ["castle", "fort", "fortress", "mansion", "palace"]
    },
		{
      value: "catching_pokemon",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>catching_pokemon</Icon> catching_pokemon
      </div>),
      tags: ["catching", "go", "pokemon", "pokestop", "travel"]
    },
		{
      value: "category",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>category</Icon> category
      </div>),
      tags: ["categories", "category", "circle", "collection", "items", "product", "shapes", "sort", "square", "triangle"]
    },
		{
      value: "celebration",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>celebration</Icon> celebration
      </div>),
      tags: ["activity", "birthday", "celebration", "event", "fun", "party"]
    },
		{
      value: "cell_tower",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cell_tower</Icon> cell_tower
      </div>),
      tags: ["broadcast", "casting", "cell", "network", "signal", "tower", "transmitting", "wireless"]
    },
		{
      value: "cell_wifi",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cell_wifi</Icon> cell_wifi
      </div>),
      tags: ["cell", "connection", "data", "internet", "mobile", "network", "phone", "service", "signal", "wifi", "wireless"]
    },
		{
      value: "center_focus_strong",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>center_focus_strong</Icon> center_focus_strong
      </div>),
      tags: ["camera", "center", "focus", "image", "lens", "photo", "photography", "strong", "zoom"]
    },
		{
      value: "center_focus_weak",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>center_focus_weak</Icon> center_focus_weak
      </div>),
      tags: ["camera", "center", "focus", "image", "lens", "photo", "photography", "weak", "zoom"]
    },
		{
      value: "chair",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>chair</Icon> chair
      </div>),
      tags: ["chair", "comfort", "couch", "decoration", "furniture", "home", "house", "living", "lounging", "loveseat", "room", "seat", "seating", "sofa"]
    },
		{
      value: "chair_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>chair_alt</Icon> chair_alt
      </div>),
      tags: ["cahir", "furniture", "home", "house", "kitchen", "lounging", "seating", "table"]
    },
		{
      value: "chalet",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>chalet</Icon> chalet
      </div>),
      tags: ["architecture", "chalet", "cottage", "estate", "home", "house", "maps", "place", "real", "residence", "residential", "stay", "traveling"]
    },
		{
      value: "change_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>change_circle</Icon> change_circle
      </div>),
      tags: ["around", "arrows", "change", "circle", "direction", "navigation", "rotate"]
    },
		{
      value: "change_history",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>change_history</Icon> change_history
      </div>),
      tags: ["change", "history", "shape", "triangle"]
    },
		{
      value: "charging_station",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>charging_station</Icon> charging_station
      </div>),
      tags: ["Android", "OS", "battery", "bolt", "cell", "charging", "device", "electric", "energy", "hardware", "iOS", "instant", "lightning", "mobile", "phone", "station", "tablet", "thunderbolt"]
    },
		{
      value: "chat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>chat</Icon> chat
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "feedback", "message", "speech"]
    },
		{
      value: "chat_bubble",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>chat_bubble</Icon> chat_bubble
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "feedback", "message", "speech"]
    },
		{
      value: "chat_bubble_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>chat_bubble_outline</Icon> chat_bubble_outline
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "feedback", "message", "outline", "speech"]
    },
		{
      value: "check",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>check</Icon> check
      </div>),
      tags: ["DISABLE_IOS", "check", "confirm", "correct", "disable_ios", "done", "enter", "mark", "ok", "okay", "select", "tick", "yes"]
    },
		{
      value: "check_box",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>check_box</Icon> check_box
      </div>),
      tags: ["approved", "box", "button", "check", "component", "control", "form", "mark", "ok", "select", "selected", "selection", "tick", "toggle", "ui", "yes"]
    },
		{
      value: "check_box_outline_blank",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>check_box_outline_blank</Icon> check_box_outline_blank
      </div>),
      tags: ["blank", "box", "button", "check", "component", "control", "dash", "dashed", "deselected", "empty", "form", "outline", "select", "selection", "square", "tick", "toggle", "ui"]
    },
		{
      value: "check_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>check_circle</Icon> check_circle
      </div>),
      tags: ["approve", "check", "circle", "complete", "confirm", "done", "mark", "ok", "select", "tick", "validate", "verified", "yes"]
    },
		{
      value: "check_circle_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>check_circle_outline</Icon> check_circle_outline
      </div>),
      tags: ["approve", "check", "circle", "complete", "done", "finished", "mark", "ok", "outline", "select", "tick", "validate", "verified", "yes"]
    },
		{
      value: "checklist",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>checklist</Icon> checklist
      </div>),
      tags: ["align", "alignment", "approve", "check", "checklist", "complete", "doc", "done", "edit", "editing", "editor", "format", "list", "mark", "notes", "ok", "select", "sheet", "spreadsheet", "text", "tick", "type", "validate", "verified", "writing", "yes"]
    },
		{
      value: "checklist_rtl",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>checklist_rtl</Icon> checklist_rtl
      </div>),
      tags: ["align", "alignment", "approve", "check", "checklist", "complete", "doc", "done", "edit", "editing", "editor", "format", "list", "mark", "notes", "ok", "rtl", "select", "sheet", "spreadsheet", "text", "tick", "type", "validate", "verified", "writing", "yes"]
    },
		{
      value: "checkroom",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>checkroom</Icon> checkroom
      </div>),
      tags: ["checkroom", "closet", "clothes", "coat check", "hanger"]
    },
		{
      value: "chevron_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>chevron_left</Icon> chevron_left
      </div>),
      tags: ["DISABLE_IOS", "arrow", "arrows", "chevron", "direction", "disable_ios", "left"]
    },
		{
      value: "chevron_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>chevron_right</Icon> chevron_right
      </div>),
      tags: ["arrow", "arrows", "chevron", "direction", "right"]
    },
		{
      value: "child_care",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>child_care</Icon> child_care
      </div>),
      tags: ["babies", "baby", "care", "child", "children", "face", "infant", "kids", "newborn", "toddler", "young"]
    },
		{
      value: "child_friendly",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>child_friendly</Icon> child_friendly
      </div>),
      tags: ["baby", "care", "carriage", "child", "children", "friendly", "infant", "kid", "newborn", "stroller", "toddler", "young"]
    },
		{
      value: "chrome_reader_mode",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>chrome_reader_mode</Icon> chrome_reader_mode
      </div>),
      tags: ["chrome", "mode", "read", "reader", "text"]
    },
		{
      value: "church",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>church</Icon> church
      </div>),
      tags: ["christian", "christianity", "ideology", "religion", "spiritual", "worship"]
    },
		{
      value: "circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>circle</Icon> circle
      </div>),
      tags: ["angle", "circle", "eye", "fish", "full", "geometry", "image", "lens", "moon", "panorama", "photo", "photography", "picture", "wide"]
    },
		{
      value: "circle_notifications",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>circle_notifications</Icon> circle_notifications
      </div>),
      tags: ["active", "alarm", "alert", "bell", "chime", "circle", "notifications", "notify", "reminder", "ring", "sound"]
    },
		{
      value: "class",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>class</Icon> class
      </div>),
      tags: ["archive", "book", "bookmark", "class", "favorite", "label", "library", "read", "reading", "remember", "ribbon", "save", "tag"]
    },
		{
      value: "clean_hands",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>clean_hands</Icon> clean_hands
      </div>),
      tags: ["bacteria", "clean", "disinfect", "germs", "gesture", "hand", "hands", "sanitize", "sanitizer"]
    },
		{
      value: "cleaning_services",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cleaning_services</Icon> cleaning_services
      </div>),
      tags: ["clean", "cleaning", "dust", "services", "sweep"]
    },
		{
      value: "clear",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>clear</Icon> clear
      </div>),
      tags: ["back", "cancel", "clear", "correct", "delete", "erase", "exit", "remove", "x"]
    },
		{
      value: "clear_all",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>clear_all</Icon> clear_all
      </div>),
      tags: ["all", "clear", "doc", "document", "format", "lines", "list"]
    },
		{
      value: "close",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>close</Icon> close
      </div>),
      tags: ["cancel", "clear", "close", "exit", "remove", "stop", "x"]
    },
		{
      value: "close_fullscreen",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>close_fullscreen</Icon> close_fullscreen
      </div>),
      tags: ["action", "arrow", "arrows", "close", "collapse", "direction", "full", "fullscreen", "minimize", "screen"]
    },
		{
      value: "closed_caption",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>closed_caption</Icon> closed_caption
      </div>),
      tags: ["accessible", "alphabet", "caption", "cc", "character", "closed", "decoder", "font", "language", "letters", "media", "movies", "subtitle", "subtitles", "symbol", "text", "tv", "type"]
    },
		{
      value: "closed_caption_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>closed_caption_disabled</Icon> closed_caption_disabled
      </div>),
      tags: ["accessible", "alphabet", "caption", "cc", "character", "closed", "decoder", "disabled", "enabled", "font", "language", "letters", "media", "movies", "off", "on", "slash", "subtitle", "subtitles", "symbol", "text", "tv", "type"]
    },
		{
      value: "closed_caption_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>closed_caption_off</Icon> closed_caption_off
      </div>),
      tags: ["accessible", "alphabet", "caption", "cc", "character", "closed", "decoder", "font", "language", "letters", "media", "movies", "off", "outline", "subtitle", "subtitles", "symbol", "text", "tv", "type"]
    },
		{
      value: "cloud",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cloud</Icon> cloud
      </div>),
      tags: ["climate", "cloud", "connection", "internet", "network", "queue", "sky", "temperature", "upload", "weather"]
    },
		{
      value: "cloud_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cloud_circle</Icon> cloud_circle
      </div>),
      tags: ["app", "application", "backup", "circle", "cloud", "connection", "drive", "files", "folders", "internet", "network", "sky", "storage", "upload"]
    },
		{
      value: "cloud_done",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cloud_done</Icon> cloud_done
      </div>),
      tags: ["app", "application", "approve", "backup", "check", "cloud", "complete", "connection", "done", "drive", "files", "folders", "internet", "mark", "network", "ok", "select", "sky", "storage", "tick", "upload", "validate", "verified", "yes"]
    },
		{
      value: "cloud_download",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cloud_download</Icon> cloud_download
      </div>),
      tags: ["app", "application", "arrow", "backup", "cloud", "connection", "down", "download", "drive", "files", "folders", "internet", "network", "sky", "storage", "upload"]
    },
		{
      value: "cloud_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cloud_off</Icon> cloud_off
      </div>),
      tags: ["app", "application", "backup", "cloud", "connection", "disabled", "drive", "enabled", "files", "folders", "internet", "network", "off", "offline", "on", "sky", "slash", "storage", "upload"]
    },
		{
      value: "cloud_queue",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cloud_queue</Icon> cloud_queue
      </div>),
      tags: ["climate", "cloud", "connection", "internet", "network", "queue", "sky", "temperature", "upload", "weather"]
    },
		{
      value: "cloud_sync",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cloud_sync</Icon> cloud_sync
      </div>),
      tags: ["app", "application", "around", "backup", "cloud", "connection", "drive", "files", "folders", "inprogress", "internet", "load", "loading refresh", "network", "renew", "rotate", "sky", "storage", "turn", "upload"]
    },
		{
      value: "cloud_upload",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cloud_upload</Icon> cloud_upload
      </div>),
      tags: ["app", "application", "arrow", "backup", "cloud", "connection", "download", "drive", "files", "folders", "internet", "network", "sky", "storage", "up", "upload"]
    },
		{
      value: "co2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>co2</Icon> co2
      </div>),
      tags: ["carbon", "chemical", "co2", "dioxide", "gas"]
    },
		{
      value: "co_present",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>co_present</Icon> co_present
      </div>),
      tags: ["arrow", "co-present", "presentation", "screen", "share", "site", "slides", "togather", "web", "website"]
    },
		{
      value: "code",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>code</Icon> code
      </div>),
      tags: ["brackets", "code", "css", "develop", "developer", "engineer", "engineering", "html", "platform"]
    },
		{
      value: "code_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>code_off</Icon> code_off
      </div>),
      tags: ["brackets", "code", "css", "develop", "developer", "disabled", "enabled", "engineer", "engineering", "html", "off", "on", "platform", "slash"]
    },
		{
      value: "coffee",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>coffee</Icon> coffee
      </div>),
      tags: ["beverage", "coffee", "cup", "drink", "mug", "plate", "set", "tea"]
    },
		{
      value: "coffee_maker",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>coffee_maker</Icon> coffee_maker
      </div>),
      tags: ["appliances", "beverage", "coffee", "cup", "drink", "machine", "maker", "mug"]
    },
		{
      value: "collections",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>collections</Icon> collections
      </div>),
      tags: ["album", "collections", "gallery", "image", "landscape", "library", "mountain", "mountains", "photo", "photography", "picture", "stack"]
    },
		{
      value: "collections_bookmark",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>collections_bookmark</Icon> collections_bookmark
      </div>),
      tags: ["album", "archive", "bookmark", "collections", "favorite", "gallery", "label", "library", "read", "reading", "remember", "ribbon", "save", "stack", "tag"]
    },
		{
      value: "color_lens",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>color_lens</Icon> color_lens
      </div>),
      tags: ["art", "color", "lens", "paint", "pallet"]
    },
		{
      value: "colorize",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>colorize</Icon> colorize
      </div>),
      tags: ["color", "colorize", "dropper", "extract", "eye", "picker", "tool"]
    },
		{
      value: "comment",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>comment</Icon> comment
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "feedback", "message", "outline", "speech"]
    },
		{
      value: "comment_bank",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>comment_bank</Icon> comment_bank
      </div>),
      tags: ["archive", "bank", "bookmark", "bubble", "cchat", "comment", "communicate", "favorite", "label", "library", "message", "remember", "ribbon", "save", "speech", "tag"]
    },
		{
      value: "comments_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>comments_disabled</Icon> comments_disabled
      </div>),
      tags: ["bubble", "chat", "comment", "comments", "communicate", "disabled", "enabled", "feedback", "message", "off", "offline", "on", "slash", "speech"]
    },
		{
      value: "commit",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>commit</Icon> commit
      </div>),
      tags: ["accomplish", "bind", "circle", "commit", "dedicate", "execute", "line", "perform", "pledge"]
    },
		{
      value: "commute",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>commute</Icon> commute
      </div>),
      tags: ["automobile", "car", "commute", "direction", "maps", "public", "train", "transportation", "trip", "vehicle"]
    },
		{
      value: "compare",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>compare</Icon> compare
      </div>),
      tags: ["adjust", "adjustment", "compare", "edit", "editing", "edits", "enhance", "fix", "image", "images", "photo", "photography", "photos", "scan", "settings"]
    },
		{
      value: "compare_arrows",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>compare_arrows</Icon> compare_arrows
      </div>),
      tags: ["arrow", "arrows", "collide", "compare", "direction", "left", "pressure", "push", "right", "together"]
    },
		{
      value: "compass_calibration",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>compass_calibration</Icon> compass_calibration
      </div>),
      tags: ["calibration", "compass", "connection", "internet", "location", "maps", "network", "refresh", "service", "signal", "wifi", "wireless"]
    },
		{
      value: "compost",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>compost</Icon> compost
      </div>),
      tags: ["bio", "compost", "compostable", "decomposable", "decompose", "eco", "green", "leaf", "leafs", "nature", "organic", "plant", "recycle", "sustainability", "sustainable"]
    },
		{
      value: "compress",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>compress</Icon> compress
      </div>),
      tags: ["arrow", "arrows", "collide", "compress", "pressure", "push", "together"]
    },
		{
      value: "computer",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>computer</Icon> computer
      </div>),
      tags: ["Android", "OS", "chrome", "computer", "desktop", "device", "hardware", "iOS", "mac", "monitor", "web", "window"]
    },
		{
      value: "confirmation_number",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>confirmation_number</Icon> confirmation_number
      </div>),
      tags: ["admission", "confirmation", "entertainment", "event", "numbers", "ticket"]
    },
		{
      value: "connect_without_contact",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>connect_without_contact</Icon> connect_without_contact
      </div>),
      tags: ["communicating", "connect", "contact", "distance", "people", "signal", "social", "socialize", "without"]
    },
		{
      value: "connected_tv",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>connected_tv</Icon> connected_tv
      </div>),
      tags: ["Android", "OS", "airplay", "chrome", "connect", "connected", "desktop", "device", "display", "hardware", "iOS", "mac", "monitor", "screen", "screencast", "streaming", "television", "tv", "web", "window", "wireless"]
    },
		{
      value: "connecting_airports",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>connecting_airports</Icon> connecting_airports
      </div>),
      tags: ["air", "aircraft", "airplane", "airplanes", "airport", "flight", "flights", "fly", "flying", "mode", "on", "plane", "planes", "signal", "transportation", "travel", "trip"]
    },
		{
      value: "construction",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>construction</Icon> construction
      </div>),
      tags: ["build", "carpenter", "construction", "equipment", "fix", "hammer", "improvement", "industrial", "industry", "repair", "tools", "wrench"]
    },
		{
      value: "contact_emergency",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>contact_emergency</Icon> contact_emergency
      </div>),
      tags: ["account", "avatar", "call", "cell", "contacts", "face", "human", "info", "information", "mobile", "people", "person", "phone", "profile", "user"]
    },
		{
      value: "contact_mail",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>contact_mail</Icon> contact_mail
      </div>),
      tags: ["account", "address", "avatar", "communicate", "contact", "email", "face", "human", "info", "information", "mail", "message", "people", "person", "profile", "user"]
    },
		{
      value: "contact_page",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>contact_page</Icon> contact_page
      </div>),
      tags: ["account", "avatar", "contact", "data", "doc", "document", "drive", "face", "file", "folder", "folders", "human", "page", "people", "person", "profile", "sheet", "slide", "storage", "user", "writing"]
    },
		{
      value: "contact_phone",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>contact_phone</Icon> contact_phone
      </div>),
      tags: ["account", "avatar", "call", "communicate", "contact", "face", "human", "info", "information", "message", "mobile", "people", "person", "phone", "profile", "user"]
    },
		{
      value: "contact_support",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>contact_support</Icon> contact_support
      </div>),
      tags: ["?", "bubble", "chat", "comment", "communicate", "contact", "help", "info", "information", "mark", "message", "punctuation", "question", "question mark", "speech", "support", "symbol"]
    },
		{
      value: "contactless",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>contactless</Icon> contactless
      </div>),
      tags: ["bluetooth", "cash", "connect", "connection", "connectivity", "contact", "contactless", "credit", "device", "finance", "pay", "payment", "signal", "transaction", "wifi", "wireless"]
    },
		{
      value: "contacts",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>contacts</Icon> contacts
      </div>),
      tags: ["account", "avatar", "call", "cell", "contacts", "face", "human", "info", "information", "mobile", "people", "person", "phone", "profile", "user"]
    },
		{
      value: "content_copy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>content_copy</Icon> content_copy
      </div>),
      tags: ["content", "copy", "cut", "doc", "document", "duplicate", "file", "multiple", "paste", "stack"]
    },
		{
      value: "content_cut",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>content_cut</Icon> content_cut
      </div>),
      tags: ["content", "copy", "cut", "doc", "document", "file", "paste", "scissors", "trim"]
    },
		{
      value: "content_paste",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>content_paste</Icon> content_paste
      </div>),
      tags: ["clipboard", "content", "copy", "cut", "doc", "document", "file", "multiple", "paste"]
    },
		{
      value: "content_paste_go",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>content_paste_go</Icon> content_paste_go
      </div>),
      tags: ["clipboard", "content", "disabled", "doc", "document", "enabled", "file", "go", "on", "paste", "slash"]
    },
		{
      value: "content_paste_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>content_paste_off</Icon> content_paste_off
      </div>),
      tags: ["clipboard", "content", "disabled", "doc", "document", "enabled", "file", "off", "on", "paste", "slash"]
    },
		{
      value: "content_paste_search",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>content_paste_search</Icon> content_paste_search
      </div>),
      tags: ["clipboard", "content", "doc", "document", "file", "find", "paste", "search", "trace", "track"]
    },
		{
      value: "contrast",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>contrast</Icon> contrast
      </div>),
      tags: ["black", "contrast", "dark theme", "edit", "editing", "effect", "filter", "grayscale", "image", "images", "photography", "picture", "pictures", "settings", "white"]
    },
		{
      value: "control_camera",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>control_camera</Icon> control_camera
      </div>),
      tags: ["adjust", "arrow", "arrows", "camera", "center", "control", "direction", "left", "move", "reposition", "right"]
    },
		{
      value: "control_point",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>control_point</Icon> control_point
      </div>),
      tags: ["+", "add", "circle", "control", "plus", "point"]
    },
		{
      value: "control_point_duplicate",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>control_point_duplicate</Icon> control_point_duplicate
      </div>),
      tags: ["+", "add", "circle", "control", "duplicate", "multiple", "new", "plus", "point", "symbol"]
    },
		{
      value: "cookie",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cookie</Icon> cookie
      </div>),
      tags: ["biscuit", "cookies", "data", "dessert", "wafer"]
    },
		{
      value: "copy_all",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>copy_all</Icon> copy_all
      </div>),
      tags: ["all", "content", "copy", "cut", "dash", "dashed", "doc", "document", "file", "multiple", "page", "paper", "past"]
    },
		{
      value: "copyright",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>copyright</Icon> copyright
      </div>),
      tags: ["alphabet", "c", "character", "copyright", "emblem", "font", "legal", "letters", "owner", "symbol", "text"]
    },
		{
      value: "coronavirus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>coronavirus</Icon> coronavirus
      </div>),
      tags: ["19", "bacteria", "coronavirus", "covid", "disease", "germs", "illness", "sick", "social"]
    },
		{
      value: "corporate_fare",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>corporate_fare</Icon> corporate_fare
      </div>),
      tags: ["architecture", "building", "business", "corporate", "estate", "fare", "organization", "place", "real", "residence", "residential", "shelter"]
    },
		{
      value: "cottage",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cottage</Icon> cottage
      </div>),
      tags: ["architecture", "beach", "cottage", "estate", "home", "house", "lake", "lodge", "maps", "place", "real", "residence", "residential", "stay", "traveling"]
    },
		{
      value: "countertops",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>countertops</Icon> countertops
      </div>),
      tags: ["counter", "countertops", "home", "house", "kitchen", "sink", "table", "tops"]
    },
		{
      value: "create",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>create</Icon> create
      </div>),
      tags: ["compose", "create", "edit", "editing", "input", "new", "pen", "pencil", "write", "writing"]
    },
		{
      value: "create_new_folder",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>create_new_folder</Icon> create_new_folder
      </div>),
      tags: ["+", "add", "create", "data", "doc", "document", "drive", "file", "folder", "new", "plus", "sheet", "slide", "storage", "symbol"]
    },
		{
      value: "credit_card",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>credit_card</Icon> credit_card
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollars", "finance", "money", "online", "pay", "payment", "price", "shopping", "symbol"]
    },
		{
      value: "credit_card_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>credit_card_off</Icon> credit_card_off
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "disabled", "dollars", "enabled", "finance", "money", "off", "online", "pay", "payment", "price", "shopping", "slash", "symbol"]
    },
		{
      value: "credit_score",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>credit_score</Icon> credit_score
      </div>),
      tags: ["approve", "bill", "card", "cash", "check", "coin", "commerce", "complete", "cost", "credit", "currency", "dollars", "done", "finance", "loan", "mark", "money", "ok", "online", "pay", "payment", "score", "select", "symbol", "tick", "validate", "verified", "yes"]
    },
		{
      value: "crib",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crib</Icon> crib
      </div>),
      tags: ["babies", "baby", "bassinet", "bed", "child", "children", "cradle", "crib", "infant", "kid", "newborn", "sleeping", "toddler"]
    },
		{
      value: "crisis_alert",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crisis_alert</Icon> crisis_alert
      </div>),
      tags: ["!", "alert", "attention", "bullseye", "caution", "crisis", "danger", "error", "exclamation", "important", "mark", "notification", "symbol", "target", "warning"]
    },
		{
      value: "crop",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop</Icon> crop
      </div>),
      tags: ["adjust", "adjustments", "area", "crop", "edit", "editing", "frame", "image", "images", "photo", "photos", "rectangle", "settings", "size", "square"]
    },
		{
      value: "crop_16_9",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop_16_9</Icon> crop_16_9
      </div>),
      tags: ["16", "9", "adjust", "adjustments", "area", "by", "crop", "edit", "editing", "frame", "image", "images", "photo", "photos", "rectangle", "settings", "size", "square"]
    },
		{
      value: "crop_3_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop_3_2</Icon> crop_3_2
      </div>),
      tags: ["2", "3", "adjust", "adjustments", "area", "by", "crop", "edit", "editing", "frame", "image", "images", "photo", "photos", "rectangle", "settings", "size", "square"]
    },
		{
      value: "crop_5_4",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop_5_4</Icon> crop_5_4
      </div>),
      tags: ["4", "5", "adjust", "adjustments", "area", "by", "crop", "edit", "editing settings", "frame", "image", "images", "photo", "photos", "rectangle", "size", "square"]
    },
		{
      value: "crop_7_5",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop_7_5</Icon> crop_7_5
      </div>),
      tags: ["5", "7", "adjust", "adjustments", "area", "by", "crop", "editing", "frame", "image", "images", "photo", "photos", "rectangle", "settings", "size", "square"]
    },
		{
      value: "crop_din",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop_din</Icon> crop_din
      </div>),
      tags: ["adjust", "adjustments", "area", "crop", "din", "edit", "editing", "frame", "image", "images", "photo", "photos", "rectangle", "settings", "size", "square"]
    },
		{
      value: "crop_free",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop_free</Icon> crop_free
      </div>),
      tags: ["adjust", "adjustments", "crop", "display", "edit", "editing", "focus", "frame", "free", "image", "photo", "photos", "settings", "size", "zoom"]
    },
		{
      value: "crop_landscape",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop_landscape</Icon> crop_landscape
      </div>),
      tags: ["adjust", "adjustments", "area", "crop", "edit", "editing", "frame", "image", "images", "landscape", "photo", "photos", "settings", "size"]
    },
		{
      value: "crop_original",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop_original</Icon> crop_original
      </div>),
      tags: ["adjust", "adjustments", "area", "crop", "edit", "editing", "frame", "image", "images", "original", "photo", "photos", "picture", "settings", "size"]
    },
		{
      value: "crop_portrait",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop_portrait</Icon> crop_portrait
      </div>),
      tags: ["adjust", "adjustments", "area", "crop", "edit", "editing", "frame", "image", "images", "photo", "photos", "portrait", "rectangle", "settings", "size", "square"]
    },
		{
      value: "crop_rotate",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop_rotate</Icon> crop_rotate
      </div>),
      tags: ["adjust", "adjustments", "area", "arrow", "arrows", "crop", "edit", "editing", "frame", "image", "images", "photo", "photos", "rotate", "settings", "size", "turn"]
    },
		{
      value: "crop_square",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>crop_square</Icon> crop_square
      </div>),
      tags: ["adjust", "adjustments", "app", "application", "area", "components", "crop", "design", "edit", "editing", "expand", "frame", "image", "images", "interface", "open", "photo", "photos", "rectangle", "screen", "settings", "shape", "shapes", "site", "size", "square", "ui", "ux", "web", "website", "window"]
    },
		{
      value: "cruelty_free",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cruelty_free</Icon> cruelty_free
      </div>),
      tags: ["animal", "bunny", "cruelty", "eco", "free", "nature", "rabbit", "social", "sustainability", "sustainable", "testing"]
    },
		{
      value: "css",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>css</Icon> css
      </div>),
      tags: ["alphabet", "brackets", "character", "code", "css", "develop", "developer", "engineer", "engineering", "font", "html", "letters", "platform", "symbol", "text", "type"]
    },
		{
      value: "currency_bitcoin",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>currency_bitcoin</Icon> currency_bitcoin
      </div>),
      tags: ["bill", "blockchain", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "digital", "dollar", "dollars", "finance", "franc", "money", "online", "pay", "payment", "price", "shopping", "symbol"]
    },
		{
      value: "currency_exchange",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>currency_exchange</Icon> currency_exchange
      </div>),
      tags: ["360", "around", "arrow", "arrows", "cash", "coin", "commerce", "currency", "direction", "dollars", "exchange", "inprogress", "money", "pay", "renew", "rotate", "sync", "turn", "universal"]
    },
		{
      value: "currency_franc",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>currency_franc</Icon> currency_franc
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollar", "dollars", "finance", "franc", "money", "online", "pay", "payment", "price", "shopping", "symbol"]
    },
		{
      value: "currency_lira",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>currency_lira</Icon> currency_lira
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollar", "dollars", "finance", "lira", "money", "online", "pay", "payment", "price", "shopping", "symbol"]
    },
		{
      value: "currency_pound",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>currency_pound</Icon> currency_pound
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollar", "dollars", "finance", "money", "online", "pay", "payment", "pound", "price", "shopping", "symbol"]
    },
		{
      value: "currency_ruble",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>currency_ruble</Icon> currency_ruble
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollar", "dollars", "finance", "money", "online", "pay", "payment", "price", "ruble", "shopping", "symbol"]
    },
		{
      value: "currency_rupee",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>currency_rupee</Icon> currency_rupee
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollar", "dollars", "finance", "money", "online", "pay", "payment", "price", "rupee", "shopping", "symbol"]
    },
		{
      value: "currency_yen",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>currency_yen</Icon> currency_yen
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollar", "dollars", "finance", "money", "online", "pay", "payment", "price", "shopping", "symbol", "yen"]
    },
		{
      value: "currency_yuan",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>currency_yuan</Icon> currency_yuan
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollar", "dollars", "finance", "money", "online", "pay", "payment", "price", "shopping", "symbol", "yuan"]
    },
		{
      value: "curtains",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>curtains</Icon> curtains
      </div>),
      tags: ["blinds", "cover", "curtains", "nest", "open", "shade", "shutter", "sunshade"]
    },
		{
      value: "curtains_closed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>curtains_closed</Icon> curtains_closed
      </div>),
      tags: ["blinds", "closed", "cover", "curtains", "nest", "shade", "shutter", "sunshade"]
    },
		{
      value: "cyclone",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>cyclone</Icon> cyclone
      </div>),
      tags: ["crisis", "disaster", "natural", "rain", "storm", "water", "weather", "wind", "winds"]
    },
		{
      value: "dangerous",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dangerous</Icon> dangerous
      </div>),
      tags: ["broken", "danger", "dangerous", "fix", "no", "sign", "stop", "update", "warning", "wrong", "x"]
    },
		{
      value: "dark_mode",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dark_mode</Icon> dark_mode
      </div>),
      tags: ["app", "application", "dark", "device", "interface", "mode", "moon", "night", "silent", "theme", "ui", "ux", "website"]
    },
		{
      value: "dashboard",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dashboard</Icon> dashboard
      </div>),
      tags: ["cards", "dashboard", "format", "grid", "layout", "rectangle", "shapes", "square", "view", "web", "website"]
    },
		{
      value: "dashboard_customize",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dashboard_customize</Icon> dashboard_customize
      </div>),
      tags: ["cards", "customize", "dashboard", "format", "layout", "rectangle", "shapes", "square", "web", "website"]
    },
		{
      value: "data_array",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>data_array</Icon> data_array
      </div>),
      tags: ["array", "brackets", "code", "coder", "data", "parentheses"]
    },
		{
      value: "data_exploration",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>data_exploration</Icon> data_exploration
      </div>),
      tags: ["analytics", "arrow", "chart", "data", "diagram", "exploration", "graph", "infographic", "measure", "metrics", "statistics", "tracking"]
    },
		{
      value: "data_object",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>data_object</Icon> data_object
      </div>),
      tags: ["brackets", "code", "coder", "data", "object", "parentheses"]
    },
		{
      value: "data_saver_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>data_saver_off</Icon> data_saver_off
      </div>),
      tags: ["analytics", "bar", "bars", "chart", "data", "diagram", "donut", "graph", "infographic", "measure", "metrics", "off", "on", "ring", "saver", "statistics", "tracking"]
    },
		{
      value: "data_saver_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>data_saver_on</Icon> data_saver_on
      </div>),
      tags: ["+", "add", "analytics", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "new", "on", "plus", "ring", "saver", "statistics", "symbol", "tracking"]
    },
		{
      value: "data_thresholding",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>data_thresholding</Icon> data_thresholding
      </div>),
      tags: ["data", "hidden", "privacy", "thresholding", "thresold"]
    },
		{
      value: "data_usage",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>data_usage</Icon> data_usage
      </div>),
      tags: ["analytics", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "statistics", "tracking", "usage"]
    },
		{
      value: "dataset",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dataset</Icon> dataset
      </div>),
      tags: []
    },
		{
      value: "dataset_linked",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dataset_linked</Icon> dataset_linked
      </div>),
      tags: []
    },
		{
      value: "date_range",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>date_range</Icon> date_range
      </div>),
      tags: ["calendar", "date", "day", "event", "month", "range", "remember", "reminder", "schedule", "time", "today", "week"]
    },
		{
      value: "deblur",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>deblur</Icon> deblur
      </div>),
      tags: ["adjust", "deblur", "edit", "editing", "enhance", "face", "image", "lines", "photo", "photography", "sharpen"]
    },
		{
      value: "deck",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>deck</Icon> deck
      </div>),
      tags: ["chairs", "deck", "home", "house", "outdoors", "outside", "patio", "social", "terrace", "umbrella", "yard"]
    },
		{
      value: "dehaze",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dehaze</Icon> dehaze
      </div>),
      tags: ["adjust", "dehaze", "edit", "editing", "enhance", "haze", "image", "lines", "photo", "photography", "remove"]
    },
		{
      value: "delete",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>delete</Icon> delete
      </div>),
      tags: ["bin", "can", "delete", "garbage", "remove", "trash"]
    },
		{
      value: "delete_forever",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>delete_forever</Icon> delete_forever
      </div>),
      tags: ["bin", "can", "cancel", "clear", "clear all", "delete", "exit", "forever", "garbage", "remove", "trash", "x"]
    },
		{
      value: "delete_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>delete_outline</Icon> delete_outline
      </div>),
      tags: ["bin", "can", "delete", "garbage", "outline", "remove", "trash"]
    },
		{
      value: "delete_sweep",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>delete_sweep</Icon> delete_sweep
      </div>),
      tags: ["bin", "can", "delete", "garbage", "remove", "sweep", "trash"]
    },
		{
      value: "delivery_dining",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>delivery_dining</Icon> delivery_dining
      </div>),
      tags: ["delivery", "dining", "food", "meal", "restaurant", "scooter", "takeout", "transportation", "vehicle", "vespa"]
    },
		{
      value: "density_large",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>density_large</Icon> density_large
      </div>),
      tags: ["density", "horizontal", "large", "lines", "rule", "rules"]
    },
		{
      value: "density_medium",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>density_medium</Icon> density_medium
      </div>),
      tags: ["density", "horizontal", "lines", "medium", "rule", "rules"]
    },
		{
      value: "density_small",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>density_small</Icon> density_small
      </div>),
      tags: ["density", "horizontal", "lines", "rule", "rules", "small"]
    },
		{
      value: "departure_board",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>departure_board</Icon> departure_board
      </div>),
      tags: ["automobile", "board", "bus", "car", "cars", "clock", "departure", "maps", "public", "schedule", "time", "transportation", "travel", "vehicle"]
    },
		{
      value: "description",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>description</Icon> description
      </div>),
      tags: ["article", "data", "description", "doc", "document", "drive", "file", "folder", "folders", "notes", "page", "paper", "sheet", "slide", "text", "writing"]
    },
		{
      value: "deselect",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>deselect</Icon> deselect
      </div>),
      tags: ["all", "disabled", "enabled", "off", "on", "selection", "slash", "square", "tool"]
    },
		{
      value: "design_services",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>design_services</Icon> design_services
      </div>),
      tags: ["compose", "create", "design", "draft", "edit", "editing", "input", "pen", "pencil", "ruler", "service", "write", "writing"]
    },
		{
      value: "desk",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>desk</Icon> desk
      </div>),
      tags: []
    },
		{
      value: "desktop_access_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>desktop_access_disabled</Icon> desktop_access_disabled
      </div>),
      tags: ["Android", "OS", "access", "chrome", "desktop", "device", "disabled", "display", "enabled", "hardware", "iOS", "mac", "monitor", "off", "offline", "on", "screen", "slash", "web", "window"]
    },
		{
      value: "desktop_mac",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>desktop_mac</Icon> desktop_mac
      </div>),
      tags: ["Android", "OS", "chrome", "desktop", "device", "display", "hardware", "iOS", "mac", "monitor", "screen", "web", "window"]
    },
		{
      value: "desktop_windows",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>desktop_windows</Icon> desktop_windows
      </div>),
      tags: ["Android", "OS", "chrome", "desktop", "device", "display", "hardware", "iOS", "mac", "monitor", "screen", "television", "tv", "web", "window", "windows"]
    },
		{
      value: "details",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>details</Icon> details
      </div>),
      tags: ["details", "edit", "editing", "enhance", "image", "photo", "photography", "sharpen", "triangle"]
    },
		{
      value: "developer_board",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>developer_board</Icon> developer_board
      </div>),
      tags: ["board", "chip", "computer", "developer", "development", "hardware", "microchip", "processor"]
    },
		{
      value: "developer_board_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>developer_board_off</Icon> developer_board_off
      </div>),
      tags: ["board", "chip", "computer", "developer", "development", "disabled", "enabled", "hardware", "microchip", "off", "on", "processor", "slash"]
    },
		{
      value: "developer_mode",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>developer_mode</Icon> developer_mode
      </div>),
      tags: ["Android", "OS", "bracket", "cell", "code", "developer", "development", "device", "engineer", "hardware", "iOS", "mobile", "mode", "phone", "tablet"]
    },
		{
      value: "device_hub",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>device_hub</Icon> device_hub
      </div>),
      tags: ["Android", "OS", "circle", "computer", "desktop", "device", "hardware", "hub", "iOS", "laptop", "mobile", "monitor", "phone", "square", "tablet", "triangle", "watch", "wearable", "web"]
    },
		{
      value: "device_thermostat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>device_thermostat</Icon> device_thermostat
      </div>),
      tags: ["celsius", "device", "fahrenheit", "meter", "temp", "temperature", "thermometer", "thermostat"]
    },
		{
      value: "device_unknown",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>device_unknown</Icon> device_unknown
      </div>),
      tags: ["?", "Android", "OS", "assistance", "cell", "device", "hardware", "help", "iOS", "info", "information", "mobile", "phone", "punctuation", "question mark", "support", "symbol", "tablet", "unknown"]
    },
		{
      value: "devices",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>devices</Icon> devices
      </div>),
      tags: ["Android", "OS", "computer", "desktop", "device", "hardware", "iOS", "laptop", "mobile", "monitor", "phone", "tablet", "watch", "wearable", "web"]
    },
		{
      value: "devices_fold",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>devices_fold</Icon> devices_fold
      </div>),
      tags: ["Android", "OS", "cell", "dash", "dashed", "device", "fold", "foldable", "hardware", "iOS", "mobile", "phone", "tablet"]
    },
		{
      value: "devices_other",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>devices_other</Icon> devices_other
      </div>),
      tags: ["Android", "OS", "ar", "cell", "chrome", "desktop", "device", "gadget", "hardware", "iOS", "ipad", "mac", "mobile", "monitor", "other", "phone", "tablet", "virtual_reality", "vr", "watch", "wearables", "window"]
    },
		{
      value: "dialer_sip",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dialer_sip</Icon> dialer_sip
      </div>),
      tags: ["alphabet", "call", "cell", "character", "contact", "device", "dialer", "font", "hardware", "initiation", "internet", "letters", "mobile", "over", "phone", "protocol", "routing", "session", "sip", "symbol", "telephone", "text", "type", "voice"]
    },
		{
      value: "dialpad",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dialpad</Icon> dialpad
      </div>),
      tags: ["buttons", "call", "contact", "device", "dial", "dialpad", "dots", "mobile", "numbers", "pad", "phone"]
    },
		{
      value: "diamond",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>diamond</Icon> diamond
      </div>),
      tags: ["diamond", "fashion", "gems", "jewelry", "logo", "retail", "valuable", "valuables"]
    },
		{
      value: "difference",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>difference</Icon> difference
      </div>),
      tags: ["compare", "content", "copy", "cut", "diff", "difference", "doc", "document", "duplicate", "file", "multiple", "past", "stack"]
    },
		{
      value: "dining",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dining</Icon> dining
      </div>),
      tags: ["cafe", "cafeteria", "cutlery", "diner", "dining", "eat", "eating", "fork", "room", "spoon"]
    },
		{
      value: "dinner_dining",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dinner_dining</Icon> dinner_dining
      </div>),
      tags: ["breakfast", "dining", "dinner", "food", "fork", "lunch", "meal", "restaurant", "spaghetti", "utensils"]
    },
		{
      value: "directions",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions</Icon> directions
      </div>),
      tags: ["arrow", "directions", "maps", "right", "route", "sign", "traffic"]
    },
		{
      value: "directions_bike",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_bike</Icon> directions_bike
      </div>),
      tags: ["bicycle", "bike", "direction", "directions", "human", "maps", "person", "public", "route", "transportation"]
    },
		{
      value: "directions_boat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_boat</Icon> directions_boat
      </div>),
      tags: ["automobile", "boat", "car", "cars", "direction", "directions", "ferry", "maps", "public", "transportation", "vehicle"]
    },
		{
      value: "directions_boat_filled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_boat_filled</Icon> directions_boat_filled
      </div>),
      tags: ["automobile", "boat", "car", "cars", "direction", "directions", "ferry", "filled", "maps", "public", "transportation", "vehicle"]
    },
		{
      value: "directions_bus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_bus</Icon> directions_bus
      </div>),
      tags: ["automobile", "bus", "car", "cars", "directions", "maps", "public", "transportation", "vehicle"]
    },
		{
      value: "directions_bus_filled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_bus_filled</Icon> directions_bus_filled
      </div>),
      tags: ["automobile", "bus", "car", "cars", "direction", "directions", "filled", "maps", "public", "transportation", "vehicle"]
    },
		{
      value: "directions_car",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_car</Icon> directions_car
      </div>),
      tags: ["automobile", "car", "cars", "direction", "directions", "maps", "public", "transportation", "vehicle"]
    },
		{
      value: "directions_car_filled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_car_filled</Icon> directions_car_filled
      </div>),
      tags: ["automobile", "car", "cars", "direction", "directions", "maps", "public", "transportation", "vehicle"]
    },
		{
      value: "directions_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_off</Icon> directions_off
      </div>),
      tags: ["arrow", "directions", "disabled", "enabled", "maps", "off", "on", "right", "route", "sign", "slash", "traffic"]
    },
		{
      value: "directions_railway",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_railway</Icon> directions_railway
      </div>),
      tags: ["automobile", "car", "cars", "direction", "maps", "public", "rail", "railway", "subway", "train", "tram", "transit", "transportation", "trolley", "vehicle"]
    },
		{
      value: "directions_railway_filled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_railway_filled</Icon> directions_railway_filled
      </div>),
      tags: ["automobile", "car", "cars", "direction", "maps", "public", "rail", "railway", "subway", "train", "tram", "transit", "transportation", "trolley", "vehicle"]
    },
		{
      value: "directions_run",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_run</Icon> directions_run
      </div>),
      tags: ["body", "directions", "human", "jogging", "maps", "people", "person", "route", "run", "running", "walk"]
    },
		{
      value: "directions_subway",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_subway</Icon> directions_subway
      </div>),
      tags: ["automobile", "car", "cars", "direction", "maps", "public", "rail", "railway", "subway", "train", "tram", "transit", "transportation", "trolley", "vehicle"]
    },
		{
      value: "directions_subway_filled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_subway_filled</Icon> directions_subway_filled
      </div>),
      tags: ["automobile", "car", "cars", "direction", "maps", "public", "rail", "railway", "subway", "train", "tram", "transit", "transportation", "trolley", "vehicle"]
    },
		{
      value: "directions_transit",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_transit</Icon> directions_transit
      </div>),
      tags: ["automobile", "car", "cars", "direction", "maps", "public", "rail", "railway", "subway", "train", "tram", "transit", "transportation", "trolley", "vehicle"]
    },
		{
      value: "directions_transit_filled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_transit_filled</Icon> directions_transit_filled
      </div>),
      tags: ["automobile", "car", "cars", "direction", "maps", "public", "rail", "railway", "subway", "train", "tram", "transit", "transportation", "trolley", "vehicle"]
    },
		{
      value: "directions_walk",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>directions_walk</Icon> directions_walk
      </div>),
      tags: ["body", "direction", "directions", "human", "jogging", "maps", "people", "person", "route", "run", "walk"]
    },
		{
      value: "dirty_lens",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dirty_lens</Icon> dirty_lens
      </div>),
      tags: ["camera", "dirty", "lens", "photo", "photography", "picture", "splat"]
    },
		{
      value: "disabled_by_default",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>disabled_by_default</Icon> disabled_by_default
      </div>),
      tags: ["box", "by", "cancel", "clear", "close", "default", "disabled", "exit", "no", "quit", "remove", "square", "stop", "x"]
    },
		{
      value: "disabled_visible",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>disabled_visible</Icon> disabled_visible
      </div>),
      tags: ["cancel", "close", "disabled", "exit", "eye", "no", "on", "quit", "remove", "reveal", "see", "show", "stop", "view", "visibility", "visible"]
    },
		{
      value: "disc_full",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>disc_full</Icon> disc_full
      </div>),
      tags: ["!", "alert", "attention", "caution", "cd", "danger", "disc", "error", "exclamation", "full", "important", "mark", "music", "notification", "storage", "symbol", "warning"]
    },
		{
      value: "discount",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>discount</Icon> discount
      </div>),
      tags: []
    },
		{
      value: "display_settings",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>display_settings</Icon> display_settings
      </div>),
      tags: ["Android", "OS", "application", "change", "chrome", "desktop", "details", "device", "display", "gear", "hardware", "iOS", "info", "information", "mac", "monitor", "options", "personal", "screen", "service", "settings", "web", "window"]
    },
		{
      value: "diversity_1",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>diversity_1</Icon> diversity_1
      </div>),
      tags: ["committee", "diverse", "diversity", "family", "friends", "group", "groups", "heart", "humans", "network", "people", "persons", "social", "team"]
    },
		{
      value: "diversity_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>diversity_2</Icon> diversity_2
      </div>),
      tags: ["committee", "diverse", "diversity", "family", "friends", "group", "groups", "heart", "humans", "network", "people", "persons", "social", "team"]
    },
		{
      value: "diversity_3",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>diversity_3</Icon> diversity_3
      </div>),
      tags: ["committee", "diverse", "diversity", "family", "friends", "group", "groups", "humans", "network", "people", "persons", "social", "team"]
    },
		{
      value: "dns",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dns</Icon> dns
      </div>),
      tags: ["address", "bars", "dns", "domain", "information", "ip", "list", "lookup", "name", "server", "system"]
    },
		{
      value: "do_disturb",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>do_disturb</Icon> do_disturb
      </div>),
      tags: ["cancel", "close", "denied", "deny", "disturb", "do", "remove", "silence", "stop"]
    },
		{
      value: "do_disturb_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>do_disturb_alt</Icon> do_disturb_alt
      </div>),
      tags: ["cancel", "close", "denied", "deny", "disturb", "do", "remove", "silence", "stop"]
    },
		{
      value: "do_disturb_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>do_disturb_off</Icon> do_disturb_off
      </div>),
      tags: ["cancel", "close", "denied", "deny", "disabled", "disturb", "do", "enabled", "off", "on", "remove", "silence", "slash", "stop"]
    },
		{
      value: "do_disturb_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>do_disturb_on</Icon> do_disturb_on
      </div>),
      tags: ["cancel", "close", "denied", "deny", "disabled", "disturb", "do", "enabled", "off", "on", "remove", "silence", "slash", "stop"]
    },
		{
      value: "do_not_disturb",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>do_not_disturb</Icon> do_not_disturb
      </div>),
      tags: ["cancel", "close", "denied", "deny", "disturb", "do", "neutral", "remove", "silence", "slash", "stop"]
    },
		{
      value: "do_not_disturb_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>do_not_disturb_alt</Icon> do_not_disturb_alt
      </div>),
      tags: ["cancel", "close", "denied", "deny", "disturb", "do", "neutral", "remove", "silence", "slash", "stop"]
    },
		{
      value: "do_not_disturb_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>do_not_disturb_off</Icon> do_not_disturb_off
      </div>),
      tags: ["cancel", "close", "dash", "denied", "deny", "disabled", "disturb", "do", "enabled", "off", "on", "remove", "silence", "stop"]
    },
		{
      value: "do_not_disturb_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>do_not_disturb_on</Icon> do_not_disturb_on
      </div>),
      tags: ["cancel", "close", "dash", "denied", "deny", "disabled", "disturb", "do", "enabled", "off", "on", "remove", "silence", "stop"]
    },
		{
      value: "do_not_disturb_on_total_silence",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>do_not_disturb_on_total_silence</Icon> do_not_disturb_on_total_silence
      </div>),
      tags: ["busy", "disturb", "do", "mute", "no", "not", "on total", "quiet", "silence"]
    },
		{
      value: "do_not_step",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>do_not_step</Icon> do_not_step
      </div>),
      tags: ["boot", "disabled", "do", "enabled", "feet", "foot", "not", "off", "on", "shoe", "slash", "sneaker", "step", "steps"]
    },
		{
      value: "do_not_touch",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>do_not_touch</Icon> do_not_touch
      </div>),
      tags: ["disabled", "do", "enabled", "fingers", "gesture", "hand", "not", "off", "on", "slash", "touch"]
    },
		{
      value: "dock",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dock</Icon> dock
      </div>),
      tags: ["Android", "OS", "cell", "charging", "connector", "device", "dock", "hardware", "iOS", "mobile", "phone", "power", "station", "tablet"]
    },
		{
      value: "document_scanner",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>document_scanner</Icon> document_scanner
      </div>),
      tags: ["article", "data", "doc", "document", "drive", "file", "folder", "folders", "notes", "page", "paper", "scan", "scanner", "sheet", "slide", "text", "writing"]
    },
		{
      value: "domain",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>domain</Icon> domain
      </div>),
      tags: ["apartment", "architecture", "building", "business", "domain", "estate", "home", "place", "real", "residence", "residential", "shelter", "web", "www"]
    },
		{
      value: "domain_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>domain_add</Icon> domain_add
      </div>),
      tags: ["+", "add", "apartment", "architecture", "building", "business", "domain", "estate", "home", "new", "place", "plus", "real", "residence", "residential", "shelter", "symbol", "web", "www"]
    },
		{
      value: "domain_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>domain_disabled</Icon> domain_disabled
      </div>),
      tags: ["apartment", "architecture", "building", "business", "company", "disabled", "domain", "enabled", "estate", "home", "internet", "maps", "off", "office", "offline", "on", "place", "real", "residence", "residential", "slash", "web", "website"]
    },
		{
      value: "domain_verification",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>domain_verification</Icon> domain_verification
      </div>),
      tags: ["app", "application desktop", "approve", "check", "complete", "design", "domain", "done", "interface", "internet", "layout", "mark", "ok", "screen", "select", "site", "tick", "ui", "ux", "validate", "verification", "verified", "web", "website", "window", "www", "yes"]
    },
		{
      value: "done",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>done</Icon> done
      </div>),
      tags: ["DISABLE_IOS", "approve", "check", "complete", "disable_ios", "done", "mark", "ok", "select", "tick", "validate", "verified", "yes"]
    },
		{
      value: "done_all",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>done_all</Icon> done_all
      </div>),
      tags: ["all", "approve", "check", "complete", "done", "layers", "mark", "multiple", "ok", "select", "stack", "tick", "validate", "verified", "yes"]
    },
		{
      value: "done_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>done_outline</Icon> done_outline
      </div>),
      tags: ["all", "approve", "check", "complete", "done", "mark", "ok", "outline", "select", "tick", "validate", "verified", "yes"]
    },
		{
      value: "donut_large",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>donut_large</Icon> donut_large
      </div>),
      tags: ["analytics", "chart", "data", "diagram", "donut", "graph", "infographic", "inprogress", "large", "measure", "metrics", "pie", "statistics", "tracking"]
    },
		{
      value: "donut_small",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>donut_small</Icon> donut_small
      </div>),
      tags: ["analytics", "chart", "data", "diagram", "donut", "graph", "infographic", "inprogress", "measure", "metrics", "pie", "small", "statistics", "tracking"]
    },
		{
      value: "door_back",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>door_back</Icon> door_back
      </div>),
      tags: ["back", "closed", "door", "doorway", "entrance", "exit", "home", "house", "way"]
    },
		{
      value: "door_front",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>door_front</Icon> door_front
      </div>),
      tags: ["closed", "door", "doorway", "entrance", "exit", "front", "home", "house", "way"]
    },
		{
      value: "door_sliding",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>door_sliding</Icon> door_sliding
      </div>),
      tags: ["auto", "automatic", "door", "doorway", "double", "entrance", "exit", "glass", "home", "house", "sliding", "two"]
    },
		{
      value: "doorbell",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>doorbell</Icon> doorbell
      </div>),
      tags: ["alarm", "bell", "door", "doorbell", "home", "house", "ringing"]
    },
		{
      value: "double_arrow",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>double_arrow</Icon> double_arrow
      </div>),
      tags: ["arrow", "arrows", "direction", "double", "multiple", "navigation", "right"]
    },
		{
      value: "downhill_skiing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>downhill_skiing</Icon> downhill_skiing
      </div>),
      tags: ["athlete", "athletic", "body", "downhill", "entertainment", "exercise", "hobby", "human", "people", "person", "ski social", "skiing", "snow", "sports", "travel", "winter"]
    },
		{
      value: "download",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>download</Icon> download
      </div>),
      tags: ["arrow", "down", "download", "downloads", "drive", "install", "upload"]
    },
		{
      value: "download_done",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>download_done</Icon> download_done
      </div>),
      tags: ["arrow", "arrows", "check", "done", "down", "download", "downloads", "drive", "install", "installed", "ok", "tick", "upload"]
    },
		{
      value: "download_for_offline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>download_for_offline</Icon> download_for_offline
      </div>),
      tags: ["arrow", "circle", "down", "download", "for offline", "install", "upload"]
    },
		{
      value: "downloading",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>downloading</Icon> downloading
      </div>),
      tags: ["arrow", "circle", "down", "download", "downloading", "downloads", "install", "pending", "progress", "upload"]
    },
		{
      value: "drafts",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>drafts</Icon> drafts
      </div>),
      tags: ["document", "draft", "drafts", "email", "file", "letters", "mail", "message", "read"]
    },
		{
      value: "drag_handle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>drag_handle</Icon> drag_handle
      </div>),
      tags: ["app", "application ui", "components", "design", "drag", "handle", "interface", "layout", "menu", "move", "screen", "site", "ui", "ux", "web", "website", "window"]
    },
		{
      value: "drag_indicator",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>drag_indicator</Icon> drag_indicator
      </div>),
      tags: ["app", "application", "circles", "components", "design", "dots", "drag", "drop", "indicator", "interface", "layout", "mobile", "monitor", "move", "phone", "screen", "shape", "shift", "site", "tablet", "ui", "ux", "web", "website", "window"]
    },
		{
      value: "draw",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>draw</Icon> draw
      </div>),
      tags: ["compose", "create", "design", "draft", "draw", "edit", "editing", "input", "pen", "pencil", "write", "writing"]
    },
		{
      value: "drive_eta",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>drive_eta</Icon> drive_eta
      </div>),
      tags: ["automobile", "car", "cars", "destination", "direction", "drive", "estimate", "eta", "maps", "public", "transportation", "travel", "trip", "vehicle"]
    },
		{
      value: "drive_file_move",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>drive_file_move</Icon> drive_file_move
      </div>),
      tags: ["arrow", "data", "doc", "document", "drive", "file", "folder", "move", "right", "sheet", "slide", "storage"]
    },
		{
      value: "drive_file_move_rtl",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>drive_file_move_rtl</Icon> drive_file_move_rtl
      </div>),
      tags: ["arrow", "arrows", "data", "direction", "doc", "document", "drive", "file", "folder", "folders", "left", "move", "rtl", "sheet", "side", "slide", "storage"]
    },
		{
      value: "drive_file_rename_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>drive_file_rename_outline</Icon> drive_file_rename_outline
      </div>),
      tags: ["compose", "create", "draft", "drive", "edit", "editing", "file", "input", "marker", "pen", "pencil", "rename", "write", "writing"]
    },
		{
      value: "drive_folder_upload",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>drive_folder_upload</Icon> drive_folder_upload
      </div>),
      tags: ["arrow", "data", "doc", "document", "drive", "file", "folder", "sheet", "slide", "storage", "up", "upload"]
    },
		{
      value: "dry",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dry</Icon> dry
      </div>),
      tags: ["air", "bathroom", "dry", "dryer", "fingers", "gesture", "hand", "wc"]
    },
		{
      value: "dry_cleaning",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dry_cleaning</Icon> dry_cleaning
      </div>),
      tags: ["cleaning", "dry", "hanger", "hotel", "laundry", "places", "service", "towel"]
    },
		{
      value: "duo",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>duo</Icon> duo
      </div>),
      tags: ["call", "chat", "conference", "device", "duo", "video"]
    },
		{
      value: "dvr",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dvr</Icon> dvr
      </div>),
      tags: ["Android", "OS", "audio", "chrome", "computer", "desktop", "device", "display", "dvr", "electronic", "hardware", "iOS", "list", "mac", "monitor", "record", "recorder", "screen", "tv", "video", "web", "window"]
    },
		{
      value: "dynamic_feed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dynamic_feed</Icon> dynamic_feed
      </div>),
      tags: ["'mail_outline'", "'markunread'. Keep 'mail' and remove others.", "Duplicate of 'email'"]
    },
		{
      value: "dynamic_form",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>dynamic_form</Icon> dynamic_form
      </div>),
      tags: ["bolt", "code", "dynamic", "electric", "fast", "form", "lightning", "lists", "questionnaire", "thunderbolt"]
    },
		{
      value: "e_mobiledata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>e_mobiledata</Icon> e_mobiledata
      </div>),
      tags: ["alphabet", "data", "e", "font", "letters", "mobile", "mobiledata", "text", "type"]
    },
		{
      value: "earbuds",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>earbuds</Icon> earbuds
      </div>),
      tags: ["accessory", "audio", "earbuds", "earphone", "headphone", "listen", "music", "sound"]
    },
		{
      value: "earbuds_battery",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>earbuds_battery</Icon> earbuds_battery
      </div>),
      tags: ["accessory", "audio", "battery", "charging", "earbuds", "earphone", "headphone", "listen", "music", "sound"]
    },
		{
      value: "east",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>east</Icon> east
      </div>),
      tags: ["arrow", "directional", "east", "maps", "navigation", "right"]
    },
		{
      value: "edgesensor_high",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edgesensor_high</Icon> edgesensor_high
      </div>),
      tags: ["Android", "OS", "cell", "device", "edge", "hardware", "high", "iOS", "mobile", "move", "phone", "sensitivity", "sensor", "tablet", "vibrate"]
    },
		{
      value: "edgesensor_low",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edgesensor_low</Icon> edgesensor_low
      </div>),
      tags: ["Android", "cell", "device", "edge", "hardware", "iOS", "low", "mobile", "move", "phone", "sensitivity", "sensor", "tablet", "vibrate"]
    },
		{
      value: "edit",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edit</Icon> edit
      </div>),
      tags: ["compose", "create", "edit", "editing", "input", "new", "pen", "pencil", "write", "writing"]
    },
		{
      value: "edit_attributes",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edit_attributes</Icon> edit_attributes
      </div>),
      tags: ["approve", "attribution", "check", "complete", "done", "edit", "mark", "ok", "select", "tick", "validate", "verified", "yes"]
    },
		{
      value: "edit_calendar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edit_calendar</Icon> edit_calendar
      </div>),
      tags: ["calendar", "compose", "create", "date", "day", "draft", "edit", "editing", "event", "month", "pen", "pencil", "schedule", "write", "writing"]
    },
		{
      value: "edit_location",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edit_location</Icon> edit_location
      </div>),
      tags: ["destination", "direction", "edit", "location", "maps", "pen", "pencil", "pin", "place", "stop"]
    },
		{
      value: "edit_location_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edit_location_alt</Icon> edit_location_alt
      </div>),
      tags: ["alt", "edit", "location", "pen", "pencil", "pin"]
    },
		{
      value: "edit_note",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edit_note</Icon> edit_note
      </div>),
      tags: ["compose", "create", "draft", "edit", "editing", "input", "lines", "note", "pen", "pencil", "text", "write", "writing"]
    },
		{
      value: "edit_notifications",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edit_notifications</Icon> edit_notifications
      </div>),
      tags: ["active", "alarm", "alert", "bell", "chime", "compose", "create", "draft", "edit", "editing", "input", "new", "notifications", "notify", "pen", "pencil", "reminder", "ring", "sound", "write", "writing"]
    },
		{
      value: "edit_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edit_off</Icon> edit_off
      </div>),
      tags: ["compose", "create", "disabled", "draft", "edit", "editing", "enabled", "input", "new", "off", "offline", "on", "pen", "pencil", "slash", "write", "writing"]
    },
		{
      value: "edit_road",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>edit_road</Icon> edit_road
      </div>),
      tags: ["destination", "direction", "edit", "highway", "maps", "pen", "pencil", "road", "street", "traffic"]
    },
		{
      value: "egg",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>egg</Icon> egg
      </div>),
      tags: ["breakfast", "brunch", "egg", "food"]
    },
		{
      value: "egg_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>egg_alt</Icon> egg_alt
      </div>),
      tags: ["breakfast", "brunch", "egg", "food"]
    },
		{
      value: "eject",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>eject</Icon> eject
      </div>),
      tags: ["disc", "drive", "dvd", "eject", "remove", "triangle", "usb"]
    },
		{
      value: "elderly",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>elderly</Icon> elderly
      </div>),
      tags: ["body", "cane", "elderly", "human", "old", "people", "person", "senior"]
    },
		{
      value: "elderly_woman",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>elderly_woman</Icon> elderly_woman
      </div>),
      tags: ["body", "cane", "elderly", "female", "gender", "girl", "human", "lady", "old", "people", "person", "senior", "social", "symbol", "woman", "women"]
    },
		{
      value: "electric_bike",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>electric_bike</Icon> electric_bike
      </div>),
      tags: ["bike", "bolt", "ebike", "electric", "electricity", "maps", "scooter", "thunderbolt", "transportation", "travel", "vespa"]
    },
		{
      value: "electric_bolt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>electric_bolt</Icon> electric_bolt
      </div>),
      tags: ["bolt", "electric", "energy", "fast", "instant", "lightning", "nest", "thunderbolt"]
    },
		{
      value: "electric_car",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>electric_car</Icon> electric_car
      </div>),
      tags: ["automobile", "bolt", "car", "cars", "electric", "electricity", "maps", "thunderbolt", "transportation", "travel", "vehicle"]
    },
		{
      value: "electric_meter",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>electric_meter</Icon> electric_meter
      </div>),
      tags: ["bolt", "electric", "energy", "fast", "instant", "lightning", "measure", "meter", "nest", "thunderbolt", "usage", "voltage", "volts"]
    },
		{
      value: "electric_moped",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>electric_moped</Icon> electric_moped
      </div>),
      tags: ["automobile", "bike", "bolt", "car", "cars", "electric", "maps", "moped", "scooter", "thunderbolt", "transportation", "travel", "vehicle", "vespa"]
    },
		{
      value: "electric_rickshaw",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>electric_rickshaw</Icon> electric_rickshaw
      </div>),
      tags: ["automobile", "bolt", "car", "cars", "electric", "india", "maps", "rickshaw", "thunderbolt", "transportation", "truck", "vehicle"]
    },
		{
      value: "electric_scooter",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>electric_scooter</Icon> electric_scooter
      </div>),
      tags: ["bike", "bolt", "direction", "directions", "electric", "maps", "scooter", "thunderbolt", "transportation", "vehicle", "vespa"]
    },
		{
      value: "electrical_services",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>electrical_services</Icon> electrical_services
      </div>),
      tags: ["charge", "cord", "electric", "electrical", "plug", "power", "services", "wire"]
    },
		{
      value: "elevator",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>elevator</Icon> elevator
      </div>),
      tags: ["body", "down", "elevator", "human", "people", "person", "up"]
    },
		{
      value: "email",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>email</Icon> email
      </div>),
      tags: ["email", "envelop", "letters", "mail", "message", "send"]
    },
		{
      value: "emergency",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>emergency</Icon> emergency
      </div>),
      tags: ["asterisk", "clinic", "emergency", "health", "hospital", "maps", "medical", "symbol"]
    },
		{
      value: "emergency_recording",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>emergency_recording</Icon> emergency_recording
      </div>),
      tags: ["alert", "attention", "camera", "caution", "danger", "emergency", "film", "filming", "hardware", "image", "important", "motion", "notification", "picture", "record", "video", "videography", "warning"]
    },
		{
      value: "emergency_share",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>emergency_share</Icon> emergency_share
      </div>),
      tags: ["alert", "attention", "caution", "danger", "emergency", "important", "notification", "share", "warning"]
    },
		{
      value: "emoji_emotions",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>emoji_emotions</Icon> emoji_emotions
      </div>),
      tags: ["+", "add", "emoji", "emotions", "expressions", "face", "feelings", "glad", "happiness", "happy", "icon", "icons", "insert", "like", "mood", "new", "person", "pleased", "plus", "smile", "smiling", "social", "survey", "symbol"]
    },
		{
      value: "emoji_events",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>emoji_events</Icon> emoji_events
      </div>),
      tags: ["achievement", "award", "chalice", "champion", "cup", "emoji", "events", "first", "prize", "reward", "sport", "trophy", "winner"]
    },
		{
      value: "emoji_food_beverage",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>emoji_food_beverage</Icon> emoji_food_beverage
      </div>),
      tags: ["beverage", "coffee", "cup", "drink", "emoji", "mug", "plate", "set", "tea"]
    },
		{
      value: "emoji_nature",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>emoji_nature</Icon> emoji_nature
      </div>),
      tags: ["animal", "bee", "bug", "daisy", "emoji", "flower", "insect", "ladybug", "nature", "petals", "spring", "summer"]
    },
		{
      value: "emoji_objects",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>emoji_objects</Icon> emoji_objects
      </div>),
      tags: ["bulb", "creative", "emoji", "idea", "light", "objects", "solution", "thinking"]
    },
		{
      value: "emoji_people",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>emoji_people</Icon> emoji_people
      </div>),
      tags: ["arm", "body", "emoji", "greeting", "human", "people", "person", "social", "waving"]
    },
		{
      value: "emoji_symbols",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>emoji_symbols</Icon> emoji_symbols
      </div>),
      tags: ["ampersand", "character", "emoji", "hieroglyph", "music", "note", "percent", "sign", "symbols"]
    },
		{
      value: "emoji_transportation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>emoji_transportation</Icon> emoji_transportation
      </div>),
      tags: ["architecture", "automobile", "building", "car", "cars", "direction", "emoji", "estate", "maps", "place", "public", "real", "residence", "residential", "shelter", "transportation", "travel", "vehicle"]
    },
		{
      value: "energy_savings_leaf",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>energy_savings_leaf</Icon> energy_savings_leaf
      </div>),
      tags: ["eco", "energy", "leaf", "leaves", "nest", "savings", "usage"]
    },
		{
      value: "engineering",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>engineering</Icon> engineering
      </div>),
      tags: ["body", "cogs", "cogwheel", "construction", "engineering", "fixing", "gears", "hat", "helmet", "human", "maintenance", "people", "person", "setting", "worker"]
    },
		{
      value: "enhanced_encryption",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>enhanced_encryption</Icon> enhanced_encryption
      </div>),
      tags: ["+", "add", "encryption", "enhanced", "lock", "locked", "new", "password", "plus", "privacy", "private", "protection", "safety", "secure", "security", "symbol"]
    },
		{
      value: "equalizer",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>equalizer</Icon> equalizer
      </div>),
      tags: ["adjustment", "analytics", "chart", "data", "equalizer", "graph", "measure", "metrics", "music", "noise", "sound", "static", "statistics", "tracking", "volume"]
    },
		{
      value: "error",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>error</Icon> error
      </div>),
      tags: ["!", "alert", "attention", "caution", "circle", "danger", "error", "exclamation", "important", "mark", "notification", "symbol", "warning"]
    },
		{
      value: "error_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>error_outline</Icon> error_outline
      </div>),
      tags: ["!", "alert", "attention", "caution", "circle", "danger", "error", "exclamation", "important", "mark", "notification", "outline", "symbol", "warning"]
    },
		{
      value: "escalator",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>escalator</Icon> escalator
      </div>),
      tags: ["down", "escalator", "staircase", "up"]
    },
		{
      value: "escalator_warning",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>escalator_warning</Icon> escalator_warning
      </div>),
      tags: ["body", "child", "escalator", "human", "kid", "parent", "people", "person", "warning"]
    },
		{
      value: "euro",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>euro</Icon> euro
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollars", "euro", "euros", "finance", "money", "online", "pay", "payment", "price", "shopping", "symbol"]
    },
		{
      value: "euro_symbol",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>euro_symbol</Icon> euro_symbol
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollars", "euro", "finance", "money", "online", "pay", "payment", "symbol"]
    },
		{
      value: "ev_station",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ev_station</Icon> ev_station
      </div>),
      tags: ["automobile", "bolt", "car", "cars", "charger", "charging", "electric", "electricity", "ev", "home", "maps", "places", "station", "thunderbolt", "transportation", "vehicle"]
    },
		{
      value: "event",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>event</Icon> event
      </div>),
      tags: ["calendar", "date", "day", "event", "mark", "month", "range", "remember", "reminder", "today", "week"]
    },
		{
      value: "event_available",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>event_available</Icon> event_available
      </div>),
      tags: ["approve", "available", "calendar", "check", "complete", "date", "done", "event", "mark", "ok", "schedule", "select", "tick", "time", "validate", "verified", "yes"]
    },
		{
      value: "event_busy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>event_busy</Icon> event_busy
      </div>),
      tags: ["busy", "calendar", "cancel", "clear", "close", "date", "event", "exit", "no", "remove", "schedule", "stop", "time", "unavailable", "x"]
    },
		{
      value: "event_note",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>event_note</Icon> event_note
      </div>),
      tags: ["calendar", "date", "event", "note", "schedule", "text", "time", "writing"]
    },
		{
      value: "event_repeat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>event_repeat</Icon> event_repeat
      </div>),
      tags: ["around", "calendar", "date", "day", "event", "inprogress", "load", "loading refresh", "month", "renew", "rotate", "schedule", "turn"]
    },
		{
      value: "event_seat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>event_seat</Icon> event_seat
      </div>),
      tags: ["assign", "assigned", "chair", "event", "furniture", "reservation", "row", "seat", "section", "sit"]
    },
		{
      value: "exit_to_app",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>exit_to_app</Icon> exit_to_app
      </div>),
      tags: ["app", "application", "arrow", "components", "design", "exit", "export", "interface", "layout", "leave", "mobile", "monitor", "move", "output", "phone", "screen", "site", "tablet", "to", "ui", "ux", "web", "website", "window"]
    },
		{
      value: "expand",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>expand</Icon> expand
      </div>),
      tags: ["arrow", "arrows", "compress", "enlarge", "expand", "grow", "move", "push", "together"]
    },
		{
      value: "expand_circle_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>expand_circle_down</Icon> expand_circle_down
      </div>),
      tags: ["arrow", "arrows", "chevron", "circle", "collapse", "direction", "down", "expand", "expandable", "list", "more"]
    },
		{
      value: "expand_less",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>expand_less</Icon> expand_less
      </div>),
      tags: ["arrow", "arrows", "chevron", "collapse", "direction", "expand", "expandable", "less", "list", "up"]
    },
		{
      value: "expand_more",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>expand_more</Icon> expand_more
      </div>),
      tags: ["arrow", "arrows", "chevron", "collapse", "direction", "down", "expand", "expandable", "list", "more"]
    },
		{
      value: "explicit",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>explicit</Icon> explicit
      </div>),
      tags: ["adult", "alphabet", "character", "content", "e", "explicit", "font", "language", "letters", "media", "movies", "music", "symbol", "text", "type"]
    },
		{
      value: "explore",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>explore</Icon> explore
      </div>),
      tags: ["compass", "destination", "direction", "east", "explore", "location", "maps", "needle", "north", "south", "travel", "west"]
    },
		{
      value: "explore_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>explore_off</Icon> explore_off
      </div>),
      tags: ["compass", "destination", "direction", "disabled", "east", "enabled", "explore", "location", "maps", "needle", "north", "off", "on", "slash", "south", "travel", "west"]
    },
		{
      value: "exposure",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>exposure</Icon> exposure
      </div>),
      tags: ["add", "brightness", "contrast", "edit", "editing", "effect", "exposure", "image", "minus", "photo", "photography", "picture", "plus", "settings", "subtract"]
    },
		{
      value: "exposure_neg_1",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>exposure_neg_1</Icon> exposure_neg_1
      </div>),
      tags: ["1", "brightness", "contrast", "digit", "edit", "editing", "effect", "exposure", "image", "neg", "negative", "numbers", "photo", "photography", "settings", "symbol"]
    },
		{
      value: "exposure_neg_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>exposure_neg_2</Icon> exposure_neg_2
      </div>),
      tags: ["2", "brightness", "contrast", "digit", "edit", "editing", "effect", "exposure", "image", "neg", "negative", "numbers", "photo", "photography", "settings", "symbol"]
    },
		{
      value: "exposure_plus_1",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>exposure_plus_1</Icon> exposure_plus_1
      </div>),
      tags: ["1", "add", "brightness", "contrast", "digit", "edit", "editing", "effect", "exposure", "image", "numbers", "photo", "photography", "plus", "settings", "symbol"]
    },
		{
      value: "exposure_plus_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>exposure_plus_2</Icon> exposure_plus_2
      </div>),
      tags: ["2", "add", "brightness", "contrast", "digit", "edit", "editing", "effect", "exposure", "image", "numbers", "photo", "photography", "plus", "settings", "symbol"]
    },
		{
      value: "exposure_zero",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>exposure_zero</Icon> exposure_zero
      </div>),
      tags: ["0", "brightness", "contrast", "digit", "edit", "editing", "effect", "exposure", "image", "numbers", "photo", "photography", "settings", "symbol", "zero"]
    },
		{
      value: "extension",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>extension</Icon> extension
      </div>),
      tags: ["app", "extended", "extension", "game", "jigsaw", "plugin add", "puzzle", "shape"]
    },
		{
      value: "extension_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>extension_off</Icon> extension_off
      </div>),
      tags: ["disabled", "enabled", "extended", "extension", "jigsaw", "off", "on", "piece", "puzzle", "shape", "slash"]
    },
		{
      value: "face",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>face</Icon> face
      </div>),
      tags: ["account", "emoji", "eyes", "face", "human", "lock", "log", "login", "logout", "people", "person", "profile", "recognition", "security", "social", "thumbnail", "unlock", "user"]
    },
		{
      value: "face_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>face_2</Icon> face_2
      </div>),
      tags: ["account", "emoji", "eyes", "face", "human", "lock", "log", "login", "logout", "people", "person", "profile", "recognition", "security", "social", "thumbnail", "unlock", "user"]
    },
		{
      value: "face_3",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>face_3</Icon> face_3
      </div>),
      tags: ["account", "emoji", "eyes", "face", "human", "lock", "log", "login", "logout", "people", "person", "profile", "recognition", "security", "social", "thumbnail", "unlock", "user"]
    },
		{
      value: "face_4",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>face_4</Icon> face_4
      </div>),
      tags: ["account", "emoji", "eyes", "face", "human", "lock", "log", "login", "logout", "people", "person", "profile", "recognition", "security", "social", "thumbnail", "unlock", "user"]
    },
		{
      value: "face_5",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>face_5</Icon> face_5
      </div>),
      tags: ["account", "emoji", "eyes", "face", "human", "lock", "log", "login", "logout", "people", "person", "profile", "recognition", "security", "social", "thumbnail", "unlock", "user"]
    },
		{
      value: "face_6",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>face_6</Icon> face_6
      </div>),
      tags: ["account", "emoji", "eyes", "face", "human", "lock", "log", "login", "logout", "people", "person", "profile", "recognition", "security", "social", "thumbnail", "unlock", "user"]
    },
		{
      value: "face_retouching_natural",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>face_retouching_natural</Icon> face_retouching_natural
      </div>),
      tags: ["ai", "artificial", "automatic", "automation", "custom", "edit", "editing", "effect", "emoji", "emotion", "face", "faces", "genai", "image", "intelligence", "magic", "natural", "photo", "photography", "retouch", "retouching", "settings", "smart", "spark", "sparkle", "star", "tag"]
    },
		{
      value: "face_retouching_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>face_retouching_off</Icon> face_retouching_off
      </div>),
      tags: ["disabled", "edit", "editing", "effect", "emoji", "emotion", "enabled", "face", "faces", "image", "natural", "off", "on", "photo", "photography", "retouch", "retouching", "settings", "slash", "tag"]
    },
		{
      value: "fact_check",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fact_check</Icon> fact_check
      </div>),
      tags: ["approve", "check", "complete", "done", "fact", "list", "mark", "ok", "select", "tick", "validate", "verified", "yes"]
    },
		{
      value: "factory",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>factory</Icon> factory
      </div>),
      tags: ["factory", "industry", "manufacturing", "warehouse"]
    },
		{
      value: "family_restroom",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>family_restroom</Icon> family_restroom
      </div>),
      tags: ["bathroom", "child", "children", "family", "father", "gender", "kids", "mother", "parents", "restroom", "wc"]
    },
		{
      value: "fast_forward",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fast_forward</Icon> fast_forward
      </div>),
      tags: ["control", "fast", "forward", "media", "music", "play", "speed", "time", "tv", "video"]
    },
		{
      value: "fast_rewind",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fast_rewind</Icon> fast_rewind
      </div>),
      tags: ["back", "control", "fast", "media", "music", "play", "rewind", "speed", "time", "tv", "video"]
    },
		{
      value: "fastfood",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fastfood</Icon> fastfood
      </div>),
      tags: ["drink", "fastfood", "food", "hamburger", "maps", "meal", "places"]
    },
		{
      value: "favorite",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>favorite</Icon> favorite
      </div>),
      tags: ["appreciate", "favorite", "heart", "like", "love", "remember", "save", "shape"]
    },
		{
      value: "favorite_border",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>favorite_border</Icon> favorite_border
      </div>),
      tags: ["border", "favorite", "heart", "like", "love", "outline", "remember", "save", "shape"]
    },
		{
      value: "fax",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fax</Icon> fax
      </div>),
      tags: ["fax", "machine", "office", "phone", "send"]
    },
		{
      value: "featured_play_list",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>featured_play_list</Icon> featured_play_list
      </div>),
      tags: ["collection", "featured", "highlighted", "list", "music", "play", "playlist", "recommended"]
    },
		{
      value: "featured_video",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>featured_video</Icon> featured_video
      </div>),
      tags: ["advertised", "advertisement", "featured", "highlighted", "recommended", "video", "watch"]
    },
		{
      value: "feed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>feed</Icon> feed
      </div>),
      tags: ["article", "feed", "headline", "information", "news", "newspaper", "paper", "public", "social", "timeline"]
    },
		{
      value: "feedback",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>feedback</Icon> feedback
      </div>),
      tags: ["!", "alert", "announcement", "attention", "caution", "chat", "chat bubble", "comment", "communicate", "communication", "conversation", "danger", "error", "exclamation", "failed", "feedback", "important", "mark", "message", "notification", "service", "sms", "speech", "symbol", "warning"]
    },
		{
      value: "female",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>female</Icon> female
      </div>),
      tags: ["female", "gender", "girl", "lady", "social", "symbol", "woman", "women"]
    },
		{
      value: "fence",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fence</Icon> fence
      </div>),
      tags: ["backyard", "barrier", "boundaries", "boundary", "door", "entrance", "fence", "flowers", "garden", "gate", "grass", "home", "house", "nature", "nest", "outdoor", "outside", "protection", "yard"]
    },
		{
      value: "festival",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>festival</Icon> festival
      </div>),
      tags: ["circus", "event", "festival", "local", "maps", "places", "tent", "tour", "travel"]
    },
		{
      value: "fiber_dvr",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fiber_dvr</Icon> fiber_dvr
      </div>),
      tags: ["alphabet", "character", "digital", "dvr", "electronics", "fiber", "font", "letters", "network", "record", "recorder", "symbol", "text", "tv", "type", "video"]
    },
		{
      value: "fiber_manual_record",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fiber_manual_record</Icon> fiber_manual_record
      </div>),
      tags: ["circle", "dot", "fiber", "manual", "play", "record", "watch"]
    },
		{
      value: "fiber_new",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fiber_new</Icon> fiber_new
      </div>),
      tags: ["alphabet", "character", "fiber", "font", "letters", "network", "new", "symbol", "text", "type"]
    },
		{
      value: "fiber_pin",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fiber_pin</Icon> fiber_pin
      </div>),
      tags: ["alphabet", "character", "fiber", "font", "letters", "network", "pin", "symbol", "text", "type"]
    },
		{
      value: "fiber_smart_record",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fiber_smart_record</Icon> fiber_smart_record
      </div>),
      tags: ["circle", "dot", "fiber", "play", "record", "smart", "watch"]
    },
		{
      value: "file_copy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>file_copy</Icon> file_copy
      </div>),
      tags: ["content", "copy", "cut", "doc", "document", "duplicate", "file", "multiple", "past", "stack"]
    },
		{
      value: "file_download",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>file_download</Icon> file_download
      </div>),
      tags: ["arrow", "arrows", "down", "download", "downloads", "drive", "export", "file", "install", "upload"]
    },
		{
      value: "file_download_done",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>file_download_done</Icon> file_download_done
      </div>),
      tags: ["arrow", "arrows", "check", "done", "down", "download", "downloads", "drive", "file", "install", "installed", "tick", "upload"]
    },
		{
      value: "file_download_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>file_download_off</Icon> file_download_off
      </div>),
      tags: ["arrow", "disabled", "down", "download", "drive", "enabled", "export", "file", "install", "off", "on", "save", "slash", "upload"]
    },
		{
      value: "file_open",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>file_open</Icon> file_open
      </div>),
      tags: ["arrow", "doc", "document", "drive", "file", "left", "open", "page", "paper"]
    },
		{
      value: "file_present",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>file_present</Icon> file_present
      </div>),
      tags: ["clip", "data", "doc", "document", "drive", "file", "folder", "folders", "note", "paper", "present", "reminder", "sheet", "slide", "storage", "writing"]
    },
		{
      value: "file_upload",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>file_upload</Icon> file_upload
      </div>),
      tags: ["arrow", "arrows", "download", "drive", "export", "file", "up", "upload"]
    },
		{
      value: "filter",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter</Icon> filter
      </div>),
      tags: ["edit", "editing", "effect", "filter", "image", "landscape", "mountain", "mountains", "photo", "photography", "picture", "settings"]
    },
		{
      value: "filter_1",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_1</Icon> filter_1
      </div>),
      tags: ["1", "digit", "edit", "editing", "effect", "filter", "image", "images", "multiple", "numbers", "photography", "picture", "pictures", "settings", "stack", "symbol"]
    },
		{
      value: "filter_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_2</Icon> filter_2
      </div>),
      tags: ["2", "digit", "edit", "editing", "effect", "filter", "image", "images", "multiple", "numbers", "photography", "picture", "pictures", "settings", "stack", "symbol"]
    },
		{
      value: "filter_3",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_3</Icon> filter_3
      </div>),
      tags: ["3", "digit", "edit", "editing", "effect", "filter", "image", "images", "multiple", "numbers", "photography", "picture", "pictures", "settings", "stack", "symbol"]
    },
		{
      value: "filter_4",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_4</Icon> filter_4
      </div>),
      tags: ["4", "digit", "edit", "editing", "effect", "filter", "image", "images", "multiple", "numbers", "photography", "picture", "pictures", "settings", "stack", "symbol"]
    },
		{
      value: "filter_5",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_5</Icon> filter_5
      </div>),
      tags: ["5", "digit", "edit", "editing", "effect", "filter", "image", "images", "multiple", "numbers", "photography", "picture", "pictures", "settings", "stack", "symbol"]
    },
		{
      value: "filter_6",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_6</Icon> filter_6
      </div>),
      tags: ["6", "digit", "edit", "editing", "effect", "filter", "image", "images", "multiple", "numbers", "photography", "picture", "pictures", "settings", "stack", "symbol"]
    },
		{
      value: "filter_7",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_7</Icon> filter_7
      </div>),
      tags: ["7", "digit", "edit", "editing", "effect", "filter", "image", "images", "multiple", "numbers", "photography", "picture", "pictures", "settings", "stack", "symbol"]
    },
		{
      value: "filter_8",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_8</Icon> filter_8
      </div>),
      tags: ["8", "digit", "edit", "editing", "effect", "filter", "image", "images", "multiple", "numbers", "photography", "picture", "pictures", "settings", "stack", "symbol"]
    },
		{
      value: "filter_9",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_9</Icon> filter_9
      </div>),
      tags: ["9", "digit", "edit", "editing", "effect", "filter", "image", "images", "multiple", "numbers", "photography", "picture", "pictures", "settings", "stack", "symbol"]
    },
		{
      value: "filter_9_plus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_9_plus</Icon> filter_9_plus
      </div>),
      tags: ["+", "9", "digit", "edit", "editing", "effect", "filter", "image", "images", "multiple", "numbers", "photography", "picture", "pictures", "plus", "settings", "stack", "symbol"]
    },
		{
      value: "filter_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_alt</Icon> filter_alt
      </div>),
      tags: ["edit", "filter", "funnel", "options", "refine", "sift"]
    },
		{
      value: "filter_alt_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_alt_off</Icon> filter_alt_off
      </div>),
      tags: ["alt", "disabled", "edit", "filter", "funnel", "off", "offline", "options", "refine", "sift", "slash"]
    },
		{
      value: "filter_b_and_w",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_b_and_w</Icon> filter_b_and_w
      </div>),
      tags: ["and", "b", "black", "contrast", "edit", "editing", "effect", "filter", "grayscale", "image", "images", "photography", "picture", "pictures", "settings", "w", "white"]
    },
		{
      value: "filter_center_focus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_center_focus</Icon> filter_center_focus
      </div>),
      tags: ["camera", "center", "dot", "edit", "filter", "focus", "image", "photo", "photography", "picture"]
    },
		{
      value: "filter_drama",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_drama</Icon> filter_drama
      </div>),
      tags: ["cloud", "drama", "edit", "editing", "effect", "filter", "image", "photo", "photography", "picture", "sky camera"]
    },
		{
      value: "filter_frames",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_frames</Icon> filter_frames
      </div>),
      tags: ["boarders", "border", "camera", "center", "edit", "editing", "effect", "filter", "filters", "focus", "frame", "frames", "image", "options", "photo", "photography", "picture"]
    },
		{
      value: "filter_hdr",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_hdr</Icon> filter_hdr
      </div>),
      tags: ["camera", "edit", "editing", "effect", "filter", "hdr", "image", "mountain", "mountains", "photo", "photography", "picture"]
    },
		{
      value: "filter_list",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_list</Icon> filter_list
      </div>),
      tags: ["filter", "lines", "list", "organize", "sort"]
    },
		{
      value: "filter_list_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_list_off</Icon> filter_list_off
      </div>),
      tags: ["alt", "disabled", "edit", "filter", "list", "off", "offline", "options", "refine", "sift", "slash"]
    },
		{
      value: "filter_none",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_none</Icon> filter_none
      </div>),
      tags: ["filter", "multiple", "none", "square", "stack"]
    },
		{
      value: "filter_tilt_shift",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_tilt_shift</Icon> filter_tilt_shift
      </div>),
      tags: ["blur", "center", "dash", "dashed", "edit", "editing", "effect", "filter", "focus", "image", "images", "photography", "picture", "pictures", "shift", "tilt"]
    },
		{
      value: "filter_vintage",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>filter_vintage</Icon> filter_vintage
      </div>),
      tags: ["edit", "editing", "effect", "filter", "flower", "image", "images", "photography", "picture", "pictures", "vintage"]
    },
		{
      value: "find_in_page",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>find_in_page</Icon> find_in_page
      </div>),
      tags: ["data", "doc", "document", "drive", "file", "find", "folder", "folders", "glass", "in", "look", "magnify", "magnifying", "page", "paper", "search", "see", "sheet", "slide", "writing"]
    },
		{
      value: "find_replace",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>find_replace</Icon> find_replace
      </div>),
      tags: ["around", "arrows", "find", "glass", "inprogress", "load", "loading refresh", "look", "magnify", "magnifying", "renew", "replace", "rotate", "search", "see"]
    },
		{
      value: "fingerprint",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fingerprint</Icon> fingerprint
      </div>),
      tags: ["finger", "fingerprint", "id", "identification", "identity", "print", "reader", "thumbprint", "verification"]
    },
		{
      value: "fire_extinguisher",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fire_extinguisher</Icon> fire_extinguisher
      </div>),
      tags: ["emergency", "extinguisher", "fire", "flame", "water"]
    },
		{
      value: "fire_hydrant_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fire_hydrant_alt</Icon> fire_hydrant_alt
      </div>),
      tags: []
    },
		{
      value: "fire_truck",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fire_truck</Icon> fire_truck
      </div>),
      tags: []
    },
		{
      value: "fireplace",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fireplace</Icon> fireplace
      </div>),
      tags: ["chimney", "fire", "fireplace", "flame", "home", "house", "living", "pit", "place", "room", "warm", "winter"]
    },
		{
      value: "first_page",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>first_page</Icon> first_page
      </div>),
      tags: ["arrow", "back", "chevron", "first", "left", "page", "rewind"]
    },
		{
      value: "fit_screen",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fit_screen</Icon> fit_screen
      </div>),
      tags: ["enlarge", "fit", "format", "layout", "reduce", "scale", "screen", "size"]
    },
		{
      value: "fitbit",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fitbit</Icon> fitbit
      </div>),
      tags: ["athlete", "athletic", "exercise", "fitbit", "fitness", "hobby", "logo"]
    },
		{
      value: "fitness_center",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fitness_center</Icon> fitness_center
      </div>),
      tags: ["athlete", "center", "dumbbell", "exercise", "fitness", "gym", "hobby", "places", "sport", "weights", "workout"]
    },
		{
      value: "flag",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flag</Icon> flag
      </div>),
      tags: ["country", "destination", "emoji", "flag", "flags", "goal", "landmark", "location", "mark", "milepost", "milestone", "nation", "place", "pole", "report", "save", "social", "start", "world"]
    },
		{
      value: "flag_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flag_circle</Icon> flag_circle
      </div>),
      tags: ["circle", "country", "flag", "goal", "mark", "nation", "report", "round", "start"]
    },
		{
      value: "flaky",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flaky</Icon> flaky
      </div>),
      tags: ["approve", "check", "close", "complete", "contrast", "done", "exit", "flaky", "mark", "no", "ok", "options", "select", "stop", "tick", "verified", "x", "yes"]
    },
		{
      value: "flare",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flare</Icon> flare
      </div>),
      tags: ["bright", "edit", "editing", "effect", "flare", "image", "images", "light", "photography", "picture", "pictures", "sun"]
    },
		{
      value: "flash_auto",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flash_auto</Icon> flash_auto
      </div>),
      tags: ["a", "auto", "bolt", "electric", "energy", "fast", "flash", "instant", "lightning", "thunderbolt"]
    },
		{
      value: "flash_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flash_off</Icon> flash_off
      </div>),
      tags: ["bolt", "disabled", "electric", "enabled", "energy", "fast", "flash", "instant", "lightning", "off", "on", "slash", "thunderbolt"]
    },
		{
      value: "flash_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flash_on</Icon> flash_on
      </div>),
      tags: ["bolt", "disabled", "electric", "enabled", "energy", "fast", "flash", "instant", "lightning", "off", "on", "slash", "thunderbolt"]
    },
		{
      value: "flashlight_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flashlight_off</Icon> flashlight_off
      </div>),
      tags: ["disabled", "enabled", "flash", "flashlight", "light", "off", "on", "slash"]
    },
		{
      value: "flashlight_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flashlight_on</Icon> flashlight_on
      </div>),
      tags: ["disabled", "enabled", "flash", "flashlight", "light", "off", "on", "slash"]
    },
		{
      value: "flatware",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flatware</Icon> flatware
      </div>),
      tags: ["cafe", "cafeteria", "cutlery", "diner", "dining", "dinner", "eat", "eating", "food", "fork", "knife", "local", "meal", "restaurant", "spoon", "utensils"]
    },
		{
      value: "flight",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flight</Icon> flight
      </div>),
      tags: ["air", "aircraft", "airplane", "airplanes", "airport", "flight", "flights", "fly", "flying", "mode", "on", "plane", "planes", "signal", "transportation", "travel", "trip"]
    },
		{
      value: "flight_class",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flight_class</Icon> flight_class
      </div>),
      tags: ["air", "aircraft", "airplane", "airplanes", "airport", "business", "business class", "first class", "flight", "flights", "fly", "flying", "plane", "planes", "seat", "signal", "transportation", "travel", "trip"]
    },
		{
      value: "flight_land",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flight_land</Icon> flight_land
      </div>),
      tags: ["air", "aircraft", "airplane", "airplanes", "airport", "arrival", "arriving", "departing", "departure", "flight", "flights", "fly", "flying", "landing", "plane", "planes", "signal", "transportation", "travel", "trip"]
    },
		{
      value: "flight_takeoff",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flight_takeoff</Icon> flight_takeoff
      </div>),
      tags: ["air", "aircraft", "airplane", "airplanes", "airport", "arrival", "arriving", "departing", "departure", "flight", "flights", "fly", "flying", "landing", "plane", "planes", "signal", "transportation", "travel", "trip"]
    },
		{
      value: "flip",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flip</Icon> flip
      </div>),
      tags: ["dash", "dashed", "edit", "editing", "flip", "image", "orientation", "scan scanning"]
    },
		{
      value: "flip_camera_android",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flip_camera_android</Icon> flip_camera_android
      </div>),
      tags: ["android", "camera", "center", "edit", "editing", "flip", "image", "mobile", "orientation", "rotate", "turn"]
    },
		{
      value: "flip_camera_ios",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flip_camera_ios</Icon> flip_camera_ios
      </div>),
      tags: ["DISABLE_IOS", "android", "camera", "disable_ios", "edit", "editing", "flip", "image", "ios", "mobile", "orientation", "rotate", "turn"]
    },
		{
      value: "flip_to_back",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flip_to_back</Icon> flip_to_back
      </div>),
      tags: ["arrange", "arrangement", "back", "dash", "dashed", "flip", "format", "front", "layout", "move", "order", "sort", "to"]
    },
		{
      value: "flip_to_front",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flip_to_front</Icon> flip_to_front
      </div>),
      tags: ["arrange", "arrangement", "back", "dash", "dashed", "flip", "format", "front", "layout", "move", "order", "sort", "to"]
    },
		{
      value: "flood",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flood</Icon> flood
      </div>),
      tags: ["crisis", "disaster", "flooding", "natural", "rain", "storm", "water", "wave", "weather"]
    },
		{
      value: "fluorescent",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fluorescent</Icon> fluorescent
      </div>),
      tags: ["bright", "fluorescent", "lamp", "light", "lightbulb"]
    },
		{
      value: "flutter_dash",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>flutter_dash</Icon> flutter_dash
      </div>),
      tags: ["app", "application", "bird", "dash", "flutter", "framework", "logo", "mascot", "open source", "program", "software"]
    },
		{
      value: "fmd_bad",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fmd_bad</Icon> fmd_bad
      </div>),
      tags: ["!", "alert", "attention", "bad", "caution", "danger", "destination", "direction", "error", "exclamation", "fmd", "important", "location", "maps", "mark", "notification", "pin", "place", "symbol", "warning"]
    },
		{
      value: "fmd_good",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fmd_good</Icon> fmd_good
      </div>),
      tags: ["destination", "direction", "fmd", "good", "location", "maps", "pin", "place", "stop"]
    },
		{
      value: "folder",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>folder</Icon> folder
      </div>),
      tags: ["data", "doc", "document", "drive", "file", "folder", "folders", "sheet", "slide", "storage"]
    },
		{
      value: "folder_copy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>folder_copy</Icon> folder_copy
      </div>),
      tags: ["content", "copy", "cut", "data", "doc", "document", "drive", "duplicate", "file", "folder", "folders", "multiple", "paste", "sheet", "slide", "storage"]
    },
		{
      value: "folder_delete",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>folder_delete</Icon> folder_delete
      </div>),
      tags: ["bin", "can", "data", "delete", "doc", "document", "drive", "file", "folder", "folders", "garbage", "remove", "sheet", "slide", "storage", "trash"]
    },
		{
      value: "folder_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>folder_off</Icon> folder_off
      </div>),
      tags: ["data", "disabled", "doc", "document", "drive", "enabled", "file", "folder", "folders", "off", "on", "online", "sheet", "slash", "slide", "storage"]
    },
		{
      value: "folder_open",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>folder_open</Icon> folder_open
      </div>),
      tags: ["data", "doc", "document", "drive", "file", "folder", "folders", "open", "sheet", "slide", "storage"]
    },
		{
      value: "folder_shared",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>folder_shared</Icon> folder_shared
      </div>),
      tags: ["account", "collaboration", "data", "doc", "document", "drive", "face", "file", "folder", "human", "people", "person", "profile", "share", "shared", "sheet", "slide", "storage", "team", "user"]
    },
		{
      value: "folder_special",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>folder_special</Icon> folder_special
      </div>),
      tags: ["bookmark", "data", "doc", "document", "drive", "favorite", "file", "folder", "highlight", "important", "marked", "save", "saved", "shape", "sheet", "slide", "special", "star", "storage"]
    },
		{
      value: "folder_zip",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>folder_zip</Icon> folder_zip
      </div>),
      tags: ["compress", "data", "doc", "document", "drive", "file", "folder", "folders", "open", "sheet", "slide", "storage", "zip"]
    },
		{
      value: "follow_the_signs",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>follow_the_signs</Icon> follow_the_signs
      </div>),
      tags: ["arrow", "body", "directional", "follow", "human", "people", "person", "right", "signs", "social", "the"]
    },
		{
      value: "font_download",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>font_download</Icon> font_download
      </div>),
      tags: ["A", "alphabet", "character", "classification", "download", "font", "letters", "square", "symbol", "text", "type", "typeface"]
    },
		{
      value: "font_download_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>font_download_off</Icon> font_download_off
      </div>),
      tags: ["alphabet", "character", "disabled", "download", "enabled", "font", "letters", "off", "on", "slash", "square", "symbol", "text", "type"]
    },
		{
      value: "food_bank",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>food_bank</Icon> food_bank
      </div>),
      tags: ["architecture", "bank", "building", "charity", "eat", "estate", "food", "fork", "house", "knife", "meal", "place", "real", "residence", "residential", "shelter", "utensils"]
    },
		{
      value: "forest",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>forest</Icon> forest
      </div>),
      tags: ["forest", "jungle", "nature", "plantation", "plants", "trees", "woodland"]
    },
		{
      value: "fork_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fork_left</Icon> fork_left
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "fork", "left", "maps", "navigation", "path", "route", "sign", "traffic"]
    },
		{
      value: "fork_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fork_right</Icon> fork_right
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "fork", "maps", "navigation", "path", "right", "route", "sign", "traffic"]
    },
		{
      value: "format_align_center",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_align_center</Icon> format_align_center
      </div>),
      tags: ["align", "alignment", "center", "doc", "edit", "editing", "editor", "format", "sheet", "spreadsheet", "text", "type", "writing"]
    },
		{
      value: "format_align_justify",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_align_justify</Icon> format_align_justify
      </div>),
      tags: ["align", "alignment", "density", "doc", "edit", "editing", "editor", "extra", "format", "justify", "sheet", "small", "spreadsheet", "text", "type", "writing"]
    },
		{
      value: "format_align_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_align_left</Icon> format_align_left
      </div>),
      tags: ["align", "alignment", "doc", "edit", "editing", "editor", "format", "left", "sheet", "spreadsheet", "text", "type", "writing"]
    },
		{
      value: "format_align_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_align_right</Icon> format_align_right
      </div>),
      tags: ["align", "alignment", "doc", "edit", "editing", "editor", "format", "right", "sheet", "spreadsheet", "text", "type", "writing"]
    },
		{
      value: "format_bold",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_bold</Icon> format_bold
      </div>),
      tags: ["B", "alphabet", "bold", "character", "doc", "edit", "editing", "editor", "font", "format", "letters", "sheet", "spreadsheet", "styles", "symbol", "text", "type", "writing"]
    },
		{
      value: "format_clear",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_clear</Icon> format_clear
      </div>),
      tags: ["T", "alphabet", "character", "clear", "disabled", "doc", "edit", "editing", "editor", "enabled", "font", "format", "letters", "off", "on", "sheet", "slash", "spreadsheet", "style", "symbol", "text", "type", "writing"]
    },
		{
      value: "format_color_fill",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_color_fill</Icon> format_color_fill
      </div>),
      tags: ["bucket", "color", "doc", "edit", "editing", "editor", "fill", "format", "paint", "sheet", "spreadsheet", "style", "text", "type", "writing"]
    },
		{
      value: "format_color_reset",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_color_reset</Icon> format_color_reset
      </div>),
      tags: ["clear", "color", "disabled", "doc", "droplet", "edit", "editing", "editor", "enabled", "fill", "format", "off", "on", "paint", "reset", "sheet", "slash", "spreadsheet", "style", "text", "type", "water", "writing"]
    },
		{
      value: "format_color_text",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_color_text</Icon> format_color_text
      </div>),
      tags: ["color", "doc", "edit", "editing", "editor", "fill", "format", "paint", "sheet", "spreadsheet", "style", "text", "type", "writing"]
    },
		{
      value: "format_indent_decrease",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_indent_decrease</Icon> format_indent_decrease
      </div>),
      tags: ["align", "alignment", "decrease", "doc", "edit", "editing", "editor", "format", "indent", "indentation", "paragraph", "sheet", "spreadsheet", "text", "type", "writing"]
    },
		{
      value: "format_indent_increase",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_indent_increase</Icon> format_indent_increase
      </div>),
      tags: ["align", "alignment", "doc", "edit", "editing", "editor", "format", "increase", "indent", "indentation", "paragraph", "sheet", "spreadsheet", "text", "type", "writing"]
    },
		{
      value: "format_italic",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_italic</Icon> format_italic
      </div>),
      tags: ["alphabet", "character", "doc", "edit", "editing", "editor", "font", "format", "italic", "letters", "sheet", "spreadsheet", "style", "symbol", "text", "type", "writing"]
    },
		{
      value: "format_line_spacing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_line_spacing</Icon> format_line_spacing
      </div>),
      tags: ["align", "alignment", "doc", "edit", "editing", "editor", "format", "line", "sheet", "spacing", "spreadsheet", "text", "type", "writing"]
    },
		{
      value: "format_list_bulleted",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_list_bulleted</Icon> format_list_bulleted
      </div>),
      tags: ["align", "alignment", "bulleted", "doc", "edit", "editing", "editor", "format", "list", "notes", "sheet", "spreadsheet", "text", "type", "writing"]
    },
		{
      value: "format_list_numbered",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_list_numbered</Icon> format_list_numbered
      </div>),
      tags: ["align", "alignment", "digit", "doc", "edit", "editing", "editor", "format", "list", "notes", "numbered", "numbers", "sheet", "spreadsheet", "symbol", "text", "type", "writing"]
    },
		{
      value: "format_list_numbered_rtl",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_list_numbered_rtl</Icon> format_list_numbered_rtl
      </div>),
      tags: ["align", "alignment", "digit", "doc", "edit", "editing", "editor", "format", "list", "notes", "numbered", "numbers", "rtl", "sheet", "spreadsheet", "symbol", "text", "type", "writing"]
    },
		{
      value: "format_overline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_overline</Icon> format_overline
      </div>),
      tags: ["alphabet", "character", "doc", "edit", "editing", "editor", "font", "format", "letters", "line", "overline", "sheet", "spreadsheet", "style", "symbol", "text", "type", "under", "writing"]
    },
		{
      value: "format_paint",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_paint</Icon> format_paint
      </div>),
      tags: ["brush", "color", "doc", "edit", "editing", "editor", "fill", "format", "paint", "roller", "sheet", "spreadsheet", "style", "text", "type", "writing"]
    },
		{
      value: "format_quote",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_quote</Icon> format_quote
      </div>),
      tags: ["doc", "edit", "editing", "editor", "format", "quotation", "quote", "sheet", "spreadsheet", "text", "type", "writing"]
    },
		{
      value: "format_shapes",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_shapes</Icon> format_shapes
      </div>),
      tags: ["alphabet", "character", "color", "doc", "edit", "editing", "editor", "fill", "font", "format", "insert", "letters", "paint", "shapes", "sheet", "spreadsheet", "style", "symbol", "text", "type", "vector", "writing"]
    },
		{
      value: "format_size",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_size</Icon> format_size
      </div>),
      tags: ["alphabet", "character", "color", "doc", "edit", "editing", "editor", "fill", "font", "format", "letters", "paint", "sheet", "size", "spreadsheet", "style", "symbol", "text", "type", "writing"]
    },
		{
      value: "format_strikethrough",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_strikethrough</Icon> format_strikethrough
      </div>),
      tags: ["alphabet", "character", "doc", "edit", "editing", "editor", "font", "format", "letters", "sheet", "spreadsheet", "strikethrough", "style", "symbol", "text", "type", "writing"]
    },
		{
      value: "format_textdirection_l_to_r",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_textdirection_l_to_r</Icon> format_textdirection_l_to_r
      </div>),
      tags: ["align", "alignment", "doc", "edit", "editing", "editor", "format", "ltr", "paragraph", "sheet", "spreadsheet", "text", "textdirection", "type", "writing"]
    },
		{
      value: "format_textdirection_r_to_l",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_textdirection_r_to_l</Icon> format_textdirection_r_to_l
      </div>),
      tags: ["align", "alignment", "doc", "edit", "editing", "editor", "format", "ltr", "paragraph", "sheet", "spreadsheet", "text", "textdirection", "type", "writing"]
    },
		{
      value: "format_underlined",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>format_underlined</Icon> format_underlined
      </div>),
      tags: ["alphabet", "character", "doc", "edit", "editing", "editor", "font", "format", "letters", "line", "sheet", "spreadsheet", "style", "symbol", "text", "type", "under", "underlined", "writing"]
    },
		{
      value: "fort",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fort</Icon> fort
      </div>),
      tags: ["castle", "fort", "fortress", "mansion", "palace"]
    },
		{
      value: "forum",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>forum</Icon> forum
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "community", "conversation", "feedback", "forum", "hub", "message", "speech"]
    },
		{
      value: "forward",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>forward</Icon> forward
      </div>),
      tags: ["arrow", "forward", "mail", "message", "playback", "right", "sent"]
    },
		{
      value: "forward_10",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>forward_10</Icon> forward_10
      </div>),
      tags: ["10", "arrow", "control", "controls", "digit", "fast", "forward", "music", "numbers", "play", "seconds", "symbol", "video"]
    },
		{
      value: "forward_30",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>forward_30</Icon> forward_30
      </div>),
      tags: ["30", "arrow", "control", "controls", "digit", "fast", "forward", "music", "numbers", "seconds", "symbol", "video"]
    },
		{
      value: "forward_5",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>forward_5</Icon> forward_5
      </div>),
      tags: ["10", "5", "arrow", "control", "controls", "digit", "fast", "forward", "music", "numbers", "seconds", "symbol", "video"]
    },
		{
      value: "forward_to_inbox",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>forward_to_inbox</Icon> forward_to_inbox
      </div>),
      tags: ["arrow", "arrows", "directions", "email", "envelop", "forward", "inbox", "letters", "mail", "message", "navigation", "outgoing", "right", "send", "to"]
    },
		{
      value: "foundation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>foundation</Icon> foundation
      </div>),
      tags: ["architecture", "base", "basis", "building", "construction", "estate", "foundation", "home", "house", "real", "residential"]
    },
		{
      value: "free_breakfast",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>free_breakfast</Icon> free_breakfast
      </div>),
      tags: ["beverage", "breakfast", "cafe", "coffee", "cup", "drink", "free", "mug", "tea"]
    },
		{
      value: "free_cancellation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>free_cancellation</Icon> free_cancellation
      </div>),
      tags: ["approve", "calendar", "cancel", "cancellation", "check", "clear", "complete", "date", "day", "done", "event", "exit", "free", "mark", "month", "no", "ok", "remove", "schedule", "select", "stop", "tick", "validate", "verified", "x", "yes"]
    },
		{
      value: "front_hand",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>front_hand</Icon> front_hand
      </div>),
      tags: ["fingers", "front", "gesture", "hand", "hello", "palm", "stop"]
    },
		{
      value: "fullscreen",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fullscreen</Icon> fullscreen
      </div>),
      tags: ["adjust", "app", "application", "components", "full", "fullscreen", "interface", "screen", "site", "size", "ui", "ux", "view", "web", "website"]
    },
		{
      value: "fullscreen_exit",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>fullscreen_exit</Icon> fullscreen_exit
      </div>),
      tags: ["adjust", "app", "application", "components", "exit", "full", "fullscreen", "interface", "screen", "site", "size", "ui", "ux", "view", "web", "website"]
    },
		{
      value: "functions",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>functions</Icon> functions
      </div>),
      tags: ["average", "calculate", "count", "custom", "doc", "edit", "editing", "editor", "functions", "math", "sheet", "spreadsheet", "style", "sum", "text", "type", "writing"]
    },
		{
      value: "g_mobiledata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>g_mobiledata</Icon> g_mobiledata
      </div>),
      tags: ["alphabet", "character", "data", "font", "g", "letters", "mobile", "network", "service", "symbol", "text", "type"]
    },
		{
      value: "g_translate",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>g_translate</Icon> g_translate
      </div>),
      tags: ["emblem", "g", "google", "language", "logo", "mark", "speaking", "speech", "translate", "translator", "words"]
    },
		{
      value: "gamepad",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gamepad</Icon> gamepad
      </div>),
      tags: ["buttons", "console", "controller", "device", "game", "gamepad", "gaming", "playstation", "video"]
    },
		{
      value: "games",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>games</Icon> games
      </div>),
      tags: ["adjust", "arrow", "arrows", "control", "controller", "direction", "games", "gaming", "left", "move", "right"]
    },
		{
      value: "garage",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>garage</Icon> garage
      </div>),
      tags: ["automobile", "automotive", "car", "cars", "direction", "garage", "maps", "transportation", "travel", "vehicle"]
    },
		{
      value: "gas_meter",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gas_meter</Icon> gas_meter
      </div>),
      tags: ["droplet", "energy", "gas", "measure", "meter", "nest", "usage", "water"]
    },
		{
      value: "gavel",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gavel</Icon> gavel
      </div>),
      tags: ["agreement", "contract", "court", "document", "gavel", "government", "judge", "law", "mallet", "official", "police", "rule", "rules", "terms"]
    },
		{
      value: "generating_tokens",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>generating_tokens</Icon> generating_tokens
      </div>),
      tags: ["access", "ai", "api", "artificial", "automatic", "automation", "coin", "custom", "genai", "generating", "intelligence", "magic", "smart", "spark", "sparkle", "star", "tokens"]
    },
		{
      value: "gesture",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gesture</Icon> gesture
      </div>),
      tags: ["doodle", "draw", "drawing", "finger", "gesture", "gestures", "hand", "motion", "scribble", "string", "thread"]
    },
		{
      value: "get_app",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>get_app</Icon> get_app
      </div>),
      tags: ["app", "arrow", "arrows", "down", "download", "downloads", "export", "get", "install", "play", "upload"]
    },
		{
      value: "gif",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gif</Icon> gif
      </div>),
      tags: ["alphabet", "animated", "animation", "bitmap", "character", "font", "format", "gif", "graphics", "interchange", "letters", "symbol", "text", "type"]
    },
		{
      value: "gif_box",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gif_box</Icon> gif_box
      </div>),
      tags: ["alphabet", "animated", "animation", "bitmap", "character", "font", "format", "gif", "graphics", "interchange", "letter", "letters", "symbol", "text", "type"]
    },
		{
      value: "girl",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>girl</Icon> girl
      </div>),
      tags: ["body", "female", "gender", "girl", "human", "lady", "people", "person", "social", "symbol", "woman", "women"]
    },
		{
      value: "gite",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gite</Icon> gite
      </div>),
      tags: ["architecture", "estate", "gite", "home", "hostel", "house", "maps", "place", "real", "residence", "residential", "stay", "traveling"]
    },
		{
      value: "golf_course",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>golf_course</Icon> golf_course
      </div>),
      tags: ["athlete", "athletic", "ball", "club", "course", "entertainment", "flag", "golf", "golfer", "golfing", "hobby", "hole", "places", "putt", "sports"]
    },
		{
      value: "gpp_bad",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gpp_bad</Icon> gpp_bad
      </div>),
      tags: ["bad", "cancel", "certified", "close", "error", "exit", "gpp", "no", "privacy", "private", "protect", "protection", "remove", "security", "shield", "sim", "stop", "verified", "x"]
    },
		{
      value: "gpp_good",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gpp_good</Icon> gpp_good
      </div>),
      tags: ["certified", "check", "good", "gpp", "ok", "pass", "security", "shield", "sim", "tick"]
    },
		{
      value: "gpp_maybe",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gpp_maybe</Icon> gpp_maybe
      </div>),
      tags: ["!", "alert", "attention", "caution", "certified", "danger", "error", "exclamation", "gpp", "important", "mark", "maybe", "notification", "privacy", "private", "protect", "protection", "security", "shield", "sim", "symbol", "verified", "warning"]
    },
		{
      value: "gps_fixed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gps_fixed</Icon> gps_fixed
      </div>),
      tags: ["destination", "direction", "fixed", "gps", "location", "maps", "pin", "place", "pointer", "stop", "tracking"]
    },
		{
      value: "gps_not_fixed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gps_not_fixed</Icon> gps_not_fixed
      </div>),
      tags: ["destination", "direction", "disabled", "enabled", "gps", "location", "maps", "not fixed", "off", "on", "online", "place", "pointer", "slash", "tracking"]
    },
		{
      value: "gps_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gps_off</Icon> gps_off
      </div>),
      tags: ["destination", "direction", "disabled", "enabled", "gps", "location", "maps", "not fixed", "off", "offline", "on", "place", "pointer", "slash", "tracking"]
    },
		{
      value: "grade",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>grade</Icon> grade
      </div>),
      tags: ["'favorite_news'  .", "'star_outline'", "Duplicate of 'star_boarder'", "star_border_purple500'"]
    },
		{
      value: "gradient",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>gradient</Icon> gradient
      </div>),
      tags: ["color", "edit", "editing", "effect", "filter", "gradient", "image", "images", "photography", "picture", "pictures"]
    },
		{
      value: "grading",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>grading</Icon> grading
      </div>),
      tags: ["'favorite'_new'.  ' Remove this icon & keep 'star'.", "'star_boarder'", "'star_border_purple500'", "'star_outline'", "'star_purple500'", "'star_rate'", "Same as 'star'"]
    },
		{
      value: "grain",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>grain</Icon> grain
      </div>),
      tags: ["dots", "edit", "editing", "effect", "filter", "grain", "image", "images", "photography", "picture", "pictures"]
    },
		{
      value: "graphic_eq",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>graphic_eq</Icon> graphic_eq
      </div>),
      tags: ["audio", "detect", "detection", "eq", "equalizer", "graphic", "music", "noise", "recording", "sound", "voice"]
    },
		{
      value: "grass",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>grass</Icon> grass
      </div>),
      tags: ["backyard", "fodder", "grass", "ground", "home", "lawn", "plant", "turf", "yard"]
    },
		{
      value: "grid_3x3",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>grid_3x3</Icon> grid_3x3
      </div>),
      tags: ["3", "grid", "layout", "line", "space"]
    },
		{
      value: "grid_4x4",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>grid_4x4</Icon> grid_4x4
      </div>),
      tags: ["4", "by", "grid", "layout", "lines", "space"]
    },
		{
      value: "grid_goldenratio",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>grid_goldenratio</Icon> grid_goldenratio
      </div>),
      tags: ["golden", "goldenratio", "grid", "layout", "lines", "ratio", "space"]
    },
		{
      value: "grid_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>grid_off</Icon> grid_off
      </div>),
      tags: ["collage", "disabled", "enabled", "grid", "image", "layout", "off", "on", "slash", "view"]
    },
		{
      value: "grid_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>grid_on</Icon> grid_on
      </div>),
      tags: ["background", "collage", "disabled", "enabled", "grid", "image", "layout", "off", "on", "slash", "view"]
    },
		{
      value: "grid_view",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>grid_view</Icon> grid_view
      </div>),
      tags: ["app", "application square", "blocks", "components", "dashboard", "design", "grid", "interface", "layout", "screen", "site", "tiles", "ui", "ux", "view", "web", "website", "window"]
    },
		{
      value: "group",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>group</Icon> group
      </div>),
      tags: ["accounts", "committee", "face", "family", "friends", "group", "humans", "network", "people", "persons", "profiles", "social", "team", "users"]
    },
		{
      value: "group_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>group_add</Icon> group_add
      </div>),
      tags: ["accounts", "add", "committee", "face", "family", "friends", "group", "humans", "increase", "more", "network", "people", "persons", "plus", "profiles", "social", "team", "users"]
    },
		{
      value: "group_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>group_off</Icon> group_off
      </div>),
      tags: ["body", "club", "collaboration", "crowd", "gathering", "group", "human", "meeting", "off", "people", "person", "social", "teams"]
    },
		{
      value: "group_remove",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>group_remove</Icon> group_remove
      </div>),
      tags: ["accounts", "committee", "face", "family", "friends", "group", "humans", "network", "people", "persons", "profiles", "remove", "social", "team", "users"]
    },
		{
      value: "group_work",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>group_work</Icon> group_work
      </div>),
      tags: ["alliance", "collaboration", "group", "partnership", "team", "teamwork", "together", "work"]
    },
		{
      value: "groups",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>groups</Icon> groups
      </div>),
      tags: ["body", "club", "collaboration", "crowd", "gathering", "groups", "human", "meeting", "people", "person", "social", "teams"]
    },
		{
      value: "groups_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>groups_2</Icon> groups_2
      </div>),
      tags: ["body", "club", "collaboration", "crowd", "gathering", "groups", "hair", "human", "meeting", "people", "person", "social", "teams"]
    },
		{
      value: "groups_3",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>groups_3</Icon> groups_3
      </div>),
      tags: ["abstract", "body", "club", "collaboration", "crowd", "gathering", "groups", "human", "meeting", "people", "person", "social", "teams"]
    },
		{
      value: "h_mobiledata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>h_mobiledata</Icon> h_mobiledata
      </div>),
      tags: ["alphabet", "character", "data", "font", "h", "letters", "mobile", "network", "service", "symbol", "text", "type"]
    },
		{
      value: "h_plus_mobiledata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>h_plus_mobiledata</Icon> h_plus_mobiledata
      </div>),
      tags: ["+", "alphabet", "character", "data", "font", "h", "letters", "mobile", "network", "plus", "service", "symbol", "text", "type"]
    },
		{
      value: "hail",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hail</Icon> hail
      </div>),
      tags: ["body", "hail", "human", "people", "person", "pick", "public", "stop", "taxi", "transportation"]
    },
		{
      value: "handshake",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>handshake</Icon> handshake
      </div>),
      tags: ["agreement", "hand", "hands", "partnership", "shake"]
    },
		{
      value: "handyman",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>handyman</Icon> handyman
      </div>),
      tags: ["build", "construction", "fix", "hammer", "handyman", "repair", "screw", "screwdriver", "tools"]
    },
		{
      value: "hardware",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hardware</Icon> hardware
      </div>),
      tags: ["break", "construction", "hammer", "hardware", "nail", "repair", "tool"]
    },
		{
      value: "hd",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hd</Icon> hd
      </div>),
      tags: ["alphabet", "character", "codec", "definition", "display", "font", "hd", "high", "high definition", "letters", "movie", "movies", "resolution", "screen", "symbol", "text", "tv", "type"]
    },
		{
      value: "hdr_auto",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hdr_auto</Icon> hdr_auto
      </div>),
      tags: ["A", "alphabet", "auto", "camera", "character", "circle", "dynamic", "font", "hdr", "high", "letters", "photo", "range", "symbol", "text", "type"]
    },
		{
      value: "hdr_auto_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hdr_auto_select</Icon> hdr_auto_select
      </div>),
      tags: ["+", "A", "alphabet", "auto", "camera", "character", "circle", "dynamic", "font", "hdr", "high", "letters", "photo", "range", "select", "symbol", "text", "type"]
    },
		{
      value: "hdr_enhanced_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hdr_enhanced_select</Icon> hdr_enhanced_select
      </div>),
      tags: ["add", "alphabet", "character", "dynamic", "enhance", "font", "hdr", "high", "letters", "plus", "range", "select", "symbol", "text", "type"]
    },
		{
      value: "hdr_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hdr_off</Icon> hdr_off
      </div>),
      tags: ["alphabet", "character", "disabled", "dynamic", "enabled", "enhance", "font", "hdr", "high", "letters", "off", "on", "range", "select", "slash", "symbol", "text", "type"]
    },
		{
      value: "hdr_off_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hdr_off_select</Icon> hdr_off_select
      </div>),
      tags: ["alphabet", "camera", "character", "circle", "disabled", "dynamic", "enabled", "font", "hdr", "high", "letters", "off", "on", "photo", "range", "select", "slash", "symbol", "text", "type"]
    },
		{
      value: "hdr_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hdr_on</Icon> hdr_on
      </div>),
      tags: ["add", "alphabet", "character", "dynamic", "enhance", "font", "hdr", "high", "letters", "on", "plus", "range", "select", "symbol", "text", "type"]
    },
		{
      value: "hdr_on_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hdr_on_select</Icon> hdr_on_select
      </div>),
      tags: ["+", "alphabet", "camera", "character", "circle", "dynamic", "font", "hdr", "high", "letters", "on", "photo", "range", "select", "symbol", "text", "type"]
    },
		{
      value: "hdr_plus",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hdr_plus</Icon> hdr_plus
      </div>),
      tags: ["+", "add", "alphabet", "character", "circle", "dynamic", "enhance", "font", "hdr", "high", "letters", "plus", "range", "select", "symbol", "text", "type"]
    },
		{
      value: "hdr_strong",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hdr_strong</Icon> hdr_strong
      </div>),
      tags: ["circles", "dots", "dynamic", "enhance", "hdr", "high", "range", "strong"]
    },
		{
      value: "hdr_weak",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hdr_weak</Icon> hdr_weak
      </div>),
      tags: ["circles", "dots", "dynamic", "enhance", "hdr", "high", "range", "weak"]
    },
		{
      value: "headphones",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>headphones</Icon> headphones
      </div>),
      tags: ["accessory", "audio", "device", "ear", "earphone", "headphones", "headset", "listen", "music", "sound"]
    },
		{
      value: "headphones_battery",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>headphones_battery</Icon> headphones_battery
      </div>),
      tags: ["accessory", "audio", "battery", "charging", "device", "ear", "earphone", "headphones", "headset", "listen", "music", "sound"]
    },
		{
      value: "headset",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>headset</Icon> headset
      </div>),
      tags: ["accessory", "audio", "device", "ear", "earphone", "headphones", "headset", "listen", "music", "sound"]
    },
		{
      value: "headset_mic",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>headset_mic</Icon> headset_mic
      </div>),
      tags: ["accessory", "audio", "chat", "device", "ear", "earphone", "headphones", "headset", "listen", "mic", "music", "sound", "talk"]
    },
		{
      value: "headset_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>headset_off</Icon> headset_off
      </div>),
      tags: ["accessory", "audio", "chat", "device", "disabled", "ear", "earphone", "enabled", "headphones", "headset", "listen", "mic", "music", "mute", "off", "on", "slash", "sound", "talk"]
    },
		{
      value: "healing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>healing</Icon> healing
      </div>),
      tags: ["bandage", "edit", "editing", "emergency", "fix", "healing", "hospital", "image", "medicine"]
    },
		{
      value: "health_and_safety",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>health_and_safety</Icon> health_and_safety
      </div>),
      tags: ["+", "add", "and", "certified", "cross", "health", "home", "nest", "plus", "privacy", "private", "protect", "protection", "safety", "security", "shield", "symbol", "verified"]
    },
		{
      value: "hearing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hearing</Icon> hearing
      </div>),
      tags: ["accessibility", "accessible", "aids", "body", "ear", "handicap", "hearing", "hearing aids", "help", "human", "impaired", "listen", "mono", "sound", "volume"]
    },
		{
      value: "hearing_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hearing_disabled</Icon> hearing_disabled
      </div>),
      tags: ["accessibility", "accessible", "aid", "disabled", "ear", "enabled", "handicap", "hearing", "help", "impaired", "listen", "mute", "off", "on", "slash", "sound", "volume"]
    },
		{
      value: "heart_broken",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>heart_broken</Icon> heart_broken
      </div>),
      tags: ["break", "broken", "core", "crush", "health", "heart", "nucleus", "split"]
    },
		{
      value: "heat_pump",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>heat_pump</Icon> heat_pump
      </div>),
      tags: ["air conditioner", "cool", "energy", "furnance", "heat", "nest", "pump", "usage"]
    },
		{
      value: "height",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>height</Icon> height
      </div>),
      tags: ["arrow", "color", "doc", "down", "edit", "editing", "editor", "fill", "format", "height", "paint", "sheet", "spreadsheet", "style", "text", "type", "up", "writing"]
    },
		{
      value: "help",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>help</Icon> help
      </div>),
      tags: ["?", "assistance", "circle", "help", "info", "information", "punctuation", "question mark", "recent", "restore", "shape", "support", "symbol"]
    },
		{
      value: "help_center",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>help_center</Icon> help_center
      </div>),
      tags: ["?", "assistance", "center", "help", "info", "information", "punctuation", "question mark", "recent", "restore", "support", "symbol"]
    },
		{
      value: "help_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>help_outline</Icon> help_outline
      </div>),
      tags: ["?", "assistance", "circle", "help", "info", "information", "outline", "punctuation", "question mark", "recent", "restore", "shape", "support", "symbol"]
    },
		{
      value: "hevc",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hevc</Icon> hevc
      </div>),
      tags: ["alphabet", "character", "coding", "efficiency", "font", "hevc", "high", "letters", "symbol", "text", "type", "video"]
    },
		{
      value: "hexagon",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hexagon</Icon> hexagon
      </div>),
      tags: ["hexagon", "shape", "six sides"]
    },
		{
      value: "hide_image",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hide_image</Icon> hide_image
      </div>),
      tags: ["disabled", "enabled", "hide", "image", "landscape", "mountain", "mountains", "off", "on", "photo", "photography", "picture", "slash"]
    },
		{
      value: "hide_source",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hide_source</Icon> hide_source
      </div>),
      tags: ["circle", "disabled", "enabled", "hide", "off", "offline", "on", "shape", "slash", "source"]
    },
		{
      value: "high_quality",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>high_quality</Icon> high_quality
      </div>),
      tags: ["alphabet", "character", "definition", "display", "font", "high", "hq", "letters", "movie", "movies", "quality", "resolution", "screen", "symbol", "text", "tv", "type"]
    },
		{
      value: "highlight",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>highlight</Icon> highlight
      </div>),
      tags: ["color", "doc", "edit", "editing", "editor", "emphasize", "fill", "flash", "format", "highlight", "light", "paint", "sheet", "spreadsheet", "style", "text", "type", "writing"]
    },
		{
      value: "highlight_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>highlight_alt</Icon> highlight_alt
      </div>),
      tags: ["alt", "arrow", "box", "click", "cursor", "draw", "focus", "highlight", "pointer", "select", "selection", "target"]
    },
		{
      value: "highlight_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>highlight_off</Icon> highlight_off
      </div>),
      tags: ["cancel", "clear", "close", "exit", "highlight", "no", "off", "quit", "remove", "stop", "x"]
    },
		{
      value: "hiking",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hiking</Icon> hiking
      </div>),
      tags: ["backpacking", "bag", "climbing", "duffle", "hiking", "mountain", "social", "sports", "stick", "trail", "travel", "walking"]
    },
		{
      value: "history",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>history</Icon> history
      </div>),
      tags: ["arrow", "back", "backwards", "clock", "date", "device", "history", "home", "nest", "refresh", "renew", "reset", "restore", "reverse", "rotate", "schedule", "time", "turn"]
    },
		{
      value: "history_edu",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>history_edu</Icon> history_edu
      </div>),
      tags: ["document", "edu", "education", "feather", "history", "letters", "paper", "pen", "quill", "school", "story", "tools", "write", "writing"]
    },
		{
      value: "history_toggle_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>history_toggle_off</Icon> history_toggle_off
      </div>),
      tags: ["clock", "dash", "dashed", "date", "history", "off", "schedule", "time", "toggle"]
    },
		{
      value: "hive",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hive</Icon> hive
      </div>),
      tags: ["bee", "honey", "honeycomb"]
    },
		{
      value: "hls",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hls</Icon> hls
      </div>),
      tags: ["alphabet", "character", "develop", "developer", "engineer", "engineering", "font", "hls", "letters", "platform", "symbol", "text", "type"]
    },
		{
      value: "hls_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hls_off</Icon> hls_off
      </div>),
      tags: ["alphabet", "character", "develop", "developer", "disabled", "enabled", "engineer", "engineering", "font", "hls", "letters", "off", "offline", "on", "platform", "slash", "symbol", "text", "type"]
    },
		{
      value: "holiday_village",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>holiday_village</Icon> holiday_village
      </div>),
      tags: ["architecture", "beach", "camping", "cottage", "estate", "holiday", "home", "house", "lake", "lodge", "maps", "place", "real", "residence", "residential", "stay", "traveling", "vacation", "village"]
    },
		{
      value: "home",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>home</Icon> home
      </div>),
      tags: ["address", "app", "application--house", "architecture", "building", "components", "design", "estate", "home", "interface", "layout", "place", "real", "residence", "residential", "screen", "shelter", "site", "structure", "ui", "unit", "ux", "web", "website", "window"]
    },
		{
      value: "home_max",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>home_max</Icon> home_max
      </div>),
      tags: ["device", "gadget", "hardware", "home", "internet", "iot", "max", "nest", "smart", "things"]
    },
		{
      value: "home_mini",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>home_mini</Icon> home_mini
      </div>),
      tags: ["Internet", "device", "gadget", "hardware", "home", "iot", "mini", "nest", "smart", "things"]
    },
		{
      value: "home_repair_service",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>home_repair_service</Icon> home_repair_service
      </div>),
      tags: ["box", "equipment", "fix", "home", "kit", "mechanic", "repair", "repairing", "service", "tool", "toolbox", "tools", "workshop"]
    },
		{
      value: "home_work",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>home_work</Icon> home_work
      </div>),
      tags: ["architecture", "building", "estate", "home", "place", "real", "residence", "residential", "shelter", "work"]
    },
		{
      value: "horizontal_distribute",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>horizontal_distribute</Icon> horizontal_distribute
      </div>),
      tags: ["alignment", "distribute", "format", "horizontal", "layout", "lines", "paragraph", "rule", "rules", "style", "text"]
    },
		{
      value: "horizontal_rule",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>horizontal_rule</Icon> horizontal_rule
      </div>),
      tags: ["gmail", "horizontal", "line", "novitas", "rule"]
    },
		{
      value: "horizontal_split",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>horizontal_split</Icon> horizontal_split
      </div>),
      tags: ["bars", "format", "horizontal", "layout", "lines", "split", "stacked"]
    },
		{
      value: "hot_tub",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hot_tub</Icon> hot_tub
      </div>),
      tags: ["bath", "bathing", "bathroom", "bathtub", "hot", "hotel", "human", "jacuzzi", "person", "shower", "spa", "steam", "travel", "tub", "water"]
    },
		{
      value: "hotel",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hotel</Icon> hotel
      </div>),
      tags: ["body", "hotel", "human", "people", "person", "sleep", "stay", "travel", "trip"]
    },
		{
      value: "hotel_class",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hotel_class</Icon> hotel_class
      </div>),
      tags: ["achievement", "bookmark", "class", "favorite", "highlight", "hotel", "important", "marked", "rank", "ranking", "rate", "rating", "reward", "save", "saved", "shape", "special", "star"]
    },
		{
      value: "hourglass_bottom",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hourglass_bottom</Icon> hourglass_bottom
      </div>),
      tags: ["bottom", "countdown", "half", "hourglass", "loading", "minute", "minutes", "time", "wait", "waiting"]
    },
		{
      value: "hourglass_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hourglass_disabled</Icon> hourglass_disabled
      </div>),
      tags: ["clock", "countdown", "disabled", "empty", "enabled", "hourglass", "loading", "minute", "minutes", "off", "on", "slash", "time", "wait", "waiting"]
    },
		{
      value: "hourglass_empty",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hourglass_empty</Icon> hourglass_empty
      </div>),
      tags: ["countdown", "empty", "full", "hourglass", "loading", "minutes", "time", "wait", "waiting"]
    },
		{
      value: "hourglass_full",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hourglass_full</Icon> hourglass_full
      </div>),
      tags: ["countdown", "empty", "full", "hourglass", "loading", "minutes", "time", "wait", "waiting"]
    },
		{
      value: "hourglass_top",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hourglass_top</Icon> hourglass_top
      </div>),
      tags: ["countdown", "half", "hourglass", "loading", "minute", "minutes", "time", "top", "wait", "waiting"]
    },
		{
      value: "house",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>house</Icon> house
      </div>),
      tags: ["architecture", "building", "estate", "family", "home", "homepage", "house", "place", "places", "real", "residence", "residential", "shelter"]
    },
		{
      value: "house_siding",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>house_siding</Icon> house_siding
      </div>),
      tags: ["architecture", "building", "construction", "estate", "exterior", "facade", "home", "house", "real", "residential", "siding"]
    },
		{
      value: "houseboat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>houseboat</Icon> houseboat
      </div>),
      tags: ["architecture", "beach", "boat", "estate", "floating", "home", "house", "houseboat", "maps", "place", "real", "residence", "residential", "sea", "stay", "traveling", "vacation"]
    },
		{
      value: "how_to_reg",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>how_to_reg</Icon> how_to_reg
      </div>),
      tags: ["approve", "ballot", "check", "complete", "done", "election", "how", "mark", "ok", "poll", "register", "registration", "select", "tick", "to reg", "validate", "verified", "vote", "yes"]
    },
		{
      value: "how_to_vote",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>how_to_vote</Icon> how_to_vote
      </div>),
      tags: ["ballot", "election", "how", "poll", "to", "vote"]
    },
		{
      value: "html",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>html</Icon> html
      </div>),
      tags: ["alphabet", "brackets", "character", "code", "css", "develop", "developer", "engineer", "engineering", "font", "html", "letters", "platform", "symbol", "text", "type"]
    },
		{
      value: "http",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>http</Icon> http
      </div>),
      tags: ["alphabet", "character", "font", "http", "letters", "symbol", "text", "transfer", "type", "url", "website"]
    },
		{
      value: "https",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>https</Icon> https
      </div>),
      tags: ["https", "lock", "locked", "password", "privacy", "private", "protection", "safety", "secure", "security"]
    },
		{
      value: "hub",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hub</Icon> hub
      </div>),
      tags: ["center", "connection", "core", "focal point", "hub", "network", "nodes", "nucleus", "topology"]
    },
		{
      value: "hvac",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>hvac</Icon> hvac
      </div>),
      tags: ["air", "conditioning", "heating", "hvac", "ventilation"]
    },
		{
      value: "ice_skating",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ice_skating</Icon> ice_skating
      </div>),
      tags: ["athlete", "athletic", "entertainment", "exercise", "hobby", "ice", "shoe", "skates", "skating", "social", "sports", "travel"]
    },
		{
      value: "icecream",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>icecream</Icon> icecream
      </div>),
      tags: ["cream", "dessert", "food", "ice", "icecream", "snack"]
    },
		{
      value: "image",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>image</Icon> image
      </div>),
      tags: ["image", "landscape", "mountain", "mountains", "photo", "photography", "picture"]
    },
		{
      value: "image_aspect_ratio",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>image_aspect_ratio</Icon> image_aspect_ratio
      </div>),
      tags: ["aspect", "image", "photo", "photography", "picture", "ratio", "rectangle", "square"]
    },
		{
      value: "image_not_supported",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>image_not_supported</Icon> image_not_supported
      </div>),
      tags: ["disabled", "enabled", "image", "landscape", "mountain", "mountains", "not", "off", "on", "photo", "photography", "picture", "slash", "supported"]
    },
		{
      value: "image_search",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>image_search</Icon> image_search
      </div>),
      tags: ["find", "glass", "image", "landscape", "look", "magnify", "magnifying", "mountain", "mountains", "photo", "photography", "picture", "search", "see"]
    },
		{
      value: "imagesearch_roller",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>imagesearch_roller</Icon> imagesearch_roller
      </div>),
      tags: ["art", "image", "imagesearch", "paint", "roller", "search"]
    },
		{
      value: "import_contacts",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>import_contacts</Icon> import_contacts
      </div>),
      tags: ["address", "book", "contacts", "import", "info", "information", "open"]
    },
		{
      value: "import_export",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>import_export</Icon> import_export
      </div>),
      tags: ["arrow", "arrows", "direction", "down", "explort", "import", "up"]
    },
		{
      value: "important_devices",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>important_devices</Icon> important_devices
      </div>),
      tags: ["Android", "OS", "desktop", "devices", "hardware", "iOS", "important", "mobile", "monitor", "phone", "star", "tablet", "web"]
    },
		{
      value: "inbox",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>inbox</Icon> inbox
      </div>),
      tags: ["archive", "category", "email", "inbox", "incoming", "mail", "message"]
    },
		{
      value: "incomplete_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>incomplete_circle</Icon> incomplete_circle
      </div>),
      tags: ["chart", "circle", "incomplete"]
    },
		{
      value: "indeterminate_check_box",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>indeterminate_check_box</Icon> indeterminate_check_box
      </div>),
      tags: ["app", "application", "box", "button", "check", "components", "control", "design", "form", "indeterminate", "interface", "screen", "select", "selected", "selection", "site", "square", "toggle", "ui", "undetermined", "ux", "web", "website"]
    },
		{
      value: "info",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>info</Icon> info
      </div>),
      tags: ["alert", "announcement", "assistance", "details", "help", "i", "info", "information", "service", "support"]
    },
		{
      value: "input",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>input</Icon> input
      </div>),
      tags: ["arrow", "box", "download", "input", "login", "move", "right"]
    },
		{
      value: "insert_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>insert_chart</Icon> insert_chart
      </div>),
      tags: ["analytics", "bar", "bars", "chart", "data", "diagram", "graph", "infographic", "insert", "measure", "metrics", "statistics", "tracking"]
    },
		{
      value: "insert_chart_outlined",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>insert_chart_outlined</Icon> insert_chart_outlined
      </div>),
      tags: ["analytics", "bar", "bars", "chart", "data", "diagram", "graph", "infographic", "insert", "measure", "metrics", "outlined", "statistics", "tracking"]
    },
		{
      value: "insert_comment",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>insert_comment</Icon> insert_comment
      </div>),
      tags: ["add", "bubble", "chat", "comment", "feedback", "insert", "message"]
    },
		{
      value: "insert_drive_file",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>insert_drive_file</Icon> insert_drive_file
      </div>),
      tags: ["doc", "drive", "file", "format", "insert", "sheet", "slide"]
    },
		{
      value: "insert_emoticon",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>insert_emoticon</Icon> insert_emoticon
      </div>),
      tags: ["account", "emoji", "emoticon", "face", "happy", "human", "insert", "people", "person", "profile", "sentiment", "smile", "user"]
    },
		{
      value: "insert_invitation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>insert_invitation</Icon> insert_invitation
      </div>),
      tags: ["calendar", "date", "day", "event", "insert", "invitation", "mark", "month", "range", "remember", "reminder", "today", "week"]
    },
		{
      value: "insert_link",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>insert_link</Icon> insert_link
      </div>),
      tags: ["add", "attach", "clip", "file", "insert", "link", "mail", "media"]
    },
		{
      value: "insert_page_break",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>insert_page_break</Icon> insert_page_break
      </div>),
      tags: ["break", "dash", "dashed", "doc", "document", "file", "page", "paper"]
    },
		{
      value: "insert_photo",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>insert_photo</Icon> insert_photo
      </div>),
      tags: ["image", "insert", "landscape", "mountain", "mountains", "photo", "photography", "picture"]
    },
		{
      value: "insights",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>insights</Icon> insights
      </div>),
      tags: ["ai", "analytics", "artificial", "automatic", "automation", "bar", "bars", "chart", "custom", "data", "diagram", "genai", "graph", "infographic", "insights", "intelligence", "magic", "measure", "metrics", "smart", "spark", "sparkle", "star", "stars", "statistics", "tracking"]
    },
		{
      value: "install_desktop",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>install_desktop</Icon> install_desktop
      </div>),
      tags: ["Android", "OS", "chrome", "desktop", "device", "display", "fix", "hardware", "iOS", "install", "mac", "monitor", "place", "pwa", "screen", "web", "window"]
    },
		{
      value: "install_mobile",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>install_mobile</Icon> install_mobile
      </div>),
      tags: ["Android", "OS", "cell", "device", "hardware", "iOS", "install", "mobile", "phone", "pwa", "tablet"]
    },
		{
      value: "integration_instructions",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>integration_instructions</Icon> integration_instructions
      </div>),
      tags: ["brackets", "clipboard", "code", "css", "develop", "developer", "doc", "document", "engineer", "engineering clipboard", "html", "instructions", "integration", "platform"]
    },
		{
      value: "interests",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>interests</Icon> interests
      </div>),
      tags: ["circle", "heart", "interests", "shapes", "social", "square", "triangle"]
    },
		{
      value: "interpreter_mode",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>interpreter_mode</Icon> interpreter_mode
      </div>),
      tags: ["account", "accounts", "committee", "dictation", "face", "family", "friends", "group", "hear", "hearing", "human", "humans", "interpreter", "keyboard", "language", "mic", "microphone", "mode", "network", "noise", "people", "person", "persons", "profile", "profiles", "record", "recorder", "social", "sound", "speaker", "speaking", "symbol", "team", "user", "users", "voice"]
    },
		{
      value: "inventory",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>inventory</Icon> inventory
      </div>),
      tags: ["archive", "box", "clipboard", "doc", "document", "file", "inventory", "organize", "packages", "product", "stock", "supply"]
    },
		{
      value: "inventory_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>inventory_2</Icon> inventory_2
      </div>),
      tags: ["archive", "box", "file", "inventory", "organize", "packages", "product", "stock", "storage", "supply"]
    },
		{
      value: "invert_colors",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>invert_colors</Icon> invert_colors
      </div>),
      tags: ["colors", "drop", "droplet", "edit", "editing", "hue", "invert", "inverted", "palette", "tone", "water"]
    },
		{
      value: "invert_colors_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>invert_colors_off</Icon> invert_colors_off
      </div>),
      tags: ["colors", "disabled", "drop", "droplet", "enabled", "hue", "invert", "inverted", "off", "offline", "on", "opacity", "palette", "slash", "tone", "water"]
    },
		{
      value: "ios_share",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ios_share</Icon> ios_share
      </div>),
      tags: ["arrow", "export", "ios", "send", "share", "up"]
    },
		{
      value: "iron",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>iron</Icon> iron
      </div>),
      tags: ["appliance", "clothes", "electric", "iron", "ironing", "machine", "object"]
    },
		{
      value: "iso",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>iso</Icon> iso
      </div>),
      tags: ["add", "edit", "editing", "effect", "image", "iso", "minus", "photography", "picture", "plus", "sensor", "shutter", "speed", "subtract"]
    },
		{
      value: "javascript",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>javascript</Icon> javascript
      </div>),
      tags: ["alphabet", "brackets", "character", "code", "css", "develop", "developer", "engineer", "engineering", "font", "html", "javascript", "letters", "platform", "symbol", "text", "type"]
    },
		{
      value: "join_full",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>join_full</Icon> join_full
      </div>),
      tags: ["circle", "combine", "command", "join", "left", "outer", "overlap", "right", "sql"]
    },
		{
      value: "join_inner",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>join_inner</Icon> join_inner
      </div>),
      tags: ["circle", "command", "inner", "join", "matching", "overlap", "sql", "values"]
    },
		{
      value: "join_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>join_left</Icon> join_left
      </div>),
      tags: ["circle", "command", "join", "left", "matching", "overlap", "sql", "values"]
    },
		{
      value: "join_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>join_right</Icon> join_right
      </div>),
      tags: ["circle", "command", "join", "matching", "overlap", "right", "sql", "values"]
    },
		{
      value: "kayaking",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>kayaking</Icon> kayaking
      </div>),
      tags: ["athlete", "athletic", "body", "canoe", "entertainment", "exercise", "hobby", "human", "kayak", "kayaking", "lake", "paddle", "paddling", "people", "person", "rafting", "river", "row", "social", "sports", "summer", "travel", "water"]
    },
		{
      value: "kebab_dining",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>kebab_dining</Icon> kebab_dining
      </div>),
      tags: ["dining", "dinner", "food", "kebab", "meal", "meat", "skewer"]
    },
		{
      value: "key",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>key</Icon> key
      </div>),
      tags: ["access", "door", "entry", "key", "lock", "password", "unlock"]
    },
		{
      value: "key_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>key_off</Icon> key_off
      </div>),
      tags: ["access", "disabled", "door", "enabled", "entry", "key", "lock", "off", "offline", "on", "password", "slash", "unlock"]
    },
		{
      value: "keyboard",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard</Icon> keyboard
      </div>),
      tags: ["computer", "device", "hardware", "input", "keyboard", "keypad", "letters", "office", "text", "type"]
    },
		{
      value: "keyboard_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_alt</Icon> keyboard_alt
      </div>),
      tags: ["computer", "device", "hardware", "input", "keyboard", "keypad", "letters", "office", "text", "type"]
    },
		{
      value: "keyboard_arrow_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_arrow_down</Icon> keyboard_arrow_down
      </div>),
      tags: ["arrow", "arrows", "down", "keyboard"]
    },
		{
      value: "keyboard_arrow_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_arrow_left</Icon> keyboard_arrow_left
      </div>),
      tags: ["arrow", "arrows", "keyboard", "left"]
    },
		{
      value: "keyboard_arrow_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_arrow_right</Icon> keyboard_arrow_right
      </div>),
      tags: ["arrow", "arrows", "keyboard", "right"]
    },
		{
      value: "keyboard_arrow_up",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_arrow_up</Icon> keyboard_arrow_up
      </div>),
      tags: ["arrow", "arrows", "chevron", "collapse", "direction", "expand", "expandable", "less", "list", "up"]
    },
		{
      value: "keyboard_backspace",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_backspace</Icon> keyboard_backspace
      </div>),
      tags: ["arrow", "back", "backspace", "keyboard", "left"]
    },
		{
      value: "keyboard_capslock",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_capslock</Icon> keyboard_capslock
      </div>),
      tags: ["arrow", "capslock", "keyboard", "up"]
    },
		{
      value: "keyboard_command_key",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_command_key</Icon> keyboard_command_key
      </div>),
      tags: ["button", "command key", "control", "keyboard"]
    },
		{
      value: "keyboard_control_key",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_control_key</Icon> keyboard_control_key
      </div>),
      tags: ["control key", "keyboard"]
    },
		{
      value: "keyboard_double_arrow_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_double_arrow_down</Icon> keyboard_double_arrow_down
      </div>),
      tags: ["arrow", "arrows", "direction", "double", "down", "multiple", "navigation"]
    },
		{
      value: "keyboard_double_arrow_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_double_arrow_left</Icon> keyboard_double_arrow_left
      </div>),
      tags: ["arrow", "arrows", "direction", "double", "left", "multiple", "navigation"]
    },
		{
      value: "keyboard_double_arrow_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_double_arrow_right</Icon> keyboard_double_arrow_right
      </div>),
      tags: ["arrow", "arrows", "direction", "double", "multiple", "navigation", "right"]
    },
		{
      value: "keyboard_double_arrow_up",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_double_arrow_up</Icon> keyboard_double_arrow_up
      </div>),
      tags: ["arrow", "arrows", "direction", "double", "multiple", "navigation", "up"]
    },
		{
      value: "keyboard_hide",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_hide</Icon> keyboard_hide
      </div>),
      tags: ["arrow", "computer", "device", "down", "hardware", "hide", "input", "keyboard", "keypad", "text"]
    },
		{
      value: "keyboard_option_key",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_option_key</Icon> keyboard_option_key
      </div>),
      tags: ["alt key", "key", "keyboard", "modifier key", "option"]
    },
		{
      value: "keyboard_return",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_return</Icon> keyboard_return
      </div>),
      tags: ["arrow", "back", "keyboard", "left", "return"]
    },
		{
      value: "keyboard_tab",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_tab</Icon> keyboard_tab
      </div>),
      tags: ["arrow", "keyboard", "left", "next", "right", "tab"]
    },
		{
      value: "keyboard_voice",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>keyboard_voice</Icon> keyboard_voice
      </div>),
      tags: ["dictation", "hear", "hearing", "keyboard", "mic", "microphone", "noise", "record", "recorder", "sound", "speaker", "voice"]
    },
		{
      value: "king_bed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>king_bed</Icon> king_bed
      </div>),
      tags: ["bed", "bedroom", "double", "furniture", "home", "hotel", "house", "king", "night", "pillows", "queen", "rest", "room", "sleep"]
    },
		{
      value: "kitchen",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>kitchen</Icon> kitchen
      </div>),
      tags: ["appliance", "cold", "food", "fridge", "home", "house", "ice", "kitchen", "places", "refrigerator", "storage"]
    },
		{
      value: "kitesurfing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>kitesurfing</Icon> kitesurfing
      </div>),
      tags: ["athlete", "athletic", "beach", "body", "entertainment", "exercise", "hobby", "human", "kitesurfing", "people", "person", "social", "sports", "surf", "travel", "water"]
    },
		{
      value: "label",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>label</Icon> label
      </div>),
      tags: ["favorite", "indent", "label", "library", "mail", "remember", "save", "stamp", "sticker", "tag"]
    },
		{
      value: "label_important",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>label_important</Icon> label_important
      </div>),
      tags: ["chevron", "favorite", "flag", "important", "indent", "label", "library", "mail", "remember", "right", "save", "stamp", "sticker", "tag", "wing"]
    },
		{
      value: "label_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>label_off</Icon> label_off
      </div>),
      tags: ["disabled", "enabled", "favorite", "indent", "label", "library", "mail", "off", "on", "remember", "save", "slash", "stamp", "sticker", "tag", "wing"]
    },
		{
      value: "lan",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lan</Icon> lan
      </div>),
      tags: ["computer", "connection", "data", "internet", "lan", "network", "service"]
    },
		{
      value: "landscape",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>landscape</Icon> landscape
      </div>),
      tags: ["image", "landscape", "mountain", "mountains", "nature", "photo", "photography", "picture"]
    },
		{
      value: "landslide",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>landslide</Icon> landslide
      </div>),
      tags: ["crisis", "disaster", "natural", "rain", "storm", "weather"]
    },
		{
      value: "language",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>language</Icon> language
      </div>),
      tags: ["globe", "internet", "language", "planet", "website", "world", "www"]
    },
		{
      value: "laptop",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>laptop</Icon> laptop
      </div>),
      tags: ["Android", "OS", "chrome", "computer", "desktop", "device", "hardware", "iOS", "laptop", "mac", "monitor", "web", "windows"]
    },
		{
      value: "laptop_chromebook",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>laptop_chromebook</Icon> laptop_chromebook
      </div>),
      tags: ["Android", "OS", "chrome", "chromebook", "device", "display", "hardware", "iOS", "laptop", "mac chromebook", "monitor", "screen", "web", "window"]
    },
		{
      value: "laptop_mac",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>laptop_mac</Icon> laptop_mac
      </div>),
      tags: ["Android", "OS", "chrome", "device", "display", "hardware", "iOS", "laptop", "mac", "monitor", "screen", "web", "window"]
    },
		{
      value: "laptop_windows",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>laptop_windows</Icon> laptop_windows
      </div>),
      tags: ["Android", "OS", "chrome", "device", "display", "hardware", "iOS", "laptop", "mac", "monitor", "screen", "web", "window", "windows"]
    },
		{
      value: "last_page",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>last_page</Icon> last_page
      </div>),
      tags: ["app", "application", "arrow", "chevron", "components", "end", "forward", "interface", "last", "page", "right", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "launch",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>launch</Icon> launch
      </div>),
      tags: ["app", "application", "arrow", "box", "components", "interface", "launch", "new", "open", "screen", "site", "ui", "ux", "web", "website", "window"]
    },
		{
      value: "layers",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>layers</Icon> layers
      </div>),
      tags: ["arrange", "disabled", "enabled", "interaction", "layers", "maps", "off", "on", "overlay", "pages", "slash", "stack"]
    },
		{
      value: "layers_clear",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>layers_clear</Icon> layers_clear
      </div>),
      tags: ["arrange", "clear", "delete", "disabled", "enabled", "interaction", "layers", "maps", "off", "on", "overlay", "pages", "slash"]
    },
		{
      value: "leaderboard",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>leaderboard</Icon> leaderboard
      </div>),
      tags: ["analytics", "bar", "bars", "chart", "data", "diagram", "graph", "infographic", "leaderboard", "measure", "metrics", "statistics", "tracking"]
    },
		{
      value: "leak_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>leak_add</Icon> leak_add
      </div>),
      tags: ["add", "connection", "data", "leak", "link", "network", "service", "signals", "synce", "wireless"]
    },
		{
      value: "leak_remove",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>leak_remove</Icon> leak_remove
      </div>),
      tags: ["connection", "data", "disabled", "enabled", "leak", "link", "network", "off", "offline", "on", "remove", "service", "signals", "slash", "synce", "wireless"]
    },
		{
      value: "legend_toggle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>legend_toggle</Icon> legend_toggle
      </div>),
      tags: ["analytics", "chart", "data", "diagram", "graph", "infographic", "legend", "measure", "metrics", "monitoring", "stackdriver", "statistics", "toggle", "tracking"]
    },
		{
      value: "lens",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lens</Icon> lens
      </div>),
      tags: ["angle", "circle", "eye", "fish", "full", "geometry", "image", "lens", "moon", "panorama", "photo", "photography", "picture", "wide"]
    },
		{
      value: "lens_blur",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lens_blur</Icon> lens_blur
      </div>),
      tags: ["blur", "camera", "dim", "dot", "effect", "foggy", "fuzzy", "image", "lens", "photo", "soften"]
    },
		{
      value: "library_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>library_add</Icon> library_add
      </div>),
      tags: ["+", "add", "collection", "layers", "library", "multiple", "music", "new", "plus", "stacked", "symbol", "video"]
    },
		{
      value: "library_add_check",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>library_add_check</Icon> library_add_check
      </div>),
      tags: ["add", "approve", "check", "collection", "complete", "done", "layers", "library", "mark", "multiple", "music", "ok", "select", "stacked", "tick", "validate", "verified", "video", "yes"]
    },
		{
      value: "library_books",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>library_books</Icon> library_books
      </div>),
      tags: ["add", "album", "audio", "book", "books", "collection", "library", "read", "reading"]
    },
		{
      value: "library_music",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>library_music</Icon> library_music
      </div>),
      tags: ["add", "album", "collection", "library", "music", "song", "sounds"]
    },
		{
      value: "light",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>light</Icon> light
      </div>),
      tags: ["bulb", "ceiling", "hanging", "inside", "interior", "lamp", "light", "lighting", "pendent", "room"]
    },
		{
      value: "light_mode",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>light_mode</Icon> light_mode
      </div>),
      tags: ["bright", "brightness", "day", "device", "light", "lighting", "mode", "morning", "sky", "sun", "sunny"]
    },
		{
      value: "lightbulb",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lightbulb</Icon> lightbulb
      </div>),
      tags: ["alert", "announcement", "bulb", "idea", "incandescent", "info", "information", "led", "light", "lightbulb", "tip"]
    },
		{
      value: "lightbulb_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lightbulb_circle</Icon> lightbulb_circle
      </div>),
      tags: ["alert", "announcement", "bulb", "idea", "incandescent", "info", "information", "led", "light", "lightbulb", "tip"]
    },
		{
      value: "line_axis",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>line_axis</Icon> line_axis
      </div>),
      tags: ["axis", "dash", "horizontal", "line", "stroke", "vertical"]
    },
		{
      value: "line_style",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>line_style</Icon> line_style
      </div>),
      tags: ["dash", "dotted", "line", "rule", "spacing", "style"]
    },
		{
      value: "line_weight",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>line_weight</Icon> line_weight
      </div>),
      tags: ["height", "line", "size", "spacing", "style", "thickness", "weight"]
    },
		{
      value: "linear_scale",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>linear_scale</Icon> linear_scale
      </div>),
      tags: ["app", "application", "components", "design", "interface", "layout", "linear", "measure", "menu", "scale", "screen", "site", "slider", "ui", "ux", "web", "website", "window"]
    },
		{
      value: "link",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>link</Icon> link
      </div>),
      tags: ["chain", "clip", "connection", "link", "linked", "links", "multimedia", "url"]
    },
		{
      value: "link_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>link_off</Icon> link_off
      </div>),
      tags: ["attached", "chain", "clip", "connection", "disabled", "enabled", "link", "linked", "links", "multimedia", "off", "on", "slash", "url"]
    },
		{
      value: "linked_camera",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>linked_camera</Icon> linked_camera
      </div>),
      tags: ["camera", "connect", "connection", "lens", "linked", "network", "photo", "photography", "picture", "signal", "signals", "sync", "wireless"]
    },
		{
      value: "liquor",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>liquor</Icon> liquor
      </div>),
      tags: ["alcohol", "bar", "bottle", "club", "cocktail", "drink", "food", "liquor", "party", "store", "wine"]
    },
		{
      value: "list",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>list</Icon> list
      </div>),
      tags: ["file", "format", "index", "list", "menu", "options"]
    },
		{
      value: "list_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>list_alt</Icon> list_alt
      </div>),
      tags: ["alt", "box", "contained", "form", "format", "lines", "list", "order", "reorder", "stacked", "title"]
    },
		{
      value: "live_help",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>live_help</Icon> live_help
      </div>),
      tags: ["?", "assistance", "bubble", "chat", "comment", "communicate", "help", "info", "information", "live", "message", "punctuation", "question mark", "recent", "restore", "speech", "support", "symbol"]
    },
		{
      value: "live_tv",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>live_tv</Icon> live_tv
      </div>),
      tags: ["Android", "OS", "antennas hardware", "chrome", "desktop", "device", "iOS", "live", "mac", "monitor", "movie", "play", "stream", "television", "tv", "web", "window"]
    },
		{
      value: "living",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>living</Icon> living
      </div>),
      tags: ["chair", "comfort", "couch", "decoration", "furniture", "home", "house", "living", "lounging", "loveseat", "room", "seat", "seating", "sofa"]
    },
		{
      value: "local_activity",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_activity</Icon> local_activity
      </div>),
      tags: ["activity", "event", "event ticket", "local", "star", "things", "ticket"]
    },
		{
      value: "local_airport",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_airport</Icon> local_airport
      </div>),
      tags: ["air", "airplane", "airport", "flight", "plane", "transportation", "travel", "trip"]
    },
		{
      value: "local_atm",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_atm</Icon> local_atm
      </div>),
      tags: ["atm", "bill", "card", "cart", "cash", "coin", "commerce", "credit", "currency", "dollars", "local", "money", "online", "pay", "payment", "shopping", "symbol"]
    },
		{
      value: "local_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_bar</Icon> local_bar
      </div>),
      tags: ["alcohol", "bar", "bottle", "club", "cocktail", "drink", "food", "liquor", "local", "wine"]
    },
		{
      value: "local_cafe",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_cafe</Icon> local_cafe
      </div>),
      tags: ["bottle", "cafe", "coffee", "cup", "drink", "food", "restaurant", "tea"]
    },
		{
      value: "local_car_wash",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_car_wash</Icon> local_car_wash
      </div>),
      tags: ["automobile", "car", "cars", "local", "maps", "transportation", "travel", "vehicle", "wash"]
    },
		{
      value: "local_convenience_store",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_convenience_store</Icon> local_convenience_store
      </div>),
      tags: ["--", "24", "bill", "building", "business", "card", "cash", "coin", "commerce", "company", "convenience", "credit", "currency", "dollars", "local", "maps", "market", "money", "new", "online", "pay", "payment", "plus", "shop", "shopping", "store", "storefront", "symbol"]
    },
		{
      value: "local_dining",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_dining</Icon> local_dining
      </div>),
      tags: ["dining", "eat", "food", "fork", "knife", "local", "meal", "restaurant", "spoon"]
    },
		{
      value: "local_drink",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_drink</Icon> local_drink
      </div>),
      tags: ["cup", "drink", "drop", "droplet", "liquid", "local", "park", "water"]
    },
		{
      value: "local_fire_department",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_fire_department</Icon> local_fire_department
      </div>),
      tags: ["911", "climate", "department", "fire", "firefighter", "flame", "heat", "home", "hot", "nest", "thermostat"]
    },
		{
      value: "local_florist",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_florist</Icon> local_florist
      </div>),
      tags: ["florist", "flower", "local", "shop"]
    },
		{
      value: "local_gas_station",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_gas_station</Icon> local_gas_station
      </div>),
      tags: ["auto", "car", "gas", "local", "oil", "station", "vehicle"]
    },
		{
      value: "local_grocery_store",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_grocery_store</Icon> local_grocery_store
      </div>),
      tags: ["grocery", "market", "shop", "store"]
    },
		{
      value: "local_hospital",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_hospital</Icon> local_hospital
      </div>),
      tags: ["911", "aid", "cross", "emergency", "first", "hospital", "local", "medicine"]
    },
		{
      value: "local_hotel",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_hotel</Icon> local_hotel
      </div>),
      tags: ["body", "hotel", "human", "local", "people", "person", "sleep", "stay", "travel", "trip"]
    },
		{
      value: "local_laundry_service",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_laundry_service</Icon> local_laundry_service
      </div>),
      tags: ["cleaning", "clothing", "dry", "dryer", "hotel", "laundry", "local", "service", "washer"]
    },
		{
      value: "local_library",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_library</Icon> local_library
      </div>),
      tags: ["book", "community learning", "library", "local", "read"]
    },
		{
      value: "local_mall",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_mall</Icon> local_mall
      </div>),
      tags: ["bag", "bill", "building", "business", "buy", "card", "cart", "cash", "coin", "commerce", "credit", "currency", "dollars", "handbag", "local", "mall", "money", "online", "pay", "payment", "shop", "shopping", "store", "storefront"]
    },
		{
      value: "local_movies",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_movies</Icon> local_movies
      </div>),
      tags: []
    },
		{
      value: "local_offer",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_offer</Icon> local_offer
      </div>),
      tags: ["deal", "discount", "offer", "price", "shop", "shopping", "store", "tag"]
    },
		{
      value: "local_parking",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_parking</Icon> local_parking
      </div>),
      tags: ["alphabet", "auto", "car", "character", "font", "garage", "letters", "local", "park", "parking", "symbol", "text", "type", "vehicle"]
    },
		{
      value: "local_pharmacy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_pharmacy</Icon> local_pharmacy
      </div>),
      tags: ["911", "aid", "cross", "emergency", "first", "hospital", "local", "medicine", "pharmacy", "places"]
    },
		{
      value: "local_phone",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_phone</Icon> local_phone
      </div>),
      tags: ["booth", "call", "communication", "phone", "telecommunication"]
    },
		{
      value: "local_pizza",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_pizza</Icon> local_pizza
      </div>),
      tags: ["drink", "fastfood", "food", "local", "meal", "pizza"]
    },
		{
      value: "local_play",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_play</Icon> local_play
      </div>),
      tags: []
    },
		{
      value: "local_police",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_police</Icon> local_police
      </div>),
      tags: ["911", "badge", "law", "local", "officer", "police", "protect", "protection", "security", "shield"]
    },
		{
      value: "local_post_office",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_post_office</Icon> local_post_office
      </div>),
      tags: ["delivery", "email", "envelop", "letters", "local", "mail", "message", "office", "package", "parcel", "post", "postal", "send", "stamp"]
    },
		{
      value: "local_printshop",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_printshop</Icon> local_printshop
      </div>),
      tags: ["draft", "fax", "ink", "local", "machine", "office", "paper", "print", "printer", "printshop", "send"]
    },
		{
      value: "local_see",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_see</Icon> local_see
      </div>),
      tags: ["camera", "lens", "local", "photo", "photography", "picture", "see"]
    },
		{
      value: "local_shipping",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_shipping</Icon> local_shipping
      </div>),
      tags: ["automobile", "car", "cars", "delivery", "letters", "local", "mail", "maps", "office", "package", "parcel", "post", "postal", "send", "shipping", "shopping", "stamp", "transportation", "truck", "vehicle"]
    },
		{
      value: "local_taxi",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>local_taxi</Icon> local_taxi
      </div>),
      tags: ["automobile", "cab", "call", "car", "cars", "direction", "local", "lyft", "maps", "public", "taxi", "transportation", "uber", "vehicle", "yellow"]
    },
		{
      value: "location_city",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>location_city</Icon> location_city
      </div>),
      tags: ["apartments", "architecture", "buildings", "business", "city", "estate", "home", "landscape", "location", "place", "real", "residence", "residential", "shelter", "town", "urban"]
    },
		{
      value: "location_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>location_disabled</Icon> location_disabled
      </div>),
      tags: ["destination", "direction", "disabled", "enabled", "location", "maps", "off", "on", "pin", "place", "pointer", "slash", "stop", "tracking"]
    },
		{
      value: "location_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>location_off</Icon> location_off
      </div>),
      tags: ["destination", "direction", "location", "maps", "off", "pin", "place", "stop"]
    },
		{
      value: "location_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>location_on</Icon> location_on
      </div>),
      tags: ["destination", "direction", "location", "maps", "on", "pin", "place", "stop"]
    },
		{
      value: "location_searching",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>location_searching</Icon> location_searching
      </div>),
      tags: ["destination", "direction", "location", "maps", "pin", "place", "pointer", "searching", "stop", "tracking"]
    },
		{
      value: "lock",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lock</Icon> lock
      </div>),
      tags: ["lock", "locked", "password", "privacy", "private", "protection", "safety", "secure", "security"]
    },
		{
      value: "lock_clock",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lock_clock</Icon> lock_clock
      </div>),
      tags: ["clock", "date", "lock", "locked", "password", "privacy", "private", "protection", "safety", "schedule", "secure", "security", "time"]
    },
		{
      value: "lock_open",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lock_open</Icon> lock_open
      </div>),
      tags: ["lock", "open", "password", "privacy", "private", "protection", "safety", "secure", "security", "unlocked"]
    },
		{
      value: "lock_person",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lock_person</Icon> lock_person
      </div>),
      tags: []
    },
		{
      value: "lock_reset",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lock_reset</Icon> lock_reset
      </div>),
      tags: ["around", "inprogress", "load", "loading refresh", "lock", "locked", "password", "privacy", "private", "protection", "renew", "rotate", "safety", "secure", "security", "turn"]
    },
		{
      value: "login",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>login</Icon> login
      </div>),
      tags: ["access", "app", "application", "arrow", "components", "design", "enter", "in", "interface", "left", "log", "login", "screen", "sign", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "logo_dev",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>logo_dev</Icon> logo_dev
      </div>),
      tags: ["dev", "dev.to", "logo"]
    },
		{
      value: "logout",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>logout</Icon> logout
      </div>),
      tags: ["app", "application", "arrow", "components", "design", "exit", "interface", "leave", "log", "login", "logout", "right", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "looks",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>looks</Icon> looks
      </div>),
      tags: ["circle", "half", "looks", "rainbow"]
    },
		{
      value: "looks_3",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>looks_3</Icon> looks_3
      </div>),
      tags: ["3", "digit", "looks", "numbers", "square", "symbol"]
    },
		{
      value: "looks_4",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>looks_4</Icon> looks_4
      </div>),
      tags: ["4", "digit", "looks", "numbers", "square", "symbol"]
    },
		{
      value: "looks_5",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>looks_5</Icon> looks_5
      </div>),
      tags: ["5", "digit", "looks", "numbers", "square", "symbol"]
    },
		{
      value: "looks_6",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>looks_6</Icon> looks_6
      </div>),
      tags: ["6", "digit", "looks", "numbers", "square", "symbol"]
    },
		{
      value: "looks_one",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>looks_one</Icon> looks_one
      </div>),
      tags: ["1", "digit", "looks", "numbers", "square", "symbol"]
    },
		{
      value: "looks_two",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>looks_two</Icon> looks_two
      </div>),
      tags: ["2", "digit", "looks", "numbers", "square", "symbol"]
    },
		{
      value: "loop",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>loop</Icon> loop
      </div>),
      tags: ["around", "arrow", "arrows", "direction", "inprogress", "load", "loading refresh", "loop", "music", "navigation", "renew", "rotate", "turn"]
    },
		{
      value: "loupe",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>loupe</Icon> loupe
      </div>),
      tags: ["+", "add", "details", "focus", "glass", "loupe", "magnifying", "new", "plus", "symbol"]
    },
		{
      value: "low_priority",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>low_priority</Icon> low_priority
      </div>),
      tags: ["arrange", "arrow", "backward", "bottom", "list", "low", "move", "order", "priority"]
    },
		{
      value: "loyalty",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>loyalty</Icon> loyalty
      </div>),
      tags: ["benefits", "card", "credit", "heart", "loyalty", "membership", "miles", "points", "program", "subscription", "tag", "travel", "trip"]
    },
		{
      value: "lte_mobiledata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lte_mobiledata</Icon> lte_mobiledata
      </div>),
      tags: ["alphabet", "character", "data", "font", "internet", "letters", "lte", "mobile", "network", "speed", "symbol", "text", "type", "wifi", "wireless"]
    },
		{
      value: "lte_plus_mobiledata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lte_plus_mobiledata</Icon> lte_plus_mobiledata
      </div>),
      tags: ["+", "alphabet", "character", "data", "font", "internet", "letters", "lte", "mobile", "network", "plus", "speed", "symbol", "text", "type", "wifi", "wireless"]
    },
		{
      value: "luggage",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>luggage</Icon> luggage
      </div>),
      tags: ["airport", "bag", "baggage", "carry", "flight", "hotel", "luggage", "on", "suitcase", "travel", "trip"]
    },
		{
      value: "lunch_dining",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lunch_dining</Icon> lunch_dining
      </div>),
      tags: ["breakfast", "dining", "dinner", "drink", "fastfood", "food", "hamburger", "lunch", "meal"]
    },
		{
      value: "lyrics",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>lyrics</Icon> lyrics
      </div>),
      tags: ["audio", "bubble", "chat", "comment", "communicate", "feedback", "key", "lyrics", "message", "music", "note", "song", "sound", "speech", "track"]
    },
		{
      value: "macro_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>macro_off</Icon> macro_off
      </div>),
      tags: ["camera", "disabled", "enabled", "flower", "garden", "image", "macro", "off", "offline", "on", "slash"]
    },
		{
      value: "mail",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mail</Icon> mail
      </div>),
      tags: ["email", "envelop", "letters", "mail", "message", "send"]
    },
		{
      value: "mail_lock",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mail_lock</Icon> mail_lock
      </div>),
      tags: ["email", "envelop", "letters", "lock", "locked", "mail", "message", "password", "privacy", "private", "protection", "safety", "secure", "security", "send"]
    },
		{
      value: "mail_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mail_outline</Icon> mail_outline
      </div>),
      tags: ["email", "envelop", "letters", "mail", "message", "outline", "send"]
    },
		{
      value: "male",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>male</Icon> male
      </div>),
      tags: ["boy", "gender", "male", "man", "social", "symbol"]
    },
		{
      value: "man",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>man</Icon> man
      </div>),
      tags: ["boy", "gender", "male", "man", "social", "symbol"]
    },
		{
      value: "man_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>man_2</Icon> man_2
      </div>),
      tags: ["boy", "gender", "male", "man", "social", "symbol"]
    },
		{
      value: "man_3",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>man_3</Icon> man_3
      </div>),
      tags: ["abstract", "boy", "gender", "male", "man", "social", "symbol"]
    },
		{
      value: "man_4",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>man_4</Icon> man_4
      </div>),
      tags: ["abstract", "boy", "gender", "male", "man", "social", "symbol"]
    },
		{
      value: "manage_accounts",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>manage_accounts</Icon> manage_accounts
      </div>),
      tags: ["accounts", "change", "details", "face", "gear", "human", "manage", "options", "people", "person", "profile", "service", "settings", "user"]
    },
		{
      value: "manage_history",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>manage_history</Icon> manage_history
      </div>),
      tags: ["application", "arrow", "back", "backwards", "change", "clock", "date", "details", "gear", "history", "options", "refresh", "renew", "reverse", "rotate", "schedule", "settings", "time", "turn"]
    },
		{
      value: "manage_search",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>manage_search</Icon> manage_search
      </div>),
      tags: ["glass", "history", "magnifying", "manage", "search", "text"]
    },
		{
      value: "map",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>map</Icon> map
      </div>),
      tags: ["destination", "direction", "location", "map", "maps", "pin", "place", "route", "stop", "travel"]
    },
		{
      value: "maps_home_work",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>maps_home_work</Icon> maps_home_work
      </div>),
      tags: ["building", "home", "house", "maps", "office", "work"]
    },
		{
      value: "maps_ugc",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>maps_ugc</Icon> maps_ugc
      </div>),
      tags: ["+", "add", "bubble", "comment", "communicate", "feedback", "maps", "message", "new", "plus", "speech", "symbol", "ugc"]
    },
		{
      value: "margin",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>margin</Icon> margin
      </div>),
      tags: ["design", "dots", "layout", "margin", "padding", "size", "square"]
    },
		{
      value: "mark_as_unread",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mark_as_unread</Icon> mark_as_unread
      </div>),
      tags: ["as", "envelop", "letters", "mail", "mark", "post", "postal", "read", "receive", "send", "unread"]
    },
		{
      value: "mark_chat_read",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mark_chat_read</Icon> mark_chat_read
      </div>),
      tags: ["approve", "bubble", "chat", "check", "comment", "communicate", "complete", "done", "mark", "message", "ok", "read", "select", "sent", "speech", "tick", "verified", "yes"]
    },
		{
      value: "mark_chat_unread",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mark_chat_unread</Icon> mark_chat_unread
      </div>),
      tags: ["alarm", "alert", "bubble", "chat", "circle", "comment", "communicate", "dot", "mark", "message", "notification", "notifications", "notify", "reminder", "speech", "unread"]
    },
		{
      value: "mark_email_read",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mark_email_read</Icon> mark_email_read
      </div>),
      tags: ["approve", "check", "complete", "done", "email", "envelop", "letters", "mail", "mark", "message", "note", "ok", "read", "select", "send", "sent", "tick", "yes"]
    },
		{
      value: "mark_email_unread",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mark_email_unread</Icon> mark_email_unread
      </div>),
      tags: ["check", "circle", "email", "envelop", "letters", "mail", "mark", "message", "note", "notification", "send", "unread"]
    },
		{
      value: "mark_unread_chat_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mark_unread_chat_alt</Icon> mark_unread_chat_alt
      </div>),
      tags: ["alarm", "alert", "bubble", "chat", "circle", "comment", "communicate", "dot", "mark", "message", "notification", "notifications", "notify", "reminder", "speech", "unread"]
    },
		{
      value: "markunread",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>markunread</Icon> markunread
      </div>),
      tags: ["email", "envelop", "letters", "mail", "markunread", "message", "send", "unread"]
    },
		{
      value: "markunread_mailbox",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>markunread_mailbox</Icon> markunread_mailbox
      </div>),
      tags: ["deliver", "envelop", "letters", "mail", "mailbox", "markunread", "post", "postal", "postbox", "receive", "send", "unread"]
    },
		{
      value: "masks",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>masks</Icon> masks
      </div>),
      tags: ["air", "cover", "covid", "face", "hospital", "masks", "medical", "pollution", "protection", "respirator", "sick", "social"]
    },
		{
      value: "maximize",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>maximize</Icon> maximize
      </div>),
      tags: ["app", "application", "components", "design", "interface", "line", "maximize", "screen", "shape", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "media_bluetooth_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>media_bluetooth_off</Icon> media_bluetooth_off
      </div>),
      tags: ["bluetooth", "connect", "connection", "connectivity", "device", "disabled", "enabled", "media", "music", "note", "off", "offline", "on", "paring", "signal", "slash", "symbol", "wireless"]
    },
		{
      value: "media_bluetooth_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>media_bluetooth_on</Icon> media_bluetooth_on
      </div>),
      tags: ["bluetooth", "connect", "connection", "connectivity", "device", "disabled", "enabled", "media", "music", "note", "off", "on", "online", "paring", "signal", "slash", "symbol", "wireless"]
    },
		{
      value: "mediation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mediation</Icon> mediation
      </div>),
      tags: ["arrow", "arrows", "direction", "dots", "mediation", "right"]
    },
		{
      value: "medical_information",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>medical_information</Icon> medical_information
      </div>),
      tags: ["badge", "card", "health", "id", "id card", "identification", "information", "medical", "services"]
    },
		{
      value: "medical_services",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>medical_services</Icon> medical_services
      </div>),
      tags: ["aid", "bag", "briefcase", "emergency", "first", "kit", "medical", "medicine", "services"]
    },
		{
      value: "medication",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>medication</Icon> medication
      </div>),
      tags: ["doctor", "drug", "emergency", "hospital", "medication", "medicine", "pharmacy", "pills", "prescription"]
    },
		{
      value: "medication_liquid",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>medication_liquid</Icon> medication_liquid
      </div>),
      tags: ["+", "bottle", "doctor", "drug", "health", "hospital", "liquid", "medications", "medicine", "pharmacy", "spoon", "vessel"]
    },
		{
      value: "meeting_room",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>meeting_room</Icon> meeting_room
      </div>),
      tags: ["building", "door", "doorway", "entrance", "home", "house", "interior", "meeting", "office", "open", "places", "room"]
    },
		{
      value: "memory",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>memory</Icon> memory
      </div>),
      tags: ["card", "chip", "digital", "memory", "micro", "processor", "sd", "storage"]
    },
		{
      value: "menu",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>menu</Icon> menu
      </div>),
      tags: ["app", "application", "components", "hamburger", "interface", "line", "lines", "menu", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "menu_book",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>menu_book</Icon> menu_book
      </div>),
      tags: ["book", "dining", "food", "meal", "menu", "restaurant"]
    },
		{
      value: "menu_open",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>menu_open</Icon> menu_open
      </div>),
      tags: ["app", "application", "arrow", "components", "hamburger", "interface", "left", "line", "lines", "menu", "open", "screen", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "merge",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>merge</Icon> merge
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "maps", "merge", "navigation", "path", "route", "sign", "traffic"]
    },
		{
      value: "merge_type",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>merge_type</Icon> merge_type
      </div>),
      tags: ["arrow", "combine", "direction", "format", "merge", "text", "type"]
    },
		{
      value: "message",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>message</Icon> message
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "feedback", "message", "speech"]
    },
		{
      value: "mic",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mic</Icon> mic
      </div>),
      tags: ["dictation", "hear", "hearing", "keyboard", "mic", "microphone", "noise", "record", "recorder", "sound", "speaker", "voice"]
    },
		{
      value: "mic_external_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mic_external_off</Icon> mic_external_off
      </div>),
      tags: ["audio", "disabled", "enabled", "external", "mic", "microphone", "off", "on", "slash", "sound", "voice"]
    },
		{
      value: "mic_external_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mic_external_on</Icon> mic_external_on
      </div>),
      tags: ["audio", "disabled", "enabled", "external", "mic", "microphone", "off", "on", "slash", "sound", "voice"]
    },
		{
      value: "mic_none",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mic_none</Icon> mic_none
      </div>),
      tags: ["dictation", "hear", "hearing", "keyboard", "mic", "microphone", "noise", "record", "recorder", "sound", "speaker", "voice"]
    },
		{
      value: "mic_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mic_off</Icon> mic_off
      </div>),
      tags: ["audio", "disabled", "enabled", "hear", "hearing", "mic", "microphone", "mute", "noise", "off", "on", "record", "recording", "slash", "sound", "voice"]
    },
		{
      value: "microwave",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>microwave</Icon> microwave
      </div>),
      tags: ["appliance", "cook", "cooking", "electric", "heat", "home", "house", "kitchen", "machine", "microwave"]
    },
		{
      value: "military_tech",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>military_tech</Icon> military_tech
      </div>),
      tags: ["army", "award", "badge", "honor", "medal", "merit", "military", "order", "privilege", "prize", "rank", "reward", "ribbon", "soldier", "star", "status", "tech", "trophy", "win", "winner"]
    },
		{
      value: "minimize",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>minimize</Icon> minimize
      </div>),
      tags: ["app", "application", "components", "design", "interface", "line", "minimize", "screen", "shape", "site", "ui", "ux", "web", "website"]
    },
		{
      value: "minor_crash",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>minor_crash</Icon> minor_crash
      </div>),
      tags: ["accident", "auto", "automobile", "car", "cars", "collision", "directions", "maps", "public", "transportation", "vehicle"]
    },
		{
      value: "miscellaneous_services",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>miscellaneous_services</Icon> miscellaneous_services
      </div>),
      tags: []
    },
		{
      value: "missed_video_call",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>missed_video_call</Icon> missed_video_call
      </div>),
      tags: ["arrow", "call", "camera", "film", "filming", "hardware", "image", "missed", "motion", "picture", "record", "video", "videography"]
    },
		{
      value: "mms",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mms</Icon> mms
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "feedback", "image", "landscape", "message", "mms", "mountain", "mountains", "multimedia", "photo", "photography", "picture", "speech"]
    },
		{
      value: "mobile_friendly",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mobile_friendly</Icon> mobile_friendly
      </div>),
      tags: ["Android", "OS", "approve", "cell", "check", "complete", "device", "done", "friendly", "hardware", "iOS", "mark", "mobile", "ok", "phone", "select", "tablet", "tick", "validate", "verified", "yes"]
    },
		{
      value: "mobile_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mobile_off</Icon> mobile_off
      </div>),
      tags: ["Android", "OS", "cell", "device", "disabled", "enabled", "hardware", "iOS", "mobile", "off", "on", "phone", "silence", "slash", "tablet"]
    },
		{
      value: "mobile_screen_share",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mobile_screen_share</Icon> mobile_screen_share
      </div>),
      tags: ["Android", "OS", "cast", "cell", "device", "hardware", "iOS", "mirror", "mobile", "monitor", "phone", "screen", "screencast", "share", "stream", "streaming", "tablet", "tv", "wireless"]
    },
		{
      value: "mobiledata_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mobiledata_off</Icon> mobiledata_off
      </div>),
      tags: ["arrow", "data", "disabled", "down", "enabled", "internet", "mobile", "network", "off", "on", "slash", "speed", "up", "wifi", "wireless"]
    },
		{
      value: "mode",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mode</Icon> mode
      </div>),
      tags: ["compose", "create", "draft", "draw", "edit", "mode", "pen", "pencil", "write"]
    },
		{
      value: "mode_comment",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mode_comment</Icon> mode_comment
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "feedback", "message", "mode comment", "speech"]
    },
		{
      value: "mode_edit",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mode_edit</Icon> mode_edit
      </div>),
      tags: ["compose", "create", "draft", "draw", "edit", "mode", "pen", "pencil", "write"]
    },
		{
      value: "mode_edit_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mode_edit_outline</Icon> mode_edit_outline
      </div>),
      tags: ["compose", "create", "draft", "draw", "edit", "mode", "outline", "pen", "pencil", "write"]
    },
		{
      value: "mode_fan_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mode_fan_off</Icon> mode_fan_off
      </div>),
      tags: ["air conditioner", "cool", "disabled", "enabled", "fan", "nest", "off", "on", "slash"]
    },
		{
      value: "mode_night",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mode_night</Icon> mode_night
      </div>),
      tags: ["dark", "disturb", "lunar", "mode", "moon", "night", "sleep"]
    },
		{
      value: "mode_of_travel",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mode_of_travel</Icon> mode_of_travel
      </div>),
      tags: ["arrow", "destination", "direction", "location", "maps", "mode", "of", "pin", "place", "stop", "transportation", "travel", "trip"]
    },
		{
      value: "mode_standby",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mode_standby</Icon> mode_standby
      </div>),
      tags: ["disturb", "mode", "power", "sleep", "standby", "target"]
    },
		{
      value: "model_training",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>model_training</Icon> model_training
      </div>),
      tags: ["arrow", "bulb", "idea", "inprogress", "light", "load", "loading", "model", "refresh", "renew", "restore", "reverse", "rotate", "training"]
    },
		{
      value: "monetization_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>monetization_on</Icon> monetization_on
      </div>),
      tags: ["bill", "card", "cash", "circle", "coin", "commerce", "cost", "credit", "currency", "dollars", "finance", "monetization", "money", "on", "online", "pay", "payment", "shopping", "symbol"]
    },
		{
      value: "money",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>money</Icon> money
      </div>),
      tags: ["100", "bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "digit", "dollars", "finance", "money", "numbers", "online", "pay", "payment", "price", "shopping", "symbol"]
    },
		{
      value: "money_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>money_off</Icon> money_off
      </div>),
      tags: ["bill", "card", "cart", "cash", "coin", "commerce", "credit", "currency", "disabled", "dollars", "enabled", "money", "off", "on", "online", "pay", "payment", "shopping", "slash", "symbol"]
    },
		{
      value: "money_off_csred",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>money_off_csred</Icon> money_off_csred
      </div>),
      tags: ["bill", "card", "cart", "cash", "coin", "commerce", "credit", "csred", "currency", "disabled", "dollars", "enabled", "money", "off", "on", "online", "pay", "payment", "shopping", "slash", "symbol"]
    },
		{
      value: "monitor",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>monitor</Icon> monitor
      </div>),
      tags: ["Android", "OS", "chrome", "device", "display", "hardware", "iOS", "mac", "monitor", "screen", "web", "window"]
    },
		{
      value: "monitor_heart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>monitor_heart</Icon> monitor_heart
      </div>),
      tags: ["baseline", "device", "ecc", "ecg", "fitness", "health", "heart", "medical", "monitor", "track"]
    },
		{
      value: "monitor_weight",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>monitor_weight</Icon> monitor_weight
      </div>),
      tags: ["body", "device", "diet", "health", "monitor", "scale", "smart", "weight"]
    },
		{
      value: "monochrome_photos",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>monochrome_photos</Icon> monochrome_photos
      </div>),
      tags: ["black", "camera", "image", "monochrome", "photo", "photography", "photos", "picture", "white"]
    },
		{
      value: "mood",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mood</Icon> mood
      </div>),
      tags: ["emoji", "emotions", "expressions", "face", "feelings", "glad", "happiness", "happy", "like", "mood", "person", "pleased", "smile", "smiling", "social", "survey"]
    },
		{
      value: "mood_bad",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mood_bad</Icon> mood_bad
      </div>),
      tags: ["bad", "disappointment", "dislike", "emoji", "emotions", "expressions", "face", "feelings", "mood", "person", "rating", "social", "survey", "unhappiness", "unhappy", "unpleased", "unsmile", "unsmiling"]
    },
		{
      value: "moped",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>moped</Icon> moped
      </div>),
      tags: ["automobile", "bike", "car", "cars", "maps", "scooter", "transportation", "vehicle", "vespa"]
    },
		{
      value: "more",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>more</Icon> more
      </div>),
      tags: ["3", "archive", "bookmark", "dots", "etc", "favorite", "indent", "label", "more", "remember", "save", "stamp", "sticker", "tab", "tag", "three"]
    },
		{
      value: "more_horiz",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>more_horiz</Icon> more_horiz
      </div>),
      tags: ["3", "DISABLE_IOS", "app", "application", "components", "disable_ios", "dots", "etc", "horiz", "horizontal", "interface", "ios", "more", "screen", "site", "three", "ui", "ux", "web", "website"]
    },
		{
      value: "more_time",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>more_time</Icon> more_time
      </div>),
      tags: ["+", "add", "clock", "date", "more", "new", "plus", "schedule", "symbol", "time"]
    },
		{
      value: "more_vert",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>more_vert</Icon> more_vert
      </div>),
      tags: ["3", "DISABLE_IOS", "android", "app", "application", "components", "disable_ios", "dots", "etc", "interface", "more", "screen", "site", "three", "ui", "ux", "vert", "vertical", "web", "website"]
    },
		{
      value: "mosque",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mosque</Icon> mosque
      </div>),
      tags: ["ideology", "islam", "islamic", "masjid", "muslim", "religion", "spiritual", "worship"]
    },
		{
      value: "motion_photos_auto",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>motion_photos_auto</Icon> motion_photos_auto
      </div>),
      tags: ["A", "alphabet", "animation", "auto", "automatic", "character", "circle", "font", "gif", "letters", "live", "motion", "photos", "symbol", "text", "type", "video"]
    },
		{
      value: "motion_photos_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>motion_photos_off</Icon> motion_photos_off
      </div>),
      tags: ["animation", "circle", "disabled", "enabled", "motion", "off", "on", "photos", "slash", "video"]
    },
		{
      value: "motion_photos_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>motion_photos_on</Icon> motion_photos_on
      </div>),
      tags: ["animation", "circle", "disabled", "enabled", "motion", "off", "on", "photos", "play", "slash", "video"]
    },
		{
      value: "motion_photos_pause",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>motion_photos_pause</Icon> motion_photos_pause
      </div>),
      tags: ["animation", "circle", "motion", "pause", "paused", "photos", "video"]
    },
		{
      value: "motion_photos_paused",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>motion_photos_paused</Icon> motion_photos_paused
      </div>),
      tags: ["animation", "circle", "motion", "pause", "paused", "photos", "video"]
    },
		{
      value: "mouse",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mouse</Icon> mouse
      </div>),
      tags: ["click", "computer", "cursor", "device", "hardware", "mouse", "wireless"]
    },
		{
      value: "move_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>move_down</Icon> move_down
      </div>),
      tags: ["arrow", "direction", "down", "jump", "move", "navigation", "transfer"]
    },
		{
      value: "move_to_inbox",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>move_to_inbox</Icon> move_to_inbox
      </div>),
      tags: ["archive", "arrow", "down", "email", "envelop", "inbox", "incoming", "letters", "mail", "message", "move to", "send"]
    },
		{
      value: "move_up",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>move_up</Icon> move_up
      </div>),
      tags: ["arrow", "direction", "jump", "move", "navigation", "transfer", "up"]
    },
		{
      value: "movie",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>movie</Icon> movie
      </div>),
      tags: ["cinema", "film", "media", "movie", "slate", "video"]
    },
		{
      value: "movie_creation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>movie_creation</Icon> movie_creation
      </div>),
      tags: ["cinema", "clapperboard", "creation", "film", "movie", "movies", "slate", "video"]
    },
		{
      value: "movie_filter",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>movie_filter</Icon> movie_filter
      </div>),
      tags: ["ai", "artificial", "automatic", "automation", "clapperboard", "creation", "custom", "film", "filter", "genai", "intelligence", "magic", "movie", "movies", "slate", "smart", "spark", "sparkle", "star", "stars", "video"]
    },
		{
      value: "moving",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>moving</Icon> moving
      </div>),
      tags: ["arrow", "direction", "moving", "navigation", "travel", "up"]
    },
		{
      value: "mp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>mp</Icon> mp
      </div>),
      tags: ["alphabet", "character", "font", "image", "letters", "megapixel", "mp", "photo", "photography", "pixels", "quality", "resolution", "symbol", "text", "type"]
    },
		{
      value: "multiline_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>multiline_chart</Icon> multiline_chart
      </div>),
      tags: ["analytics", "bar", "bars", "chart", "data", "diagram", "graph", "infographic", "line", "measure", "metrics", "multiple", "statistics", "tracking"]
    },
		{
      value: "multiple_stop",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>multiple_stop</Icon> multiple_stop
      </div>),
      tags: ["arrows", "dash", "dashed", "directions", "dots", "left", "maps", "multiple", "navigation", "right", "stop"]
    },
		{
      value: "museum",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>museum</Icon> museum
      </div>),
      tags: ["architecture", "attraction", "building", "estate", "event", "exhibition", "explore", "local", "museum", "places", "real", "see", "shop", "store", "tour"]
    },
		{
      value: "music_note",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>music_note</Icon> music_note
      </div>),
      tags: ["audio", "audiotrack", "key", "music", "note", "sound", "track"]
    },
		{
      value: "music_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>music_off</Icon> music_off
      </div>),
      tags: ["audio", "audiotrack", "disabled", "enabled", "key", "music", "mute", "note", "off", "on", "slash", "sound", "track"]
    },
		{
      value: "music_video",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>music_video</Icon> music_video
      </div>),
      tags: ["band", "music", "recording", "screen", "tv", "video", "watch"]
    },
		{
      value: "my_location",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>my_location</Icon> my_location
      </div>),
      tags: ["destination", "direction", "location", "maps", "navigation", "pin", "place", "point", "stop"]
    },
		{
      value: "nat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nat</Icon> nat
      </div>),
      tags: ["communication", "nat"]
    },
		{
      value: "nature",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nature</Icon> nature
      </div>),
      tags: ["forest", "nature", "outdoor", "outside", "park", "tree", "wilderness"]
    },
		{
      value: "nature_people",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nature_people</Icon> nature_people
      </div>),
      tags: ["activity", "body", "forest", "human", "landscape", "nature", "outdoor", "outside", "park", "people", "person", "tree", "wilderness"]
    },
		{
      value: "navigate_before",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>navigate_before</Icon> navigate_before
      </div>),
      tags: ["arrow", "arrows", "before", "direction", "left", "navigate"]
    },
		{
      value: "navigate_next",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>navigate_next</Icon> navigate_next
      </div>),
      tags: ["arrow", "arrows", "direction", "navigate", "next", "right"]
    },
		{
      value: "navigation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>navigation</Icon> navigation
      </div>),
      tags: ["destination", "direction", "location", "maps", "navigation", "pin", "place", "point", "stop"]
    },
		{
      value: "near_me",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>near_me</Icon> near_me
      </div>),
      tags: ["destination", "direction", "location", "maps", "me", "navigation", "near", "pin", "place", "point", "stop"]
    },
		{
      value: "near_me_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>near_me_disabled</Icon> near_me_disabled
      </div>),
      tags: ["destination", "direction", "disabled", "enabled", "location", "maps", "me", "navigation", "near", "off", "on", "pin", "place", "point", "slash"]
    },
		{
      value: "nearby_error",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nearby_error</Icon> nearby_error
      </div>),
      tags: ["!", "alert", "attention", "caution", "danger", "error", "exclamation", "important", "mark", "nearby", "notification", "symbol", "warning"]
    },
		{
      value: "nearby_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nearby_off</Icon> nearby_off
      </div>),
      tags: ["disabled", "enabled", "nearby", "off", "on", "slash"]
    },
		{
      value: "nest_cam_wired_stand",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nest_cam_wired_stand</Icon> nest_cam_wired_stand
      </div>),
      tags: ["camera", "film", "filming", "hardware", "image", "motion", "nest", "picture", "stand", "video", "videography", "wired"]
    },
		{
      value: "network_cell",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>network_cell</Icon> network_cell
      </div>),
      tags: ["cell", "cellular", "data", "internet", "mobile", "network", "phone", "speed", "wifi", "wireless"]
    },
		{
      value: "network_check",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>network_check</Icon> network_check
      </div>),
      tags: ["check", "connect", "connection", "internet", "meter", "network", "signal", "speed", "tick", "wifi", "wireless"]
    },
		{
      value: "network_locked",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>network_locked</Icon> network_locked
      </div>),
      tags: ["alert", "available", "cellular", "connection", "data", "error", "internet", "lock", "locked", "mobile", "network", "not", "privacy", "private", "protection", "restricted", "safety", "secure", "security", "service", "signal", "warning", "wifi", "wireless"]
    },
		{
      value: "network_ping",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>network_ping</Icon> network_ping
      </div>),
      tags: ["alert", "available", "cellular", "connection", "data", "internet", "ip", "mobile", "network", "ping", "service", "signal", "wifi", "wireless"]
    },
		{
      value: "network_wifi",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>network_wifi</Icon> network_wifi
      </div>),
      tags: ["cell", "cellular", "data", "internet", "mobile", "network", "phone", "speed", "wifi", "wireless"]
    },
		{
      value: "network_wifi_1_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>network_wifi_1_bar</Icon> network_wifi_1_bar
      </div>),
      tags: ["cell", "cellular", "data", "internet", "mobile", "network", "phone", "speed", "wifi", "wireless"]
    },
		{
      value: "network_wifi_2_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>network_wifi_2_bar</Icon> network_wifi_2_bar
      </div>),
      tags: ["cell", "cellular", "data", "internet", "mobile", "network", "phone", "speed", "wifi", "wireless"]
    },
		{
      value: "network_wifi_3_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>network_wifi_3_bar</Icon> network_wifi_3_bar
      </div>),
      tags: ["cell", "cellular", "data", "internet", "mobile", "network", "phone", "speed", "wifi", "wireless"]
    },
		{
      value: "new_label",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>new_label</Icon> new_label
      </div>),
      tags: ["+", "add", "archive", "bookmark", "favorite", "label", "library", "new", "plus", "read", "reading", "remember", "ribbon", "save", "symbol", "tag"]
    },
		{
      value: "new_releases",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>new_releases</Icon> new_releases
      </div>),
      tags: ["approve", "award", "check", "checkmark", "complete", "done", "new", "notification", "ok", "release", "releases", "select", "star", "symbol", "tick", "verification", "verified", "warning", "yes"]
    },
		{
      value: "newspaper",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>newspaper</Icon> newspaper
      </div>),
      tags: ["article", "data", "doc", "document", "drive", "file", "folder", "folders", "magazine", "media", "news", "newspaper", "notes", "page", "paper", "sheet", "slide", "text", "writing"]
    },
		{
      value: "next_plan",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>next_plan</Icon> next_plan
      </div>),
      tags: ["arrow", "circle", "next", "plan", "right"]
    },
		{
      value: "next_week",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>next_week</Icon> next_week
      </div>),
      tags: ["arrow", "bag", "baggage", "briefcase", "business", "case", "next", "suitcase", "week"]
    },
		{
      value: "nfc",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nfc</Icon> nfc
      </div>),
      tags: ["communication", "data", "field", "mobile", "near", "nfc", "wireless"]
    },
		{
      value: "night_shelter",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>night_shelter</Icon> night_shelter
      </div>),
      tags: ["architecture", "bed", "building", "estate", "homeless", "house", "night", "place", "real", "shelter", "sleep"]
    },
		{
      value: "nightlife",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nightlife</Icon> nightlife
      </div>),
      tags: ["alcohol", "bar", "bottle", "club", "cocktail", "dance", "drink", "food", "glass", "liquor", "music", "nightlife", "note", "wine"]
    },
		{
      value: "nightlight",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nightlight</Icon> nightlight
      </div>),
      tags: ["dark", "disturb", "mode", "moon", "night", "nightlight", "sleep"]
    },
		{
      value: "nightlight_round",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nightlight_round</Icon> nightlight_round
      </div>),
      tags: ["dark", "half", "light", "mode", "moon", "night", "nightlight", "round"]
    },
		{
      value: "nights_stay",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nights_stay</Icon> nights_stay
      </div>),
      tags: ["climate", "cloud", "crescent", "dark", "lunar", "mode", "moon", "nights", "phases", "silence", "silent", "sky", "stay", "time", "weather"]
    },
		{
      value: "no_accounts",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_accounts</Icon> no_accounts
      </div>),
      tags: ["account", "accounts", "avatar", "disabled", "enabled", "face", "human", "no", "off", "offline", "on", "people", "person", "profile", "slash", "thumbnail", "unavailable", "unidentifiable", "unknown", "user"]
    },
		{
      value: "no_adult_content",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_adult_content</Icon> no_adult_content
      </div>),
      tags: []
    },
		{
      value: "no_backpack",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_backpack</Icon> no_backpack
      </div>),
      tags: ["accessory", "backpack", "bag", "bookbag", "knapsack", "no", "pack", "travel"]
    },
		{
      value: "no_cell",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_cell</Icon> no_cell
      </div>),
      tags: ["Android", "OS", "cell", "device", "disabled", "enabled", "hardware", "iOS", "mobile", "no", "off", "on", "phone", "slash", "tablet"]
    },
		{
      value: "no_crash",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_crash</Icon> no_crash
      </div>),
      tags: ["accident", "auto", "automobile", "car", "cars", "check", "collision", "confirm", "correct", "crash", "direction", "done", "enter", "maps", "mark", "no", "ok", "okay", "select", "tick", "transportation", "vehicle", "yes"]
    },
		{
      value: "no_drinks",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_drinks</Icon> no_drinks
      </div>),
      tags: ["alcohol", "beverage", "bottle", "cocktail", "drink", "drinks", "food", "liquor", "no", "wine"]
    },
		{
      value: "no_encryption",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_encryption</Icon> no_encryption
      </div>),
      tags: ["disabled", "enabled", "encryption", "lock", "no", "off", "on", "password", "safety", "security", "slash"]
    },
		{
      value: "no_encryption_gmailerrorred",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_encryption_gmailerrorred</Icon> no_encryption_gmailerrorred
      </div>),
      tags: ["disabled", "enabled", "encryption", "error", "gmail", "lock", "locked", "no", "off", "on", "slash"]
    },
		{
      value: "no_flash",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_flash</Icon> no_flash
      </div>),
      tags: ["bolt", "camera", "disabled", "electric", "enabled", "energy", "flash", "image", "instant", "lightning", "no", "off", "on", "photo", "photography", "picture", "slash", "thunderbolt"]
    },
		{
      value: "no_food",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_food</Icon> no_food
      </div>),
      tags: ["disabled", "drink", "enabled", "fastfood", "food", "hamburger", "meal", "no", "off", "on", "slash"]
    },
		{
      value: "no_luggage",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_luggage</Icon> no_luggage
      </div>),
      tags: ["bag", "baggage", "carry", "disabled", "enabled", "luggage", "no", "off", "on", "slash", "suitcase", "travel"]
    },
		{
      value: "no_meals",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_meals</Icon> no_meals
      </div>),
      tags: ["dining", "disabled", "eat", "enabled", "food", "fork", "knife", "meal", "meals", "no", "off", "on", "restaurant", "slash", "spoon", "utensils"]
    },
		{
      value: "no_meeting_room",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_meeting_room</Icon> no_meeting_room
      </div>),
      tags: ["building", "disabled", "door", "doorway", "enabled", "entrance", "home", "house", "interior", "meeting", "no", "off", "office", "on", "open", "places", "room", "slash"]
    },
		{
      value: "no_photography",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_photography</Icon> no_photography
      </div>),
      tags: ["camera", "disabled", "enabled", "image", "no", "off", "on", "photo", "photography", "picture", "slash"]
    },
		{
      value: "no_sim",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_sim</Icon> no_sim
      </div>),
      tags: ["camera", "card", "device", "eject", "insert", "memory", "no", "phone", "sim", "storage"]
    },
		{
      value: "no_stroller",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_stroller</Icon> no_stroller
      </div>),
      tags: ["baby", "care", "carriage", "child", "children", "disabled", "enabled", "infant", "kid", "newborn", "no", "off", "on", "parents", "slash", "stroller", "toddler", "young"]
    },
		{
      value: "no_transfer",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>no_transfer</Icon> no_transfer
      </div>),
      tags: ["automobile", "bus", "car", "cars", "direction", "disabled", "enabled", "maps", "no", "off", "on", "public", "slash", "transfer", "transportation", "vehicle"]
    },
		{
      value: "noise_aware",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>noise_aware</Icon> noise_aware
      </div>),
      tags: ["audio", "aware", "cancellation", "music", "noise", "note", "sound"]
    },
		{
      value: "noise_control_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>noise_control_off</Icon> noise_control_off
      </div>),
      tags: ["audio", "aware", "cancel", "cancellation", "control", "disabled", "enabled", "music", "noise", "note", "off", "offline", "on", "slash", "sound"]
    },
		{
      value: "nordic_walking",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>nordic_walking</Icon> nordic_walking
      </div>),
      tags: ["athlete", "athletic", "body", "entertainment", "exercise", "hiking", "hobby", "human", "nordic", "people", "person", "social", "sports", "travel", "walker", "walking"]
    },
		{
      value: "north",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>north</Icon> north
      </div>),
      tags: ["arrow", "directional", "maps", "navigation", "north", "up"]
    },
		{
      value: "north_east",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>north_east</Icon> north_east
      </div>),
      tags: ["arrow", "east", "maps", "navigation", "noth", "right", "up"]
    },
		{
      value: "north_west",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>north_west</Icon> north_west
      </div>),
      tags: ["arrow", "directional", "left", "maps", "navigation", "north", "up", "west"]
    },
		{
      value: "not_accessible",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>not_accessible</Icon> not_accessible
      </div>),
      tags: ["accessibility", "accessible", "body", "handicap", "help", "human", "not", "person", "wheelchair"]
    },
		{
      value: "not_interested",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>not_interested</Icon> not_interested
      </div>),
      tags: ["cancel", "clear", "close", "dislike", "exit", "interested", "no", "not", "off", "quit", "remove", "stop", "x"]
    },
		{
      value: "not_listed_location",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>not_listed_location</Icon> not_listed_location
      </div>),
      tags: ["?", "assistance", "destination", "direction", "help", "info", "information", "listed", "location", "maps", "not", "pin", "place", "punctuation", "question mark", "stop", "support", "symbol"]
    },
		{
      value: "not_started",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>not_started</Icon> not_started
      </div>),
      tags: ["circle", "media", "not", "pause", "play", "started", "video"]
    },
		{
      value: "note",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>note</Icon> note
      </div>),
      tags: ["bookmark", "data", "doc", "document", "drive", "file", "folder", "folders", "message", "note", "page", "paper", "plus", "sheet", "slide", "symbol", "writing"]
    },
		{
      value: "note_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>note_add</Icon> note_add
      </div>),
      tags: ["+", "add", "data", "doc", "document", "drive", "file", "folder", "folders", "new", "note", "page", "paper", "plus", "sheet", "slide", "symbol", "writing"]
    },
		{
      value: "note_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>note_alt</Icon> note_alt
      </div>),
      tags: ["alt", "clipboard", "document", "file", "memo", "note", "page", "paper", "writing"]
    },
		{
      value: "notes",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>notes</Icon> notes
      </div>),
      tags: ["comment", "doc", "document", "note", "notes", "text", "write", "writing"]
    },
		{
      value: "notification_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>notification_add</Icon> notification_add
      </div>),
      tags: ["+", "active", "add", "alarm", "alert", "bell", "chime", "notification", "notifications", "notify", "plus", "reminder", "ring", "sound", "symbol"]
    },
		{
      value: "notification_important",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>notification_important</Icon> notification_important
      </div>),
      tags: ["!", "active", "alarm", "alert", "attention", "bell", "caution", "chime", "danger", "error", "exclamation", "important", "mark", "notification", "notifications", "notify", "reminder", "ring", "sound", "symbol", "warning"]
    },
		{
      value: "notifications",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>notifications</Icon> notifications
      </div>),
      tags: ["active", "alarm", "alert", "bell", "chime", "notifications", "notify", "reminder", "ring", "sound"]
    },
		{
      value: "notifications_active",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>notifications_active</Icon> notifications_active
      </div>),
      tags: ["active", "alarm", "alert", "bell", "chime", "notifications", "notify", "reminder", "ring", "ringing", "sound"]
    },
		{
      value: "notifications_none",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>notifications_none</Icon> notifications_none
      </div>),
      tags: ["alarm", "alert", "bell", "none", "notifications", "notify", "reminder", "sound"]
    },
		{
      value: "notifications_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>notifications_off</Icon> notifications_off
      </div>),
      tags: ["active", "alarm", "alert", "bell", "chime", "disabled", "enabled", "notifications", "notify", "off", "offline", "on", "reminder", "ring", "slash", "sound"]
    },
		{
      value: "notifications_paused",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>notifications_paused</Icon> notifications_paused
      </div>),
      tags: ["active", "alarm", "alert", "bell", "chime", "ignore", "notifications", "notify", "paused", "quiet", "reminder", "ring --- pause", "sleep", "snooze", "sound", "z", "zzz"]
    },
		{
      value: "numbers",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>numbers</Icon> numbers
      </div>),
      tags: ["digit", "numbers", "symbol"]
    },
		{
      value: "offline_bolt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>offline_bolt</Icon> offline_bolt
      </div>),
      tags: ["bolt", "circle", "electric", "energy", "fast", "instant", "lightning", "offline", "thunderbolt"]
    },
		{
      value: "offline_pin",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>offline_pin</Icon> offline_pin
      </div>),
      tags: ["approve", "check", "checkmark", "circle", "complete", "done", "mark", "offline", "ok", "pin", "select", "tick", "validate", "verified", "yes"]
    },
		{
      value: "offline_share",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>offline_share</Icon> offline_share
      </div>),
      tags: ["Android", "OS", "arrow", "cell", "connect", "device", "direction", "hardware", "iOS", "link", "mobile", "multiple", "offline", "phone", "right", "share", "tablet"]
    },
		{
      value: "oil_barrel",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>oil_barrel</Icon> oil_barrel
      </div>),
      tags: ["barrel", "droplet", "gas", "gasoline", "nest", "oil", "water"]
    },
		{
      value: "on_device_training",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>on_device_training</Icon> on_device_training
      </div>),
      tags: ["arrow", "bulb", "call", "cell", "contact", "device", "hardware", "idea", "inprogress", "light", "load", "loading", "mobile", "model", "phone", "refresh", "renew", "restore", "reverse", "rotate", "telephone", "training"]
    },
		{
      value: "ondemand_video",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ondemand_video</Icon> ondemand_video
      </div>),
      tags: ["Android", "OS", "chrome", "demand", "desktop", "device", "hardware", "iOS", "mac", "monitor", "ondemand", "play", "television", "tv", "video", "web", "window"]
    },
		{
      value: "online_prediction",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>online_prediction</Icon> online_prediction
      </div>),
      tags: ["bulb", "connection", "idea", "light", "network", "online", "prediction", "signal", "wireless"]
    },
		{
      value: "opacity",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>opacity</Icon> opacity
      </div>),
      tags: ["color", "drop", "droplet", "hue", "invert", "inverted", "opacity", "palette", "tone", "water"]
    },
		{
      value: "open_in_browser",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>open_in_browser</Icon> open_in_browser
      </div>),
      tags: ["arrow", "browser", "in", "open", "site", "up", "web", "website", "window"]
    },
		{
      value: "open_in_full",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>open_in_full</Icon> open_in_full
      </div>),
      tags: ["action", "arrow", "arrows", "expand", "full", "grow", "in", "move", "open"]
    },
		{
      value: "open_in_new",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>open_in_new</Icon> open_in_new
      </div>),
      tags: ["app", "application", "arrow", "box", "components", "in", "interface", "new", "open", "right", "screen", "site", "ui", "up", "ux", "web", "website", "window"]
    },
		{
      value: "open_in_new_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>open_in_new_off</Icon> open_in_new_off
      </div>),
      tags: ["arrow", "box", "disabled", "enabled", "export", "in", "new", "off", "on", "open", "slash", "window"]
    },
		{
      value: "open_with",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>open_with</Icon> open_with
      </div>),
      tags: ["arrow", "arrows", "direction", "expand", "move", "open", "pan", "with"]
    },
		{
      value: "other_houses",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>other_houses</Icon> other_houses
      </div>),
      tags: ["architecture", "cottage", "estate", "home", "house", "houses", "maps", "other", "place", "real", "residence", "residential", "stay", "traveling"]
    },
		{
      value: "outbound",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>outbound</Icon> outbound
      </div>),
      tags: ["arrow", "circle", "directional", "outbound", "right", "up"]
    },
		{
      value: "outbox",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>outbox</Icon> outbox
      </div>),
      tags: ["box", "mail", "outbox", "send", "sent"]
    },
		{
      value: "outdoor_grill",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>outdoor_grill</Icon> outdoor_grill
      </div>),
      tags: ["barbecue", "bbq", "charcoal", "cook", "cooking", "grill", "home", "house", "outdoor", "outside"]
    },
		{
      value: "outlet",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>outlet</Icon> outlet
      </div>),
      tags: ["connect", "connecter", "electricity", "outlet", "plug", "power"]
    },
		{
      value: "outlined_flag",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>outlined_flag</Icon> outlined_flag
      </div>),
      tags: ["country", "flag", "goal", "mark", "nation", "outlined", "report", "start"]
    },
		{
      value: "output",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>output</Icon> output
      </div>),
      tags: []
    },
		{
      value: "padding",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>padding</Icon> padding
      </div>),
      tags: ["design", "dots", "layout", "margin", "padding", "size", "square"]
    },
		{
      value: "pages",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pages</Icon> pages
      </div>),
      tags: ["article", "gplus", "pages", "paper", "post", "star"]
    },
		{
      value: "pageview",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pageview</Icon> pageview
      </div>),
      tags: ["doc", "document", "find", "glass", "magnifying", "page", "paper", "search", "view"]
    },
		{
      value: "paid",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>paid</Icon> paid
      </div>),
      tags: ["bill", "card", "cash", "circle", "coin", "commerce", "cost", "credit", "currency", "dollars", "finance", "monetization", "money", "on", "online", "pay", "payment", "shopping", "symbol"]
    },
		{
      value: "palette",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>palette</Icon> palette
      </div>),
      tags: ["art", "color", "colors", "filters", "paint", "palette"]
    },
		{
      value: "pan_tool",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pan_tool</Icon> pan_tool
      </div>),
      tags: ["fingers", "gesture", "hand", "hands", "human", "move", "pan", "scan", "stop", "tool"]
    },
		{
      value: "pan_tool_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pan_tool_alt</Icon> pan_tool_alt
      </div>),
      tags: ["fingers", "gesture", "hand", "hands", "human", "move", "pan", "scan", "stop", "tool"]
    },
		{
      value: "panorama",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>panorama</Icon> panorama
      </div>),
      tags: ["angle", "image", "mountain", "mountains", "panorama", "photo", "photography", "picture", "view", "wide"]
    },
		{
      value: "panorama_fish_eye",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>panorama_fish_eye</Icon> panorama_fish_eye
      </div>),
      tags: ["angle", "circle", "eye", "fish", "full", "geometry", "image", "lens", "moon", "panorama", "photo", "photography", "picture", "wide"]
    },
		{
      value: "panorama_horizontal",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>panorama_horizontal</Icon> panorama_horizontal
      </div>),
      tags: ["angle", "horizontal", "image", "panorama", "photo", "photography", "picture", "wide"]
    },
		{
      value: "panorama_horizontal_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>panorama_horizontal_select</Icon> panorama_horizontal_select
      </div>),
      tags: ["angle", "horizontal", "image", "panorama", "photo", "photography", "picture", "select", "wide"]
    },
		{
      value: "panorama_photosphere",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>panorama_photosphere</Icon> panorama_photosphere
      </div>),
      tags: ["angle", "horizontal", "image", "panorama", "photo", "photography", "photosphere", "picture", "wide"]
    },
		{
      value: "panorama_photosphere_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>panorama_photosphere_select</Icon> panorama_photosphere_select
      </div>),
      tags: ["angle", "horizontal", "image", "panorama", "photo", "photography", "photosphere", "picture", "select", "wide"]
    },
		{
      value: "panorama_vertical",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>panorama_vertical</Icon> panorama_vertical
      </div>),
      tags: ["angle", "image", "panorama", "photo", "photography", "picture", "vertical", "wide"]
    },
		{
      value: "panorama_vertical_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>panorama_vertical_select</Icon> panorama_vertical_select
      </div>),
      tags: ["angle", "image", "panorama", "photo", "photography", "picture", "select", "vertical", "wide"]
    },
		{
      value: "panorama_wide_angle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>panorama_wide_angle</Icon> panorama_wide_angle
      </div>),
      tags: ["angle", "image", "panorama", "photo", "photography", "picture", "wide"]
    },
		{
      value: "panorama_wide_angle_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>panorama_wide_angle_select</Icon> panorama_wide_angle_select
      </div>),
      tags: ["angle", "image", "panorama", "photo", "photography", "picture", "select", "wide"]
    },
		{
      value: "paragliding",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>paragliding</Icon> paragliding
      </div>),
      tags: ["athlete", "athletic", "body", "entertainment", "exercise", "fly", "gliding", "hobby", "human", "parachute", "paragliding", "people", "person", "sky", "skydiving", "social", "sports", "travel"]
    },
		{
      value: "park",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>park</Icon> park
      </div>),
      tags: ["attraction", "fresh", "local", "nature", "outside", "park", "plant", "tree"]
    },
		{
      value: "party_mode",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>party_mode</Icon> party_mode
      </div>),
      tags: ["camera", "lens", "mode", "party", "photo", "photography", "picture"]
    },
		{
      value: "password",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>password</Icon> password
      </div>),
      tags: ["code", "key", "login", "password", "pin", "security", "star", "unlock"]
    },
		{
      value: "pattern",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pattern</Icon> pattern
      </div>),
      tags: ["key", "login", "password", "pattern", "pin", "security", "star", "unlock"]
    },
		{
      value: "pause",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pause</Icon> pause
      </div>),
      tags: ["control", "controls", "media", "music", "pause", "video"]
    },
		{
      value: "pause_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pause_circle</Icon> pause_circle
      </div>),
      tags: ["circle", "control", "controls", "media", "music", "pause", "video"]
    },
		{
      value: "pause_circle_filled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pause_circle_filled</Icon> pause_circle_filled
      </div>),
      tags: ["circle", "control", "controls", "filled", "media", "music", "pause", "video"]
    },
		{
      value: "pause_circle_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pause_circle_outline</Icon> pause_circle_outline
      </div>),
      tags: ["circle", "control", "controls", "media", "music", "outline", "pause", "video"]
    },
		{
      value: "pause_presentation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pause_presentation</Icon> pause_presentation
      </div>),
      tags: ["app", "application desktop", "device", "pause", "present", "presentation", "screen", "share", "site", "slides", "web", "website", "window", "www"]
    },
		{
      value: "payment",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>payment</Icon> payment
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollars", "finance", "money", "online", "pay", "payment", "price", "shopping", "symbol"]
    },
		{
      value: "payments",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>payments</Icon> payments
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollars", "finance", "layer", "money", "multiple", "online", "pay", "payment", "payments", "price", "shopping", "symbol"]
    },
		{
      value: "pedal_bike",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pedal_bike</Icon> pedal_bike
      </div>),
      tags: ["automobile", "bicycle", "bike", "car", "cars", "direction", "human", "maps", "pedal", "public", "route", "scooter", "transportation", "vehicle", "vespa"]
    },
		{
      value: "pending",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pending</Icon> pending
      </div>),
      tags: ["circle", "dots", "loading", "pending", "progress", "wait", "waiting"]
    },
		{
      value: "pending_actions",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pending_actions</Icon> pending_actions
      </div>),
      tags: ["actions", "clipboard", "clock", "date", "doc", "document", "pending", "remember", "schedule", "time"]
    },
		{
      value: "pentagon",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pentagon</Icon> pentagon
      </div>),
      tags: ["five sides", "pentagon", "shape"]
    },
		{
      value: "people",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>people</Icon> people
      </div>),
      tags: ["accounts", "committee", "face", "family", "friends", "humans", "network", "people", "persons", "profiles", "social", "team", "users"]
    },
		{
      value: "people_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>people_alt</Icon> people_alt
      </div>),
      tags: ["accounts", "committee", "face", "family", "friends", "humans", "network", "people", "persons", "profiles", "social", "team", "users"]
    },
		{
      value: "people_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>people_outline</Icon> people_outline
      </div>),
      tags: ["accounts", "committee", "face", "family", "friends", "humans", "network", "outline", "people", "persons", "profiles", "social", "team", "users"]
    },
		{
      value: "percent",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>percent</Icon> percent
      </div>),
      tags: ["math", "numbers", "percent", "symbol"]
    },
		{
      value: "perm_camera_mic",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>perm_camera_mic</Icon> perm_camera_mic
      </div>),
      tags: ["camera", "image", "microphone", "min", "perm", "photo", "photography", "picture", "speaker"]
    },
		{
      value: "perm_contact_calendar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>perm_contact_calendar</Icon> perm_contact_calendar
      </div>),
      tags: ["account", "calendar", "contact", "date", "face", "human", "information", "people", "perm", "person", "profile", "schedule", "time", "user"]
    },
		{
      value: "perm_data_setting",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>perm_data_setting</Icon> perm_data_setting
      </div>),
      tags: ["data", "gear", "info", "information", "perm", "settings"]
    },
		{
      value: "perm_device_information",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>perm_device_information</Icon> perm_device_information
      </div>),
      tags: ["Android", "OS", "alert", "announcement", "device", "hardware", "i", "iOS", "info", "information", "mobile", "perm", "phone", "tablet"]
    },
		{
      value: "perm_identity",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>perm_identity</Icon> perm_identity
      </div>),
      tags: ["account", "avatar", "face", "human", "identity", "people", "perm", "person", "profile", "thumbnail", "user"]
    },
		{
      value: "perm_media",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>perm_media</Icon> perm_media
      </div>),
      tags: ["collection", "copy", "data", "doc", "document", "duplicate", "file", "folder", "folders", "image", "landscape", "media", "mountain", "mountains", "perm", "photo", "photography", "picture", "stack", "storage"]
    },
		{
      value: "perm_phone_msg",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>perm_phone_msg</Icon> perm_phone_msg
      </div>),
      tags: ["bubble", "call", "cell", "chat", "comment", "communicate", "contact", "device", "message", "msg", "perm", "phone", "recording", "speech", "telephone", "voice"]
    },
		{
      value: "perm_scan_wifi",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>perm_scan_wifi</Icon> perm_scan_wifi
      </div>),
      tags: ["alert", "announcement", "connection", "info", "information", "internet", "network", "perm", "scan", "service", "signal", "wifi", "wireless"]
    },
		{
      value: "person",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person</Icon> person
      </div>),
      tags: ["account", "face", "human", "people", "person", "profile", "user"]
    },
		{
      value: "person_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_2</Icon> person_2
      </div>),
      tags: ["account", "face", "human", "people", "person", "profile", "user"]
    },
		{
      value: "person_3",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_3</Icon> person_3
      </div>),
      tags: ["account", "face", "human", "people", "person", "profile", "user"]
    },
		{
      value: "person_4",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_4</Icon> person_4
      </div>),
      tags: ["account", "face", "human", "people", "person", "profile", "user"]
    },
		{
      value: "person_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_add</Icon> person_add
      </div>),
      tags: ["+", "account", "add", "avatar", "face", "human", "new", "people", "person", "plus", "profile", "symbol", "user"]
    },
		{
      value: "person_add_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_add_alt</Icon> person_add_alt
      </div>),
      tags: ["+", "account", "add", "face", "human", "people", "person", "plus", "profile", "user"]
    },
		{
      value: "person_add_alt_1",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_add_alt_1</Icon> person_add_alt_1
      </div>),
      tags: []
    },
		{
      value: "person_add_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_add_disabled</Icon> person_add_disabled
      </div>),
      tags: ["+", "account", "add", "disabled", "enabled", "face", "human", "new", "off", "offline", "on", "people", "person", "plus", "profile", "slash", "symbol", "user"]
    },
		{
      value: "person_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_off</Icon> person_off
      </div>),
      tags: ["account", "avatar", "disabled", "enabled", "face", "human", "off", "on", "people", "person", "profile", "slash", "user"]
    },
		{
      value: "person_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_outline</Icon> person_outline
      </div>),
      tags: ["account", "face", "human", "outline", "people", "person", "profile", "user"]
    },
		{
      value: "person_pin",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_pin</Icon> person_pin
      </div>),
      tags: ["account", "avatar", "destination", "direction", "face", "human", "location", "maps", "people", "person", "pin", "place", "profile", "stop", "user"]
    },
		{
      value: "person_pin_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_pin_circle</Icon> person_pin_circle
      </div>),
      tags: ["account", "circle", "destination", "direction", "face", "human", "location", "maps", "people", "person", "pin", "place", "profile", "stop", "user"]
    },
		{
      value: "person_remove",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_remove</Icon> person_remove
      </div>),
      tags: ["account", "avatar", "delete", "face", "human", "minus", "people", "person", "profile", "remove", "unfriend", "user"]
    },
		{
      value: "person_remove_alt_1",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_remove_alt_1</Icon> person_remove_alt_1
      </div>),
      tags: []
    },
		{
      value: "person_search",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>person_search</Icon> person_search
      </div>),
      tags: ["account", "avatar", "face", "find", "glass", "human", "look", "magnify", "magnifying", "people", "person", "profile", "search", "user"]
    },
		{
      value: "personal_injury",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>personal_injury</Icon> personal_injury
      </div>),
      tags: ["accident", "aid", "arm", "bandage", "body", "broke", "cast", "fracture", "health", "human", "injury", "medical", "patient", "people", "person", "personal", "sling", "social"]
    },
		{
      value: "personal_video",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>personal_video</Icon> personal_video
      </div>),
      tags: ["Android", "OS", "cam", "chrome", "desktop", "device", "hardware", "iOS", "mac", "monitor", "personal", "television", "tv", "video", "web", "window"]
    },
		{
      value: "pest_control",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pest_control</Icon> pest_control
      </div>),
      tags: ["bug", "control", "exterminator", "insects", "pest"]
    },
		{
      value: "pest_control_rodent",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pest_control_rodent</Icon> pest_control_rodent
      </div>),
      tags: ["control", "exterminator", "mice", "mouse", "pest", "rodent"]
    },
		{
      value: "pets",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pets</Icon> pets
      </div>),
      tags: ["animal", "cat", "dog", "hand", "paw", "pet"]
    },
		{
      value: "phishing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phishing</Icon> phishing
      </div>),
      tags: ["fish", "fishing", "fraud", "hook", "phishing", "scam"]
    },
		{
      value: "phone",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phone</Icon> phone
      </div>),
      tags: ["call", "cell", "contact", "device", "hardware", "mobile", "phone", "telephone"]
    },
		{
      value: "phone_android",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phone_android</Icon> phone_android
      </div>),
      tags: ["OS", "android", "cell", "device", "hardware", "iOS", "mobile", "phone", "tablet"]
    },
		{
      value: "phone_bluetooth_speaker",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phone_bluetooth_speaker</Icon> phone_bluetooth_speaker
      </div>),
      tags: ["bluetooth", "call", "cell", "connect", "connection", "connectivity", "contact", "device", "hardware", "mobile", "phone", "signal", "speaker", "symbol", "telephone", "wireless"]
    },
		{
      value: "phone_callback",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phone_callback</Icon> phone_callback
      </div>),
      tags: ["arrow", "call", "callback", "cell", "contact", "device", "down", "hardware", "mobile", "phone", "telephone"]
    },
		{
      value: "phone_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phone_disabled</Icon> phone_disabled
      </div>),
      tags: ["call", "cell", "contact", "device", "disabled", "enabled", "hardware", "mobile", "off", "offline", "on", "phone", "slash", "telephone"]
    },
		{
      value: "phone_enabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phone_enabled</Icon> phone_enabled
      </div>),
      tags: ["call", "cell", "contact", "device", "enabled", "hardware", "mobile", "phone", "telephone"]
    },
		{
      value: "phone_forwarded",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phone_forwarded</Icon> phone_forwarded
      </div>),
      tags: ["arrow", "call", "cell", "contact", "device", "direction", "forwarded", "hardware", "mobile", "phone", "right", "telephone"]
    },
		{
      value: "phone_iphone",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phone_iphone</Icon> phone_iphone
      </div>),
      tags: ["Android", "OS", "cell", "device", "hardware", "iOS", "iphone", "mobile", "phone", "tablet"]
    },
		{
      value: "phone_locked",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phone_locked</Icon> phone_locked
      </div>),
      tags: ["call", "cell", "contact", "device", "hardware", "lock", "locked", "mobile", "password", "phone", "privacy", "private", "protection", "safety", "secure", "security", "telephone"]
    },
		{
      value: "phone_missed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phone_missed</Icon> phone_missed
      </div>),
      tags: ["arrow", "call", "cell", "contact", "device", "hardware", "missed", "mobile", "phone", "telephone"]
    },
		{
      value: "phone_paused",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phone_paused</Icon> phone_paused
      </div>),
      tags: ["call", "cell", "contact", "device", "hardware", "mobile", "pause", "paused", "phone", "telephone"]
    },
		{
      value: "phonelink",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phonelink</Icon> phonelink
      </div>),
      tags: ["Android", "OS", "chrome", "computer", "connect", "desktop", "device", "hardware", "iOS", "link", "mac", "mobile", "phone", "phonelink", "sync", "tablet", "web", "windows"]
    },
		{
      value: "phonelink_erase",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phonelink_erase</Icon> phonelink_erase
      </div>),
      tags: ["Android", "OS", "cancel", "cell", "clear", "close", "connection", "device", "erase", "exit", "hardware", "iOS", "mobile", "no", "phone", "phonelink", "remove", "stop", "tablet", "x"]
    },
		{
      value: "phonelink_lock",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phonelink_lock</Icon> phonelink_lock
      </div>),
      tags: ["Android", "OS", "cell", "connection", "device", "erase", "hardware", "iOS", "lock", "locked", "mobile", "password", "phone", "phonelink", "privacy", "private", "protection", "safety", "secure", "security", "tablet"]
    },
		{
      value: "phonelink_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phonelink_off</Icon> phonelink_off
      </div>),
      tags: ["Android", "OS", "chrome", "computer", "connect", "desktop", "device", "disabled", "enabled", "hardware", "iOS", "link", "mac", "mobile", "off", "on", "phone", "phonelink", "slash", "sync", "tablet", "web", "windows"]
    },
		{
      value: "phonelink_ring",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phonelink_ring</Icon> phonelink_ring
      </div>),
      tags: ["Android", "OS", "cell", "connection", "data", "device", "hardware", "iOS", "mobile", "network", "phone", "phonelink", "ring", "service", "signal", "tablet", "wireless"]
    },
		{
      value: "phonelink_setup",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>phonelink_setup</Icon> phonelink_setup
      </div>),
      tags: ["Android", "OS", "call", "chat", "device", "hardware", "iOS", "info", "mobile", "phone", "phonelink", "settings", "setup", "tablet", "text"]
    },
		{
      value: "photo",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>photo</Icon> photo
      </div>),
      tags: ["image", "mountain", "mountains", "photo", "photography", "picture"]
    },
		{
      value: "photo_album",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>photo_album</Icon> photo_album
      </div>),
      tags: ["album", "archive", "bookmark", "image", "label", "library", "mountain", "mountains", "photo", "photography", "picture", "ribbon", "save", "tag"]
    },
		{
      value: "photo_camera",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>photo_camera</Icon> photo_camera
      </div>),
      tags: ["camera", "image", "photo", "photography", "picture"]
    },
		{
      value: "photo_camera_back",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>photo_camera_back</Icon> photo_camera_back
      </div>),
      tags: ["back", "camera", "image", "landscape", "mountain", "mountains", "photo", "photography", "picture", "rear"]
    },
		{
      value: "photo_camera_front",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>photo_camera_front</Icon> photo_camera_front
      </div>),
      tags: ["account", "camera", "face", "front", "human", "image", "people", "person", "photo", "photography", "picture", "portrait", "profile", "user"]
    },
		{
      value: "photo_filter",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>photo_filter</Icon> photo_filter
      </div>),
      tags: ["ai", "artificial", "automatic", "automation", "custom", "filter", "filters", "genai", "image", "intelligence", "magic", "photo", "photography", "picture", "smart", "spark", "sparkle", "star"]
    },
		{
      value: "photo_library",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>photo_library</Icon> photo_library
      </div>),
      tags: ["album", "image", "library", "mountain", "mountains", "photo", "photography", "picture"]
    },
		{
      value: "photo_size_select_actual",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>photo_size_select_actual</Icon> photo_size_select_actual
      </div>),
      tags: ["actual", "dash", "dashed", "image", "mountain", "mountains", "photo", "photography", "picture", "select", "size"]
    },
		{
      value: "photo_size_select_large",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>photo_size_select_large</Icon> photo_size_select_large
      </div>),
      tags: ["adjust", "album", "dash", "dashed", "edit", "editing", "image", "large", "library", "mountain", "mountains", "photo", "photography", "picture", "select", "size"]
    },
		{
      value: "photo_size_select_small",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>photo_size_select_small</Icon> photo_size_select_small
      </div>),
      tags: ["adjust", "album", "edit", "editing", "image", "large", "library", "mountain", "mountains", "photo", "photography", "picture", "select", "size", "small"]
    },
		{
      value: "php",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>php</Icon> php
      </div>),
      tags: ["alphabet", "brackets", "character", "code", "css", "develop", "developer", "engineer", "engineering", "font", "html", "letters", "php", "platform", "symbol", "text", "type"]
    },
		{
      value: "piano",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>piano</Icon> piano
      </div>),
      tags: ["instrument", "keyboard", "keys", "music", "musical", "piano", "social"]
    },
		{
      value: "piano_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>piano_off</Icon> piano_off
      </div>),
      tags: ["disabled", "enabled", "instrument", "keyboard", "keys", "music", "musical", "off", "on", "piano", "slash", "social"]
    },
		{
      value: "picture_as_pdf",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>picture_as_pdf</Icon> picture_as_pdf
      </div>),
      tags: ["alphabet", "as", "character", "copy", "document", "duplicate", "file", "font", "image", "letters", "multiple", "pdf", "photo", "photography", "picture", "stack", "symbol", "text", "type"]
    },
		{
      value: "picture_in_picture",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>picture_in_picture</Icon> picture_in_picture
      </div>),
      tags: ["chat", "crop", "cropped", "display", "displays", "layout", "multitasking", "overlap", "photo", "picture", "pip", "position", "shape", "sizes", "talktrack"]
    },
		{
      value: "picture_in_picture_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>picture_in_picture_alt</Icon> picture_in_picture_alt
      </div>),
      tags: ["chat", "crop", "cropped", "display", "displays", "layout", "multitasking", "overlap", "photo", "picture", "pip", "position", "shape", "sizes", "talktrack"]
    },
		{
      value: "pie_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pie_chart</Icon> pie_chart
      </div>),
      tags: ["analytics", "bar", "bars", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "pie", "statistics", "tracking"]
    },
		{
      value: "pie_chart_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pie_chart_outline</Icon> pie_chart_outline
      </div>),
      tags: ["analytics", "bar", "bars", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "outline", "pie", "statistics", "tracking"]
    },
		{
      value: "pin",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pin</Icon> pin
      </div>),
      tags: ["1", "2", "3", "digit", "key", "login", "logout", "numbers", "password", "pattern", "pin", "security", "star", "symbol", "unlock"]
    },
		{
      value: "pin_drop",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pin_drop</Icon> pin_drop
      </div>),
      tags: ["destination", "direction", "drop", "location", "maps", "navigation", "pin", "place", "stop"]
    },
		{
      value: "pin_end",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pin_end</Icon> pin_end
      </div>),
      tags: ["action", "arrow", "dot", "end", "pin"]
    },
		{
      value: "pin_invoke",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pin_invoke</Icon> pin_invoke
      </div>),
      tags: ["action", "arrow", "dot", "invoke", "pin"]
    },
		{
      value: "pinch",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pinch</Icon> pinch
      </div>),
      tags: ["arrow", "arrows", "compress", "direction", "finger", "grasp", "hand", "navigation", "nip", "pinch", "squeeze", "tweak"]
    },
		{
      value: "pivot_table_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pivot_table_chart</Icon> pivot_table_chart
      </div>),
      tags: ["analytics", "arrow", "arrows", "bar", "bars", "chart", "data", "diagram", "direction", "drive", "edit", "editing", "graph", "grid", "infographic", "measure", "metrics", "pivot", "rotate", "sheet", "statistics", "table", "tracking"]
    },
		{
      value: "pix",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pix</Icon> pix
      </div>),
      tags: ["bill", "brazil", "card", "cash", "commerce", "credit", "currency", "finance", "money", "payment"]
    },
		{
      value: "place",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>place</Icon> place
      </div>),
      tags: ["destination", "direction", "location", "maps", "navigation", "pin", "place", "point", "stop"]
    },
		{
      value: "plagiarism",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>plagiarism</Icon> plagiarism
      </div>),
      tags: ["doc", "document", "find", "glass", "look", "magnifying", "page", "paper", "plagiarism", "search", "see"]
    },
		{
      value: "play_arrow",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>play_arrow</Icon> play_arrow
      </div>),
      tags: ["app", "application", "arrow", "back", "components", "control", "controls", "direction", "forward", "interface", "media", "music", "navigation", "play", "right", "screen", "site", "triangle", "ui", "ux", "video", "web", "website"]
    },
		{
      value: "play_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>play_circle</Icon> play_circle
      </div>),
      tags: ["arrow", "circle", "control", "controls", "media", "music", "play", "video"]
    },
		{
      value: "play_circle_filled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>play_circle_filled</Icon> play_circle_filled
      </div>),
      tags: ["arrow", "circle", "control", "controls", "media", "music", "play", "video"]
    },
		{
      value: "play_circle_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>play_circle_outline</Icon> play_circle_outline
      </div>),
      tags: ["arrow", "circle", "control", "controls", "media", "music", "outline", "play", "video"]
    },
		{
      value: "play_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>play_disabled</Icon> play_disabled
      </div>),
      tags: ["control", "controls", "disabled", "enabled", "media", "music", "off", "on", "play", "slash", "video"]
    },
		{
      value: "play_for_work",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>play_for_work</Icon> play_for_work
      </div>),
      tags: ["arrow", "circle", "down", "google", "half", "play", "work"]
    },
		{
      value: "play_lesson",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>play_lesson</Icon> play_lesson
      </div>),
      tags: ["audio", "book", "bookmark", "digital", "ebook", "lesson", "multimedia", "play", "play lesson", "read", "reading", "ribbon"]
    },
		{
      value: "playlist_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>playlist_add</Icon> playlist_add
      </div>),
      tags: ["+", "add", "collection", "list", "music", "new", "playlist", "plus", "symbol"]
    },
		{
      value: "playlist_add_check",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>playlist_add_check</Icon> playlist_add_check
      </div>),
      tags: ["add", "approve", "check", "collection", "complete", "done", "list", "mark", "music", "ok", "playlist", "select", "tick", "validate", "verified", "yes"]
    },
		{
      value: "playlist_add_check_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>playlist_add_check_circle</Icon> playlist_add_check_circle
      </div>),
      tags: ["add", "album", "artist", "audio", "cd", "check", "circle", "collection", "list", "mark", "music", "playlist", "record", "sound", "track"]
    },
		{
      value: "playlist_add_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>playlist_add_circle</Icon> playlist_add_circle
      </div>),
      tags: ["add", "album", "artist", "audio", "cd", "check", "circle", "collection", "list", "mark", "music", "playlist", "record", "sound", "track"]
    },
		{
      value: "playlist_play",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>playlist_play</Icon> playlist_play
      </div>),
      tags: ["arrow", "collection", "list", "music", "play", "playlist"]
    },
		{
      value: "playlist_remove",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>playlist_remove</Icon> playlist_remove
      </div>),
      tags: ["-", "collection", "list", "minus", "music", "playlist", "remove"]
    },
		{
      value: "plumbing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>plumbing</Icon> plumbing
      </div>),
      tags: ["build", "construction", "fix", "handyman", "plumbing", "repair", "tools", "wrench"]
    },
		{
      value: "plus_one",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>plus_one</Icon> plus_one
      </div>),
      tags: ["1", "add", "digit", "increase", "numbers", "one", "plus", "symbol"]
    },
		{
      value: "podcasts",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>podcasts</Icon> podcasts
      </div>),
      tags: ["broadcast", "casting", "network", "podcasts", "signal", "transmitting", "wireless"]
    },
		{
      value: "point_of_sale",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>point_of_sale</Icon> point_of_sale
      </div>),
      tags: ["checkout", "cost", "machine", "merchant", "money", "of", "pay", "payment", "point", "pos", "retail", "sale", "system", "transaction"]
    },
		{
      value: "policy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>policy</Icon> policy
      </div>),
      tags: ["certified", "find", "glass", "legal", "look", "magnify", "magnifying", "policy", "privacy", "private", "protect", "protection", "search", "security", "see", "shield", "verified"]
    },
		{
      value: "poll",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>poll</Icon> poll
      </div>),
      tags: ["analytics", "bar", "bars", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "poll", "statistics", "survey", "tracking", "vote"]
    },
		{
      value: "polyline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>polyline</Icon> polyline
      </div>),
      tags: ["compose", "connect", "connection", "create", "design", "draw", "line", "node", "polyline", "vector"]
    },
		{
      value: "polymer",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>polymer</Icon> polymer
      </div>),
      tags: ["emblem", "logo", "mark", "polymer"]
    },
		{
      value: "pool",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pool</Icon> pool
      </div>),
      tags: ["athlete", "athletic", "beach", "body", "entertainment", "exercise", "hobby", "human", "ocean", "people", "person", "places", "pool", "sea", "sports", "swim", "swimming", "water"]
    },
		{
      value: "portable_wifi_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>portable_wifi_off</Icon> portable_wifi_off
      </div>),
      tags: ["connection", "data", "disabled", "enabled", "internet", "network", "off", "offline", "on", "portable", "service", "signal", "slash", "wifi", "wireless"]
    },
		{
      value: "portrait",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>portrait</Icon> portrait
      </div>),
      tags: ["account", "face", "human", "people", "person", "photo", "picture", "portrait", "profile", "user"]
    },
		{
      value: "post_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>post_add</Icon> post_add
      </div>),
      tags: ["+", "add", "data", "doc", "document", "drive", "file", "folder", "folders", "page", "paper", "plus", "post", "sheet", "slide", "text", "writing"]
    },
		{
      value: "power",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>power</Icon> power
      </div>),
      tags: ["charge", "cord", "electric", "electrical", "outlet", "plug", "power"]
    },
		{
      value: "power_input",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>power_input</Icon> power_input
      </div>),
      tags: ["input", "lines", "power", "supply"]
    },
		{
      value: "power_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>power_off</Icon> power_off
      </div>),
      tags: ["charge", "cord", "disabled", "electric", "electrical", "enabled", "off", "on", "outlet", "plug", "power", "slash"]
    },
		{
      value: "power_settings_new",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>power_settings_new</Icon> power_settings_new
      </div>),
      tags: ["info", "information", "off", "on", "power", "save", "settings", "shutdown"]
    },
		{
      value: "precision_manufacturing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>precision_manufacturing</Icon> precision_manufacturing
      </div>),
      tags: ["arm", "automatic", "chain", "conveyor", "crane", "factory", "industry", "machinery", "manufacturing", "mechanical", "precision", "production", "repairing", "robot", "supply", "warehouse"]
    },
		{
      value: "pregnant_woman",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>pregnant_woman</Icon> pregnant_woman
      </div>),
      tags: ["baby", "birth", "body", "female", "human", "lady", "maternity", "mom", "mother", "people", "person", "pregnant", "women"]
    },
		{
      value: "present_to_all",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>present_to_all</Icon> present_to_all
      </div>),
      tags: ["all", "arrow", "present", "presentation", "screen", "share", "site", "slides", "to", "web", "website"]
    },
		{
      value: "preview",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>preview</Icon> preview
      </div>),
      tags: ["design", "eye", "layout", "preview", "reveal", "screen", "see", "show", "site", "view", "web", "website", "window", "www"]
    },
		{
      value: "price_change",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>price_change</Icon> price_change
      </div>),
      tags: ["arrows", "bill", "card", "cash", "change", "coin", "commerce", "cost", "credit", "currency", "dollars", "down", "finance", "money", "online", "pay", "payment", "price", "shopping", "symbol", "up"]
    },
		{
      value: "price_check",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>price_check</Icon> price_check
      </div>),
      tags: ["approve", "bill", "card", "cash", "check", "coin", "commerce", "complete", "cost", "credit", "currency", "dollars", "done", "finance", "mark", "money", "ok", "online", "pay", "payment", "price", "select", "shopping", "symbol", "tick", "validate", "verified", "yes"]
    },
		{
      value: "print",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>print</Icon> print
      </div>),
      tags: ["draft", "fax", "ink", "machine", "office", "paper", "print", "printer", "send"]
    },
		{
      value: "print_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>print_disabled</Icon> print_disabled
      </div>),
      tags: ["disabled", "enabled", "off", "on", "paper", "print", "printer", "slash"]
    },
		{
      value: "priority_high",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>priority_high</Icon> priority_high
      </div>),
      tags: ["!", "alert", "attention", "caution", "danger", "error", "exclamation", "high", "important", "mark", "notification", "symbol", "warning"]
    },
		{
      value: "privacy_tip",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>privacy_tip</Icon> privacy_tip
      </div>),
      tags: ["alert", "announcement", "assistance", "certified", "details", "help", "i", "info", "information", "privacy", "private", "protect", "protection", "security", "service", "shield", "support", "tip", "verified"]
    },
		{
      value: "private_connectivity",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>private_connectivity</Icon> private_connectivity
      </div>),
      tags: ["connectivity", "lock", "locked", "password", "privacy", "private", "protection", "safety", "secure", "security"]
    },
		{
      value: "production_quantity_limits",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>production_quantity_limits</Icon> production_quantity_limits
      </div>),
      tags: ["!", "alert", "attention", "bill", "card", "cart", "cash", "caution", "coin", "commerce", "credit", "currency", "danger", "dollars", "error", "exclamation", "important", "limits", "mark", "money", "notification", "online", "pay", "payment", "production", "quantity", "shopping", "symbol", "warning"]
    },
		{
      value: "propane",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>propane</Icon> propane
      </div>),
      tags: ["gas", "nest", "propane"]
    },
		{
      value: "propane_tank",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>propane_tank</Icon> propane_tank
      </div>),
      tags: ["bbq", "gas", "grill", "nest", "propane", "tank"]
    },
		{
      value: "psychology",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>psychology</Icon> psychology
      </div>),
      tags: ["behavior", "body", "brain", "cognitive", "function", "gear", "head", "human", "intellectual", "mental", "mind", "people", "person", "preferences", "psychiatric", "psychology", "science", "settings", "social", "therapy", "thinking", "thoughts"]
    },
		{
      value: "psychology_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>psychology_alt</Icon> psychology_alt
      </div>),
      tags: ["?", "assistance", "behavior", "body", "brain", "cognitive", "function", "gear", "head", "help", "human", "info", "information", "intellectual", "mental", "mind", "people", "person", "preferences", "psychiatric", "psychology", "punctuation", "question mark", "science", "settings", "social", "support", "symbol", "therapy", "thinking", "thoughts"]
    },
		{
      value: "public",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>public</Icon> public
      </div>),
      tags: ["earth", "global", "globe", "map", "network", "planet", "public", "social", "space", "web", "world"]
    },
		{
      value: "public_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>public_off</Icon> public_off
      </div>),
      tags: ["disabled", "earth", "enabled", "global", "globe", "map", "network", "off", "on", "planet", "public", "slash", "social", "space", "web", "world"]
    },
		{
      value: "publish",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>publish</Icon> publish
      </div>),
      tags: ["arrow", "cloud", "file", "import", "publish", "up", "upload"]
    },
		{
      value: "published_with_changes",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>published_with_changes</Icon> published_with_changes
      </div>),
      tags: ["approve", "arrow", "arrows", "changes", "check", "complete", "done", "inprogress", "load", "loading", "mark", "ok", "published", "refresh", "renew", "replace", "rotate", "select", "tick", "validate", "verified", "with", "yes"]
    },
		{
      value: "punch_clock",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>punch_clock</Icon> punch_clock
      </div>),
      tags: ["clock", "date", "punch", "schedule", "time", "timer", "timesheet"]
    },
		{
      value: "push_pin",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>push_pin</Icon> push_pin
      </div>),
      tags: ["location", "marker", "pin", "place", "push", "remember", "save"]
    },
		{
      value: "qr_code",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>qr_code</Icon> qr_code
      </div>),
      tags: ["barcode", "camera", "code", "media", "product", "qr", "quick", "response", "smartphone", "url", "urls"]
    },
		{
      value: "qr_code_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>qr_code_2</Icon> qr_code_2
      </div>),
      tags: ["barcode", "camera", "code", "media", "product", "qr", "quick", "response", "smartphone", "url", "urls"]
    },
		{
      value: "qr_code_scanner",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>qr_code_scanner</Icon> qr_code_scanner
      </div>),
      tags: ["barcode", "camera", "code", "media", "product", "qr", "quick", "response", "scanner", "smartphone", "url", "urls"]
    },
		{
      value: "query_builder",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>query_builder</Icon> query_builder
      </div>),
      tags: ["builder", "clock", "date", "query", "schedule", "time"]
    },
		{
      value: "query_stats",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>query_stats</Icon> query_stats
      </div>),
      tags: ["analytics", "chart", "data", "diagram", "find", "glass", "graph", "infographic", "line", "look", "magnify", "magnifying", "measure", "metrics", "query", "search", "see", "statistics", "stats", "tracking"]
    },
		{
      value: "question_answer",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>question_answer</Icon> question_answer
      </div>),
      tags: ["answer", "bubble", "chat", "comment", "communicate", "conversation", "feedback", "message", "question", "speech", "talk"]
    },
		{
      value: "question_mark",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>question_mark</Icon> question_mark
      </div>),
      tags: ["?", "assistance", "help", "info", "information", "punctuation", "question mark", "support", "symbol"]
    },
		{
      value: "queue",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>queue</Icon> queue
      </div>),
      tags: ["add", "collection", "layers", "list", "multiple", "music", "playlist", "queue", "stack", "stream", "video"]
    },
		{
      value: "queue_music",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>queue_music</Icon> queue_music
      </div>),
      tags: ["collection", "list", "music", "playlist", "queue"]
    },
		{
      value: "queue_play_next",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>queue_play_next</Icon> queue_play_next
      </div>),
      tags: ["+", "add", "arrow", "desktop", "device", "display", "hardware", "monitor", "new", "next", "play", "plus", "queue", "screen", "stream", "symbol", "tv"]
    },
		{
      value: "quickreply",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>quickreply</Icon> quickreply
      </div>),
      tags: ["bolt", "bubble", "chat", "comment", "communicate", "electric", "energy", "fast", "instant", "lightning", "message", "quick", "quickreply", "reply", "speech", "thunderbolt"]
    },
		{
      value: "quiz",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>quiz</Icon> quiz
      </div>),
      tags: ["?", "assistance", "faq", "help", "info", "information", "punctuation", "question mark", "quiz", "support", "symbol", "test"]
    },
		{
      value: "r_mobiledata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>r_mobiledata</Icon> r_mobiledata
      </div>),
      tags: ["alphabet", "character", "data", "font", "letters", "mobile", "r", "symbol", "text", "type"]
    },
		{
      value: "radar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>radar</Icon> radar
      </div>),
      tags: ["detect", "military", "near", "network", "position", "radar", "scan"]
    },
		{
      value: "radio",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>radio</Icon> radio
      </div>),
      tags: ["antenna", "audio", "device", "frequency", "hardware", "listen", "media", "music", "player", "radio", "signal", "tune"]
    },
		{
      value: "radio_button_checked",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>radio_button_checked</Icon> radio_button_checked
      </div>),
      tags: ["app", "application", "bullet", "button", "checked", "circle", "components", "design", "form", "interface", "off", "on", "point", "radio", "record", "screen", "select", "selected", "site", "toggle", "ui", "ux", "web", "website"]
    },
		{
      value: "radio_button_unchecked",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>radio_button_unchecked</Icon> radio_button_unchecked
      </div>),
      tags: ["bullet", "button", "circle", "deselected", "form", "off", "on", "point", "radio", "record", "select", "toggle", "unchecked"]
    },
		{
      value: "railway_alert",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>railway_alert</Icon> railway_alert
      </div>),
      tags: ["!", "alert", "attention", "automobile", "bike", "car", "cars", "caution", "danger", "direction", "error", "exclamation", "important", "maps", "mark", "notification", "public", "railway", "scooter", "subway", "symbol", "train", "transportation", "vehicle", "vespa", "warning"]
    },
		{
      value: "ramen_dining",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ramen_dining</Icon> ramen_dining
      </div>),
      tags: ["breakfast", "dining", "dinner", "drink", "fastfood", "food", "lunch", "meal", "noodles", "ramen", "restaurant"]
    },
		{
      value: "ramp_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ramp_left</Icon> ramp_left
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "left", "maps", "navigation", "path", "ramp", "route", "sign", "traffic"]
    },
		{
      value: "ramp_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ramp_right</Icon> ramp_right
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "maps", "navigation", "path", "ramp", "right", "route", "sign", "traffic"]
    },
		{
      value: "rate_review",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rate_review</Icon> rate_review
      </div>),
      tags: ["comment", "feedback", "pen", "pencil", "rate", "review", "stars", "write"]
    },
		{
      value: "raw_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>raw_off</Icon> raw_off
      </div>),
      tags: ["alphabet", "character", "disabled", "enabled", "font", "image", "letters", "off", "on", "original", "photo", "photography", "raw", "slash", "symbol", "text", "type"]
    },
		{
      value: "raw_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>raw_on</Icon> raw_on
      </div>),
      tags: ["alphabet", "character", "disabled", "enabled", "font", "image", "letters", "off", "on", "original", "photo", "photography", "raw", "slash", "symbol", "text", "type"]
    },
		{
      value: "read_more",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>read_more</Icon> read_more
      </div>),
      tags: ["arrow", "more", "read", "text"]
    },
		{
      value: "real_estate_agent",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>real_estate_agent</Icon> real_estate_agent
      </div>),
      tags: ["agent", "architecture", "broker", "estate", "hand", "home", "house", "loan", "mortgage", "property", "real", "residence", "residential", "sales", "social"]
    },
		{
      value: "receipt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>receipt</Icon> receipt
      </div>),
      tags: []
    },
		{
      value: "receipt_long",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>receipt_long</Icon> receipt_long
      </div>),
      tags: ["bill", "check", "document", "list", "long", "paper", "paperwork", "receipt", "record", "store", "transaction"]
    },
		{
      value: "recent_actors",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>recent_actors</Icon> recent_actors
      </div>),
      tags: ["account", "actors", "avatar", "card", "cards", "carousel", "face", "human", "layers", "list", "people", "person", "profile", "recent", "thumbnail", "user"]
    },
		{
      value: "recommend",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>recommend</Icon> recommend
      </div>),
      tags: ["approved", "circle", "confirm", "favorite", "gesture", "hand", "like", "reaction", "recommend", "social", "support", "thumbs", "up", "well"]
    },
		{
      value: "record_voice_over",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>record_voice_over</Icon> record_voice_over
      </div>),
      tags: ["account", "dictation", "face", "human", "over", "people", "person", "profile", "record", "recording", "speak", "speaking", "speech", "transcript", "user", "voice"]
    },
		{
      value: "rectangle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rectangle</Icon> rectangle
      </div>),
      tags: ["four sides", "parallelograms", "polygons", "quadrilaterals", "recangle", "shape"]
    },
		{
      value: "recycling",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>recycling</Icon> recycling
      </div>),
      tags: ["bio", "eco", "green", "loop", "recyclable", "recycle", "recycling", "rotate", "sustainability", "sustainable", "trash"]
    },
		{
      value: "redeem",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>redeem</Icon> redeem
      </div>),
      tags: ["bill", "card", "cart", "cash", "certificate", "coin", "commerce", "credit", "currency", "dollars", "gift", "giftcard", "money", "online", "pay", "payment", "present", "redeem", "shopping"]
    },
		{
      value: "redo",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>redo</Icon> redo
      </div>),
      tags: ["arrow", "backward", "forward", "next", "redo", "repeat", "rotate", "undo"]
    },
		{
      value: "reduce_capacity",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>reduce_capacity</Icon> reduce_capacity
      </div>),
      tags: ["arrow", "body", "capacity", "covid", "decrease", "down", "human", "people", "person", "reduce", "social"]
    },
		{
      value: "refresh",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>refresh</Icon> refresh
      </div>),
      tags: ["around", "arrow", "arrows", "direction", "inprogress", "load", "loading refresh", "navigation", "refresh", "renew", "right", "rotate", "turn"]
    },
		{
      value: "remember_me",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>remember_me</Icon> remember_me
      </div>),
      tags: ["Android", "OS", "avatar", "device", "hardware", "human", "iOS", "identity", "me", "mobile", "people", "person", "phone", "profile", "remember", "tablet", "user"]
    },
		{
      value: "remove",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>remove</Icon> remove
      </div>),
      tags: ["can", "delete", "minus", "negative", "remove", "substract", "trash"]
    },
		{
      value: "remove_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>remove_circle</Icon> remove_circle
      </div>),
      tags: ["block", "can", "circle", "delete", "minus", "negative", "remove", "substract", "trash"]
    },
		{
      value: "remove_circle_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>remove_circle_outline</Icon> remove_circle_outline
      </div>),
      tags: ["block", "can", "circle", "delete", "minus", "negative", "outline", "remove", "substract", "trash"]
    },
		{
      value: "remove_done",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>remove_done</Icon> remove_done
      </div>),
      tags: ["approve", "check", "complete", "disabled", "done", "enabled", "finished", "mark", "multiple", "off", "ok", "on", "remove", "select", "slash", "tick", "yes"]
    },
		{
      value: "remove_from_queue",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>remove_from_queue</Icon> remove_from_queue
      </div>),
      tags: ["desktop", "device", "display", "from", "hardware", "monitor", "queue", "remove", "screen", "stream"]
    },
		{
      value: "remove_moderator",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>remove_moderator</Icon> remove_moderator
      </div>),
      tags: ["certified", "disabled", "enabled", "moderator", "off", "on", "privacy", "private", "protect", "protection", "remove", "security", "shield", "slash", "verified"]
    },
		{
      value: "remove_red_eye",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>remove_red_eye</Icon> remove_red_eye
      </div>),
      tags: ["eye", "iris", "look", "looking", "preview", "red", "remove", "see", "sight", "vision"]
    },
		{
      value: "remove_road",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>remove_road</Icon> remove_road
      </div>),
      tags: ["-", "cancel", "clear", "close", "destination", "direction", "exit", "highway", "maps", "minus", "new", "no", "remove", "road", "stop", "street", "symbol", "traffic", "x"]
    },
		{
      value: "remove_shopping_cart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>remove_shopping_cart</Icon> remove_shopping_cart
      </div>),
      tags: ["card", "cart", "cash", "checkout", "coin", "commerce", "credit", "currency", "delete", "dollars", "minus", "online", "pay", "payment", "remember", "remove", "ribbon", "save", "shopping", "subtract"]
    },
		{
      value: "reorder",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>reorder</Icon> reorder
      </div>),
      tags: ["format", "lines", "list", "order", "reorder", "stacked"]
    },
		{
      value: "repartition",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>repartition</Icon> repartition
      </div>),
      tags: ["arrow", "arrows", "data", "partition", "refresh", "renew", "repartition", "restore", "table"]
    },
		{
      value: "repeat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>repeat</Icon> repeat
      </div>),
      tags: ["arrow", "arrows", "control", "controls", "loop", "media", "music", "repeat", "video"]
    },
		{
      value: "repeat_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>repeat_on</Icon> repeat_on
      </div>),
      tags: ["arrow", "arrows", "control", "controls", "loop", "media", "music", "on", "repeat", "video"]
    },
		{
      value: "repeat_one",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>repeat_one</Icon> repeat_one
      </div>),
      tags: ["1", "arrow", "arrows", "control", "controls", "digit", "loop", "media", "music", "numbers", "one", "repeat", "symbol", "video"]
    },
		{
      value: "repeat_one_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>repeat_one_on</Icon> repeat_one_on
      </div>),
      tags: ["arrow", "arrows", "control", "controls", "digit", "loop", "media", "music", "numbers", "on", "one", "repeat", "symbol", "video"]
    },
		{
      value: "replay",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>replay</Icon> replay
      </div>),
      tags: ["arrow", "arrows", "control", "controls", "music", "refresh", "renew", "repeat", "replay", "video"]
    },
		{
      value: "replay_10",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>replay_10</Icon> replay_10
      </div>),
      tags: ["10", "arrow", "arrows", "control", "controls", "digit", "music", "numbers", "refresh", "renew", "repeat", "replay", "symbol", "ten", "video"]
    },
		{
      value: "replay_30",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>replay_30</Icon> replay_30
      </div>),
      tags: ["30", "arrow", "arrows", "control", "controls", "digit", "music", "numbers", "refresh", "renew", "repeat", "replay", "symbol", "thirty", "video"]
    },
		{
      value: "replay_5",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>replay_5</Icon> replay_5
      </div>),
      tags: ["5", "arrow", "arrows", "control", "controls", "digit", "five", "music", "numbers", "refresh", "renew", "repeat", "replay", "symbol", "video"]
    },
		{
      value: "replay_circle_filled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>replay_circle_filled</Icon> replay_circle_filled
      </div>),
      tags: ["arrow", "arrows", "circle", "control", "controls", "filled", "music", "refresh", "renew", "repeat", "replay", "video"]
    },
		{
      value: "reply",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>reply</Icon> reply
      </div>),
      tags: ["arrow", "backward", "left", "mail", "message", "reply", "send", "share"]
    },
		{
      value: "reply_all",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>reply_all</Icon> reply_all
      </div>),
      tags: ["all", "arrow", "backward", "group", "left", "mail", "message", "multiple", "reply", "send", "share"]
    },
		{
      value: "report",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>report</Icon> report
      </div>),
      tags: ["!", "alert", "attention", "caution", "danger", "error", "exclamation", "important", "mark", "notification", "octagon", "report", "symbol", "warning"]
    },
		{
      value: "report_gmailerrorred",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>report_gmailerrorred</Icon> report_gmailerrorred
      </div>),
      tags: ["!", "alert", "attention", "caution", "danger", "error", "exclamation", "gmail", "gmailerrorred", "important", "mark", "notification", "octagon", "report", "symbol", "warning"]
    },
		{
      value: "report_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>report_off</Icon> report_off
      </div>),
      tags: ["!", "alert", "attention", "caution", "danger", "disabled", "enabled", "error", "exclamation", "important", "mark", "notification", "octagon", "off", "offline", "on", "report", "slash", "symbol", "warning"]
    },
		{
      value: "report_problem",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>report_problem</Icon> report_problem
      </div>),
      tags: ["!", "alert", "attention", "caution", "danger", "error", "exclamation", "feedback", "important", "mark", "notification", "problem", "report", "symbol", "triangle", "warning"]
    },
		{
      value: "request_page",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>request_page</Icon> request_page
      </div>),
      tags: ["data", "doc", "document", "drive", "file", "folder", "folders", "page", "paper", "request", "sheet", "slide", "writing"]
    },
		{
      value: "request_quote",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>request_quote</Icon> request_quote
      </div>),
      tags: ["bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollars", "finance", "money", "online", "pay", "payment", "price", "quote", "request", "shopping", "symbol"]
    },
		{
      value: "reset_tv",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>reset_tv</Icon> reset_tv
      </div>),
      tags: ["arrow", "arrows", "device", "hardware", "monitor", "reset", "television", "tv"]
    },
		{
      value: "restart_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>restart_alt</Icon> restart_alt
      </div>),
      tags: ["alt", "around", "arrow", "inprogress", "load", "loading refresh", "reboot", "renew", "repeat", "reset", "restart"]
    },
		{
      value: "restaurant",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>restaurant</Icon> restaurant
      </div>),
      tags: ["cafe", "cafeteria", "cutlery", "diner", "dining", "dinner", "eat", "eating", "food", "fork", "knife", "local", "meal", "restaurant", "spoon", "utensils"]
    },
		{
      value: "restaurant_menu",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>restaurant_menu</Icon> restaurant_menu
      </div>),
      tags: ["dining", "eat", "food", "fork", "knife", "local", "meal", "menu", "restaurant", "spoon"]
    },
		{
      value: "restore",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>restore</Icon> restore
      </div>),
      tags: ["arrow", "back", "backwards", "clock", "date", "device", "history", "home", "nest", "refresh", "renew", "reset", "restore", "reverse", "rotate", "schedule", "time", "turn"]
    },
		{
      value: "restore_from_trash",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>restore_from_trash</Icon> restore_from_trash
      </div>),
      tags: ["arrow", "back", "backwards", "clock", "date", "history", "refresh", "renew", "restore", "reverse", "rotate", "schedule", "time", "turn"]
    },
		{
      value: "restore_page",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>restore_page</Icon> restore_page
      </div>),
      tags: ["arrow", "data", "doc", "file", "page", "paper", "refresh", "restore", "rotate", "sheet", "storage"]
    },
		{
      value: "reviews",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>reviews</Icon> reviews
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "feedback", "message", "rate", "rating", "recommendation", "reviews", "speech"]
    },
		{
      value: "rice_bowl",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rice_bowl</Icon> rice_bowl
      </div>),
      tags: ["bowl", "dinner", "food", "lunch", "meal", "restaurant", "rice"]
    },
		{
      value: "ring_volume",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ring_volume</Icon> ring_volume
      </div>),
      tags: ["call", "calling", "cell", "contact", "device", "hardware", "incoming", "mobile", "phone", "ring", "ringer", "sound", "telephone", "volume"]
    },
		{
      value: "rocket",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rocket</Icon> rocket
      </div>),
      tags: ["astronaut", "fast", "quick", "rocket", "space", "spaceship", "speed"]
    },
		{
      value: "rocket_launch",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rocket_launch</Icon> rocket_launch
      </div>),
      tags: ["astronaut", "fast", "launch", "quick", "rocket", "space", "spaceship", "speed", "takeoff"]
    },
		{
      value: "roller_shades",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>roller_shades</Icon> roller_shades
      </div>),
      tags: ["blinds", "cover", "curtains", "nest", "open", "roller", "shade", "shutter", "sunshade"]
    },
		{
      value: "roller_shades_closed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>roller_shades_closed</Icon> roller_shades_closed
      </div>),
      tags: ["blinds", "closed", "cover", "curtains", "nest", "roller", "shade", "shutter", "sunshade"]
    },
		{
      value: "roller_skating",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>roller_skating</Icon> roller_skating
      </div>),
      tags: ["athlete", "athletic", "entertainment", "exercise", "hobby", "roller", "shoe", "skate", "skates", "skating", "social", "sports", "travel"]
    },
		{
      value: "roofing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>roofing</Icon> roofing
      </div>),
      tags: ["architecture", "building", "chimney", "construction", "estate", "home", "house", "real", "residence", "residential", "roof", "roofing", "service", "shelter"]
    },
		{
      value: "room",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>room</Icon> room
      </div>),
      tags: ["destination", "direction", "location", "maps", "pin", "place", "room", "stop"]
    },
		{
      value: "room_preferences",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>room_preferences</Icon> room_preferences
      </div>),
      tags: ["building", "door", "doorway", "entrance", "gear", "home", "house", "interior", "office", "open", "preferences", "room", "settings"]
    },
		{
      value: "room_service",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>room_service</Icon> room_service
      </div>),
      tags: ["alert", "bell", "delivery", "hotel", "notify", "room", "service"]
    },
		{
      value: "rotate_90_degrees_ccw",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rotate_90_degrees_ccw</Icon> rotate_90_degrees_ccw
      </div>),
      tags: ["90", "arrow", "arrows", "ccw", "degrees", "direction", "edit", "editing", "image", "photo", "rotate", "turn"]
    },
		{
      value: "rotate_90_degrees_cw",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rotate_90_degrees_cw</Icon> rotate_90_degrees_cw
      </div>),
      tags: ["90", "arrow", "arrows", "ccw", "degrees", "direction", "edit", "editing", "image", "photo", "rotate", "turn"]
    },
		{
      value: "rotate_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rotate_left</Icon> rotate_left
      </div>),
      tags: ["around", "arrow", "dash", "dashed", "direction", "inprogress", "left", "load", "loading refresh", "renew", "rotate", "turn"]
    },
		{
      value: "rotate_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rotate_right</Icon> rotate_right
      </div>),
      tags: ["around", "arrow", "direction", "inprogress", "load", "loading refresh", "renew", "right", "rotate", "turn"]
    },
		{
      value: "roundabout_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>roundabout_left</Icon> roundabout_left
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "left", "maps", "navigation", "path", "roundabout", "route", "sign", "traffic"]
    },
		{
      value: "roundabout_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>roundabout_right</Icon> roundabout_right
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "maps", "navigation", "path", "right", "roundabout", "route", "sign", "traffic"]
    },
		{
      value: "rounded_corner",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rounded_corner</Icon> rounded_corner
      </div>),
      tags: ["adjust", "corner", "dash", "dashed", "edit", "rounded", "shape", "square", "transform"]
    },
		{
      value: "route",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>route</Icon> route
      </div>),
      tags: ["directions", "maps", "path", "route", "sign", "traffic"]
    },
		{
      value: "router",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>router</Icon> router
      </div>),
      tags: ["box", "cable", "connection", "hardware", "internet", "network", "router", "signal", "wifi"]
    },
		{
      value: "rowing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rowing</Icon> rowing
      </div>),
      tags: ["activity", "boat", "body", "canoe", "human", "people", "person", "row", "rowing", "sport", "water"]
    },
		{
      value: "rss_feed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rss_feed</Icon> rss_feed
      </div>),
      tags: ["application", "blog", "connection", "data", "feed", "internet", "network", "rss", "service", "signal", "website", "wifi", "wireless"]
    },
		{
      value: "rsvp",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rsvp</Icon> rsvp
      </div>),
      tags: ["alphabet", "character", "font", "invitation", "invite", "letters", "plaît", "respond", "rsvp", "répondez", "sil", "symbol", "text", "type", "vous"]
    },
		{
      value: "rtt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rtt</Icon> rtt
      </div>),
      tags: ["call", "real", "rrt", "text", "time"]
    },
		{
      value: "rule",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rule</Icon> rule
      </div>),
      tags: ["approve", "check", "complete", "done", "incomplete", "line", "mark", "missing", "no", "ok", "rule", "select", "tick", "validate", "verified", "wrong", "x", "yes"]
    },
		{
      value: "rule_folder",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rule_folder</Icon> rule_folder
      </div>),
      tags: ["approve", "cancel", "check", "close", "complete", "data", "doc", "document", "done", "drive", "exit", "file", "folder", "mark", "no", "ok", "remove", "rule", "select", "sheet", "slide", "storage", "tick", "validate", "verified", "x", "yes"]
    },
		{
      value: "run_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>run_circle</Icon> run_circle
      </div>),
      tags: ["body", "circle", "exercise", "human", "people", "person", "run", "running"]
    },
		{
      value: "running_with_errors",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>running_with_errors</Icon> running_with_errors
      </div>),
      tags: ["!", "alert", "attention", "caution", "danger", "duration", "error", "errors", "exclamation", "important", "mark", "notification", "process", "processing", "running", "symbol", "time", "warning", "with"]
    },
		{
      value: "rv_hookup",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>rv_hookup</Icon> rv_hookup
      </div>),
      tags: ["arrow", "attach", "automobile", "automotive", "back", "car", "cars", "connect", "direction", "hookup", "left", "maps", "public", "right", "rv", "trailer", "transportation", "travel", "truck", "van", "vehicle"]
    },
		{
      value: "safety_check",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>safety_check</Icon> safety_check
      </div>),
      tags: ["certified", "check", "clock", "privacy", "private", "protect", "protection", "safety", "schedule", "security", "shield", "time", "verified"]
    },
		{
      value: "safety_divider",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>safety_divider</Icon> safety_divider
      </div>),
      tags: ["apart", "distance", "divider", "safety", "separate", "social", "space"]
    },
		{
      value: "sailing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sailing</Icon> sailing
      </div>),
      tags: ["boat", "entertainment", "fishing", "hobby", "ocean", "sailboat", "sailing", "sea", "social sports", "travel", "water"]
    },
		{
      value: "sanitizer",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sanitizer</Icon> sanitizer
      </div>),
      tags: ["bacteria", "bottle", "clean", "covid", "disinfect", "germs", "pump", "sanitizer"]
    },
		{
      value: "satellite",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>satellite</Icon> satellite
      </div>),
      tags: ["bluetooth", "connect", "connection", "connectivity", "data", "device", "image", "internet", "landscape", "location", "maps", "mountain", "mountains", "network", "photo", "photography", "picture", "satellite", "scan", "service", "signal", "symbol", "wifi", "wireless"]
    },
		{
      value: "satellite_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>satellite_alt</Icon> satellite_alt
      </div>),
      tags: ["alternative", "artificial", "communication", "satellite", "space", "space station", "television"]
    },
		{
      value: "save",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>save</Icon> save
      </div>),
      tags: ["data", "disk", "document", "drive", "file", "floppy", "multimedia", "save", "storage"]
    },
		{
      value: "save_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>save_alt</Icon> save_alt
      </div>),
      tags: ["alt", "arrow", "disk", "document", "down", "file", "floppy", "multimedia", "save"]
    },
		{
      value: "save_as",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>save_as</Icon> save_as
      </div>),
      tags: ["compose", "create", "data", "disk", "document", "draft", "drive", "edit", "editing", "file", "floppy", "input", "multimedia", "pen", "pencil", "save", "storage", "write", "writing"]
    },
		{
      value: "saved_search",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>saved_search</Icon> saved_search
      </div>),
      tags: ["find", "glass", "important", "look", "magnify", "magnifying", "marked", "saved", "search", "see", "star"]
    },
		{
      value: "savings",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>savings</Icon> savings
      </div>),
      tags: ["bank", "bill", "card", "cash", "coin", "commerce", "cost", "credit", "currency", "dollars", "finance", "money", "online", "pay", "payment", "pig", "piggy", "savings", "symbol"]
    },
		{
      value: "scale",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>scale</Icon> scale
      </div>),
      tags: ["measure", "monitor", "scale", "weight"]
    },
		{
      value: "scanner",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>scanner</Icon> scanner
      </div>),
      tags: ["copy", "device", "hardware", "machine", "scan", "scanner"]
    },
		{
      value: "scatter_plot",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>scatter_plot</Icon> scatter_plot
      </div>),
      tags: ["analytics", "bar", "bars", "chart", "circles", "data", "diagram", "dot", "graph", "infographic", "measure", "metrics", "plot", "scatter", "statistics", "tracking"]
    },
		{
      value: "schedule",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>schedule</Icon> schedule
      </div>),
      tags: ["clock", "date", "history", "recent", "schedule", "time"]
    },
		{
      value: "schedule_send",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>schedule_send</Icon> schedule_send
      </div>),
      tags: ["calendar", "clock", "date", "email", "letters", "mail", "remember", "schedule", "send", "share", "time"]
    },
		{
      value: "schema",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>schema</Icon> schema
      </div>),
      tags: ["analytics", "chart", "data", "diagram", "flow", "graph", "infographic", "measure", "metrics", "schema", "squares", "statistics", "tracking"]
    },
		{
      value: "school",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>school</Icon> school
      </div>),
      tags: ["academy", "achievement", "cap", "class", "college", "education", "graduation", "hat", "knowledge", "learning", "school", "university"]
    },
		{
      value: "science",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>science</Icon> science
      </div>),
      tags: ["beaker", "chemical", "chemistry", "experiment", "flask", "glass", "laboratory", "research", "science", "tube"]
    },
		{
      value: "score",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>score</Icon> score
      </div>),
      tags: ["2k", "alphabet", "analytics", "bar", "bars", "character", "chart", "data", "diagram", "digit", "font", "graph", "infographic", "letters", "measure", "metrics", "numbers", "score", "statistics", "symbol", "text", "tracking", "type"]
    },
		{
      value: "scoreboard",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>scoreboard</Icon> scoreboard
      </div>),
      tags: ["board", "points", "score", "scoreboard", "sports"]
    },
		{
      value: "screen_lock_landscape",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>screen_lock_landscape</Icon> screen_lock_landscape
      </div>),
      tags: ["Android", "OS", "device", "hardware", "iOS", "landscape", "lock", "mobile", "phone", "rotate", "screen", "tablet"]
    },
		{
      value: "screen_lock_portrait",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>screen_lock_portrait</Icon> screen_lock_portrait
      </div>),
      tags: ["Android", "OS", "device", "hardware", "iOS", "lock", "mobile", "phone", "portrait", "rotate", "screen", "tablet"]
    },
		{
      value: "screen_lock_rotation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>screen_lock_rotation</Icon> screen_lock_rotation
      </div>),
      tags: ["Android", "OS", "arrow", "device", "hardware", "iOS", "lock", "mobile", "phone", "rotate", "rotation", "screen", "tablet", "turn"]
    },
		{
      value: "screen_rotation",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>screen_rotation</Icon> screen_rotation
      </div>),
      tags: ["Android", "OS", "arrow", "device", "hardware", "iOS", "mobile", "phone", "rotate", "rotation", "screen", "tablet", "turn"]
    },
		{
      value: "screen_rotation_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>screen_rotation_alt</Icon> screen_rotation_alt
      </div>),
      tags: ["Android", "OS", "arrow", "device", "hardware", "iOS", "mobile", "phone", "rotate", "rotation", "screen", "tablet", "turn"]
    },
		{
      value: "screen_search_desktop",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>screen_search_desktop</Icon> screen_search_desktop
      </div>),
      tags: ["Android", "OS", "arrow", "desktop", "device", "hardware", "iOS", "lock", "monitor", "rotate", "screen", "web"]
    },
		{
      value: "screen_share",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>screen_share</Icon> screen_share
      </div>),
      tags: ["Android", "OS", "arrow", "cast", "chrome", "device", "display", "hardware", "iOS", "laptop", "mac", "mirror", "monitor", "screen", "share", "stream", "streaming", "web", "window"]
    },
		{
      value: "screenshot",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>screenshot</Icon> screenshot
      </div>),
      tags: ["Android", "OS", "cell", "crop", "device", "hardware", "iOS", "mobile", "phone", "screen", "screenshot", "tablet"]
    },
		{
      value: "screenshot_monitor",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>screenshot_monitor</Icon> screenshot_monitor
      </div>),
      tags: ["Android", "OS", "chrome", "desktop", "device", "display", "hardware", "iOS", "mac", "monitor", "screen", "screengrab", "screenshot", "web", "window"]
    },
		{
      value: "scuba_diving",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>scuba_diving</Icon> scuba_diving
      </div>),
      tags: ["diving", "entertainment", "exercise", "hobby", "scuba", "social", "swim", "swimming"]
    },
		{
      value: "sd",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sd</Icon> sd
      </div>),
      tags: ["alphabet", "camera", "card", "character", "data", "device", "digital", "drive", "flash", "font", "image", "letters", "memory", "photo", "sd", "secure", "symbol", "text", "type"]
    },
		{
      value: "sd_card",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sd_card</Icon> sd_card
      </div>),
      tags: ["camera", "card", "digital", "memory", "photos", "sd", "secure", "storage"]
    },
		{
      value: "sd_card_alert",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sd_card_alert</Icon> sd_card_alert
      </div>),
      tags: ["!", "alert", "attention", "camera", "card", "caution", "danger", "digital", "error", "exclamation", "important", "mark", "memory", "notification", "photos", "sd", "secure", "storage", "symbol", "warning"]
    },
		{
      value: "sd_storage",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sd_storage</Icon> sd_storage
      </div>),
      tags: ["camera", "card", "data", "digital", "memory", "sd", "secure", "storage"]
    },
		{
      value: "search",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>search</Icon> search
      </div>),
      tags: ["filter", "find", "glass", "look", "magnify", "magnifying", "search", "see"]
    },
		{
      value: "search_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>search_off</Icon> search_off
      </div>),
      tags: ["cancel", "clear", "close", "disabled", "enabled", "find", "glass", "look", "magnify", "magnifying", "off", "on", "search", "see", "slash", "stop", "x"]
    },
		{
      value: "security",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>security</Icon> security
      </div>),
      tags: ["certified", "privacy", "private", "protect", "protection", "security", "shield", "verified"]
    },
		{
      value: "security_update",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>security_update</Icon> security_update
      </div>),
      tags: ["Android", "OS", "arrow", "device", "down", "download", "hardware", "iOS", "mobile", "phone", "security", "tablet", "update"]
    },
		{
      value: "security_update_good",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>security_update_good</Icon> security_update_good
      </div>),
      tags: ["Android", "OS", "checkmark", "device", "good", "hardware", "iOS", "mobile", "ok", "phone", "security", "tablet", "tick", "update"]
    },
		{
      value: "security_update_warning",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>security_update_warning</Icon> security_update_warning
      </div>),
      tags: ["!", "Android", "OS", "alert", "attention", "caution", "danger", "device", "download", "error", "exclamation", "hardware", "iOS", "important", "mark", "mobile", "notification", "phone", "security", "symbol", "tablet", "update", "warning"]
    },
		{
      value: "segment",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>segment</Icon> segment
      </div>),
      tags: ["alignment", "fonts", "format", "lines", "list", "paragraph", "part", "piece", "rule", "rules", "segment", "style", "text"]
    },
		{
      value: "select_all",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>select_all</Icon> select_all
      </div>),
      tags: ["all", "dash", "dashed", "select", "selection", "square", "tool"]
    },
		{
      value: "self_improvement",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>self_improvement</Icon> self_improvement
      </div>),
      tags: ["body", "calm", "care", "chi", "human", "improvement", "meditate", "meditation", "people", "person", "relax", "self", "sitting", "wellbeing", "yoga", "zen"]
    },
		{
      value: "sell",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sell</Icon> sell
      </div>),
      tags: ["bill", "card", "cart", "cash", "coin", "commerce", "credit", "currency", "dollars", "money", "online", "pay", "payment", "price", "sell", "shopping", "tag"]
    },
		{
      value: "send",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>send</Icon> send
      </div>),
      tags: ["email", "mail", "message", "paper", "plane", "reply", "right", "send", "share"]
    },
		{
      value: "send_and_archive",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>send_and_archive</Icon> send_and_archive
      </div>),
      tags: ["archive", "arrow", "down", "download", "email", "letters", "mail", "save", "send", "share"]
    },
		{
      value: "send_time_extension",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>send_time_extension</Icon> send_time_extension
      </div>),
      tags: ["deliver", "dispatch", "envelop", "extension", "mail", "message", "schedule", "send", "time"]
    },
		{
      value: "send_to_mobile",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>send_to_mobile</Icon> send_to_mobile
      </div>),
      tags: ["Android", "OS", "arrow", "device", "export", "forward", "hardware", "iOS", "mobile", "phone", "right", "send", "share", "tablet", "to"]
    },
		{
      value: "sensor_door",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sensor_door</Icon> sensor_door
      </div>),
      tags: ["alarm", "security", "security system"]
    },
		{
      value: "sensor_occupied",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sensor_occupied</Icon> sensor_occupied
      </div>),
      tags: ["body", "body response", "connection", "fitbit", "human", "network", "people", "person", "scan", "sensors", "signal", "smart body scan sensor", "wireless"]
    },
		{
      value: "sensor_window",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sensor_window</Icon> sensor_window
      </div>),
      tags: ["alarm", "security", "security system"]
    },
		{
      value: "sensors",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sensors</Icon> sensors
      </div>),
      tags: ["connection", "network", "scan", "sensors", "signal", "wireless"]
    },
		{
      value: "sensors_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sensors_off</Icon> sensors_off
      </div>),
      tags: ["connection", "disabled", "enabled", "network", "off", "on", "scan", "sensors", "signal", "slash", "wireless"]
    },
		{
      value: "sentiment_dissatisfied",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sentiment_dissatisfied</Icon> sentiment_dissatisfied
      </div>),
      tags: ["angry", "disappointed", "dislike", "dissatisfied", "emotions", "expressions", "face", "feelings", "frown", "mood", "person", "sad", "sentiment", "survey", "unhappy", "unsatisfied", "upset"]
    },
		{
      value: "sentiment_neutral",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sentiment_neutral</Icon> sentiment_neutral
      </div>),
      tags: ["emotionless", "emotions", "expressions", "face", "feelings", "fine", "indifference", "mood", "neutral", "okay", "person", "sentiment", "survey"]
    },
		{
      value: "sentiment_satisfied",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sentiment_satisfied</Icon> sentiment_satisfied
      </div>),
      tags: ["emotions", "expressions", "face", "feelings", "glad", "happiness", "happy", "like", "mood", "person", "pleased", "satisfied", "sentiment", "smile", "smiling", "survey"]
    },
		{
      value: "sentiment_satisfied_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sentiment_satisfied_alt</Icon> sentiment_satisfied_alt
      </div>),
      tags: ["account", "alt", "emoji", "face", "happy", "human", "people", "person", "profile", "satisfied", "sentiment", "smile", "user"]
    },
		{
      value: "sentiment_very_dissatisfied",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sentiment_very_dissatisfied</Icon> sentiment_very_dissatisfied
      </div>),
      tags: ["angry", "disappointed", "dislike", "dissatisfied", "emotions", "expressions", "face", "feelings", "mood", "person", "sad", "sentiment", "sorrow", "survey", "unhappy", "unsatisfied", "upset", "very"]
    },
		{
      value: "sentiment_very_satisfied",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sentiment_very_satisfied</Icon> sentiment_very_satisfied
      </div>),
      tags: ["emotions", "expressions", "face", "feelings", "glad", "happiness", "happy", "like", "mood", "person", "pleased", "satisfied", "sentiment", "smile", "smiling", "survey", "very"]
    },
		{
      value: "set_meal",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>set_meal</Icon> set_meal
      </div>),
      tags: ["chopsticks", "dinner", "fish", "food", "lunch", "meal", "restaurant", "set", "teishoku"]
    },
		{
      value: "settings",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings</Icon> settings
      </div>),
      tags: ["application", "change", "details", "gear", "info", "information", "options", "personal", "service", "settings"]
    },
		{
      value: "settings_accessibility",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_accessibility</Icon> settings_accessibility
      </div>),
      tags: ["accessibility", "body", "details", "human", "information", "people", "person", "personal", "preferences", "profile", "settings", "user"]
    },
		{
      value: "settings_applications",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_applications</Icon> settings_applications
      </div>),
      tags: ["application", "change", "details", "gear", "info", "information", "options", "personal", "service", "settings"]
    },
		{
      value: "settings_backup_restore",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_backup_restore</Icon> settings_backup_restore
      </div>),
      tags: ["arrow", "back", "backup", "backwards", "refresh", "restore", "reverse", "rotate", "settings"]
    },
		{
      value: "settings_bluetooth",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_bluetooth</Icon> settings_bluetooth
      </div>),
      tags: ["bluetooth", "connect", "connection", "connectivity", "device", "settings", "signal", "symbol"]
    },
		{
      value: "settings_brightness",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_brightness</Icon> settings_brightness
      </div>),
      tags: ["brightness", "dark", "filter", "light", "mode", "setting", "settings"]
    },
		{
      value: "settings_cell",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_cell</Icon> settings_cell
      </div>),
      tags: ["Android", "OS", "cell", "device", "hardware", "iOS", "mobile", "phone", "settings", "tablet"]
    },
		{
      value: "settings_ethernet",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_ethernet</Icon> settings_ethernet
      </div>),
      tags: ["arrows", "computer", "connect", "connection", "connectivity", "dots", "ethernet", "internet", "network", "settings", "wifi"]
    },
		{
      value: "settings_input_antenna",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_input_antenna</Icon> settings_input_antenna
      </div>),
      tags: ["airplay", "antenna", "arrows", "cast", "computer", "connect", "connection", "connectivity", "dots", "input", "internet", "network", "screencast", "settings", "stream", "wifi", "wireless"]
    },
		{
      value: "settings_input_component",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_input_component</Icon> settings_input_component
      </div>),
      tags: ["audio", "av", "cable", "cables", "component", "connect", "connection", "connectivity", "input", "internet", "plug", "points", "settings", "video", "wifi"]
    },
		{
      value: "settings_input_composite",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_input_composite</Icon> settings_input_composite
      </div>),
      tags: ["component", "composite", "connection", "connectivity", "input", "plug", "points", "settings"]
    },
		{
      value: "settings_input_hdmi",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_input_hdmi</Icon> settings_input_hdmi
      </div>),
      tags: ["cable", "connection", "connectivity", "definition", "hdmi", "high", "input", "plug", "plugin", "points", "settings", "video", "wire"]
    },
		{
      value: "settings_input_svideo",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_input_svideo</Icon> settings_input_svideo
      </div>),
      tags: ["cable", "connection", "connectivity", "definition", "input", "plug", "plugin", "points", "settings", "standard", "svideo", "video"]
    },
		{
      value: "settings_overscan",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_overscan</Icon> settings_overscan
      </div>),
      tags: ["arrows", "expand", "image", "photo", "picture", "scan", "settings"]
    },
		{
      value: "settings_phone",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_phone</Icon> settings_phone
      </div>),
      tags: ["call", "cell", "contact", "device", "hardware", "mobile", "phone", "settings", "telephone"]
    },
		{
      value: "settings_power",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_power</Icon> settings_power
      </div>),
      tags: ["info", "information", "off", "on", "power", "save", "settings", "shutdown"]
    },
		{
      value: "settings_remote",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_remote</Icon> settings_remote
      </div>),
      tags: ["bluetooth", "connection", "connectivity", "device", "remote", "settings", "signal", "wifi", "wireless"]
    },
		{
      value: "settings_suggest",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_suggest</Icon> settings_suggest
      </div>),
      tags: ["ai", "artificial", "automatic", "automation", "change", "custom", "details", "gear", "genai", "intelligence", "magic", "options", "recommendation", "service", "settings", "smart", "spark", "sparkle", "star", "suggest", "suggestion", "system"]
    },
		{
      value: "settings_system_daydream",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_system_daydream</Icon> settings_system_daydream
      </div>),
      tags: ["backup", "cloud", "daydream", "drive", "settings", "storage", "system"]
    },
		{
      value: "settings_voice",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>settings_voice</Icon> settings_voice
      </div>),
      tags: ["mic", "microphone", "record", "recorder", "settings", "speaker", "voice"]
    },
		{
      value: "severe_cold",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>severe_cold</Icon> severe_cold
      </div>),
      tags: ["!", "alert", "attention", "caution", "climate", "cold", "crisis", "danger", "disaster", "error", "exclamation", "important", "notification", "severe", "snow", "snowflake", "warning", "weather", "winter"]
    },
		{
      value: "shape_line",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shape_line</Icon> shape_line
      </div>),
      tags: ["circle", "draw", "edit", "editing", "line", "shape", "square"]
    },
		{
      value: "share",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>share</Icon> share
      </div>),
      tags: ["DISABLE_IOS", "android", "connect", "contect", "disable_ios", "link", "media", "multimedia", "multiple", "network", "options", "share", "shared", "sharing", "social"]
    },
		{
      value: "share_location",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>share_location</Icon> share_location
      </div>),
      tags: ["dash", "dashed", "destination", "direction", "gps", "location", "maps", "pin", "place", "share", "stop", "tracking"]
    },
		{
      value: "shield",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shield</Icon> shield
      </div>),
      tags: ["certified", "privacy", "private", "protect", "protection", "security", "shield", "verified"]
    },
		{
      value: "shield_moon",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shield_moon</Icon> shield_moon
      </div>),
      tags: ["certified", "do not disturb", "moon", "night", "privacy", "private", "protect", "protection", "security", "shield", "verified"]
    },
		{
      value: "shop",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shop</Icon> shop
      </div>),
      tags: ["bag", "bill", "buy", "card", "cart", "cash", "coin", "commerce", "credit", "currency", "dollars", "google", "money", "online", "pay", "payment", "play", "shop", "shopping", "store"]
    },
		{
      value: "shop_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shop_2</Icon> shop_2
      </div>),
      tags: ["2", "add", "arrow", "buy", "cart", "google", "play", "purchase", "shop", "shopping"]
    },
		{
      value: "shop_two",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shop_two</Icon> shop_two
      </div>),
      tags: ["add", "arrow", "buy", "cart", "google", "play", "purchase", "shop", "shopping", "two"]
    },
		{
      value: "shopping_bag",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shopping_bag</Icon> shopping_bag
      </div>),
      tags: ["bag", "bill", "business", "buy", "card", "cart", "cash", "coin", "commerce", "credit", "currency", "dollars", "money", "online", "pay", "payment", "shop", "shopping", "store", "storefront"]
    },
		{
      value: "shopping_basket",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shopping_basket</Icon> shopping_basket
      </div>),
      tags: ["add", "basket", "bill", "buy", "card", "cart", "cash", "checkout", "coin", "commerce", "credit", "currency", "dollars", "money", "online", "pay", "payment", "shopping"]
    },
		{
      value: "shopping_cart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shopping_cart</Icon> shopping_cart
      </div>),
      tags: ["add", "bill", "buy", "card", "cart", "cash", "checkout", "coin", "commerce", "credit", "currency", "dollars", "money", "online", "pay", "payment", "shopping"]
    },
		{
      value: "shopping_cart_checkout",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shopping_cart_checkout</Icon> shopping_cart_checkout
      </div>),
      tags: ["arrow", "cart", "cash", "checkout", "coin", "commerce", "currency", "dollars", "money", "online", "pay", "payment", "right", "shopping"]
    },
		{
      value: "short_text",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>short_text</Icon> short_text
      </div>),
      tags: ["brief", "comment", "doc", "document", "note", "short", "text", "write", "writing"]
    },
		{
      value: "shortcut",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shortcut</Icon> shortcut
      </div>),
      tags: ["arrow", "direction", "forward", "right", "shortcut"]
    },
		{
      value: "show_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>show_chart</Icon> show_chart
      </div>),
      tags: ["analytics", "bar", "bars", "chart", "data", "diagram", "graph", "infographic", "line", "measure", "metrics", "presentation", "show chart", "statistics", "tracking"]
    },
		{
      value: "shower",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shower</Icon> shower
      </div>),
      tags: ["bath", "bathroom", "closet", "home", "house", "place", "plumbing", "room", "shower", "sprinkler", "wash", "water", "wc"]
    },
		{
      value: "shuffle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shuffle</Icon> shuffle
      </div>),
      tags: ["arrow", "arrows", "control", "controls", "music", "random", "shuffle", "video"]
    },
		{
      value: "shuffle_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shuffle_on</Icon> shuffle_on
      </div>),
      tags: ["arrow", "arrows", "control", "controls", "music", "on", "random", "shuffle", "video"]
    },
		{
      value: "shutter_speed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>shutter_speed</Icon> shutter_speed
      </div>),
      tags: ["aperture", "camera", "duration", "image", "lens", "photo", "photography", "photos", "picture", "setting", "shutter", "speed", "stop", "time", "timer", "watch"]
    },
		{
      value: "sick",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sick</Icon> sick
      </div>),
      tags: ["covid", "discomfort", "emotions", "expressions", "face", "feelings", "fever", "flu", "ill", "mood", "pain", "person", "sick", "survey", "upset"]
    },
		{
      value: "sign_language",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sign_language</Icon> sign_language
      </div>),
      tags: ["communication", "deaf", "fingers", "gesture", "hand", "language", "sign"]
    },
		{
      value: "signal_cellular_0_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_cellular_0_bar</Icon> signal_cellular_0_bar
      </div>),
      tags: ["0", "bar", "cell", "cellular", "data", "internet", "mobile", "network", "phone", "signal", "speed", "wifi", "wireless"]
    },
		{
      value: "signal_cellular_4_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_cellular_4_bar</Icon> signal_cellular_4_bar
      </div>),
      tags: ["4", "bar", "cell", "cellular", "data", "internet", "mobile", "network", "phone", "signal", "speed", "wifi", "wireless"]
    },
		{
      value: "signal_cellular_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_cellular_alt</Icon> signal_cellular_alt
      </div>),
      tags: ["alt", "analytics", "bar", "cell", "cellular", "chart", "data", "diagram", "graph", "infographic", "internet", "measure", "metrics", "mobile", "network", "phone", "signal", "statistics", "tracking", "wifi", "wireless"]
    },
		{
      value: "signal_cellular_alt_1_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_cellular_alt_1_bar</Icon> signal_cellular_alt_1_bar
      </div>),
      tags: ["1", "bar", "cell", "cellular", "data", "internet", "mobile", "network", "phone", "signal", "speed", "wifi", "wireless"]
    },
		{
      value: "signal_cellular_alt_2_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_cellular_alt_2_bar</Icon> signal_cellular_alt_2_bar
      </div>),
      tags: ["2", "bar", "cell", "cellular", "data", "internet", "mobile", "network", "phone", "signal", "speed", "wifi", "wireless"]
    },
		{
      value: "signal_cellular_connected_no_internet_0_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_cellular_connected_no_internet_0_bar</Icon> signal_cellular_connected_no_internet_0_bar
      </div>),
      tags: ["!", "0", "alert", "attention", "bar", "caution", "cell", "cellular", "connected", "danger", "data", "error", "exclamation", "important", "internet", "mark", "mobile", "network", "no", "notification", "phone", "signal", "symbol", "warning", "wifi", "wireless"]
    },
		{
      value: "signal_cellular_connected_no_internet_4_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_cellular_connected_no_internet_4_bar</Icon> signal_cellular_connected_no_internet_4_bar
      </div>),
      tags: ["!", "4", "alert", "attention", "bar", "caution", "cell", "cellular", "connected", "danger", "data", "error", "exclamation", "important", "internet", "mark", "mobile", "network", "no", "notification", "phone", "signal", "symbol", "warning", "wifi", "wireless"]
    },
		{
      value: "signal_cellular_no_sim",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_cellular_no_sim</Icon> signal_cellular_no_sim
      </div>),
      tags: ["camera", "card", "cellular", "chip", "device", "disabled", "enabled", "memory", "no", "off", "offline", "on", "phone", "signal", "sim", "slash", "storage"]
    },
		{
      value: "signal_cellular_nodata",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_cellular_nodata</Icon> signal_cellular_nodata
      </div>),
      tags: ["cell", "cellular", "clear", "data", "internet", "mobile", "network", "no", "nodata", "offline", "phone", "quit", "remove", "signal", "wifi", "wireless", "x"]
    },
		{
      value: "signal_cellular_null",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_cellular_null</Icon> signal_cellular_null
      </div>),
      tags: ["cell", "cellular", "data", "internet", "mobile", "network", "null", "phone", "signal", "wifi", "wireless"]
    },
		{
      value: "signal_cellular_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_cellular_off</Icon> signal_cellular_off
      </div>),
      tags: ["cell", "cellular", "data", "disabled", "enabled", "internet", "mobile", "network", "off", "offline", "on", "phone", "signal", "slash", "wifi", "wireless"]
    },
		{
      value: "signal_wifi_0_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_wifi_0_bar</Icon> signal_wifi_0_bar
      </div>),
      tags: ["0", "bar", "cell", "cellular", "data", "internet", "mobile", "network", "phone", "signal", "wifi", "wireless"]
    },
		{
      value: "signal_wifi_4_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_wifi_4_bar</Icon> signal_wifi_4_bar
      </div>),
      tags: ["4", "bar", "cell", "cellular", "data", "internet", "mobile", "network", "phone", "signal", "wifi", "wireless"]
    },
		{
      value: "signal_wifi_4_bar_lock",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_wifi_4_bar_lock</Icon> signal_wifi_4_bar_lock
      </div>),
      tags: ["4", "bar", "cell", "cellular", "data", "internet", "lock", "locked", "mobile", "network", "password", "phone", "privacy", "private", "protection", "safety", "secure", "security", "signal", "wifi", "wireless"]
    },
		{
      value: "signal_wifi_bad",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_wifi_bad</Icon> signal_wifi_bad
      </div>),
      tags: ["bad", "bar", "cancel", "cell", "cellular", "clear", "close", "data", "exit", "internet", "mobile", "network", "no", "phone", "quit", "remove", "signal", "stop", "wifi", "wireless", "x"]
    },
		{
      value: "signal_wifi_connected_no_internet_4",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_wifi_connected_no_internet_4</Icon> signal_wifi_connected_no_internet_4
      </div>),
      tags: ["4", "cell", "cellular", "connected", "data", "internet", "mobile", "network", "no", "offline", "phone", "signal", "wifi", "wireless", "x"]
    },
		{
      value: "signal_wifi_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_wifi_off</Icon> signal_wifi_off
      </div>),
      tags: ["cell", "cellular", "data", "disabled", "enabled", "internet", "mobile", "network", "off", "on", "phone", "signal", "slash", "speed", "wifi", "wireless"]
    },
		{
      value: "signal_wifi_statusbar_4_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_wifi_statusbar_4_bar</Icon> signal_wifi_statusbar_4_bar
      </div>),
      tags: ["4", "bar", "cell", "cellular", "data", "internet", "mobile", "network", "phone", "signal", "speed", "statusbar", "wifi", "wireless"]
    },
		{
      value: "signal_wifi_statusbar_connected_no_internet_4",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_wifi_statusbar_connected_no_internet_4</Icon> signal_wifi_statusbar_connected_no_internet_4
      </div>),
      tags: ["!", "4", "alert", "attention", "caution", "cell", "cellular", "connected", "danger", "data", "error", "exclamation", "important", "internet", "mark", "mobile", "network", "no", "notification", "phone", "signal", "speed", "statusbar", "symbol", "warning", "wifi", "wireless"]
    },
		{
      value: "signal_wifi_statusbar_null",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signal_wifi_statusbar_null</Icon> signal_wifi_statusbar_null
      </div>),
      tags: ["cell", "cellular", "data", "internet", "mobile", "network", "null", "phone", "signal", "speed", "statusbar", "wifi", "wireless"]
    },
		{
      value: "signpost",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>signpost</Icon> signpost
      </div>),
      tags: ["arrow", "direction", "left", "maps", "right", "signal", "signs", "street", "traffic"]
    },
		{
      value: "sim_card",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sim_card</Icon> sim_card
      </div>),
      tags: ["camera", "card", "chip", "device", "memory", "phone", "sim", "storage"]
    },
		{
      value: "sim_card_alert",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sim_card_alert</Icon> sim_card_alert
      </div>),
      tags: ["!", "alert", "attention", "camera", "card", "caution", "danger", "digital", "error", "exclamation", "important", "mark", "memory", "notification", "photos", "sd", "secure", "storage", "symbol", "warning"]
    },
		{
      value: "sim_card_download",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sim_card_download</Icon> sim_card_download
      </div>),
      tags: ["arrow", "camera", "card", "chip", "device", "down", "download", "memory", "phone", "sim", "storage"]
    },
		{
      value: "single_bed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>single_bed</Icon> single_bed
      </div>),
      tags: ["bed", "bedroom", "double", "furniture", "home", "hotel", "house", "king", "night", "pillows", "queen", "rest", "room", "single", "sleep", "twin"]
    },
		{
      value: "sip",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sip</Icon> sip
      </div>),
      tags: ["alphabet", "call", "character", "dialer", "font", "initiation", "internet", "letters", "over", "phone", "protocol", "routing", "session", "sip", "symbol", "text", "type", "voice"]
    },
		{
      value: "skateboarding",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>skateboarding</Icon> skateboarding
      </div>),
      tags: ["athlete", "athletic", "body", "entertainment", "exercise", "hobby", "human", "people", "person", "skate", "skateboarder", "skateboarding", "social", "sports"]
    },
		{
      value: "skip_next",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>skip_next</Icon> skip_next
      </div>),
      tags: ["arrow", "control", "controls", "music", "next", "play", "previous", "skip", "video"]
    },
		{
      value: "skip_previous",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>skip_previous</Icon> skip_previous
      </div>),
      tags: ["arrow", "control", "controls", "music", "next", "play", "previous", "skip", "video"]
    },
		{
      value: "sledding",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sledding</Icon> sledding
      </div>),
      tags: ["athlete", "athletic", "body", "entertainment", "exercise", "hobby", "human", "people", "person", "sled", "sledding", "sledge", "snow", "social", "sports", "travel", "winter"]
    },
		{
      value: "slideshow",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>slideshow</Icon> slideshow
      </div>),
      tags: ["movie", "photos", "play", "slideshow", "square", "video", "view"]
    },
		{
      value: "slow_motion_video",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>slow_motion_video</Icon> slow_motion_video
      </div>),
      tags: ["arrow", "control", "controls", "dash", "dashed", "motion", "music", "play", "slow", "speed", "video"]
    },
		{
      value: "smart_button",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>smart_button</Icon> smart_button
      </div>),
      tags: ["action", "ai", "artificial", "automatic", "automation", "button", "components", "composer", "custom", "function", "genai", "intelligence", "interface", "magic", "site", "smart", "spark", "sparkle", "special", "star", "stars", "ui", "ux", "web", "website"]
    },
		{
      value: "smart_display",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>smart_display</Icon> smart_display
      </div>),
      tags: ["airplay", "cast", "chrome", "connect", "device", "display", "play", "screen", "screencast", "smart", "stream", "television", "tv", "video", "wireless"]
    },
		{
      value: "smart_screen",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>smart_screen</Icon> smart_screen
      </div>),
      tags: ["Android", "OS", "airplay", "cast", "cell", "connect", "device", "hardware", "iOS", "mobile", "phone", "screen", "screencast", "smart", "stream", "tablet", "video"]
    },
		{
      value: "smart_toy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>smart_toy</Icon> smart_toy
      </div>),
      tags: ["bot", "droid", "games", "robot", "smart", "toy"]
    },
		{
      value: "smartphone",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>smartphone</Icon> smartphone
      </div>),
      tags: ["Android", "OS", "call", "cell", "cellphone", "chat", "device", "hardware", "iOS", "mobile", "phone", "smartphone", "tablet", "text"]
    },
		{
      value: "smoke_free",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>smoke_free</Icon> smoke_free
      </div>),
      tags: ["cigarette", "disabled", "enabled", "free", "never", "no", "off", "on", "places", "prohibited", "slash", "smoke", "smoking", "tobacco", "warning", "zone"]
    },
		{
      value: "smoking_rooms",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>smoking_rooms</Icon> smoking_rooms
      </div>),
      tags: ["allowed", "cigarette", "places", "rooms", "smoke", "smoking", "tobacco", "zone"]
    },
		{
      value: "sms",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sms</Icon> sms
      </div>),
      tags: ["3", "bubble", "chat", "communication", "conversation", "dots", "message", "more", "service", "sms", "speech", "three"]
    },
		{
      value: "sms_failed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sms_failed</Icon> sms_failed
      </div>),
      tags: ["!", "alert", "announcement", "attention", "caution", "chat", "chat bubble", "comment", "communicate", "communication", "conversation", "danger", "error", "exclamation", "failed", "feedback", "important", "mark", "message", "notification", "service", "sms", "speech", "symbol", "warning"]
    },
		{
      value: "snippet_folder",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>snippet_folder</Icon> snippet_folder
      </div>),
      tags: ["data", "doc", "document", "drive", "file", "folder", "sheet", "slide", "snippet", "storage"]
    },
		{
      value: "snooze",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>snooze</Icon> snooze
      </div>),
      tags: ["alarm", "bell", "clock", "duration", "notification", "snooze", "time", "timer", "watch", "z"]
    },
		{
      value: "snowboarding",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>snowboarding</Icon> snowboarding
      </div>),
      tags: ["athlete", "athletic", "body", "entertainment", "exercise", "hobby", "human", "people", "person", "snow", "snowboarding", "social", "sports", "travel", "winter"]
    },
		{
      value: "snowmobile",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>snowmobile</Icon> snowmobile
      </div>),
      tags: ["automobile", "car", "direction", "skimobile", "snow", "snowmobile", "social", "sports", "transportation", "travel", "vehicle", "winter"]
    },
		{
      value: "snowshoeing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>snowshoeing</Icon> snowshoeing
      </div>),
      tags: ["body", "human", "people", "person", "snow", "snowshoe", "snowshoeing", "sports", "travel", "walking", "winter"]
    },
		{
      value: "soap",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>soap</Icon> soap
      </div>),
      tags: ["bathroom", "clean", "fingers", "gesture", "hand", "soap", "wash", "wc"]
    },
		{
      value: "social_distance",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>social_distance</Icon> social_distance
      </div>),
      tags: ["6", "apart", "body", "distance", "ft", "human", "people", "person", "social", "space"]
    },
		{
      value: "solar_power",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>solar_power</Icon> solar_power
      </div>),
      tags: ["eco", "energy", "heat", "nest", "power", "solar", "sun", "sunny"]
    },
		{
      value: "sort",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sort</Icon> sort
      </div>),
      tags: ["filter", "find", "lines", "list", "organize", "sort"]
    },
		{
      value: "sort_by_alpha",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sort_by_alpha</Icon> sort_by_alpha
      </div>),
      tags: ["alphabet", "alphabetize", "az", "by alpha", "character", "font", "letters", "list", "order", "organize", "sort", "symbol", "text", "type"]
    },
		{
      value: "sos",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sos</Icon> sos
      </div>),
      tags: ["font", "help", "letters", "save", "sos", "text", "type"]
    },
		{
      value: "soup_kitchen",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>soup_kitchen</Icon> soup_kitchen
      </div>),
      tags: ["breakfast", "brunch", "dining", "food", "kitchen", "lunch", "meal", "soup"]
    },
		{
      value: "source",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>source</Icon> source
      </div>),
      tags: ["code", "composer", "content", "creation", "data", "doc", "document", "file", "folder", "mode", "source", "storage", "view"]
    },
		{
      value: "south",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>south</Icon> south
      </div>),
      tags: ["arrow", "directional", "down", "maps", "navigation", "south"]
    },
		{
      value: "south_america",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>south_america</Icon> south_america
      </div>),
      tags: ["continent", "landscape", "place", "region", "south america"]
    },
		{
      value: "south_east",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>south_east</Icon> south_east
      </div>),
      tags: ["arrow", "directional", "down", "east", "maps", "navigation", "right", "south"]
    },
		{
      value: "south_west",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>south_west</Icon> south_west
      </div>),
      tags: ["arrow", "directional", "down", "left", "maps", "navigation", "south", "west"]
    },
		{
      value: "spa",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>spa</Icon> spa
      </div>),
      tags: ["aromatherapy", "flower", "healthcare", "leaf", "massage", "meditation", "nature", "petals", "places", "relax", "spa", "wellbeing", "wellness"]
    },
		{
      value: "space_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>space_bar</Icon> space_bar
      </div>),
      tags: ["bar", "keyboard", "line", "space"]
    },
		{
      value: "space_dashboard",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>space_dashboard</Icon> space_dashboard
      </div>),
      tags: ["cards", "dashboard", "format", "grid", "layout", "rectangle", "shapes", "space", "squares", "web", "website"]
    },
		{
      value: "spatial_audio",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>spatial_audio</Icon> spatial_audio
      </div>),
      tags: ["audio", "music", "note", "sound", "spatial"]
    },
		{
      value: "spatial_audio_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>spatial_audio_off</Icon> spatial_audio_off
      </div>),
      tags: ["audio", "disabled", "enabled", "music", "note", "off", "offline", "on", "slash", "sound", "spatial"]
    },
		{
      value: "spatial_tracking",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>spatial_tracking</Icon> spatial_tracking
      </div>),
      tags: ["audio", "disabled", "enabled", "music", "note", "off", "offline", "on", "slash", "sound", "spatial", "tracking"]
    },
		{
      value: "speaker",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>speaker</Icon> speaker
      </div>),
      tags: ["box", "electronic", "loud", "music", "sound", "speaker", "stereo", "system", "video"]
    },
		{
      value: "speaker_group",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>speaker_group</Icon> speaker_group
      </div>),
      tags: ["box", "electronic", "group", "loud", "multiple", "music", "sound", "speaker", "stereo", "system", "video"]
    },
		{
      value: "speaker_notes",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>speaker_notes</Icon> speaker_notes
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "format", "list", "message", "notes", "speaker", "speech", "text"]
    },
		{
      value: "speaker_notes_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>speaker_notes_off</Icon> speaker_notes_off
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "disabled", "enabled", "format", "list", "message", "notes", "off", "on", "slash", "speaker", "speech", "text"]
    },
		{
      value: "speaker_phone",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>speaker_phone</Icon> speaker_phone
      </div>),
      tags: ["Android", "OS", "audio", "cell", "device", "hardware", "iOS", "mobile", "phone", "sound", "speaker", "tablet", "volume"]
    },
		{
      value: "speed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>speed</Icon> speed
      </div>),
      tags: ["arrow", "control", "controls", "fast", "gauge", "meter", "motion", "music", "slow", "speed", "speedometer", "velocity", "video"]
    },
		{
      value: "spellcheck",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>spellcheck</Icon> spellcheck
      </div>),
      tags: ["a", "alphabet", "approve", "character", "check", "font", "letters", "mark", "ok", "processor", "proofread", "select", "spell", "spellcheck", "symbol", "text", "tick", "type", "word", "write", "yes"]
    },
		{
      value: "splitscreen",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>splitscreen</Icon> splitscreen
      </div>),
      tags: ["column", "grid", "layout", "multitasking", "row", "screen", "spaces", "split", "splitscreen", "window"]
    },
		{
      value: "spoke",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>spoke</Icon> spoke
      </div>),
      tags: ["connection", "network", "radius", "spoke"]
    },
		{
      value: "sports",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports</Icon> sports
      </div>),
      tags: ["athlete", "athletic", "blowing", "coach", "entertainment", "exercise", "game", "hobby", "instrument", "referee", "social", "sound", "sports", "warning", "whistle"]
    },
		{
      value: "sports_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_bar</Icon> sports_bar
      </div>),
      tags: ["alcohol", "bar", "beer", "drink", "liquor", "pint", "places", "pub", "sports"]
    },
		{
      value: "sports_baseball",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_baseball</Icon> sports_baseball
      </div>),
      tags: ["athlete", "athletic", "ball", "baseball", "entertainment", "exercise", "game", "hobby", "social", "sports"]
    },
		{
      value: "sports_basketball",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_basketball</Icon> sports_basketball
      </div>),
      tags: ["athlete", "athletic", "ball", "basketball", "entertainment", "exercise", "game", "hobby", "social", "sports"]
    },
		{
      value: "sports_cricket",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_cricket</Icon> sports_cricket
      </div>),
      tags: ["athlete", "athletic", "ball", "bat", "cricket", "entertainment", "exercise", "game", "hobby", "social", "sports"]
    },
		{
      value: "sports_esports",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_esports</Icon> sports_esports
      </div>),
      tags: ["asset", "console", "control", "controller", "device", "entertainment", "esports", "game", "gamepad", "gaming", "google", "handheld", "hardware", "hobby", "online", "playstation", "remote", "social", "sports", "stadia", "video", "video game", "videogame", "xbox"]
    },
		{
      value: "sports_football",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_football</Icon> sports_football
      </div>),
      tags: ["athlete", "athletic", "ball", "entertainment", "exercise", "football", "game", "hobby", "social", "sports"]
    },
		{
      value: "sports_golf",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_golf</Icon> sports_golf
      </div>),
      tags: ["athlete", "athletic", "ball", "club", "entertainment", "exercise", "game", "golf", "golfer", "golfing", "hobby", "social", "sports"]
    },
		{
      value: "sports_gymnastics",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_gymnastics</Icon> sports_gymnastics
      </div>),
      tags: ["athlete", "athletic", "entertainment", "exercise", "gymnastics", "hobby", "social", "sports"]
    },
		{
      value: "sports_handball",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_handball</Icon> sports_handball
      </div>),
      tags: ["athlete", "athletic", "ball", "body", "entertainment", "exercise", "game", "handball", "hobby", "human", "people", "person", "social", "sports"]
    },
		{
      value: "sports_hockey",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_hockey</Icon> sports_hockey
      </div>),
      tags: ["athlete", "athletic", "entertainment", "exercise", "game", "hobby", "hockey", "social", "sports", "sticks"]
    },
		{
      value: "sports_kabaddi",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_kabaddi</Icon> sports_kabaddi
      </div>),
      tags: ["athlete", "athletic", "body", "combat", "entertainment", "exercise", "fighting", "game", "hobby", "human", "kabaddi", "people", "person", "social", "sports", "wrestle", "wrestling"]
    },
		{
      value: "sports_martial_arts",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_martial_arts</Icon> sports_martial_arts
      </div>),
      tags: ["arts", "athlete", "athletic", "entertainment", "exercise", "hobby", "human", "karate", "martial", "people", "person", "social", "sports"]
    },
		{
      value: "sports_mma",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_mma</Icon> sports_mma
      </div>),
      tags: ["arts", "athlete", "athletic", "boxing", "combat", "entertainment", "exercise", "fighting", "game", "glove", "hobby", "martial", "mixed", "mma", "social", "sports"]
    },
		{
      value: "sports_motorsports",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_motorsports</Icon> sports_motorsports
      </div>),
      tags: ["athlete", "athletic", "automobile", "bike", "drive", "driving", "entertainment", "helmet", "hobby", "motorcycle", "motorsports", "protect", "social", "sports", "vehicle"]
    },
		{
      value: "sports_rugby",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_rugby</Icon> sports_rugby
      </div>),
      tags: ["athlete", "athletic", "ball", "entertainment", "exercise", "game", "hobby", "rugby", "social", "sports"]
    },
		{
      value: "sports_score",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_score</Icon> sports_score
      </div>),
      tags: ["destination", "flag", "goal", "score", "sports"]
    },
		{
      value: "sports_soccer",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_soccer</Icon> sports_soccer
      </div>),
      tags: ["athlete", "athletic", "ball", "entertainment", "exercise", "football", "game", "hobby", "soccer", "social", "sports"]
    },
		{
      value: "sports_tennis",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_tennis</Icon> sports_tennis
      </div>),
      tags: ["athlete", "athletic", "ball", "bat", "entertainment", "exercise", "game", "hobby", "racket", "social", "sports", "tennis"]
    },
		{
      value: "sports_volleyball",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sports_volleyball</Icon> sports_volleyball
      </div>),
      tags: ["athlete", "athletic", "ball", "entertainment", "exercise", "game", "hobby", "social", "sports", "volleyball"]
    },
		{
      value: "square",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>square</Icon> square
      </div>),
      tags: ["draw", "four", "quadrangle", "shape", "sides", "square"]
    },
		{
      value: "square_foot",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>square_foot</Icon> square_foot
      </div>),
      tags: ["construction", "feet", "foot", "inches", "length", "measurement", "ruler", "school", "set", "square", "tools"]
    },
		{
      value: "ssid_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>ssid_chart</Icon> ssid_chart
      </div>),
      tags: ["chart", "graph", "lines", "network", "ssid", "wifi"]
    },
		{
      value: "stacked_bar_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stacked_bar_chart</Icon> stacked_bar_chart
      </div>),
      tags: ["analytics", "bar", "chart-chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "stacked", "statistics", "tracking"]
    },
		{
      value: "stacked_line_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stacked_line_chart</Icon> stacked_line_chart
      </div>),
      tags: ["analytics", "chart", "data", "diagram", "graph", "infographic", "line", "measure", "metrics", "stacked", "statistics", "tracking"]
    },
		{
      value: "stadium",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stadium</Icon> stadium
      </div>),
      tags: ["activity", "amphitheater", "arena", "coliseum", "event", "local", "stadium", "star", "things", "ticket"]
    },
		{
      value: "stairs",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stairs</Icon> stairs
      </div>),
      tags: ["down", "staircase", "stairs", "stairway", "stairwell", "steps", "up"]
    },
		{
      value: "star",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>star</Icon> star
      </div>),
      tags: ["best", "bookmark", "favorite", "highlight", "ranking", "rate", "rating", "save", "star", "toggle"]
    },
		{
      value: "star_border",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>star_border</Icon> star_border
      </div>),
      tags: ["best", "bookmark", "border", "favorite", "highlight", "outline", "ranking", "rate", "rating", "save", "star", "toggle"]
    },
		{
      value: "star_border_purple500",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>star_border_purple500</Icon> star_border_purple500
      </div>),
      tags: ["500", "best", "bookmark", "border", "favorite", "highlight", "outline", "purple", "ranking", "rate", "rating", "save", "star", "toggle"]
    },
		{
      value: "star_half",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>star_half</Icon> star_half
      </div>),
      tags: ["achievement", "bookmark", "favorite", "half", "highlight", "important", "marked", "ranking", "rate", "rating rank", "reward", "save", "saved", "shape", "special", "star", "toggle"]
    },
		{
      value: "star_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>star_outline</Icon> star_outline
      </div>),
      tags: ["bookmark", "favorite", "half", "highlight", "ranking", "rate", "rating", "save", "star", "toggle"]
    },
		{
      value: "star_purple500",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>star_purple500</Icon> star_purple500
      </div>),
      tags: ["500", "best", "bookmark", "favorite", "highlight", "purple", "ranking", "rate", "rating", "save", "star", "toggle"]
    },
		{
      value: "star_rate",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>star_rate</Icon> star_rate
      </div>),
      tags: ["achievement", "bookmark", "favorite", "highlight", "important", "marked", "ranking", "rate", "rating rank", "reward", "save", "saved", "shape", "special", "star"]
    },
		{
      value: "stars",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stars</Icon> stars
      </div>),
      tags: ["achievement", "bookmark", "circle", "favorite", "highlight", "important", "marked", "ranking", "rate", "rating rank", "reward", "save", "saved", "shape", "special", "star"]
    },
		{
      value: "start",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>start</Icon> start
      </div>),
      tags: ["arrow", "keyboard", "next", "right", "start"]
    },
		{
      value: "stay_current_landscape",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stay_current_landscape</Icon> stay_current_landscape
      </div>),
      tags: ["Android", "OS", "current", "device", "hardware", "iOS", "landscape", "mobile", "phone", "stay", "tablet"]
    },
		{
      value: "stay_current_portrait",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stay_current_portrait</Icon> stay_current_portrait
      </div>),
      tags: ["Android", "OS", "current", "device", "hardware", "iOS", "mobile", "phone", "portrait", "stay", "tablet"]
    },
		{
      value: "stay_primary_landscape",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stay_primary_landscape</Icon> stay_primary_landscape
      </div>),
      tags: ["Android", "OS", "current", "device", "hardware", "iOS", "landscape", "mobile", "phone", "primary", "stay", "tablet"]
    },
		{
      value: "stay_primary_portrait",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stay_primary_portrait</Icon> stay_primary_portrait
      </div>),
      tags: ["Android", "OS", "current", "device", "hardware", "iOS", "mobile", "phone", "portrait", "primary", "stay", "tablet"]
    },
		{
      value: "sticky_note_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sticky_note_2</Icon> sticky_note_2
      </div>),
      tags: ["2", "bookmark", "mark", "message", "note", "paper", "sticky", "text", "writing"]
    },
		{
      value: "stop",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stop</Icon> stop
      </div>),
      tags: ["control", "controls", "music", "pause", "play", "square", "stop", "video"]
    },
		{
      value: "stop_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stop_circle</Icon> stop_circle
      </div>),
      tags: ["circle", "control", "controls", "music", "pause", "play", "square", "stop", "video"]
    },
		{
      value: "stop_screen_share",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stop_screen_share</Icon> stop_screen_share
      </div>),
      tags: ["Android", "OS", "arrow", "cast", "chrome", "device", "disabled", "display", "enabled", "hardware", "iOS", "laptop", "mac", "mirror", "monitor", "off", "offline", "on", "screen", "share", "slash", "stop", "stream", "streaming", "web", "window"]
    },
		{
      value: "storage",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>storage</Icon> storage
      </div>),
      tags: ["computer", "data", "drive", "memory", "storage"]
    },
		{
      value: "store",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>store</Icon> store
      </div>),
      tags: ["bill", "building", "business", "card", "cash", "coin", "commerce", "company", "credit", "currency", "dollars", "market", "money", "online", "pay", "payment", "shop", "shopping", "store", "storefront"]
    },
		{
      value: "store_mall_directory",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>store_mall_directory</Icon> store_mall_directory
      </div>),
      tags: ["directory", "mall", "store"]
    },
		{
      value: "storefront",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>storefront</Icon> storefront
      </div>),
      tags: ["business", "buy", "cafe", "commerce", "front", "market", "places", "restaurant", "retail", "sell", "shop", "shopping", "store", "storefront"]
    },
		{
      value: "storm",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>storm</Icon> storm
      </div>),
      tags: ["forecast", "hurricane", "storm", "temperature", "twister", "weather", "wind"]
    },
		{
      value: "straight",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>straight</Icon> straight
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "maps", "navigation", "path", "route", "sign", "straight", "traffic", "up"]
    },
		{
      value: "straighten",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>straighten</Icon> straighten
      </div>),
      tags: ["length", "measure", "measurement", "ruler", "size", "straighten"]
    },
		{
      value: "stream",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stream</Icon> stream
      </div>),
      tags: ["cast", "connected", "feed", "live", "network", "signal", "stream", "wireless"]
    },
		{
      value: "streetview",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>streetview</Icon> streetview
      </div>),
      tags: ["maps", "street", "streetview", "view"]
    },
		{
      value: "strikethrough_s",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>strikethrough_s</Icon> strikethrough_s
      </div>),
      tags: ["alphabet", "character", "cross", "doc", "edit", "editing", "editor", "font", "letters", "out", "s", "sheet", "spreadsheet", "strikethrough", "styles", "symbol", "text", "type", "writing"]
    },
		{
      value: "stroller",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>stroller</Icon> stroller
      </div>),
      tags: ["baby", "care", "carriage", "child", "children", "infant", "kid", "newborn", "stroller", "toddler", "young"]
    },
		{
      value: "style",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>style</Icon> style
      </div>),
      tags: ["booklet", "cards", "filters", "options", "style", "tags"]
    },
		{
      value: "subdirectory_arrow_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>subdirectory_arrow_left</Icon> subdirectory_arrow_left
      </div>),
      tags: ["arrow", "directory", "down", "left", "navigation", "sub", "subdirectory"]
    },
		{
      value: "subdirectory_arrow_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>subdirectory_arrow_right</Icon> subdirectory_arrow_right
      </div>),
      tags: ["arrow", "directory", "down", "navigation", "right", "sub", "subdirectory"]
    },
		{
      value: "subject",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>subject</Icon> subject
      </div>),
      tags: ["alignment", "doc", "document", "email", "full", "justify", "list", "note", "subject", "text", "writing"]
    },
		{
      value: "subscript",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>subscript</Icon> subscript
      </div>),
      tags: ["2", "doc", "edit", "editing", "editor", "gmail", "novitas", "sheet", "spreadsheet", "style", "subscript", "symbol", "text", "writing", "x"]
    },
		{
      value: "subscriptions",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>subscriptions</Icon> subscriptions
      </div>),
      tags: ["enroll", "list", "media", "order", "play", "signup", "stack", "subscribe", "subscriptions"]
    },
		{
      value: "subtitles",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>subtitles</Icon> subtitles
      </div>),
      tags: ["accessible", "caption", "cc", "character", "closed", "decoder", "language", "live caption", "media", "movies", "subtitle", "subtitles", "tv"]
    },
		{
      value: "subtitles_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>subtitles_off</Icon> subtitles_off
      </div>),
      tags: ["accessibility", "accessible", "caption", "cc", "closed", "disabled", "enabled", "language", "live caption", "off", "on", "slash", "subtitle", "subtitles", "translate", "video"]
    },
		{
      value: "subway",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>subway</Icon> subway
      </div>),
      tags: ["automobile", "bike", "car", "cars", "maps", "rail", "scooter", "subway", "train", "transportation", "travel", "tunnel", "underground", "vehicle", "vespa"]
    },
		{
      value: "summarize",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>summarize</Icon> summarize
      </div>),
      tags: ["doc", "document", "form", "list", "menu", "note", "report", "summary"]
    },
		{
      value: "superscript",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>superscript</Icon> superscript
      </div>),
      tags: ["2", "doc", "edit", "editing", "editor", "gmail", "novitas", "sheet", "spreadsheet", "style", "superscript", "symbol", "text", "writing", "x"]
    },
		{
      value: "supervised_user_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>supervised_user_circle</Icon> supervised_user_circle
      </div>),
      tags: ["account", "avatar", "circle", "control", "face", "human", "parental", "parents", "people", "person", "profile", "supervised", "supervisor", "user"]
    },
		{
      value: "supervisor_account",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>supervisor_account</Icon> supervisor_account
      </div>),
      tags: ["account", "avatar", "control", "custodian", "face", "guardian", "human", "parental", "parental control", "parents", "people", "person", "profile", "supervised", "supervisor", "user"]
    },
		{
      value: "support",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>support</Icon> support
      </div>),
      tags: ["assist", "buoy", "help", "life", "lifebuoy", "rescue", "safe", "safety", "support"]
    },
		{
      value: "support_agent",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>support_agent</Icon> support_agent
      </div>),
      tags: ["agent", "care", "customer", "face", "headphone", "person", "representative", "service", "support"]
    },
		{
      value: "surfing",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>surfing</Icon> surfing
      </div>),
      tags: ["athlete", "athletic", "beach", "body", "entertainment", "exercise", "hobby", "human", "people", "person", "sea", "social sports", "sports", "summer", "surfing", "water", "wave"]
    },
		{
      value: "surround_sound",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>surround_sound</Icon> surround_sound
      </div>),
      tags: ["circle", "signal", "sound", "speaker", "surround", "system", "volumn", "wireless"]
    },
		{
      value: "swap_calls",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swap_calls</Icon> swap_calls
      </div>),
      tags: ["arrow", "arrows", "calls", "device", "direction", "mobile", "share", "swap"]
    },
		{
      value: "swap_horiz",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swap_horiz</Icon> swap_horiz
      </div>),
      tags: ["arrow", "arrows", "back", "forward", "horizontal", "swap"]
    },
		{
      value: "swap_horizontal_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swap_horizontal_circle</Icon> swap_horizontal_circle
      </div>),
      tags: ["arrow", "arrows", "back", "circle", "forward", "horizontal", "swap"]
    },
		{
      value: "swap_vert",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swap_vert</Icon> swap_vert
      </div>),
      tags: ["arrow", "arrows", "direction", "down", "navigation", "sort", "sorting", "swap", "up", "vert", "vertical"]
    },
		{
      value: "swap_vertical_circle",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swap_vertical_circle</Icon> swap_vertical_circle
      </div>),
      tags: ["arrow", "arrows", "circle", "down", "swap", "up", "vertical"]
    },
		{
      value: "swipe",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swipe</Icon> swipe
      </div>),
      tags: ["arrow", "arrows", "fingers", "gesture", "hand", "hands", "swipe", "touch"]
    },
		{
      value: "swipe_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swipe_down</Icon> swipe_down
      </div>),
      tags: ["arrows", "direction", "disable", "down", "enable", "finger", "hands", "hit", "navigation", "strike", "swing", "swpie", "take"]
    },
		{
      value: "swipe_down_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swipe_down_alt</Icon> swipe_down_alt
      </div>),
      tags: ["alt", "arrows", "direction", "disable", "down", "enable", "finger", "hands", "hit", "navigation", "strike", "swing", "swpie", "take"]
    },
		{
      value: "swipe_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swipe_left</Icon> swipe_left
      </div>),
      tags: ["arrow", "arrows", "finger", "hand", "hit", "left", "navigation", "reject", "strike", "swing", "swipe", "take"]
    },
		{
      value: "swipe_left_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swipe_left_alt</Icon> swipe_left_alt
      </div>),
      tags: ["alt", "arrow", "arrows", "finger", "hand", "hit", "left", "navigation", "reject", "strike", "swing", "swipe", "take"]
    },
		{
      value: "swipe_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swipe_right</Icon> swipe_right
      </div>),
      tags: ["accept", "arrows", "direction", "finger", "hands", "hit", "navigation", "right", "strike", "swing", "swpie", "take"]
    },
		{
      value: "swipe_right_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swipe_right_alt</Icon> swipe_right_alt
      </div>),
      tags: ["accept", "alt", "arrows", "direction", "finger", "hands", "hit", "navigation", "right", "strike", "swing", "swpie", "take"]
    },
		{
      value: "swipe_up",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swipe_up</Icon> swipe_up
      </div>),
      tags: ["arrows", "direction", "disable", "enable", "finger", "hands", "hit", "navigation", "strike", "swing", "swpie", "take", "up"]
    },
		{
      value: "swipe_up_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swipe_up_alt</Icon> swipe_up_alt
      </div>),
      tags: ["alt", "arrows", "direction", "disable", "enable", "finger", "hands", "hit", "navigation", "strike", "swing", "swpie", "take", "up"]
    },
		{
      value: "swipe_vertical",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>swipe_vertical</Icon> swipe_vertical
      </div>),
      tags: ["arrows", "direction", "finger", "hands", "hit", "navigation", "strike", "swing", "swpie", "take", "verticle"]
    },
		{
      value: "switch_access_shortcut",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>switch_access_shortcut</Icon> switch_access_shortcut
      </div>),
      tags: ["access", "arrow", "arrows", "direction", "navigation", "new", "north", "shortcut", "switch", "symbol", "up"]
    },
		{
      value: "switch_access_shortcut_add",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>switch_access_shortcut_add</Icon> switch_access_shortcut_add
      </div>),
      tags: ["+", "access", "add", "arrow", "arrows", "direction", "navigation", "new", "north", "plus", "shortcut", "switch", "symbol", "up"]
    },
		{
      value: "switch_account",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>switch_account</Icon> switch_account
      </div>),
      tags: ["account", "choices", "face", "human", "multiple", "options", "people", "person", "profile", "social", "stack", "switch", "user"]
    },
		{
      value: "switch_camera",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>switch_camera</Icon> switch_camera
      </div>),
      tags: ["arrow", "arrows", "camera", "photo", "photography", "picture", "switch"]
    },
		{
      value: "switch_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>switch_left</Icon> switch_left
      </div>),
      tags: ["arrows", "directional", "left", "navigation", "switch", "toggle"]
    },
		{
      value: "switch_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>switch_right</Icon> switch_right
      </div>),
      tags: ["arrows", "directional", "navigation", "right", "switch", "toggle"]
    },
		{
      value: "switch_video",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>switch_video</Icon> switch_video
      </div>),
      tags: ["arrow", "arrows", "camera", "photography", "switch", "video", "videos"]
    },
		{
      value: "synagogue",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>synagogue</Icon> synagogue
      </div>),
      tags: ["ideology", "jew", "jewish", "religion", "shul", "spiritual", "temple", "worship"]
    },
		{
      value: "sync",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sync</Icon> sync
      </div>),
      tags: ["360", "around", "arrow", "arrows", "direction", "inprogress", "load", "loading refresh", "renew", "rotate", "sync", "turn"]
    },
		{
      value: "sync_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sync_alt</Icon> sync_alt
      </div>),
      tags: ["alt", "arrow", "arrows", "horizontal", "internet", "sync", "technology", "up", "update", "wifi"]
    },
		{
      value: "sync_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sync_disabled</Icon> sync_disabled
      </div>),
      tags: ["360", "around", "arrow", "arrows", "direction", "disabled", "enabled", "inprogress", "load", "loading refresh", "off", "on", "renew", "rotate", "slash", "sync", "turn"]
    },
		{
      value: "sync_lock",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sync_lock</Icon> sync_lock
      </div>),
      tags: ["around", "arrow", "arrows", "lock", "locked", "password", "privacy", "private", "protection", "renew", "rotate", "safety", "secure", "security", "sync", "turn"]
    },
		{
      value: "sync_problem",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>sync_problem</Icon> sync_problem
      </div>),
      tags: ["!", "360", "alert", "around", "arrow", "arrows", "attention", "caution", "danger", "direction", "error", "exclamation", "important", "inprogress", "load", "loading refresh", "mark", "notification", "problem", "renew", "rotate", "symbol", "sync", "turn", "warning"]
    },
		{
      value: "system_security_update",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>system_security_update</Icon> system_security_update
      </div>),
      tags: ["Android", "OS", "arrow", "cell", "device", "down", "hardware", "iOS", "mobile", "phone", "security", "system", "tablet", "update"]
    },
		{
      value: "system_security_update_good",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>system_security_update_good</Icon> system_security_update_good
      </div>),
      tags: ["Android", "OS", "approve", "cell", "check", "complete", "device", "done", "good", "hardware", "iOS", "mark", "mobile", "ok", "phone", "security", "select", "system", "tablet", "tick", "update", "validate", "verified", "yes"]
    },
		{
      value: "system_security_update_warning",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>system_security_update_warning</Icon> system_security_update_warning
      </div>),
      tags: ["!", "Android", "OS", "alert", "attention", "caution", "cell", "danger", "device", "error", "exclamation", "hardware", "iOS", "important", "mark", "mobile", "notification", "phone", "security", "symbol", "system", "tablet", "update", "warning"]
    },
		{
      value: "system_update",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>system_update</Icon> system_update
      </div>),
      tags: ["Android", "OS", "arrow", "arrows", "cell", "device", "direction", "down", "download", "hardware", "iOS", "install", "mobile", "phone", "system", "tablet", "update"]
    },
		{
      value: "system_update_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>system_update_alt</Icon> system_update_alt
      </div>),
      tags: ["arrow", "down", "download", "export", "system", "update"]
    },
		{
      value: "tab",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tab</Icon> tab
      </div>),
      tags: ["browser", "computer", "document", "documents", "folder", "internet", "tab", "tabs", "web", "website", "window", "windows"]
    },
		{
      value: "tab_unselected",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tab_unselected</Icon> tab_unselected
      </div>),
      tags: ["browser", "computer", "dash", "dashed", "document", "documents", "folder", "internet", "tab", "tabs", "unselected", "web", "website", "window", "windows"]
    },
		{
      value: "table_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>table_bar</Icon> table_bar
      </div>),
      tags: ["bar", "cafe", "round", "table"]
    },
		{
      value: "table_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>table_chart</Icon> table_chart
      </div>),
      tags: ["analytics", "bar", "bars", "chart", "data", "diagram", "graph", "infographic grid", "measure", "metrics", "statistics", "table", "tracking"]
    },
		{
      value: "table_restaurant",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>table_restaurant</Icon> table_restaurant
      </div>),
      tags: ["bar", "dining", "table"]
    },
		{
      value: "table_rows",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>table_rows</Icon> table_rows
      </div>),
      tags: ["background", "grid", "layout", "lines", "rows", "stacked", "table"]
    },
		{
      value: "table_view",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>table_view</Icon> table_view
      </div>),
      tags: ["format", "grid", "group", "layout", "multiple", "table", "view"]
    },
		{
      value: "tablet",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tablet</Icon> tablet
      </div>),
      tags: ["Android", "OS", "device", "hardware", "iOS", "ipad", "mobile", "tablet", "web"]
    },
		{
      value: "tablet_android",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tablet_android</Icon> tablet_android
      </div>),
      tags: ["OS", "android", "device", "hardware", "iOS", "ipad", "mobile", "tablet", "web"]
    },
		{
      value: "tablet_mac",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tablet_mac</Icon> tablet_mac
      </div>),
      tags: ["Android", "OS", "device", "hardware", "iOS", "ipad", "mobile", "tablet mac", "web"]
    },
		{
      value: "tag",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tag</Icon> tag
      </div>),
      tags: ["hash", "hashtag", "key", "media", "numbers", "pound", "social", "tag", "trend"]
    },
		{
      value: "tag_faces",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tag_faces</Icon> tag_faces
      </div>),
      tags: ["emoji", "emotion", "faces", "happy", "satisfied", "smile", "tag"]
    },
		{
      value: "takeout_dining",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>takeout_dining</Icon> takeout_dining
      </div>),
      tags: ["box", "container", "delivery", "dining", "food", "meal", "restaurant", "takeout"]
    },
		{
      value: "tap_and_play",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tap_and_play</Icon> tap_and_play
      </div>),
      tags: ["Android", "OS wifi", "cell", "connection", "device", "hardware", "iOS", "internet", "mobile", "network", "phone", "play", "signal", "tablet", "tap", "to", "wireless"]
    },
		{
      value: "tapas",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tapas</Icon> tapas
      </div>),
      tags: ["appetizer", "brunch", "dinner", "food", "lunch", "restaurant", "snack", "tapas"]
    },
		{
      value: "task",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>task</Icon> task
      </div>),
      tags: ["approve", "check", "complete", "data", "doc", "document", "done", "drive", "file", "folder", "folders", "mark", "ok", "page", "paper", "select", "sheet", "slide", "task", "tick", "validate", "verified", "writing", "yes"]
    },
		{
      value: "task_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>task_alt</Icon> task_alt
      </div>),
      tags: ["approve", "check", "circle", "complete", "done", "mark", "ok", "select", "task", "tick", "validate", "verified", "yes"]
    },
		{
      value: "taxi_alert",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>taxi_alert</Icon> taxi_alert
      </div>),
      tags: ["!", "alert", "attention", "automobile", "cab", "car", "cars", "caution", "danger", "direction", "error", "exclamation", "important", "lyft", "maps", "mark", "notification", "public", "symbol", "taxi", "transportation", "uber", "vehicle", "warning", "yellow"]
    },
		{
      value: "temple_buddhist",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>temple_buddhist</Icon> temple_buddhist
      </div>),
      tags: ["buddha", "buddhism", "buddhist", "ideology", "monastery", "religion", "spiritual", "temple", "worship"]
    },
		{
      value: "temple_hindu",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>temple_hindu</Icon> temple_hindu
      </div>),
      tags: ["hindu", "hinduism", "hindus", "ideology", "mandir", "religion", "spiritual", "temple", "worship"]
    },
		{
      value: "terminal",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>terminal</Icon> terminal
      </div>),
      tags: ["application", "code", "emulator", "program", "software", "terminal"]
    },
		{
      value: "terrain",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>terrain</Icon> terrain
      </div>),
      tags: ["geography", "landscape", "mountain", "terrain"]
    },
		{
      value: "text_decrease",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>text_decrease</Icon> text_decrease
      </div>),
      tags: ["-", "alphabet", "character", "decrease", "font", "letters", "minus", "remove", "resize", "subtract", "symbol", "text", "type"]
    },
		{
      value: "text_fields",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>text_fields</Icon> text_fields
      </div>),
      tags: ["T", "add", "alphabet", "character", "field", "fields", "font", "input", "letters", "symbol", "text", "type"]
    },
		{
      value: "text_format",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>text_format</Icon> text_format
      </div>),
      tags: ["alphabet", "character", "font", "format", "letters", "square A", "style", "symbol", "text", "type"]
    },
		{
      value: "text_increase",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>text_increase</Icon> text_increase
      </div>),
      tags: ["+", "add", "alphabet", "character", "font", "increase", "letters", "new", "plus", "resize", "symbol", "text", "type"]
    },
		{
      value: "text_rotate_up",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>text_rotate_up</Icon> text_rotate_up
      </div>),
      tags: ["A", "alphabet", "arrow", "character", "field", "font", "letters", "move", "rotate", "symbol", "text", "type", "up"]
    },
		{
      value: "text_rotate_vertical",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>text_rotate_vertical</Icon> text_rotate_vertical
      </div>),
      tags: ["A", "alphabet", "arrow", "character", "down", "field", "font", "letters", "move", "rotate", "symbol", "text", "type", "vertical"]
    },
		{
      value: "text_rotation_angledown",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>text_rotation_angledown</Icon> text_rotation_angledown
      </div>),
      tags: ["A", "alphabet", "angledown", "arrow", "character", "field", "font", "letters", "move", "rotate", "symbol", "text", "type"]
    },
		{
      value: "text_rotation_angleup",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>text_rotation_angleup</Icon> text_rotation_angleup
      </div>),
      tags: ["A", "alphabet", "angleup", "arrow", "character", "field", "font", "letters", "move", "rotate", "symbol", "text", "type"]
    },
		{
      value: "text_rotation_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>text_rotation_down</Icon> text_rotation_down
      </div>),
      tags: ["A", "alphabet", "arrow", "character", "dow", "field", "font", "letters", "move", "rotate", "symbol", "text", "type"]
    },
		{
      value: "text_rotation_none",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>text_rotation_none</Icon> text_rotation_none
      </div>),
      tags: ["A", "alphabet", "arrow", "character", "field", "font", "letters", "move", "none", "rotate", "symbol", "text", "type"]
    },
		{
      value: "text_snippet",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>text_snippet</Icon> text_snippet
      </div>),
      tags: ["data", "doc", "document", "file", "note", "notes", "snippet", "storage", "text", "writing"]
    },
		{
      value: "textsms",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>textsms</Icon> textsms
      </div>),
      tags: ["bubble", "chat", "comment", "communicate", "dots", "feedback", "message", "speech", "textsms"]
    },
		{
      value: "texture",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>texture</Icon> texture
      </div>),
      tags: ["diagonal", "lines", "pattern", "stripes", "texture"]
    },
		{
      value: "theater_comedy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>theater_comedy</Icon> theater_comedy
      </div>),
      tags: ["broadway", "comedy", "event", "mask", "masks", "movie", "musical", "places", "show", "standup", "theater", "tour", "watch"]
    },
		{
      value: "theaters",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>theaters</Icon> theaters
      </div>),
      tags: ["film", "movie", "movies", "show", "showtimes", "theater", "theaters", "watch"]
    },
		{
      value: "thermostat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>thermostat</Icon> thermostat
      </div>),
      tags: ["climate", "forecast", "temperature", "thermostat", "weather"]
    },
		{
      value: "thermostat_auto",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>thermostat_auto</Icon> thermostat_auto
      </div>),
      tags: ["A", "auto", "celsius", "fahrenheit", "meter", "temp", "temperature", "thermometer", "thermostat"]
    },
		{
      value: "thumb_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>thumb_down</Icon> thumb_down
      </div>),
      tags: ["ate", "dislike", "down", "favorite", "fingers", "gesture", "hand", "hands", "like", "rank", "ranking", "rating", "thumb"]
    },
		{
      value: "thumb_down_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>thumb_down_alt</Icon> thumb_down_alt
      </div>),
      tags: ["bad", "decline", "disapprove", "dislike", "down", "feedback", "hate", "negative", "no", "reject", "social", "thumb", "veto", "vote"]
    },
		{
      value: "thumb_down_off_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>thumb_down_off_alt</Icon> thumb_down_off_alt
      </div>),
      tags: ["disabled", "dislike", "down", "enabled", "favorite", "filled", "fingers", "gesture", "hand", "hands", "like", "off", "offline", "on", "rank", "ranking", "rate", "rating", "slash", "thumb"]
    },
		{
      value: "thumb_up",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>thumb_up</Icon> thumb_up
      </div>),
      tags: ["favorite", "fingers", "gesture", "hand", "hands", "like", "rank", "ranking", "rate", "rating", "thumb", "up"]
    },
		{
      value: "thumb_up_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>thumb_up_alt</Icon> thumb_up_alt
      </div>),
      tags: ["agreed", "approved", "confirm", "correct", "favorite", "feedback", "good", "happy", "like", "okay", "positive", "satisfaction", "social", "thumb", "up", "vote", "yes"]
    },
		{
      value: "thumb_up_off_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>thumb_up_off_alt</Icon> thumb_up_off_alt
      </div>),
      tags: ["alt", "disabled", "enabled", "favorite", "fingers", "gesture", "hand", "hands", "like", "off", "offline", "on", "rank", "ranking", "rate", "rating", "slash", "thumb", "up"]
    },
		{
      value: "thumbs_up_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>thumbs_up_down</Icon> thumbs_up_down
      </div>),
      tags: ["dislike", "down", "favorite", "fingers", "gesture", "hands", "like", "rate", "rating", "thumbs", "up"]
    },
		{
      value: "thunderstorm",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>thunderstorm</Icon> thunderstorm
      </div>),
      tags: ["bolt", "climate", "cloud", "cloudy", "lightning", "rain", "rainfall", "rainstorm", "storm", "thunder", "thunderstorm", "weather"]
    },
		{
      value: "time_to_leave",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>time_to_leave</Icon> time_to_leave
      </div>),
      tags: ["automobile", "car", "cars", "destination", "direction", "drive", "estimate", "eta", "maps", "public", "transportation", "travel", "trip", "vehicle"]
    },
		{
      value: "timelapse",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>timelapse</Icon> timelapse
      </div>),
      tags: ["duration", "motion", "photo", "time", "timelapse", "timer", "video"]
    },
		{
      value: "timeline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>timeline</Icon> timeline
      </div>),
      tags: ["data", "history", "line", "movement", "point", "points", "timeline", "tracking", "trending", "zigzag"]
    },
		{
      value: "timer",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>timer</Icon> timer
      </div>),
      tags: ["alarm", "alert", "bell", "clock", "disabled", "duration", "enabled", "notification", "time", "timer", "watch"]
    },
		{
      value: "timer_10",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>timer_10</Icon> timer_10
      </div>),
      tags: ["10", "alarm", "alert", "bell", "clock", "digits", "disabled", "duration", "enabled", "notification", "numbers", "seconds", "ten", "time", "timer", "watch"]
    },
		{
      value: "timer_10_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>timer_10_select</Icon> timer_10_select
      </div>),
      tags: ["10", "alphabet", "camera", "character", "digit", "font", "letters", "numbers", "seconds", "select", "symbol", "ten", "text", "timer", "type"]
    },
		{
      value: "timer_3",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>timer_3</Icon> timer_3
      </div>),
      tags: ["3", "digits", "duration", "numbers", "seconds", "three", "time", "timer"]
    },
		{
      value: "timer_3_select",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>timer_3_select</Icon> timer_3_select
      </div>),
      tags: ["3", "alphabet", "camera", "character", "digit", "font", "letters", "numbers", "seconds", "select", "symbol", "text", "three", "timer", "type"]
    },
		{
      value: "timer_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>timer_off</Icon> timer_off
      </div>),
      tags: ["alarm", "alert", "bell", "clock", "disabled", "duration", "enabled", "notification", "off", "on", "slash", "stop", "time", "timer", "watch"]
    },
		{
      value: "tips_and_updates",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tips_and_updates</Icon> tips_and_updates
      </div>),
      tags: ["ai", "alert", "and", "announcement", "artificial", "automatic", "automation", "custom", "electricity", "genai", "idea", "info", "information", "intelligence", "light", "lightbulb", "magic", "smart", "spark", "sparkle", "star", "tips", "updates"]
    },
		{
      value: "tire_repair",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tire_repair</Icon> tire_repair
      </div>),
      tags: ["auto", "automobile", "car", "cars", "gauge", "mechanic", "pressure", "repair", "tire", "vehicle"]
    },
		{
      value: "title",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>title</Icon> title
      </div>),
      tags: ["T", "alphabet", "character", "font", "header", "letters", "subject", "symbol", "text", "title", "type"]
    },
		{
      value: "toc",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>toc</Icon> toc
      </div>),
      tags: ["content", "format", "lines", "list", "order", "reorder", "stacked", "table", "title", "titles", "toc"]
    },
		{
      value: "today",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>today</Icon> today
      </div>),
      tags: ["calendar", "date", "day", "event", "mark", "month", "remember", "reminder", "schedule", "time", "today"]
    },
		{
      value: "toggle_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>toggle_off</Icon> toggle_off
      </div>),
      tags: ["active", "app", "application", "components", "configuration", "control", "design", "disable", "inable", "inactive", "interface", "off", "on", "selection", "settings", "site", "slider", "switch", "toggle", "ui", "ux", "web", "website"]
    },
		{
      value: "toggle_on",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>toggle_on</Icon> toggle_on
      </div>),
      tags: ["active", "app", "application", "components", "configuration", "control", "design", "disable", "inable", "inactive", "interface", "off", "on", "selection", "settings", "site", "slider", "switch", "toggle", "ui", "ux", "web", "website"]
    },
		{
      value: "token",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>token</Icon> token
      </div>),
      tags: ["badge", "hexagon", "mark", "shield", "sign", "symbol"]
    },
		{
      value: "toll",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>toll</Icon> toll
      </div>),
      tags: ["bill", "booth", "car", "card", "cash", "coin", "commerce", "credit", "currency", "dollars", "highway", "money", "online", "pay", "payment", "ticket", "toll"]
    },
		{
      value: "tonality",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tonality</Icon> tonality
      </div>),
      tags: ["circle", "edit", "editing", "filter", "greyscale", "image", "photography", "picture", "shade", "shadow", "tonality"]
    },
		{
      value: "topic",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>topic</Icon> topic
      </div>),
      tags: ["data", "doc", "document", "drive", "file", "folder", "sheet", "slide", "storage", "topic"]
    },
		{
      value: "tornado",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tornado</Icon> tornado
      </div>),
      tags: ["crisis", "disaster", "natural", "rain", "storm", "tornado", "weather", "wind"]
    },
		{
      value: "touch_app",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>touch_app</Icon> touch_app
      </div>),
      tags: ["app", "command", "fingers", "gesture", "hand", "press", "tap", "touch"]
    },
		{
      value: "tour",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tour</Icon> tour
      </div>),
      tags: ["destination", "flag", "places", "tour", "travel", "visit"]
    },
		{
      value: "toys",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>toys</Icon> toys
      </div>),
      tags: ["car", "games", "kids", "toy", "toys", "windmill"]
    },
		{
      value: "track_changes",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>track_changes</Icon> track_changes
      </div>),
      tags: ["bullseye", "changes", "circle", "evolve", "lines", "movement", "rotate", "shift", "target", "track"]
    },
		{
      value: "traffic",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>traffic</Icon> traffic
      </div>),
      tags: ["direction", "light", "maps", "signal", "street", "traffic"]
    },
		{
      value: "train",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>train</Icon> train
      </div>),
      tags: ["automobile", "car", "cars", "direction", "maps", "public", "rail", "railway", "subway", "train", "tram", "transit", "transportation", "trolley", "vehicle"]
    },
		{
      value: "tram",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tram</Icon> tram
      </div>),
      tags: ["automobile", "car", "cars", "direction", "maps", "public", "rail", "railway", "subway", "train", "tram", "transit", "transportation", "trolley", "vehicle"]
    },
		{
      value: "transcribe",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>transcribe</Icon> transcribe
      </div>),
      tags: []
    },
		{
      value: "transfer_within_a_station",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>transfer_within_a_station</Icon> transfer_within_a_station
      </div>),
      tags: ["a", "arrow", "arrows", "body", "direction", "human", "left", "maps", "people", "person", "public", "right", "route", "station", "stop", "transfer", "transportation", "vehicle", "walk", "within"]
    },
		{
      value: "transform",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>transform</Icon> transform
      </div>),
      tags: ["adjust", "crop", "edit", "editing", "image", "photo", "picture", "transform"]
    },
		{
      value: "transgender",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>transgender</Icon> transgender
      </div>),
      tags: ["female", "gender", "lgbt", "male", "neutral", "social", "symbol", "transgender"]
    },
		{
      value: "transit_enterexit",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>transit_enterexit</Icon> transit_enterexit
      </div>),
      tags: ["arrow", "direction", "enterexit", "maps", "navigation", "route", "transit", "transportation"]
    },
		{
      value: "translate",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>translate</Icon> translate
      </div>),
      tags: ["language", "speaking", "speech", "translate", "translator", "words"]
    },
		{
      value: "travel_explore",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>travel_explore</Icon> travel_explore
      </div>),
      tags: ["browser", "earth", "explore", "find", "glass", "global", "globe", "look", "magnify", "magnifying", "map", "network", "planet", "search", "see", "social", "space", "travel", "web", "world"]
    },
		{
      value: "trending_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>trending_down</Icon> trending_down
      </div>),
      tags: ["analytics", "arrow", "data", "diagram", "down", "graph", "infographic", "measure", "metrics", "movement", "rate", "rating", "statistics", "tracking", "trending"]
    },
		{
      value: "trending_flat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>trending_flat</Icon> trending_flat
      </div>),
      tags: ["arrow", "change", "data", "flat", "metric", "movement", "rate", "right", "track", "tracking", "trending"]
    },
		{
      value: "trending_up",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>trending_up</Icon> trending_up
      </div>),
      tags: ["analytics", "arrow", "data", "diagram", "graph", "infographic", "measure", "metrics", "movement", "rate", "rating", "statistics", "tracking", "trending", "up"]
    },
		{
      value: "trip_origin",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>trip_origin</Icon> trip_origin
      </div>),
      tags: ["circle", "departure", "origin", "trip"]
    },
		{
      value: "troubleshoot",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>troubleshoot</Icon> troubleshoot
      </div>),
      tags: ["analytics", "chart", "data", "diagram", "find", "glass", "graph", "infographic", "line", "look", "magnify", "magnifying", "measure", "metrics", "search", "see", "statistics", "tracking", "troubleshoot"]
    },
		{
      value: "try",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>try</Icon> try
      </div>),
      tags: ["bookmark", "bubble", "chat", "comment", "communicate", "favorite", "feedback", "highlight", "important", "marked", "message", "save", "saved", "shape", "special", "speech", "star", "try"]
    },
		{
      value: "tsunami",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tsunami</Icon> tsunami
      </div>),
      tags: ["crisis", "disaster", "flood", "ocean", "rain", "sea", "storm", "tsunami", "water", "wave", "weather"]
    },
		{
      value: "tty",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tty</Icon> tty
      </div>),
      tags: ["call", "cell", "contact", "deaf", "device", "hardware", "impaired", "mobile", "phone", "speech", "talk", "telephone", "text", "tty"]
    },
		{
      value: "tune",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tune</Icon> tune
      </div>),
      tags: ["adjust", "audio", "controls", "custom", "customize", "edit", "editing", "filter", "filters", "instant", "mix", "music", "options", "setting", "settings", "slider", "sliders", "switches", "tune"]
    },
		{
      value: "tungsten",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tungsten</Icon> tungsten
      </div>),
      tags: ["balance", "bright", "edit", "editing", "electricity", "indoor", "iridescent", "lamp", "light", "lightbulb", "lighting", "setting", "settings", "tungsten", "white", "wp"]
    },
		{
      value: "turn_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>turn_left</Icon> turn_left
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "left", "maps", "navigation", "path", "route", "sign", "traffic", "turn"]
    },
		{
      value: "turn_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>turn_right</Icon> turn_right
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "maps", "navigation", "path", "right", "route", "sign", "traffic", "turn"]
    },
		{
      value: "turn_sharp_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>turn_sharp_left</Icon> turn_sharp_left
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "left", "maps", "navigation", "path", "route", "sharp", "sign", "traffic", "turn"]
    },
		{
      value: "turn_sharp_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>turn_sharp_right</Icon> turn_sharp_right
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "maps", "navigation", "path", "right", "route", "sharp", "sign", "traffic", "turn"]
    },
		{
      value: "turn_slight_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>turn_slight_left</Icon> turn_slight_left
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "maps", "navigation", "path", "right", "route", "sign", "slight", "traffic", "turn"]
    },
		{
      value: "turn_slight_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>turn_slight_right</Icon> turn_slight_right
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "maps", "navigation", "path", "right", "route", "sharp", "sign", "slight", "traffic", "turn"]
    },
		{
      value: "turned_in",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>turned_in</Icon> turned_in
      </div>),
      tags: ["archive", "bookmark", "favorite", "in", "label", "library", "read", "reading", "remember", "ribbon", "save", "tag", "turned"]
    },
		{
      value: "turned_in_not",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>turned_in_not</Icon> turned_in_not
      </div>),
      tags: ["archive", "bookmark", "favorite", "in", "label", "library", "not", "read", "reading", "remember", "ribbon", "save", "tag", "turned"]
    },
		{
      value: "tv",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tv</Icon> tv
      </div>),
      tags: ["device", "display", "monitor", "screen", "screencast", "stream", "television", "tv", "video", "wireless"]
    },
		{
      value: "tv_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>tv_off</Icon> tv_off
      </div>),
      tags: ["Android", "OS", "chrome", "desktop", "device", "disabled", "enabled", "hardware", "iOS", "mac", "monitor", "off", "on", "slash", "television", "tv", "web", "window"]
    },
		{
      value: "two_wheeler",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>two_wheeler</Icon> two_wheeler
      </div>),
      tags: ["automobile", "bike", "car", "cars", "direction", "maps", "motorcycle", "public", "scooter", "sport", "transportation", "travel", "two wheeler", "vehicle"]
    },
		{
      value: "type_specimen",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>type_specimen</Icon> type_specimen
      </div>),
      tags: []
    },
		{
      value: "u_turn_left",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>u_turn_left</Icon> u_turn_left
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "left", "maps", "navigation", "path", "route", "sign", "traffic", "u-turn"]
    },
		{
      value: "u_turn_right",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>u_turn_right</Icon> u_turn_right
      </div>),
      tags: ["arrow", "arrows", "direction", "directions", "maps", "navigation", "path", "right", "route", "sign", "traffic", "u-turn"]
    },
		{
      value: "umbrella",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>umbrella</Icon> umbrella
      </div>),
      tags: ["beach", "protection", "rain", "sun", "sunny", "umbrella"]
    },
		{
      value: "unarchive",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>unarchive</Icon> unarchive
      </div>),
      tags: ["archive", "arrow", "inbox", "mail", "store", "unarchive", "undo", "up"]
    },
		{
      value: "undo",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>undo</Icon> undo
      </div>),
      tags: ["arrow", "backward", "mail", "previous", "redo", "repeat", "rotate", "undo"]
    },
		{
      value: "unfold_less",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>unfold_less</Icon> unfold_less
      </div>),
      tags: ["arrow", "arrows", "chevron", "collapse", "direction", "expand", "expandable", "inward", "less", "list", "navigation", "unfold", "up"]
    },
		{
      value: "unfold_less_double",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>unfold_less_double</Icon> unfold_less_double
      </div>),
      tags: ["arrow", "arrows", "chevron", "collapse", "direction", "double", "expand", "expandable", "inward", "less", "list", "navigation", "unfold", "up"]
    },
		{
      value: "unfold_more",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>unfold_more</Icon> unfold_more
      </div>),
      tags: ["arrow", "arrows", "chevron", "collapse", "direction", "down", "expand", "expandable", "list", "more", "navigation", "unfold"]
    },
		{
      value: "unfold_more_double",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>unfold_more_double</Icon> unfold_more_double
      </div>),
      tags: ["arrow", "arrows", "chevron", "collapse", "direction", "double", "down", "expand", "expandable", "list", "more", "navigation", "unfold"]
    },
		{
      value: "unpublished",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>unpublished</Icon> unpublished
      </div>),
      tags: ["approve", "check", "circle", "complete", "disabled", "done", "enabled", "mark", "off", "ok", "on", "select", "slash", "tick", "unpublished", "validate", "verified", "yes"]
    },
		{
      value: "unsubscribe",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>unsubscribe</Icon> unsubscribe
      </div>),
      tags: ["cancel", "close", "email", "envelop", "letters", "mail", "message", "newsletter", "off", "remove", "send", "subscribe", "unsubscribe"]
    },
		{
      value: "upcoming",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>upcoming</Icon> upcoming
      </div>),
      tags: ["alarm", "calendar", "mail", "message", "notification", "upcoming"]
    },
		{
      value: "update",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>update</Icon> update
      </div>),
      tags: ["arrow", "back", "backwards", "clock", "forward", "history", "load", "refresh", "reverse", "schedule", "time", "update"]
    },
		{
      value: "update_disabled",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>update_disabled</Icon> update_disabled
      </div>),
      tags: ["arrow", "back", "backwards", "clock", "date", "disabled", "enabled", "forward", "history", "load", "off", "on", "refresh", "reverse", "rotate", "schedule", "slash", "time", "update"]
    },
		{
      value: "upgrade",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>upgrade</Icon> upgrade
      </div>),
      tags: ["arrow", "export", "instal", "line", "replace", "up", "update", "upgrade"]
    },
		{
      value: "upload",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>upload</Icon> upload
      </div>),
      tags: ["arrow", "arrows", "download", "drive", "up", "upload"]
    },
		{
      value: "upload_file",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>upload_file</Icon> upload_file
      </div>),
      tags: ["arrow", "data", "doc", "document", "download", "drive", "file", "folder", "folders", "page", "paper", "sheet", "slide", "up", "upload", "writing"]
    },
		{
      value: "usb",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>usb</Icon> usb
      </div>),
      tags: ["cable", "connection", "device", "usb", "wire"]
    },
		{
      value: "usb_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>usb_off</Icon> usb_off
      </div>),
      tags: ["cable", "connection", "device", "off", "usb", "wire"]
    },
		{
      value: "vaccines",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vaccines</Icon> vaccines
      </div>),
      tags: ["aid", "covid", "doctor", "drug", "emergency", "hospital", "immunity", "injection", "medical", "medication", "medicine", "needle", "pharmacy", "sick", "syringe", "vaccination", "vaccines", "vial"]
    },
		{
      value: "vape_free",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vape_free</Icon> vape_free
      </div>),
      tags: ["disabled", "e-cigarette", "enabled", "free", "never", "no", "off", "on", "places", "prohibited", "slash", "smoke", "smoking", "tobacco", "vape", "vaping", "vapor", "warning", "zone"]
    },
		{
      value: "vaping_rooms",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vaping_rooms</Icon> vaping_rooms
      </div>),
      tags: ["allowed", "e-cigarette", "never", "no", "places", "prohibited", "smoke", "smoking", "tobacco", "vape", "vaping", "vapor", "warning", "zone"]
    },
		{
      value: "verified",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>verified</Icon> verified
      </div>),
      tags: ["approve", "badge", "burst", "check", "complete", "done", "mark", "ok", "select", "star", "tick", "validate", "verified", "yes"]
    },
		{
      value: "verified_user",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>verified_user</Icon> verified_user
      </div>),
      tags: ["approve", "certified", "check", "complete", "done", "mark", "ok", "privacy", "private", "protect", "protection", "security", "select", "shield", "tick", "user", "validate", "verified", "yes"]
    },
		{
      value: "vertical_align_bottom",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vertical_align_bottom</Icon> vertical_align_bottom
      </div>),
      tags: ["align", "alignment", "arrow", "bottom", "doc", "down", "edit", "editing", "editor", "sheet", "spreadsheet", "text", "type", "vertical", "writing"]
    },
		{
      value: "vertical_align_center",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vertical_align_center</Icon> vertical_align_center
      </div>),
      tags: ["align", "alignment", "arrow", "center", "doc", "down", "edit", "editing", "editor", "sheet", "spreadsheet", "text", "type", "up", "vertical", "writing"]
    },
		{
      value: "vertical_align_top",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vertical_align_top</Icon> vertical_align_top
      </div>),
      tags: ["align", "alignment", "arrow", "doc", "edit", "editing", "editor", "sheet", "spreadsheet", "text", "top", "type", "up", "vertical", "writing"]
    },
		{
      value: "vertical_distribute",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vertical_distribute</Icon> vertical_distribute
      </div>),
      tags: ["alignment", "distribute", "format", "layout", "lines", "paragraph", "rule", "rules", "style", "text", "vertical"]
    },
		{
      value: "vertical_shades",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vertical_shades</Icon> vertical_shades
      </div>),
      tags: ["blinds", "cover", "curtains", "nest", "open", "shade", "shutter", "sunshade", "vertical"]
    },
		{
      value: "vertical_shades_closed",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vertical_shades_closed</Icon> vertical_shades_closed
      </div>),
      tags: ["blinds", "closed", "cover", "curtains", "nest", "roller", "shade", "shutter", "sunshade"]
    },
		{
      value: "vertical_split",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vertical_split</Icon> vertical_split
      </div>),
      tags: ["design", "format", "grid", "layout", "paragraph", "split", "text", "vertical", "website", "writing"]
    },
		{
      value: "vibration",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vibration</Icon> vibration
      </div>),
      tags: ["Android", "OS", "alert", "cell", "device", "hardware", "iOS", "mobile", "mode", "motion", "notification", "phone", "silence", "silent", "tablet", "vibrate", "vibration"]
    },
		{
      value: "video_call",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>video_call</Icon> video_call
      </div>),
      tags: ["+", "add", "call", "camera", "chat", "conference", "film", "filming", "hardware", "image", "motion", "new", "picture", "plus", "symbol", "video", "videography"]
    },
		{
      value: "video_camera_back",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>video_camera_back</Icon> video_camera_back
      </div>),
      tags: ["back", "camera", "image", "landscape", "mountain", "mountains", "photo", "photography", "picture", "rear", "video"]
    },
		{
      value: "video_camera_front",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>video_camera_front</Icon> video_camera_front
      </div>),
      tags: ["account", "camera", "face", "front", "human", "image", "people", "person", "photo", "photography", "picture", "profile", "user", "video"]
    },
		{
      value: "video_chat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>video_chat</Icon> video_chat
      </div>),
      tags: ["bubble", "cam", "camera", "chat", "comment", "communicate", "facetime", "feedback", "message", "speech", "video", "voice"]
    },
		{
      value: "video_file",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>video_file</Icon> video_file
      </div>),
      tags: ["camera", "doc", "document", "film", "filming", "hardware", "image", "motion", "picture", "video", "videography"]
    },
		{
      value: "video_label",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>video_label</Icon> video_label
      </div>),
      tags: ["label", "screen", "video", "window"]
    },
		{
      value: "video_library",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>video_library</Icon> video_library
      </div>),
      tags: ["arrow", "collection", "library", "play", "video"]
    },
		{
      value: "video_settings",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>video_settings</Icon> video_settings
      </div>),
      tags: ["change", "details", "gear", "info", "information", "options", "play", "screen", "service", "setting", "settings", "video", "window"]
    },
		{
      value: "video_stable",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>video_stable</Icon> video_stable
      </div>),
      tags: ["film", "filming", "recording", "setting", "stability", "stable", "taping", "video"]
    },
		{
      value: "videocam",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>videocam</Icon> videocam
      </div>),
      tags: ["cam", "camera", "conference", "film", "filming", "hardware", "image", "motion", "picture", "video", "videography"]
    },
		{
      value: "videocam_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>videocam_off</Icon> videocam_off
      </div>),
      tags: ["cam", "camera", "conference", "disabled", "enabled", "film", "filming", "hardware", "image", "motion", "off", "offline", "on", "picture", "slash", "video", "videography"]
    },
		{
      value: "videogame_asset",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>videogame_asset</Icon> videogame_asset
      </div>),
      tags: ["asset", "console", "control", "controller", "device", "entertainment", "esports", "game", "gamepad", "gaming", "google", "handheld", "hardware", "hobby", "online", "playstation", "remote", "social", "sports", "stadia", "video", "video game", "videogame", "xbox"]
    },
		{
      value: "videogame_asset_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>videogame_asset_off</Icon> videogame_asset_off
      </div>),
      tags: ["asset", "console", "control", "controller", "device", "disabled", "enabled", "entertainment", "esports", "game", "gamepad", "gaming", "google", "handheld", "hardware", "hobby", "off", "on", "online", "playstation", "remote", "slash", "social", "sports", "stadia", "video", "video game", "videogame", "xbox"]
    },
		{
      value: "view_agenda",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_agenda</Icon> view_agenda
      </div>),
      tags: ["agenda", "cards", "design", "format", "grid", "layout", "stacked", "view", "website"]
    },
		{
      value: "view_array",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_array</Icon> view_array
      </div>),
      tags: ["array", "design", "format", "grid", "layout", "view", "website"]
    },
		{
      value: "view_carousel",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_carousel</Icon> view_carousel
      </div>),
      tags: ["cards", "carousel", "design", "format", "grid", "layout", "view", "website"]
    },
		{
      value: "view_column",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_column</Icon> view_column
      </div>),
      tags: ["column", "design", "format", "grid", "layout", "vertical", "view", "website"]
    },
		{
      value: "view_comfy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_comfy</Icon> view_comfy
      </div>),
      tags: ["comfy", "grid", "layout", "pattern", "squares", "view"]
    },
		{
      value: "view_comfy_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_comfy_alt</Icon> view_comfy_alt
      </div>),
      tags: ["alt", "comfy", "cozy", "design", "format", "layout", "view", "web"]
    },
		{
      value: "view_compact",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_compact</Icon> view_compact
      </div>),
      tags: ["compact", "grid", "layout", "pattern", "squares", "view"]
    },
		{
      value: "view_compact_alt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_compact_alt</Icon> view_compact_alt
      </div>),
      tags: ["alt", "compact", "design", "format", "layout dense", "view", "web"]
    },
		{
      value: "view_cozy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_cozy</Icon> view_cozy
      </div>),
      tags: ["comfy", "cozy", "design", "format", "layout", "view", "web"]
    },
		{
      value: "view_day",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_day</Icon> view_day
      </div>),
      tags: ["cards", "carousel", "day", "design", "format", "grid", "layout", "view", "website"]
    },
		{
      value: "view_headline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_headline</Icon> view_headline
      </div>),
      tags: ["design", "format", "grid", "headline", "layout", "paragraph", "text", "view", "website"]
    },
		{
      value: "view_in_ar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_in_ar</Icon> view_in_ar
      </div>),
      tags: ["3d", "ar", "augmented", "cube", "daydream", "headset", "in", "reality", "square", "view", "virtual_reality", "vr"]
    },
		{
      value: "view_kanban",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_kanban</Icon> view_kanban
      </div>),
      tags: ["grid", "kanban", "layout", "pattern", "squares", "view"]
    },
		{
      value: "view_list",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_list</Icon> view_list
      </div>),
      tags: ["design", "format", "grid", "layout", "lines", "list", "stacked", "view", "website"]
    },
		{
      value: "view_module",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_module</Icon> view_module
      </div>),
      tags: ["design", "format", "grid", "layout", "module", "square", "squares", "stacked", "view", "website"]
    },
		{
      value: "view_quilt",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_quilt</Icon> view_quilt
      </div>),
      tags: ["design", "format", "grid", "layout", "quilt", "square", "squares", "stacked", "view", "website"]
    },
		{
      value: "view_sidebar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_sidebar</Icon> view_sidebar
      </div>),
      tags: ["design", "format", "grid", "layout", "sidebar", "view", "web"]
    },
		{
      value: "view_stream",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_stream</Icon> view_stream
      </div>),
      tags: ["design", "format", "grid", "layout", "lines", "list", "stacked", "stream", "view", "website"]
    },
		{
      value: "view_timeline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_timeline</Icon> view_timeline
      </div>),
      tags: ["grid", "layout", "pattern", "squares", "timeline", "view"]
    },
		{
      value: "view_week",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>view_week</Icon> view_week
      </div>),
      tags: ["bars", "columns", "design", "format", "grid", "layout", "view", "website", "week"]
    },
		{
      value: "vignette",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vignette</Icon> vignette
      </div>),
      tags: ["border", "edit", "editing", "filter", "gradient", "image", "photo", "photography", "setting", "vignette"]
    },
		{
      value: "villa",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>villa</Icon> villa
      </div>),
      tags: ["architecture", "beach", "estate", "home", "house", "maps", "place", "real", "residence", "residential", "traveling", "vacation stay", "villa"]
    },
		{
      value: "visibility",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>visibility</Icon> visibility
      </div>),
      tags: ["eye", "on", "reveal", "see", "show", "view", "visibility"]
    },
		{
      value: "visibility_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>visibility_off</Icon> visibility_off
      </div>),
      tags: ["disabled", "enabled", "eye", "off", "on", "reveal", "see", "show", "slash", "view", "visibility"]
    },
		{
      value: "voice_chat",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>voice_chat</Icon> voice_chat
      </div>),
      tags: ["bubble", "cam", "camera", "chat", "comment", "communicate", "facetime", "feedback", "message", "speech", "video", "voice"]
    },
		{
      value: "voice_over_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>voice_over_off</Icon> voice_over_off
      </div>),
      tags: ["account", "disabled", "enabled", "face", "human", "mute", "off", "on", "over", "people", "person", "profile", "recording", "slash", "speak", "speaking", "speech", "transcript", "user", "voice"]
    },
		{
      value: "voicemail",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>voicemail</Icon> voicemail
      </div>),
      tags: ["call", "device", "message", "missed", "mobile", "phone", "recording", "voice", "voicemail"]
    },
		{
      value: "volcano",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>volcano</Icon> volcano
      </div>),
      tags: ["crisis", "disaster", "eruption", "lava", "magma", "natural", "volcano"]
    },
		{
      value: "volume_down",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>volume_down</Icon> volume_down
      </div>),
      tags: ["audio", "control", "down", "music", "sound", "speaker", "tv", "volume"]
    },
		{
      value: "volume_mute",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>volume_mute</Icon> volume_mute
      </div>),
      tags: ["audio", "control", "music", "mute", "sound", "speaker", "tv", "volume"]
    },
		{
      value: "volume_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>volume_off</Icon> volume_off
      </div>),
      tags: ["audio", "control", "disabled", "enabled", "low", "music", "mute", "off", "on", "slash", "sound", "speaker", "tv", "volume"]
    },
		{
      value: "volume_up",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>volume_up</Icon> volume_up
      </div>),
      tags: ["audio", "control", "music", "sound", "speaker", "tv", "up", "volume"]
    },
		{
      value: "volunteer_activism",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>volunteer_activism</Icon> volunteer_activism
      </div>),
      tags: ["activism", "donation", "fingers", "gesture", "giving", "hand", "hands", "heart", "love", "sharing", "volunteer"]
    },
		{
      value: "vpn_key",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vpn_key</Icon> vpn_key
      </div>),
      tags: ["access", "code", "door", "entry", "key", "lock", "network", "passcode", "password", "unlock", "vpn"]
    },
		{
      value: "vpn_key_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vpn_key_off</Icon> vpn_key_off
      </div>),
      tags: ["access", "code", "disabled", "door", "enabled", "entry", "key", "lock", "network", "off", "offline", "on", "passcode", "password", "slash", "unlock", "vpn"]
    },
		{
      value: "vpn_lock",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vpn_lock</Icon> vpn_lock
      </div>),
      tags: ["earth", "globe", "lock", "locked", "network", "password", "privacy", "private", "protection", "safety", "secure", "security", "virtual", "vpn", "world"]
    },
		{
      value: "vrpano",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>vrpano</Icon> vrpano
      </div>),
      tags: ["angle", "image", "landscape", "mountain", "mountains", "panorama", "photo", "photography", "picture", "view", "vrpano", "wide"]
    },
		{
      value: "wallet",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wallet</Icon> wallet
      </div>),
      tags: []
    },
		{
      value: "wallpaper",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wallpaper</Icon> wallpaper
      </div>),
      tags: ["background", "dash", "dashed", "image", "landscape", "photo", "photography", "picture", "wallpaper"]
    },
		{
      value: "warehouse",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>warehouse</Icon> warehouse
      </div>),
      tags: ["garage", "industry", "manufacturing", "storage", "warehouse"]
    },
		{
      value: "warning",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>warning</Icon> warning
      </div>),
      tags: ["!", "alert", "attention", "caution", "danger", "error", "exclamation", "important", "mark", "notification", "symbol", "triangle", "warning"]
    },
		{
      value: "warning_amber",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>warning_amber</Icon> warning_amber
      </div>),
      tags: ["!", "alert", "amber", "attention", "caution", "danger", "error", "exclamation", "important", "mark", "notification", "symbol", "triangle", "warning"]
    },
		{
      value: "wash",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wash</Icon> wash
      </div>),
      tags: ["bathroom", "clean", "fingers", "gesture", "hand", "wash", "wc"]
    },
		{
      value: "watch",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>watch</Icon> watch
      </div>),
      tags: ["Android", "OS", "ar", "clock", "gadget", "iOS", "time", "vr", "watch", "wearables", "web", "wristwatch"]
    },
		{
      value: "watch_later",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>watch_later</Icon> watch_later
      </div>),
      tags: ["clock", "date", "later", "schedule", "time", "watch"]
    },
		{
      value: "watch_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>watch_off</Icon> watch_off
      </div>),
      tags: ["Android", "OS", "ar", "clock", "close", "gadget", "iOS", "off", "shut", "time", "vr", "watch", "wearables", "web", "wristwatch"]
    },
		{
      value: "water",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>water</Icon> water
      </div>),
      tags: ["aqua", "beach", "lake", "ocean", "river", "water", "waves", "weather"]
    },
		{
      value: "water_damage",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>water_damage</Icon> water_damage
      </div>),
      tags: ["architecture", "building", "damage", "drop", "droplet", "estate", "house", "leak", "plumbing", "real", "residence", "residential", "shelter", "water"]
    },
		{
      value: "water_drop",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>water_drop</Icon> water_drop
      </div>),
      tags: ["drink", "drop", "droplet", "eco", "liquid", "nature", "ocean", "rain", "social", "water"]
    },
		{
      value: "waterfall_chart",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>waterfall_chart</Icon> waterfall_chart
      </div>),
      tags: ["analytics", "bar", "chart", "data", "diagram", "graph", "infographic", "measure", "metrics", "statistics", "tracking", "waterfall"]
    },
		{
      value: "waves",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>waves</Icon> waves
      </div>),
      tags: ["beach", "lake", "ocean", "pool", "river", "sea", "swim", "water", "wave", "waves"]
    },
		{
      value: "waving_hand",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>waving_hand</Icon> waving_hand
      </div>),
      tags: ["bye", "fingers", "gesture", "goodbye", "greetings", "hand", "hello", "palm", "wave", "waving"]
    },
		{
      value: "wb_auto",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wb_auto</Icon> wb_auto
      </div>),
      tags: ["A", "W", "alphabet", "auto", "automatic", "balance", "character", "edit", "editing", "font", "image", "letters", "photo", "photography", "symbol", "text", "type", "white", "wp"]
    },
		{
      value: "wb_cloudy",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wb_cloudy</Icon> wb_cloudy
      </div>),
      tags: ["balance", "cloud", "cloudy", "edit", "editing", "white", "wp"]
    },
		{
      value: "wb_incandescent",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wb_incandescent</Icon> wb_incandescent
      </div>),
      tags: ["balance", "bright", "edit", "editing", "incandescent", "light", "lighting", "setting", "settings", "white", "wp"]
    },
		{
      value: "wb_iridescent",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wb_iridescent</Icon> wb_iridescent
      </div>),
      tags: ["balance", "bright", "edit", "editing", "electricity", "indoor", "iridescent", "lamp", "light", "lightbulb", "lighting", "setting", "settings", "tungsten", "white", "wp"]
    },
		{
      value: "wb_shade",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wb_shade</Icon> wb_shade
      </div>),
      tags: ["balance", "house", "light", "lighting", "shade", "wb", "white"]
    },
		{
      value: "wb_sunny",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wb_sunny</Icon> wb_sunny
      </div>),
      tags: ["balance", "bright", "light", "lighting", "sun", "sunny", "wb", "white"]
    },
		{
      value: "wb_twilight",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wb_twilight</Icon> wb_twilight
      </div>),
      tags: ["balance", "light", "lighting", "noon", "sun", "sunset", "twilight", "wb", "white"]
    },
		{
      value: "wc",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wc</Icon> wc
      </div>),
      tags: ["bathroom", "closet", "female", "gender", "male", "man", "restroom", "room", "wash", "water", "wc", "women"]
    },
		{
      value: "web",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>web</Icon> web
      </div>),
      tags: ["browser", "internet", "page", "screen", "site", "web", "website", "www"]
    },
		{
      value: "web_asset",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>web_asset</Icon> web_asset
      </div>),
      tags: ["-website", "app", "application desktop", "asset", "browser", "design", "download", "image", "interface", "internet", "layout", "screen", "site", "ui", "ux", "video", "web", "website", "window", "www"]
    },
		{
      value: "web_asset_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>web_asset_off</Icon> web_asset_off
      </div>),
      tags: ["asset", "browser", "disabled", "enabled", "internet", "off", "on", "page", "screen", "slash", "web", "webpage", "website", "windows", "www"]
    },
		{
      value: "web_stories",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>web_stories</Icon> web_stories
      </div>),
      tags: ["google", "images", "logo", "stories", "web"]
    },
		{
      value: "webhook",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>webhook</Icon> webhook
      </div>),
      tags: ["api", "developer", "development", "enterprise", "software", "webhook"]
    },
		{
      value: "weekend",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>weekend</Icon> weekend
      </div>),
      tags: ["chair", "couch", "furniture", "home", "living", "lounge", "relax", "room", "weekend"]
    },
		{
      value: "west",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>west</Icon> west
      </div>),
      tags: ["arrow", "directional", "left", "maps", "navigation", "west"]
    },
		{
      value: "whatshot",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>whatshot</Icon> whatshot
      </div>),
      tags: ["arrow", "circle", "direction", "fire", "frames", "hot", "round", "whatshot"]
    },
		{
      value: "wheelchair_pickup",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wheelchair_pickup</Icon> wheelchair_pickup
      </div>),
      tags: ["accessibility", "accessible", "body", "handicap", "help", "human", "person", "pickup", "wheelchair"]
    },
		{
      value: "where_to_vote",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>where_to_vote</Icon> where_to_vote
      </div>),
      tags: ["approve", "ballot", "check", "complete", "destination", "direction", "done", "location", "maps", "mark", "ok", "pin", "place", "poll", "select", "stop", "tick", "to", "validate election", "verified", "vote", "where", "yes"]
    },
		{
      value: "widgets",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>widgets</Icon> widgets
      </div>),
      tags: ["app", "box", "menu", "setting", "squares", "ui", "widgets"]
    },
		{
      value: "width_full",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>width_full</Icon> width_full
      </div>),
      tags: []
    },
		{
      value: "width_normal",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>width_normal</Icon> width_normal
      </div>),
      tags: []
    },
		{
      value: "width_wide",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>width_wide</Icon> width_wide
      </div>),
      tags: []
    },
		{
      value: "wifi",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi</Icon> wifi
      </div>),
      tags: ["connection", "data", "internet", "network", "scan", "service", "signal", "wifi", "wireless"]
    },
		{
      value: "wifi_1_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_1_bar</Icon> wifi_1_bar
      </div>),
      tags: ["1", "bar", "cell", "cellular", "connection", "data", "internet", "mobile", "network", "phone", "scan", "service", "signal", "wifi", "wireless"]
    },
		{
      value: "wifi_2_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_2_bar</Icon> wifi_2_bar
      </div>),
      tags: ["2", "bar", "cell", "cellular", "connection", "data", "internet", "mobile", "network", "phone", "scan", "service", "signal", "wifi", "wireless"]
    },
		{
      value: "wifi_calling",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_calling</Icon> wifi_calling
      </div>),
      tags: ["call", "calling", "cell", "connect", "connection", "connectivity", "contact", "device", "hardware", "mobile", "phone", "signal", "telephone", "wifi", "wireless"]
    },
		{
      value: "wifi_calling_3",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_calling_3</Icon> wifi_calling_3
      </div>),
      tags: ["3", "calling", "cell", "cellular", "data", "internet", "mobile", "network", "phone", "speed", "wifi", "wireless"]
    },
		{
      value: "wifi_channel",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_channel</Icon> wifi_channel
      </div>),
      tags: ["cellular", "channel", "connection", "data", "internet", "mobile", "network", "scan", "service", "signal", "wifi", "wireless"]
    },
		{
      value: "wifi_find",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_find</Icon> wifi_find
      </div>),
      tags: ["cellular", "connection", "data", "detect", "discover", "find", "internet", "look", "magnifying glass", "mobile", "network", "notice", "scan", "search", "service", "signal", "wifi", "wireless"]
    },
		{
      value: "wifi_lock",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_lock</Icon> wifi_lock
      </div>),
      tags: ["cellular", "connection", "data", "internet", "lock", "locked", "mobile", "network", "password", "privacy", "private", "protection", "safety", "secure", "security", "service", "signal", "wifi", "wireless"]
    },
		{
      value: "wifi_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_off</Icon> wifi_off
      </div>),
      tags: ["connection", "data", "disabled", "enabled", "internet", "network", "off", "offline", "on", "scan", "service", "signal", "slash", "wifi", "wireless"]
    },
		{
      value: "wifi_password",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_password</Icon> wifi_password
      </div>),
      tags: ["cellular", "connection", "data", "internet", "lock", "mobile", "network", "password", "scan", "secure", "service", "signal", "wifi", "wireless"]
    },
		{
      value: "wifi_protected_setup",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_protected_setup</Icon> wifi_protected_setup
      </div>),
      tags: ["around", "arrow", "arrows", "protected", "rotate", "setup", "wifi"]
    },
		{
      value: "wifi_tethering",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_tethering</Icon> wifi_tethering
      </div>),
      tags: ["cell", "cellular", "connection", "data", "internet", "mobile", "network", "phone", "scan", "service", "signal", "speed", "tethering", "wifi", "wireless"]
    },
		{
      value: "wifi_tethering_error",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_tethering_error</Icon> wifi_tethering_error
      </div>),
      tags: ["!", "alert", "attention", "caution", "cell", "cellular", "connection", "danger", "data", "error", "exclamation", "important", "internet", "mark", "mobile", "network", "notification", "phone", "rounded", "scan", "service", "signal", "speed", "symbol", "tethering", "warning", "wifi", "wireless"]
    },
		{
      value: "wifi_tethering_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wifi_tethering_off</Icon> wifi_tethering_off
      </div>),
      tags: ["cell", "cellular", "connection", "data", "disabled", "enabled", "internet", "mobile", "network", "off", "offline", "on", "phone", "scan", "service", "signal", "slash", "speed", "tethering", "wifi", "wireless"]
    },
		{
      value: "wind_power",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wind_power</Icon> wind_power
      </div>),
      tags: ["eco", "energy", "nest", "power", "wind", "windy"]
    },
		{
      value: "window",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>window</Icon> window
      </div>),
      tags: ["close", "glass", "grid", "home", "house", "interior", "layout", "outside", "window"]
    },
		{
      value: "wine_bar",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wine_bar</Icon> wine_bar
      </div>),
      tags: ["alcohol", "bar", "cocktail", "cup", "drink", "glass", "liquor", "wine"]
    },
		{
      value: "woman",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>woman</Icon> woman
      </div>),
      tags: ["female", "gender", "girl", "lady", "social", "symbol", "woman", "women"]
    },
		{
      value: "woman_2",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>woman_2</Icon> woman_2
      </div>),
      tags: ["female", "gender", "girl", "lady", "social", "symbol", "woman", "women"]
    },
		{
      value: "work",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>work</Icon> work
      </div>),
      tags: ["bag", "baggage", "briefcase", "business", "case", "job", "suitcase", "work"]
    },
		{
      value: "work_history",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>work_history</Icon> work_history
      </div>),
      tags: ["back", "backwards", "bag", "baggage", "briefcase", "business", "case", "clock", "date", "history", "job", "pending", "recent", "schedule", "suitcase", "time", "updates", "work"]
    },
		{
      value: "work_off",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>work_off</Icon> work_off
      </div>),
      tags: ["bag", "baggage", "briefcase", "business", "case", "disabled", "enabled", "job", "off", "on", "slash", "suitcase", "work"]
    },
		{
      value: "work_outline",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>work_outline</Icon> work_outline
      </div>),
      tags: ["bag", "baggage", "briefcase", "business", "case", "job", "suitcase", "work"]
    },
		{
      value: "workspace_premium",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>workspace_premium</Icon> workspace_premium
      </div>),
      tags: ["certification", "degree", "ecommerce", "guarantee", "medal", "permit", "premium", "ribbon", "verification", "workspace"]
    },
		{
      value: "workspaces",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>workspaces</Icon> workspaces
      </div>),
      tags: ["circles", "collaboration", "dot", "filled", "group", "outline", "space", "team", "work", "workspaces"]
    },
		{
      value: "wrap_text",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wrap_text</Icon> wrap_text
      </div>),
      tags: ["arrow writing", "doc", "edit", "editing", "editor", "sheet", "spreadsheet", "text", "type", "wrap", "write", "writing"]
    },
		{
      value: "wrong_location",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wrong_location</Icon> wrong_location
      </div>),
      tags: ["cancel", "clear", "close", "destination", "direction", "exit", "location", "maps", "no", "pin", "place", "quit", "remove", "stop", "wrong", "x"]
    },
		{
      value: "wysiwyg",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>wysiwyg</Icon> wysiwyg
      </div>),
      tags: ["composer", "mode", "screen", "site", "software", "system", "text", "view", "visibility", "web", "website", "window", "wysiwyg"]
    },
		{
      value: "yard",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>yard</Icon> yard
      </div>),
      tags: ["backyard", "flower", "garden", "home", "house", "nature", "pettle", "plants", "yard"]
    },
		{
      value: "youtube_searched_for",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>youtube_searched_for</Icon> youtube_searched_for
      </div>),
      tags: ["arrow", "back", "backwards", "find", "glass", "history", "inprogress", "load", "loading", "look", "magnify", "magnifying", "refresh", "renew", "restore", "reverse", "rotate", "search", "see", "youtube"]
    },
		{
      value: "zoom_in",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>zoom_in</Icon> zoom_in
      </div>),
      tags: ["big", "bigger", "find", "glass", "grow", "in", "look", "magnify", "magnifying", "plus", "scale", "search", "see", "size", "zoom"]
    },
		{
      value: "zoom_in_map",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>zoom_in_map</Icon> zoom_in_map
      </div>),
      tags: ["arrow", "arrows", "center", "destination", "in", "location", "maps", "middle", "move", "place", "stop", "zoom"]
    },
		{
      value: "zoom_out",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>zoom_out</Icon> zoom_out
      </div>),
      tags: ["find", "glass", "look", "magnify", "magnifying", "minus", "negative", "out", "scale", "search", "see", "size", "small", "smaller", "zoom"]
    },
		{
      value: "zoom_out_map",
      label: (<div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Icon>zoom_out_map</Icon> zoom_out_map
      </div>),
      tags: ["arrow", "arrows", "center", "destination", "location", "maps", "middle", "move", "out", "place", "stop", "zoom"]
    }
  ];
  