import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Task {
  static async reset() {
    const response = await HttpMethods.request_get("/v1/task/reset");
    if (!response) {
      addErrorMessageGlobal("Error al resetear las tasks");
    }
    return response;
  }

  static async list(framecode, props) {
    let datacode = props.datacode;
    if (datacode) {
      datacode = `&filetype=${datacode}`;
    } else {
      datacode = "";
    }
    let response = await HttpMethods.request_get(
      `/v1/task/user/active/myemail@test.com`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener las tareas");
      response = [];
    }
    return response;
  }

  static async listByUser(email, first = 0, pagination = 100) {
    const response = await HttpMethods.request_get(
      `/v1/task/user/active/${email}?format=flat`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener las tareas");
    }
    return response;
  }

  static async dossier(dossierCode) {
    let response = await HttpMethods.request_get(
      `/v1/flow/dossier/${dossierCode}`
    );
    if (!response) {
      addErrorMessageGlobal("Error al obtener las tareas");
      response = [];
    }
    return response;
  }

  static async resetOneTask(filetype) {
    const response = await HttpMethods.request_get(
      `/v1/flow/reset/${filetype}`
    );
    if (!response) {
      addErrorMessageGlobal("Error al resetear las tareas");
    }
    return response;
  }
}
