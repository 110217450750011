import { useState, useEffect } from "react";
import { useParams } from "react-router";
import Input from "src/modules/components/common/Input/Input";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import Button from "src/modules/components/common/Button/Button";
import useSetNavbarTitle from "src/modules/hooks/useSetNavBarTitle";
import { Resume } from "src/lib/api"; // Asegúrate de que esta API esté configurada
import { useNavigate } from "react-router-dom";

const EditResume = () => {
  const { resumecode } = useParams();
  const [resume, setResume] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Cambiar el título según se esté editando o creando
  useSetNavbarTitle(resumecode ? "Editar resumen" : "Crear resumen");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Resume.read(resumecode);
        console.log(data);
        if (!data) throw new Error("Resumen no encontrado.");
        setResume(data);
      } catch (err) {
        console.error(err);
        setError("Error al cargar el resumen.");
      } finally {
        setLoading(false);
      }
    };

    if (resumecode) {
      fetchData();
    } else {
      // Modo creación: inicializa con valores por defecto
      setResume({
        name: "",
        scope: "",
        resumecode: "",
        who: "",
        timestamp: "",
        active: false,
        limit: "",
        sort: "",
        group: "",
        aggregate: "",
        filter: "",
      });
      setLoading(false);
    }
  }, [resumecode]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setResume((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Resume.crupdate(resume);
      if (!resumecode && response && response.resumecode) {
        navigate(`/resume/${response.resumecode}`);
      }
    } catch (err) {
      console.error("Error al guardar:", err);
    }
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      await Resume.delete(resumecode);
      navigate(-1);
    } catch (err) {
      console.error("Error al borrar:", err);
    }
  };

  if (loading) return <div>Cargando resumen...</div>;
  if (error) return <div>{error}</div>;
  if (!resume) return <div>No se encontró el resumen.</div>;

  return (
    <form
      style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      onSubmit={handleSubmit}
    >
      {/* Línea 1 */}
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        <Input
          label="Nombre"
          name="name"
          value={resume.name || ""}
          onChange={handleChange}
          styleInputContainer={{ flex: "1 1 250px" }}
        />
        <Input
          label="Ámbito"
          name="scope"
          value={resume.scope || ""}
          onChange={handleChange}
          styleInputContainer={{ flex: "1 1 250px" }}
        />
        <Input
          label="Código del resumen"
          name="resumecode"
          value={resume.resumecode || ""}
          onChange={handleChange}
          // Solo se deshabilita si se está editando (cuando existe resumecode en la URL)
          disabled={!!resumecode}
          styleInputContainer={{ flex: "1 1 250px" }}
        />
      </div>

      {/* Línea 2 */}
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        <Input
          label="Editado por"
          name="who"
          value={resume.who || ""}
          disabled
          styleInputContainer={{ flex: "1 1 250px" }}
        />
        <Input
          label="Última modificación"
          name="timestamp"
          value={resume.timestamp || ""}
          disabled
          styleInputContainer={{ flex: "1 1 250px" }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            flex: "1 1 250px",
          }}
        >
          <input
            type="checkbox"
            name="active"
            checked={resume.active || false}
            onChange={handleChange}
            id="active"
          />
          <label htmlFor="active">¿Resumen activo?</label>
        </div>
      </div>

      {/* Línea 3 */}
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        <Input
          label="Límite de agrupaciones"
          type="number"
          name="limit"
          value={resume.limit || ""}
          onChange={handleChange}
          styleInputContainer={{ flex: "1 1 250px" }}
        />
        <Input
          label="Ordenar por"
          name="sort"
          value={resume.sort || ""}
          onChange={handleChange}
          styleInputContainer={{ flex: "1 1 250px" }}
        />
      </div>

      {/* Línea 4 */}
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        <Input
          label="Agrupar por"
          name="group"
          value={resume.group || ""}
          onChange={handleChange}
          styleInputContainer={{ flex: "1 1 250px" }}
        />
        <Input
          label="Variables (separadas por coma)"
          name="aggregate"
          value={resume.aggregate || ""}
          onChange={handleChange}
          styleInputContainer={{ flex: "1 1 250px" }}
        />
      </div>

      {/* Filtro */}
      <TextArea
        label="Filtro (formato MongoDB)"
        name="filter"
        value={resume.filter || ""}
        onChange={handleChange}
        styleTextArea={{ minHeight: "100px" }}
      />

      {/* Botones */}
      <div
        style={{
          position: "fixed",
          bottom: "1rem",
          right: "1rem",
          display: "flex",
          gap: "1rem",
        }}
      >
        <Button
          type="submit"
          tooltip="Guardar resumen"
          icon="save"
        />
        {/* Mostrar el botón de borrar solo en modo edición */}
        {resumecode && (
          <Button
            tooltip="Borrar resumen"
            icon="delete"
            onClick={handleDelete}
          />
        )}
      </div>
    </form>
  );
};

export default EditResume;
