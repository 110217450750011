import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import Select from "src/modules/components/common/Select/Select";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import constants from "src/definitions/constants";
import { Agent as AgentAPI } from "src/lib/api";

// Opciones para el select de tipos de agentes
const tiposAgentes = Object.entries(constants.tiposAgentes).map(
  ([, value]) => ({ value: value.value, label: value.label })
);

const AgenteModal = ({
  modalType,
  editedAgent,
  handleEditAgente,
  onSelectChange,
  handleSaveAgente,
  handleDeleteAgente,
  onClose,
}) => {
  const [modelsOptions, setModelsOptions] = useState([]);

  useEffect(() => {
    if (
      editedAgent &&
      editedAgent.type === constants.tiposAgentes.openllm.value &&
      editedAgent.url
    ) {
      AgentAPI.models(editedAgent.url).then((res) => {
        if (res.error) {
          setModelsOptions([]);
          return;
        }
        setModelsOptions(res.models.models);
      });
    }
  }, [editedAgent?.url, editedAgent?.type]);

  const modelSelectOptions = modelsOptions.map((modelItem) => ({
    value: modelItem.model,
    label: `${modelItem.name} (${modelItem.details.parameter_size})`,
  }));

  const isNewAgent = modalType === "ADD_AGENT";
  const boxTitle =
    modalType === "UPLOAD_FILES"
      ? "Agregar archivos"
      : isNewAgent
      ? "Agregar Agente"
      : "Editar Agente";
  // Función que retorna los campos según el tipo de agente
  const renderAgentFields = () => {
    switch (editedAgent?.type) {
      case constants.tiposAgentes.openai.value:
      case constants.tiposAgentes.gemini.value:
        return (
          <>
            <Input
              name="apikey"
              type="text"
              onChange={handleEditAgente}
              value={editedAgent?.apikey || ""}
              label="API Key"
            />
            <Input
              name="agentid"
              type="text"
              onChange={handleEditAgente}
              value={editedAgent?.agentid || ""}
              label="Agent ID"
            />
            <TextArea
              name="systemprompt"
              onChange={handleEditAgente}
              value={editedAgent?.systemprompt || ""}
              label="System Prompt"
              styleTextArea={{ minHeight: "180px" }}
            />
          </>
        );
      // Para openllm se muestran URL, select de modelos y system prompt
      case constants.tiposAgentes.openllm.value:
        return (
          <>
            <Input
              name="url"
              type="text"
              placeholder="http://192.168.40.90:11434"
              onBlur={handleEditAgente}
              defaultValue={editedAgent?.url || ""}
              label="URL"
            />
            <Select
              name="modelSelect"
              options={modelSelectOptions}
              onChange={(event) => onSelectChange(event, "model")}
              label="Modelos disponibles"
              value={modelSelectOptions.find(
                (option) => option.value === editedAgent?.model
              )}
            />
            <TextArea
              name="systemprompt"
              onChange={handleEditAgente}
              value={editedAgent?.systemprompt || ""}
              label="System Prompt"
              styleTextArea={{ minHeight: "180px" }}
            />
          </>
        );
      // Para n8n solo se muestra la URL
      case constants.tiposAgentes.n8n.value:
        return (
          <>
            <Input
              name="url"
              type="text"
              placeholder="URL"
              onBlur={handleEditAgente}
              defaultValue={editedAgent?.url || ""}
              label="URL"
            />
            <TextArea
              name="systemprompt"
              onChange={handleEditAgente}
              value={editedAgent?.systemprompt || ""}
              label="System Prompt"
              styleTextArea={{ minHeight: "180px" }}
            />
          </>
        );
      default:
        return null;
    }
  };
  const footer = (
    <div className="agente-container-buttons">
      {(modalType === "ADD_AGENT" || modalType === "EDIT_AGENT") && (
        <>
          <Button icon="save" onClick={handleSaveAgente} />
          {!isNewAgent && <Button icon="delete" onClick={handleDeleteAgente} />}
        </>
      )}
    </div>
  );

  return (
    <BoxFloat title={boxTitle} onClickExit={onClose} footer={footer}>
      {(modalType === "ADD_AGENT" || modalType === "EDIT_AGENT") && (
        <>
          <Input
            name="name"
            type="text"
            onChange={handleEditAgente}
            value={editedAgent?.name || ""}
            label="Nombre"
          />
          <Input
            name="description"
            type="text"
            onChange={handleEditAgente}
            value={editedAgent?.description || ""}
            label="Descripción"
          />
          <Select
            name="type"
            options={tiposAgentes}
            onChange={(event) => onSelectChange(event, "type")}
            label="Tipo"
            value={tiposAgentes.find(
              (tipo) => tipo.value === editedAgent?.type
            )}
          />
          {renderAgentFields()}
        </>
      )}
    </BoxFloat>
  );
};

AgenteModal.propTypes = {
  modalType: PropTypes.oneOf(["UPLOAD_FILES", "ADD_AGENT", "EDIT_AGENT"])
    .isRequired,
  editedAgent: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    apikey: PropTypes.string,
    agentid: PropTypes.string,
    systemprompt: PropTypes.string,
    url: PropTypes.string,
    model: PropTypes.string,
  }),
  handleEditAgente: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  handleSaveAgente: PropTypes.func.isRequired,
  handleDeleteAgente: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AgenteModal;
