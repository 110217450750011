import React from "react";
import SimpleEnum from "./SimpleEnum";
import RelationalEnum from "./RelationalEnum";
import ColorEnum from "./ColorEnum";
import IconsEnum from "./IconsEnum";
import "../EnumEditor.css";

const EnumOption = ({ option, index, enumData, onEditList }) => {
  const optionByType = () => {
    switch (enumData.header.type) {
      case "relational":
        return (
          <RelationalEnum
            option={option}
            onChange={(e) => {
              const newList = [...enumData.list];
              newList[index] = {
                ...newList[index],
                [e.target.name]: e.target.value,
              };
              onEditList(newList);
            }}
          />
        );
      case "color":
        return (
          <ColorEnum
            option={option}
            onChange={(e) => {
              const newList = [...enumData.list];
              const property = e.target ? "value" : "label";
              const value = e.target ? e.target.value : e;
              newList[index] = {
                ...newList[index],
                [property]: value,
              };
              onEditList(newList);
            }}
          />
        );
      case "icons":
        return (
          <IconsEnum
            option={option}
            onChange={(e) => {
              const newList = [...enumData.list];
              newList[index] = {
                ...newList[index],
                [e.target.name]: e.target.value,
              };
              onEditList(newList);
            }}
          />
        );
      default:
        return (
          <SimpleEnum
            option={option}
            onChange={(e) => {
              const newList = [...enumData.list];
              newList[index] = {
                ...newList[index],
                value: e.target.value,
              };
              onEditList(newList);
            }}
          />
        );
    }
  };

  return <>{optionByType()}</>;
};

export default EnumOption;
