import React, { useContext, useEffect } from "react";
import Button from "src/modules/components/common/Button/Button";
import { MenuCanvaContext } from "src/modules/contexts/MenuCanvaContextProvider";
import "./MenuCanva.css";
import MenuCanvaList from "./MenuCanvaList/MenuCanvaList";
import {
  loadMenuData,
  loadRoles,
  resetMenuData,
  setMenuListData,
  setUpdateSetupVariable,
  setVariableModal,
} from "src/modules/actions/menuCanvaActions";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import menuCanvaConstants from "./MenuCanvaConstants";
import MenuCanvaModalList from "./MenuCanvaModalList/MenuCanvaModalList";
import MenuCanvaModalCard from "./MenuCanvaModalCard/MenuCanvaModalCard";
import { plainStructureMenu } from "./helpers";
import { Dev } from "src/lib/api";
const MenuCanva = () => {
  const { state, dispatch } = useContext(MenuCanvaContext);
  const { setupData, setSetupData } = useAppContext();
  const {
    menuData,
    loading,
    backupMenuData,
    modalVariable,
    updateMenuToSetup,
    hasBackupMenuDataBeenLoadedOnce,
  } = state;
  const {
    VAR_ADD_MENULIST,
    VAR_EDIT_MENULIST,
    VAR_EDIT_MENUCARD,
    VAR_ADD_MENUCARD,
  } = menuCanvaConstants;
  useEffect(() => {
    loadMenuData(dispatch, setupData.menu, hasBackupMenuDataBeenLoadedOnce);
  }, [dispatch, setupData.menu, hasBackupMenuDataBeenLoadedOnce]);

  useEffect(() => {
    loadRoles(dispatch, setupData.roles);
  }, [dispatch, setupData.roles]);

  //Updates changes to setup
  useEffect(() => {
    if (updateMenuToSetup === true) {
      const plainedMenuData = plainStructureMenu(menuData);
      setSetupData((prevSetupData) => ({
        ...prevSetupData,
        menu: plainedMenuData,
      }));
      setUpdateSetupVariable(dispatch, false);
    }
  }, [updateMenuToSetup, setSetupData, menuData, dispatch]);

  const menuReset = async () => {
    await Dev.fillmenu();
    window.location.reload();
  };
  return (
    <div className="setup-menu__dashboard">
      <div className="setup-menu__buttons">
        <Button
          content={"Deshacer cambios"}
          onClick={() => resetMenuData(dispatch, backupMenuData)}
        />
        <Button content={"Actualizar menús"} onClick={menuReset} />
      </div>
      {!loading && (
        <div className="setup-menu__lists" data-droppable>
          {menuData.map((menu, index) => {
            const menuToIgnore = ["login", "register"];
            if (menuToIgnore.includes(menu.id)) return null;
            return (
              <MenuCanvaList
                key={menu.id}
                idMenuList={menu.id}
                data={menu}
                indexMenuList={index}
              />
            );
          })}
          <div className="menu-list__container">
            <div
              className="menu-list-add"
              onClick={() => {
                setMenuListData(dispatch, {});
                setVariableModal(dispatch, VAR_ADD_MENULIST);
              }}
            >
              +
            </div>
          </div>
        </div>
      )}
      {modalVariable === VAR_ADD_MENULIST && <MenuCanvaModalList />}
      {modalVariable === VAR_EDIT_MENULIST && <MenuCanvaModalList />}
      {modalVariable === VAR_ADD_MENUCARD && <MenuCanvaModalCard />}
      {modalVariable === VAR_EDIT_MENUCARD && <MenuCanvaModalCard />}
    </div>
  );
};

export default MenuCanva;
