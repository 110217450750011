import React from "react";
import "./Movimientos.css";

const MovimientosLeftTab = ({ movementData, onSelectItem }) => {
  return (
    <>
      <h3>Formas de pago</h3>
      <div className="etiquetas-formasPago">
        {movementData.way.map((item, index) => (
          <Etiqueta
            key={index}
            label={item.code}
            category="way"
            index={index}
            onSelectItem={onSelectItem}
          />
        ))}
      </div>
      <h3>Grupos contables</h3>
      <div className="etiquetas-gruposContables">
        {movementData.type.map((item, index) => (
          <Etiqueta
            key={index}
            label={item.code}
            category="type"
            index={index}
            onSelectItem={onSelectItem}
          />
        ))}
      </div>
      <h3>Servicios</h3>
      <div className="etiquetas-servicios">
        {movementData.service.map((item, index) => (
          <Etiqueta
            key={index}
            label={item}
            category="service"
            index={index}
            onSelectItem={onSelectItem}
          />
        ))}
      </div>
      <h3>Grupos</h3>
      <div className="etiquetas-grupos">
        {movementData.group.map((item, index) => (
          <Etiqueta
            key={index}
            label={item.name}
            category="group"
            index={index}
            onSelectItem={onSelectItem}
          />
        ))}
      </div>
    </>
  );
};

const Etiqueta = ({ label, category, index, onSelectItem }) => {
  return (
    <div
      className="movimientos-etiqueta"
      onClick={() => onSelectItem(category, index)}
    >
      <span>{label}</span>
    </div>
  );
};

export default MovimientosLeftTab;
