// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.executive-chat {
  display: flex;
  gap: 2%;
  flex-wrap: wrap;
  align-content: start;
  height: 90vh;
  padding: 8px;
}

.executive-cat__header {
  width: 100%;
  height: 5%;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  align-content: center;
  padding: 4px 20px;
}

.executive-cat__sidebar {
  width: 50%;
  height: 90%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
}

.executive-cat__content {
  flex-grow: 1;
  height: 90%;
}

.executive-chat__content__content {
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 20px;
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/routes/ExecutiveChat/ExecutiveChat.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,OAAO;EACP,eAAe;EACf,oBAAoB;EACpB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,WAAW;EACX,UAAU;EACV,oCAAoC;EACpC,mBAAmB;EACnB,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,WAAW;EACX,cAAc;EACd,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,oCAAoC;EACpC,mBAAmB;EACnB,WAAW;EACX,YAAY;AACd","sourcesContent":[".executive-chat {\r\n  display: flex;\r\n  gap: 2%;\r\n  flex-wrap: wrap;\r\n  align-content: start;\r\n  height: 90vh;\r\n  padding: 8px;\r\n}\r\n\r\n.executive-cat__header {\r\n  width: 100%;\r\n  height: 5%;\r\n  background-color: rgba(0, 0, 0, 0.6);\r\n  border-radius: 20px;\r\n  align-content: center;\r\n  padding: 4px 20px;\r\n}\r\n\r\n.executive-cat__sidebar {\r\n  width: 50%;\r\n  height: 90%;\r\n  max-width: 50%;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.executive-cat__content {\r\n  flex-grow: 1;\r\n  height: 90%;\r\n}\r\n\r\n.executive-chat__content__content {\r\n  background-color: rgba(0, 0, 0, 0.6);\r\n  border-radius: 20px;\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
