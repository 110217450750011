import React, { useEffect, useRef, useState } from "react";
import "./Table.css";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { Link } from 'react-router-dom';
import { Icon } from "@mui/material";

const Table = ({ columns, data, style }) => {
  const endOfTableRef = useRef(null);
  const [prevDataLength, setPrevDataLength] = useState(0);
  
  useEffect(() => {
    if (data.length === prevDataLength + 1) {
      endOfTableRef.current.scrollTo({
        top: endOfTableRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
    setPrevDataLength(data.length);
  }, [data.length, prevDataLength]);

  const renderCell = (row, column) => {
    const cellValue = row[column.key];
    if (column.type === "checkbox") {
      return (
        <div className="cell-content" title={cellValue ? "Activo" : "Inactivo"}>
          <Input
            type="checkbox"
            value={cellValue}
            styleInputContainer={{ justifyContent: "center" }}
            onChange={() => {}}
            disabled={true}
          />
        </div>
      );
    } else if (column.type === "link") {
      return (
        <div className="cell-content" title={cellValue}>
          <Link /*to={""}*/ rel="noreferrer">{cellValue ?? ""}</Link>
        </div>
      );
    } else if (column.type === "actions") {
      return (
        <div className="cell-content" title={cellValue}>
          {column.buttons.map((el, index) => (
            <Button
              onClick={() => el.onClick(row)}
              key={index}
              icon={el.icon}
              buttonStyle={{
                minWidth: "40px",
                maxWidth: "50px",
                maxHeight: "100px",
                minHeight: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          ))}
        </div>
      );
    } else {
      return (
        <div className="cell-content" title={cellValue}>
          {cellValue ?? ""}
          {row.icon === "warning" && column.key === "title" ? (
            <Icon title="Revise el pathmenu">{row.icon}</Icon>
          ) : null}
        </div>
      );
    }
  };

  return (
    <div className="table" ref={endOfTableRef} style={style}>
      <div className="table-header">
        {columns.map((column) => (
          <div
            className="header-cell"
            key={column.key}
            style={{
              ...column.style_header_cell,
              minWidth: column.style_header_cell?.minWidth || "50px",
            }}
          >
            {column.label}
          </div>
        ))}
      </div>
      <div className="table-body">
        {data.map((row, index) => (
          <div className="table-row" key={`row_${index}`}>
            {columns.map((column) => (
              <div
                className={`table-cell table-cell_row${index}`}
                key={column.key}
                data-cell={column.key}
                style={{
                  ...column.style_body_cell,
                  minWidth: column.style_body_cell?.minWidth || "50px",
                }}
              >
                {renderCell(row, column)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.string,
      link: PropTypes.string,
      style_header_cell: PropTypes.object,
      style_body_cell: PropTypes.object,
      buttons: PropTypes.arrayOf(
        PropTypes.shape({
          onClick: PropTypes.func,
          icon: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  style: PropTypes.object, // Añadido para la validación de style
};

export default Table;
