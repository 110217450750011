// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.color-picker__wrapper {
    width: min-content !important;
    padding: 8px;
    border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/modules/components/common/ColorPicker/ColorPicker.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".color-picker__wrapper {\r\n    width: min-content !important;\r\n    padding: 8px;\r\n    border-radius: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
