import React, { useState, useEffect } from "react";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";

const MovimientosWay = ({
  movementData,
  setMovementData,
  selectedItem,
  clearSelectedItem,
}) => {
  const [showForm, setShowForm] = useState(false);
  const [item, setItem] = useState({ code: "", description: "" });
  const isEditing =
    selectedItem.category === "way" && selectedItem.index !== null;

  // Si se selecciona un elemento desde la lista, se carga en el formulario
  useEffect(() => {
    if (isEditing) {
      const existingItem = movementData.way[selectedItem.index];
      setItem(existingItem);
      setShowForm(true);
    }
  }, [selectedItem]);

  const handleInputChange = (e) => {
    setItem({ ...item, [e.target.name]: e.target.value });
  };

  const handleSave = () => {
    if (isEditing) {
      // Actualizar elemento existente
      const updatedArray = [...movementData.way];
      updatedArray[selectedItem.index] = item;
      setMovementData({ ...movementData, way: updatedArray });
    } else {
      // Agregar nuevo elemento
      setMovementData({ ...movementData, way: [...movementData.way, item] });
    }
    setShowForm(false);
    clearSelectedItem();
    setItem({ code: "", description: "" });
  };

  const handleDelete = () => {
    if (isEditing) {
      const updatedArray = movementData.way.filter(
        (_, idx) => idx !== selectedItem.index
      );
      setMovementData({ ...movementData, way: updatedArray });
      setShowForm(false);
      clearSelectedItem();
      setItem({ code: "", description: "" });
    }
  };

  return (
    <div className="movimientosTab">
      <Button
        icon="add"
        onClick={() => {
          setShowForm(true);
          setItem({ code: "", description: "" });
        }}
      />
      {showForm && (
        <>
          <div className="movimientosTab-editFields">
            <Input
              name="code"
              type="text"
              placeholder="Código"
              onChange={handleInputChange}
              value={item.code}
              label="Code"
            />
            <Input
              name="description"
              type="text"
              placeholder="Descripción"
              onChange={handleInputChange}
              value={item.description}
              label="Descripción"
            />
          </div>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button icon="save" onClick={handleSave} />
            {isEditing && <Button icon="delete" onClick={handleDelete} />}
          </div>
        </>
      )}
    </div>
  );
};

export default MovimientosWay;
