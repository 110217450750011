import React from "react";
import IconSelect from "src/modules/components/common/IconSelect";
import Input from "src/modules/components/common/Input/Input";

// Value es el nombre del icono, label es el propio icono
const IconsEnum = ({ option, onChange }) => {
  return (
    <>
      <Input
        name="value"
        label="Valor:"
        value={option.value}
        onChange={onChange}
      />

      <IconSelect
        name="label"
        label="Icono:"
        value={option.label}
        onChange={(e) => {
          e = {
            target: {
              name: "label",
              value: e ? e.value : '',
            },
          };
          onChange(e);
        }}
      />
    </>
  );
};

export default IconsEnum;
