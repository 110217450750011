import React, { useState } from "react";
import PropTypes from "prop-types";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import Button from "src/modules/components/common/Button/Button";
import PeriodicityModal from "./components/PeriodicityModal";
import ISO3166 from "src/definitions/ISO3166.json";
import constants from "src/definitions/constants.js";
import { Bank as BankAPI, Setup as SetupAPI } from "src/lib/api";
import "./BancosStyles.css";
import { useBankManagement } from "./hooks/useBankManagement";
import BankTableBody from "./components/BankTableBody";
import NewBankModal from "./components/NewBankModal";

const columns = [
  {
    field: "active",
    headerName: "Activo",
    type: "checkbox",
    width: 20,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "bank",
    headerName: "Banco",
    type: "string",
    width: 200,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "cuenta",
    headerName: "Cuenta",
    type: "string",
    width: 300,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "name",
    headerName: "Descripción",
    type: "string",
    width: 200,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "ownername",
    headerName: "Propietario",
    type: "string",
    width: 200,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "status",
    headerName: "Estado",
    type: "string",
    width: 200,
    align: "left",
    headerAlign: "left",
  },
  {
    field: "balance",
    headerName: "Saldos",
    type: "number",
    width: 100,
    align: "right",
    headerAlign: "left",
  },
  {
    field: "lastaccess",
    headerName: "Fecha Último Acceso",
    type: "string",
    width: 200,
    align: "center",
    headerAlign: "left",
  },
  {
    field: "actions",
    headerName: "Acciones",
    type: "buttons",
    width: 200,
    align: "left",
    headerAlign: "left",
    elements: [
      {
        type: "button",
        content: "Editar",
        onClick: (e) => e, // se delega la lógica al componente correspondiente
        icon: "delete",
      },
      {
        type: "button",
        content: "Actualizar",
        onClick: (e) => e,
        icon: "refresh",
      },
    ],
  },
];

const Bancos = ({ setIsAccountSet }) => {
  const { setupData: setupInfo, setSetupData: setSetupInfo } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const {
    institutions,
    setInstitutions,
    isFetchingInstitutions,
    boxNewBank,
    setBoxNewBank,
    boxPeriodicityMovements,
    setBoxPeriodicityMovements,
    selectedBank,
    setSelectedBank,
    peridocity,
    setPeridocity,
    isGocardlessAuthorized,
    fetchInstitutions,
    addBank,
    saveNewBank,
    openPeriodicityModal,
    savePeriodicity,
    deleteBank,
    updateAccount,
  } = useBankManagement(setupInfo, setSetupInfo);

  const countryOptions = ISO3166.map((country) => ({
    value: country.iso,
    label: country.country,
  }));

  const periodicityOptions = Object.entries(constants.periodicity).map(
    ([key, value]) => ({
      value: value.value,
      label: value.label,
    })
  );

  return (
    <div className="bancos-container-box-float">
      {boxNewBank && (
        <NewBankModal
          countryOptions={countryOptions}
          institutions={institutions}
          isFetchingInstitutions={isFetchingInstitutions}
          selectedBank={selectedBank}
          onCountryChange={(event) => {
            if (!event) return;
            setSelectedBank((prev) => ({ ...prev, country: event.value }));
            fetchInstitutions(event.value);
          }}
          onBankChange={(event) => {
            if (!event) return;
            setSelectedBank((prev) => ({ ...prev, bank: event.value }));
          }}
          onClose={() => {
            setBoxNewBank(false);
            // Se limpian los estados relacionados
            setInstitutions([]);
            setSelectedBank({});
          }}
          onSave={saveNewBank}
        />
      )}
      {boxPeriodicityMovements && (
        <PeriodicityModal
          periodicityOptions={periodicityOptions}
          onPeriodicityChange={(event) => {
            if (!event) return;
            setPeridocity(event.value);
          }}
          onClose={() => setBoxPeriodicityMovements(false)}
          onSave={savePeriodicity}
        />
      )}
      {!isGocardlessAuthorized && (
        <div
          style={{ marginBottom: "10px", color: "red", textAlign: "center" }}
        >
          Comprobar las credenciales de GoCardless
        </div>
      )}
      <div
        className="buttons-bancos"
        style={{
          display: "flex",
          gap: "20px",
          justifyContent: "space-between",
          paddingBottom: "10px",
        }}
      >
        <Button icon="add" onClick={addBank} tooltip="Añadir nuevo banco" />
        <Button
          content="Periodicidad movimientos"
          onClick={openPeriodicityModal}
        />
        <Button
          content={isLoading ? "Cargando..." : "Actualizar cuentas"}
          onClick={async () => {
            setIsLoading(true);
            const result = await BankAPI.bankRequisitions();
            if (result) {
              const setup = await SetupAPI.read();
              setSetupInfo(setup);
            }
            setIsLoading(false);
          }}
        />
        <Button
          content="Keys"
          onClick={() => setIsAccountSet((prev) => !prev)}
        />
      </div>
      <table className="table-bancos">
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.field}
                style={{
                  width: column.width,
                  textAlign: column.headerAlign || column.align,
                }}
              >
                {column.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <BankTableBody
            rows={setupInfo.bank.accounts}
            columns={columns}
            setupInfo={setupInfo}
            setSetupInfo={setSetupInfo}
            deleteBank={deleteBank}
            updateAccount={updateAccount}
          />
        </tbody>
      </table>
    </div>
  );
};

Bancos.propTypes = {
  setIsAccountSet: PropTypes.func,
};

export default Bancos;
