import React, { useContext } from "react";
import BoxFloat from "src/modules/components/common/BoxFloat/BoxFloat";
import FormGenerator from "src/modules/components/FormGenerator/FormGenerator";
import { generateUniqueId } from "../helpers";
import {
  addMenuListToMenu,
  modifyMenuListAtMenu,
  setMenuListData,
  setVariableModal,
} from "src/modules/actions/menuCanvaActions";
import { MenuCanvaContext } from "src/modules/contexts/MenuCanvaContextProvider";
import menuCanvaConstants from "../MenuCanvaConstants";
import { listElements, defaultValue } from "./listElements";

const fillUndefinedProps = (data) => {
  return {
    ...defaultValue,
    ...data,
  };
};
const MenuCanvaModalList = () => {
  const { VAR_EDIT_MENULIST, VAR_ADD_MENULIST } = menuCanvaConstants;
  const { state, dispatch } = useContext(MenuCanvaContext);
  const { menuData, rolesOptions, modalVariable, listData } = state;

  const updateDataValues = (e) => {
    const filledProps = fillUndefinedProps(e);
    const menuListFilled = {
      ...filledProps,
      pathmenu: `/${filledProps.id}`,
    };
    if (modalVariable === VAR_EDIT_MENULIST)
      modifyMenuListAtMenu(dispatch, menuListFilled, menuData);
    else if (modalVariable === VAR_ADD_MENULIST) {
      menuListFilled.id = generateUniqueId();
      addMenuListToMenu(dispatch, menuListFilled, menuData);
    }
    setVariableModal(dispatch, null);
    setMenuListData(dispatch, null);
  };
  const elements = listElements;
  const elementRolesTargetIndex = listElements.findIndex(
    (el) => el.name === "roles"
  );
  elements[elementRolesTargetIndex].options = rolesOptions;

  return (
    <BoxFloat
      title="Nuevo menú"
      onClickExit={() => setVariableModal(dispatch, null)}
      onClose={() => setVariableModal(dispatch, null)}
    >
      <FormGenerator
        form={{ elements: elements }}
        onSubmit={updateDataValues}
        initialValues={listData}
      />
    </BoxFloat>
  );
};

export default MenuCanvaModalList;
