import React from 'react'
import { Icon, Tooltip } from '@mui/material';
import './Input.css';

const InputLabel = ({ label, tooltip }) => (
  label && (
    <label className="input-label">
      {label}
      {tooltip && (
        <Tooltip title={tooltip}>
          <Icon className="info-icon" size={20}>info</Icon>
        </Tooltip>
      )}
    </label>
  )
);

export default InputLabel