import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import Title from "src/modules/components/common/Title/Title";
import Menu from "./menu";
import WorkingClock from "./WorkingClock/WorkingClock";
import Notifications from "./Notifications/Notifications";
import "./Navbar.css";
import { useScrollState } from "./hooks/useScrollState";
import { useMenuGenerator } from "./hooks/useMenuGenerator";
import useSetNavbarTitle from "src/modules/hooks/useSetNavBarTitle";

const NavBar = () => {
  const navRef = useRef();
  const { role, publicSetup, navBarTitle, controlUserActivity } =
    useAppContext();

  const isScrolled = useScrollState();
  const menu = useMenuGenerator(publicSetup);

  const toggleNavBar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };
  useSetNavbarTitle();
  return (
    menu.length > 0 && (
      <header className={isScrolled ? "scrolled" : ""}>
        <i className="fas fa-bars nav-btn" onClick={toggleNavBar} />
        <Link to={publicSetup.linklogo}>
          <img
            id="logo"
            src={publicSetup.logo}
            alt="logo"
            className="resize_fit_center"
          />
        </Link>
        <Title text={navBarTitle} />
        <nav ref={navRef}>
          {menu.map(
            (menuItem, index) =>
              role &&
              menuItem.roles.includes(role) &&
              menuItem.active && (
                <Menu
                  val={menuItem}
                  key={menuItem.id}
                  isLastItem={index === menu.length - 1}
                />
              )
          )}
          <Notifications />
          <i className="fas fa-times nav-close-btn" onClick={toggleNavBar} />
          {controlUserActivity === "true" && <WorkingClock />}
        </nav>
      </header>
    )
  );
};

export default NavBar;
