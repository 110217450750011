// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.executives {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: auto;
  max-width: 1280px;
  justify-content: space-between;
}

.grid-layout {
  margin: auto;
  width: 100%;
}

/* Estilos del botón de candado */
.lock-button {
  position: absolute;
  top: 8px;
  right: 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  color: #333;
  transition: color 0.3s ease;
}

.lock-button:hover {
  color: #007bff;
}
`, "",{"version":3,"sources":["webpack://./src/routes/Executives/Executives.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA,iCAAiC;AACjC;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,WAAW;EACX,2BAA2B;AAC7B;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".executives {\r\n  position: relative;\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n  display: flex;\r\n  width: auto;\r\n  max-width: 1280px;\r\n  justify-content: space-between;\r\n}\r\n\r\n.grid-layout {\r\n  margin: auto;\r\n  width: 100%;\r\n}\r\n\r\n/* Estilos del botón de candado */\r\n.lock-button {\r\n  position: absolute;\r\n  top: 8px;\r\n  right: 16px;\r\n  background: none;\r\n  border: none;\r\n  cursor: pointer;\r\n  font-size: 24px;\r\n  color: #333;\r\n  transition: color 0.3s ease;\r\n}\r\n\r\n.lock-button:hover {\r\n  color: #007bff;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
