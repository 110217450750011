import React, { useEffect, useState } from "react";
import "./ExecutiveChat.css";
import { useParams } from "react-router";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import { Executive as ExecutiveAPI } from "src/lib/api";
import Chat from "src/modules/components/common/Chat/Chat";
import Button from "src/modules/components/common/Button/Button";
import { useNavigate } from "react-router-dom";

const ExecutiveChat = () => {
  const { executiveid } = useParams();
  const { setNavBarTitle } = useAppContext();
  const [messages, setMessages] = useState([]);
  const [loadingAgentMessage, setLoadingAgentMessage] = useState(false);
  const [executiveData, setExecutiveData] = useState({});
  const [threadId, setThreadId] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchExecutive = async () => {
      const executive = await ExecutiveAPI.read(executiveid).then(
        (executive) => executive.executive
      );
      setNavBarTitle(executive.name);
      setExecutiveData(executive);
    };
    fetchExecutive();
  }, []);

  useEffect(() => {
    const lastMessage = messages.slice(-1)[0];
    if (lastMessage?.role === "user") {
      setLoadingAgentMessage(true);
      ExecutiveAPI.question(executiveData.id, lastMessage, threadId).then(
        (response) => {
          const agent = response.agent;
          if (agent.message) {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                text: agent.message,
                role: "agent",
                status: agent.status,
                executiveid: agent.executiveid,
              },
            ]);
            if (agent.threadid) setThreadId(agent.threadid);
            setLoadingAgentMessage(false);
          }
        }
      );
    }
  }, [messages]);

  const sendMessage = (message) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { text: message, role: "user" },
    ]);
  };
  const returnToExecutives = () => {
    setTimeout(() => navigate(-1), 500);
  };

  return (
    <div className="executive-chat">
      <div className="executive-cat__header">
        <Button onClick={returnToExecutives} icon="arrow_back" />
      </div>
      <div className="executive-cat__sidebar">
        <Chat
          chatTitle="Chat"
          messages={messages}
          loadingAgentMessage={loadingAgentMessage}
          onSubmitMessage={sendMessage}
          onClearChat={() => {
            setMessages([]), setLoadingAgentMessage(false);
            setThreadId(null);
          }}
        />
      </div>
      <div className="executive-cat__content">
        <div className="executive-chat__content__content"></div>
      </div>
    </div>
  );
};

export default ExecutiveChat;
