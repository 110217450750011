import React, { useContext, useMemo } from "react";
import { ViewContext } from "src/modules/contexts/ViewContextProvider";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import LoadingSpinner from "src/modules/components/common/LoadingSpinner/LoadingSpinner";
import "./MetaWindow.css";

const MetaWindow = ({ onClick, onClickError }) => {
  const { state } = useContext(ViewContext);
  const urlParams = useMemo(
    () => new URLSearchParams(window.location.search),
    []
  );
  const paramDc = useMemo(() => urlParams.get("dc"), [urlParams]);

  const matchedObjectsOriginal = useMemo(() => {
    if (!state.view || !state.data) return [];

    const dataIDs = state.view.frames
      .filter((item) => item.scope === "dossier" && item.type === "show")
      .map((item) =>
        item.datacode === "{{params.dc}}" ? paramDc : item.datacode
      );

    return dataIDs.map((id) => state.data[id]).filter(Boolean);
  }, [state.data, state.view, paramDc]);

  if (!state.view || !state.data) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <div className="dashboard-floating-resize">
      {matchedObjectsOriginal.map((dossier) => (
        <div
          key={dossier.header.code}
          className="dashboard-floating-resize-actions-open"
          onClick={() => onClick(dossier.header.code)}
        >
          <Tooltip
            title={`Abrir frame: ${dossier.header.name}`}
            placement="left"
            arrow
          >
            <span className="dashboard-floating-resize-actions-open-name">
              {dossier.header.name}
            </span>
          </Tooltip>
          <div className="dashboard-floating-resize-actions-metadata">
            {dossier.metadata?.nerrors > 0 && (
              <Tooltip
                title={`Ver errores del dossier: ${dossier.header.name}`}
                placement="left"
                arrow
              >
                <span
                  className="dashboard-floating-resize-actions-errors"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickError(dossier.header.code);
                  }}
                >
                  {dossier.metadata.nerrors} error
                  {dossier.metadata.nerrors !== 1 ? "es" : ""}
                </span>
              </Tooltip>
            )}
            {dossier.metadata?.activetasks > 0 && (
              <Tooltip
                title={`Ver tareas del dossier: ${dossier.header.name}`}
                placement="left"
                arrow
              >
                <span
                  className="dashboard-floating-resize-actions-tasks"
                  onClick={(e) => {
                    e.stopPropagation();
                    onClick(dossier.header.code);
                  }}
                >
                  {dossier.metadata.activetasks} tarea
                  {dossier.metadata.activetasks !== 1 ? "s" : ""}
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

MetaWindow.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClickError: PropTypes.func.isRequired,
};

export default MetaWindow;
