import React, { useEffect, useRef } from "react";
import "./List.css";
import { useMustache } from "src/modules/hooks/useMustache";
import Icon from "@mui/material/Icon";
import Graph from "src/modules/components/common/Graph/Graph";
import ReactDOM from "react-dom/client"; // Cambia a ReactDOM.createRoot
import { Resume } from "src/lib/api";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Common components
import Input from "src/modules/components/common/Input/Input";
import Select from "src/modules/components/common/Select/Select";
import Value from "src/modules/components/common/Value/Value";
import { useLocation } from "react-router";

const TableCell = React.memo(
  ({
    onDragStart,
    draggable,
    dragdropObject = "column",
    dragdropIndex,
    width,
    onResize,
    isHeader,
    children,
    rowIndex,
    rowData,
    column,
    minWidth,
    setColumnWidths,
  }) => {
    const mustache = useMustache();
    const cellRef = useRef(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const handleMouseDown = (event) => {
      event.preventDefault(); // Evita la selección de texto predeterminada

      document.body.style.userSelect = "none";

      const startX = event.clientX;
      const startWidth = width;

      const handleMouseMove = (e) => {
        const newWidth = startWidth + e.clientX - startX;
        onResize(newWidth);
      };

      const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);
        document.body.style.userSelect = "auto";
      };

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    const handleEdit = (rowIndex, colAccessor, newValue) => {
      if (!newValue) newValue = null;
      // const updatedData = [...state.data[frame._id]];
      // updatedData[rowIndex][colAccessor] = newValue;

      // Update the state.data with the new value
      // state.data[frame._id] = updatedData;
    };

    const renderCell = (rowIndex, rowData, column) => {
      if (!rowData || !column) return;
      const cellValue = mustache.replaceMustache(
        column.variable,
        rowData,
        queryParams
      );

      if (column.type === "checkbox" || column.type === "boolean") {
        return (
          <Input
            type="checkbox"
            name={column.variable}
            checked={cellValue}
            onChange={(e) =>
              handleEdit(rowIndex, column.variable, e.target.checked)
            }
            disabled={true}
          />
        );
      } else if (column.type === "select") {
        return (
          <Select
            isSearchable={true}
            isClearable={true}
            name={column.variable}
            options={JSON.parse(column.options)}
            placeholder="Scope..."
            onChange={(e) =>
              handleEdit(rowIndex, column.variable, e.value || null)
            }
            value={JSON.parse(column.options).find(
              (option) => option.value === cellValue
            )}
          />
        );
      } else if (column.type === "graph") {
        let subelements = cellValue.split("/");
        const domgraph = `listgraph${rowIndex}`;
        if (subelements[0] === "resume") {
          Resume.graph(subelements[1]).then((data) => {
            const graph = document.getElementById(domgraph);
            const root = ReactDOM.createRoot(graph); // Usa ReactDOM.createRoot
            root.render(<Graph chart={data} />);
          });
        }
        return <Icon>pie_chart</Icon>;
      } else if (column.link) {
        const link = mustache.replaceMustache(
          column.link,
          rowData,
          queryParams
        );
        return (
          <Link to={link} target="" title={cellValue}>
            {column.icon && <Icon>{column.icon}</Icon>}
            {cellValue}
          </Link>
        );
      } else if (column.type === "link") {
        return (
          <a
            href={cellValue}
            target="_blank"
            rel="noreferrer"
            title={cellValue}
          >
            {cellValue ? column.text : ""}
          </a>
        );
      } else if (column.type === "image") {
        return (
          <>
            {cellValue ? (
              <img
                src={cellValue}
                alt={cellValue}
                className="executivelist_card-image"
              />
            ) : (
              <div className="executivelist_card-placeholder">No Image</div>
            )}
          </>
        );
      } else if (column.editable) {
        return (
          <span
            title={cellValue}
            contentEditable={true}
            onBlur={(e) =>
              handleEdit(rowIndex, column.variable, e.target.textContent)
            }
            dangerouslySetInnerHTML={{ __html: cellValue }}
          />
        );
      } else {
        return (
          <Value
            type={column.type}
            content={cellValue ?? ""}
            options={
              column.options ? JSON.parse(column.options) : column.options
            }
          />
        );
      }
    };
    useEffect(() => {
      if (!isHeader || !setColumnWidths) return;
      cellRef.current.style.width = "auto";
      setColumnWidths(rowIndex, cellRef.current.offsetWidth + 28);
      cellRef.current.style.width = `${width}px`;
    }, [isHeader, rowIndex, setColumnWidths]);

    return (
      <span
        title={typeof children === "string" ? children : null}
        ref={isHeader ? cellRef : null}
        className={`lf-table-cell ${isHeader ? "lf-header-cell" : ""}`}
        style={{ width: `${width}px`, minWidth: `${minWidth}px` }}
        onDragStart={isHeader ? onDragStart : undefined}
        data-dragdrop-object={dragdropObject} // Usa data-atributos
        data-dragdrop-index={dragdropIndex} // Usa data-atributos
        draggable={isHeader ? draggable : undefined}
      >
        {isHeader && (
          <span className="lf-resizer" onMouseDown={handleMouseDown} />
        )}
        {children ? children : renderCell(rowIndex, rowData, column)}
      </span>
    );
  }
);

TableCell.displayName = "TableCell"; // Añade esta línea

TableCell.propTypes = {
  onDragStart: PropTypes.func,
  draggable: PropTypes.bool,
  dragdropObject: PropTypes.string,
  dragdropIndex: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onResize: PropTypes.func,
  isHeader: PropTypes.bool,
  children: PropTypes.node,
  rowIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowData: PropTypes.object,
  column: PropTypes.shape({
    variable: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string,
        })
      ),
    ]),
    link: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
    editable: PropTypes.bool,
  }),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setColumnWidths: PropTypes.func,
};

export default TableCell;
