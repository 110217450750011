import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import { menuCanvaReducer } from "../reducers/menuCanvaReducer";

const initialState = {
  hasBackupMenuDataBeenLoadedOnce: false,
  menuData: [],
  backupMenuData: [],
  rolesOptions: [],
  listData: null, //{}
  cardData: null, //{}
  modalVariable: null,
  loading: true,
  error: null,
  updateMenuToSetup: false, //When true updates changes to setup
};

export const MenuCanvaContext = createContext(initialState);

export const MenuCanvaContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(menuCanvaReducer, initialState);
  return (
    <MenuCanvaContext.Provider value={{ state, dispatch }}>
      {children}
    </MenuCanvaContext.Provider>
  );
};

MenuCanvaContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
