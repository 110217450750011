import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Movement, Setup as SetupAPI } from "src/lib/api";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import TextArea from "src/modules/components/common/Text Area/TextArea";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import useSetNavbarTitle from "src/modules/hooks/useSetNavBarTitle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PaymentsIcon from "@mui/icons-material/Payments";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Select, {
  onSelectChangeSingle,
} from "src/modules/components/common/Select/Select";
import { useAppContext } from "src/modules/contexts/AppContextProvider";

const EditMovement = () => {
  const [setupMovementsOptions, setSetupMovementsOptions] = useState({});
  const { setupData, setSetupData } = useAppContext();
  useEffect(() => {
    // Tener en cuenta que esto no funcionara para que lo utilize un usuario
    // sin permisos elevados, ya que se tiene que hacer la peticion a setup
    // Como setuppublic no devuelve estas variables se ha tenido que hacer
    // de esta forma :DDD
    function fetchSetup() {
      SetupAPI.read().then((res) => {
        setSetupData(res);
      });
    }
    fetchSetup();
  }, []);
  useEffect(() => {
    const setupMovements = setupData?.movement || {
      way: [],
      type: [],
      service: [],
      group: [],
    };
    //Saneamos los datos para que se pueden utilizar en un select
    setSetupMovementsOptions({
      way: setupMovements.way.map((item) => ({
        value: item.code,
        label: item.description,
      })),
      type: setupMovements.type.map((item) => ({
        value: item.code,
        label: item.description,
      })),
      service: setupMovements.service.map((item) => ({
        value: item,
        label: item,
      })),
      group: setupMovements.group.map((item) => ({
        value: item.name,
        label: item.name,
        subgroup: item.subgroup,
      })),
    });
  }, [setupData]);

  const [movement, setMovement] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showOriginDestination, setShowOriginDestination] = useState(false);

  const { movementcode } = useParams();
  useSetNavbarTitle("Editar movimiento");

  useEffect(() => {
    const loadData = async () => {
      try {
        const data = await Movement.read(movementcode);
        if (!data || !data.movement) {
          throw new Error("Movimiento no encontrado.");
        }
        setMovement(data.movement);
      } catch (error) {
        console.error(error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (movementcode) {
      loadData();
    }
  }, [movementcode]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMovement((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleNestedInputChange = (e, section) => {
    const { name, value } = e.target;
    setMovement((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [name]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await Movement.update(movementcode, movement);
    } catch (error) {
      console.error(error);
    }
  };

  // Componente recursivo para visualizar la estructura de datos
  const DataViewer = ({ data }) => {
    if (typeof data !== "object" || data === null) {
      return <span title={`Tipo: ${typeof data}`}>{String(data)}</span>;
    }

    return (
      <ul
        style={{
          listStyle: "none",
          paddingLeft: "1rem",
          borderLeft: "1px solid #ccc",
        }}
      >
        {Object.entries(data).map(([key, value]) => (
          <li key={key} style={{ marginBottom: "0.5rem" }}>
            <span
              title={`Tipo: ${typeof value}`}
              style={{ fontWeight: "bold" }}
            >
              {key}:
            </span>{" "}
            <DataViewer data={value} />
          </li>
        ))}
      </ul>
    );
  };

  const getPaymentIcon = (way) => {
    switch (way?.toLowerCase()) {
      case "efectivo":
      case "cash":
        return <PaymentsIcon style={{ marginRight: "8px" }} />;
      case "banco":
      case "bank":
        return <AccountBalanceIcon style={{ marginRight: "8px" }} />;
      case "tarjeta de credito":
      case "tarjeta":
      case "credit card":
      case "card":
        return <CreditCardIcon style={{ marginRight: "8px" }} />;
      default:
        return <MoreHorizIcon style={{ marginRight: "8px" }} />;
    }
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!movement) {
    return <div>No se encontró información del movimiento.</div>;
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
      >
        {/* Información de identificación e Información de fechas en la misma línea */}
        <div style={{ display: "flex", gap: "1rem" }}>
          {/* Información de identificación */}
          <fieldset style={{ flex: 1 }}>
            <legend>Información de identificación</legend>
            <Input
              label="Código del movimiento:"
              name="movementcode"
              value={movement.movementcode}
              onChange={handleInputChange}
              styleInputContainer={{ marginBottom: "0.5rem" }}
              disabled={true}
            />
          </fieldset>

          {/* Información de fechas */}
          <fieldset style={{ flex: 1 }}>
            <legend>Información de fechas</legend>
            <div style={{ display: "flex", gap: "1rem" }}>
              <Input
                type="date"
                label="Fecha oficial:"
                name="date"
                value={movement.date}
                styleInputContainer={{ marginBottom: "0.5rem" }}
                onChange={handleInputChange}
                disabled={true}
              />
              <Input
                type="datetime-local"
                label="Fecha real:"
                name="when"
                value={movement.when}
                styleInputContainer={{ marginBottom: "0.5rem" }}
                onChange={handleInputChange}
                disabled={true}
              />
            </div>
          </fieldset>
        </div>

        {/* Información financiera */}
        <fieldset>
          <legend>Información financiera</legend>
          <div style={{ display: "flex", gap: "1rem" }}>
            {/* Descripción a la izquierda */}
            <div style={{ flex: 1 }}>
              <TextArea
                label="Descripción:"
                name="description"
                type="text"
                value={movement.description}
                onChange={handleInputChange}
                styleTextArea={{ minHeight: "100px", marginBottom: "0.5rem" }}
              />
            </div>
            {/* Descripción original a la derecha */}
            <div style={{ flex: 1 }}>
              <TextArea
                label="Descripción original:"
                name="originaldescription"
                type="text"
                value={movement.originaldescription}
                onChange={handleInputChange}
                styleTextArea={{ minHeight: "100px", marginBottom: "0.5rem" }}
                disabled={true}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "3rem",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flex: "1 1 200px",
              }}
            >
              <span>Gasto:</span>
              <Input
                type="number"
                name="debit"
                value={movement.debit}
                onChange={handleInputChange}
                unit={movement.currency}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flex: "1 1 200px",
              }}
            >
              <span>Ingreso:</span>
              <Input
                type="number"
                name="credit"
                value={movement.credit}
                onChange={handleInputChange}
                unit={movement.currency}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flex: "1 1 200px",
              }}
            >
              <span>Contratado:</span>
              <Input
                type="number"
                name="hired"
                value={movement.hired}
                onChange={handleInputChange}
                unit={movement.currency}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flex: "1 1 200px",
              }}
            >
              <span>Deuda:</span>
              <Input
                type="number"
                name="loan"
                value={movement.loan}
                onChange={handleInputChange}
                unit={movement.currency}
              />
            </div>
          </div>
        </fieldset>

        {/* Sección Origen --> Destino */}
        <fieldset>
          <legend
            onClick={() => setShowOriginDestination(!showOriginDestination)}
            style={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              {/* Imagen en Origen si hay Débito */}
              {movement.debit > 0 && movement.originlogourl && (
                <img
                  src={movement.originlogourl}
                  alt="Logo Banco Origen"
                  style={{
                    width: "20px",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              )}
              Origen {movement.from?.name || "Sin nombre"}
              <span style={{ display: "flex", flexDirection: "column" }}>
                {getPaymentIcon(movement.way)}
                <ArrowForwardIcon />
              </span>
              {/* Imagen en Destino si NO hay Débito (hay Crédito) */}
              {movement.debit === 0 && movement.originlogourl && (
                <img
                  src={movement.originlogourl}
                  alt="Logo Banco Destino"
                  style={{
                    width: "20px",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              )}
              Destino {movement.to?.name || "Sin nombre"}
            </span>
            {showOriginDestination ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </legend>
          {showOriginDestination && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              {/* Columna Izquierda: Origen */}
              <fieldset style={{ flex: 1 }}>
                <legend>Origen</legend>
                <Input
                  label="ID:"
                  name="id"
                  value={movement.from?.id || ""}
                  onChange={(e) => handleNestedInputChange(e, "from")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="Nombre:"
                  name="name"
                  value={movement.from?.name || ""}
                  onChange={(e) => handleNestedInputChange(e, "from")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="NIF/CIF:"
                  name="tin"
                  value={movement.from?.tin || ""}
                  onChange={(e) => handleNestedInputChange(e, "from")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="Dirección:"
                  name="address"
                  value={movement.from?.address || ""}
                  onChange={(e) => handleNestedInputChange(e, "from")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="Email:"
                  name="email"
                  value={movement.from?.email || ""}
                  onChange={(e) => handleNestedInputChange(e, "from")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="IBAN:"
                  name="iban"
                  value={movement.from?.iban || ""}
                  onChange={(e) => handleNestedInputChange(e, "from")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="Número de tarjeta:"
                  name="cardnumber"
                  value={movement.from?.cardnumber || ""}
                  onChange={(e) => handleNestedInputChange(e, "from")}
                />
              </fieldset>

              {/* Columna Derecha: Destino */}
              <fieldset style={{ flex: 1 }}>
                <legend>Destino</legend>
                <Input
                  label="ID:"
                  name="id"
                  value={movement.to?.id || ""}
                  onChange={(e) => handleNestedInputChange(e, "to")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="Nombre:"
                  name="name"
                  value={movement.to?.name || ""}
                  onChange={(e) => handleNestedInputChange(e, "to")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="NIF/CIF:"
                  name="tin"
                  value={movement.to?.tin || ""}
                  onChange={(e) => handleNestedInputChange(e, "to")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="Dirección:"
                  name="address"
                  value={movement.to?.address || ""}
                  onChange={(e) => handleNestedInputChange(e, "to")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="Email:"
                  name="email"
                  value={movement.to?.email || ""}
                  onChange={(e) => handleNestedInputChange(e, "to")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="IBAN:"
                  name="iban"
                  value={movement.to?.iban || ""}
                  onChange={(e) => handleNestedInputChange(e, "to")}
                  styleInputContainer={{ marginBottom: "0.5rem" }}
                />
                <Input
                  label="Número de tarjeta:"
                  name="cardnumber"
                  value={movement.to?.cardnumber || ""}
                  onChange={(e) => handleNestedInputChange(e, "to")}
                />
              </fieldset>
            </div>
          )}
        </fieldset>

        {/* Clasificación */}
        <fieldset>
          <legend>Clasificación</legend>
          {/* Primera fila: Forma de pago, Tipo y Servicio */}
          <div style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
            {/* <Input
              label="Forma de pago:"
              name="way"
              value={movement.way}
              onChange={handleInputChange}
              styleInputContainer={{ flex: 1 }}
            /> */}
            <Select
              creatable
              label="Forma de pago:"
              name="way"
              options={setupMovementsOptions.way}
              value={
                setupMovementsOptions.way.find(
                  (option) => option.value === movement.way
                ) || { value: movement.way, label: movement.way }
              }
              onChange={(option) =>
                onSelectChangeSingle(option, setMovement, "way")
              }
            />
            {/* Este input es un select cuando este listo el endpoint para sacar los valores de las option */}
            <Select
              creatable
              label="Tipo:"
              name="type"
              options={setupMovementsOptions.type}
              value={
                setupMovementsOptions.type.find(
                  (option) => option.value === movement.type
                ) || { value: movement.type, label: movement.type }
              }
              onChange={(option) =>
                onSelectChangeSingle(option, setMovement, "type")
              }
            />
            <Select
              creatable
              label="Servicio:"
              name="service"
              options={setupMovementsOptions.service}
              value={
                setupMovementsOptions.service.find(
                  (option) => option.value === movement.service
                ) || { value: movement.service, label: movement.service }
              }
              onChange={(option) =>
                onSelectChangeSingle(option, setMovement, "service")
              }
            />
          </div>
          {/* Segunda fila: Grupo, Código de expediente y Nombre del expediente */}
          <div style={{ display: "flex", gap: "1rem", marginBottom: "0.5rem" }}>
            <Select
              creatable
              label="Grupo:"
              name="group"
              options={setupMovementsOptions.group}
              value={
                setupMovementsOptions.group.find(
                  (option) => option.value === movement.group
                ) || { value: movement.group, label: movement.group }
              }
              onChange={(option) => {
                onSelectChangeSingle(option, setMovement, "group");
                setMovement((prev) => ({
                  ...prev,
                  subgroup: "",
                }));
              }}
            />

            <Select
              creatable
              label="Sub Grupo:"
              name="subgroup"
              options={
                setupMovementsOptions.group
                  .find((group) => group.value === movement.group)
                  ?.subgroup.map((sub) => ({
                    value: sub,
                    label: sub,
                  })) || []
              }
              value={
                movement.subgroup
                  ? { value: movement.subgroup, label: movement.subgroup }
                  : null
              }
              onChange={(option) => {
                onSelectChangeSingle(option, setMovement, "subgroup");
                Movement.train_group(
                  movement.movementcode,
                  movement.group,
                  option.value
                )
                  .then((res) => console.log("train_group response:", res))
                  .catch((err) => console.error("train_group error:", err));
              }}
            />
            <Input
              label="Unidad:"
              name="unit"
              value={movement.unit}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <Input
              label="Nombre del expediente:"
              name="dossiername"
              value={movement.dossiername}
              onChange={handleInputChange}
              styleInputContainer={{ flex: 1 }}
            />

            <Input
              label="Código de expediente:"
              name="dossiercode"
              value={movement.dossiercode}
              onChange={handleInputChange}
              styleInputContainer={{ flex: 1 }}
            />
          </div>
        </fieldset>

        {/* Documentación relacionada */}
        <fieldset style={{ marginBottom: "4rem" }}>
          <legend>Documentación relacionada</legend>
          <Input
            label="Documento de pago:"
            name="paydocument"
            value={movement.paydocument}
            onChange={handleInputChange}
            styleInputContainer={{ marginBottom: "0.5rem" }}
          />
          <Input
            label="Documento de factura:"
            name="invoicedocument"
            value={movement.invoicedocument}
            onChange={handleInputChange}
            styleInputContainer={{ marginBottom: "0.5rem" }}
          />
          <Input
            label="Número de factura:"
            name="invoicenumber"
            value={movement.invoicenumber}
            onChange={handleInputChange}
            styleInputContainer={{ marginBottom: "0.5rem" }}
          />
          <Input
            label="Email de factura:"
            name="emailfactura"
            value={movement.emailfactura}
            onChange={handleInputChange}
            styleInputContainer={{ marginBottom: "0.5rem" }}
          />
          <div className="input-container">
            <TextArea
              label="Notas:"
              name="notes"
              value={movement.notes}
              onChange={handleInputChange}
            />
          </div>
        </fieldset>

        {/* Botón para guardar cambios */}
        <Button
          type="submit"
          onClick={handleSubmit}
          tooltip="Guardar cambios"
          icon="save"
          style={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
          }}
        />
      </form>

      {/* Visualización de la estructura de datos */}
      <div style={{ marginTop: "2rem" }}>
        <h2>Datos originales</h2>
        <DataViewer data={JSON.parse(movement.original)} />
      </div>
    </div>
  );
};

export default EditMovement;
