import React from "react";
import PropTypes from "prop-types";
import Input from "src/modules/components/common/Input/Input";
import Button from "src/modules/components/common/Button/Button";
import { generateUUID } from "../utils/generateUUID";
import { Bank as BankAPI, Setup as SetupAPI } from "src/lib/api";
import "../BancosStyles.css";
const BankTableBody = ({
  rows,
  columns,
  setupInfo,
  setSetupInfo,
  deleteBank,
  updateAccount,
}) => {
  const onClickRevalidar = (row) => {
    BankAPI.bankRevalidation(row.id).then((res) => {
      window.open(res.link, "_blank");
    });
  };

  // Maneja el cambio de texto en la descripción
  const handleDescriptionChange = (e, row) => {
    const newSetupInfo = { ...setupInfo };
    if (!row._id) {
      row._id = generateUUID();
    }
    const rowIndex = newSetupInfo.bank.accounts.findIndex(
      (account) => account._id === row._id
    );
    if (rowIndex !== -1) {
      newSetupInfo.bank.accounts[rowIndex].name = e.target.value;
    }
    setSetupInfo(newSetupInfo);
  };

  // Se llama al hacer focus para guardar el valor original y compararlo después
  const handleDescriptionFocus = (row) => {
    if (row._originalName === undefined) {
      row._originalName = row.name;
    }
  };

  // Al perder el foco, se guarda el setup solo si hubo modificaciones
  const handleDescriptionBlur = async (row) => {
    if (row.name !== row._originalName) {
      await SetupAPI.write(setupInfo);
      row._originalName = row.name;
    }
  };

  const handleCheckboxChange = async (event, row) => {
    const newSetupInfo = { ...setupInfo };
    if (!row._id) {
      row._id = generateUUID();
    }
    const rowIndex = newSetupInfo.bank.accounts.findIndex(
      (account) => account._id === row._id
    );
    if (rowIndex !== -1) {
      newSetupInfo.bank.accounts[rowIndex].active = event.target.checked;
    }
    setSetupInfo(newSetupInfo);
    await SetupAPI.write(newSetupInfo);
  };

  // Maneja la acción de refrescar, bloqueando el botón mientras se completa la petición
  const handleRefresh = async (row) => {
    await updateAccount(row);
  };

  const renderCell = (column, row) => {
    if (column.field === "status") {
      return row.status === "CR" ? "CR (Pendiente Validacion)" : row.status;
    } else if (column.field === "name") {
      return (
        <input
          type="text"
          value={row.name || ""}
          onFocus={() => handleDescriptionFocus(row)}
          onChange={(e) => handleDescriptionChange(e, row)}
          onBlur={() => handleDescriptionBlur(row)}
          className="editable-input"
          // style={{
          //   width: "100%",
          //   boxSizing: "border-box",
          //   backgroundColor: "transparent",
          //   border: "none",
          //   padding: "6px",
          // }}
        />
      );
    } else if (column.field === "balance") {
      if (
        row.balances &&
        Array.isArray(row.balances.balances) &&
        row.balances.balances.length > 0
      ) {
        const closingBalance = row.balances.balances.find(
          (b) => b.balanceType === "closingBooked"
        );
        const balanceValue = closingBalance
          ? closingBalance.balanceAmount.amount
          : row.balances.balances[0].balanceAmount.amount;
        return balanceValue;
      }
      return "";
    } else if (column.type === "checkbox") {
      return (
        <Input
          type="checkbox"
          value={row[column.field]}
          onChange={(event) => handleCheckboxChange(event, row)}
          styleInputContainer={{ justifyContent: "center" }}
        />
      );
    } else if (column.field === "bank" && row.logo) {
      return (
        <div
          className="value_and_logo"
          style={{ display: "flex", gap: "15px", alignItems: "center" }}
        >
          <img src={row.logo} alt={row.banco} style={{ maxHeight: "30px" }} />
          <p>{row[column.field]}</p>
        </div>
      );
    } else if (
      column.field === "cuenta" &&
      row.status !== "CR" &&
      (!row.accountid || row.accountid === "")
    ) {
      return (
        <div className="divlink" onClick={() => onClickRevalidar(row)}>
          Revalidar
        </div>
      );
    } else if (column.field === "cuenta" && row.validationlink) {
      return (
        <a href={row.validationlink} target="_blank" rel="noopener noreferrer">
          Pendiente validación {row[column.field]}
        </a>
      );
    } else if (column.field === "cuenta") {
      return row.iban;
    } else if (column.field === "lastaccess") {
      if (!row.lastaccess) return "";
      const lastAccessDate = new Date(row.lastaccess);
      const now = new Date();
      if (
        lastAccessDate.getFullYear() === now.getFullYear() &&
        lastAccessDate.getMonth() === now.getMonth() &&
        lastAccessDate.getDate() === now.getDate()
      ) {
        return lastAccessDate.toLocaleTimeString("en-GB", { hour12: false });
      } else {
        const year = lastAccessDate.getFullYear();
        const month = String(lastAccessDate.getMonth() + 1).padStart(2, "0");
        const day = String(lastAccessDate.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
      }
    } else if (column.field === "actions") {
      return (
        <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          {row.validationlink ? (
            <Button
              onClick={() => deleteBank(row)}
              icon="delete"
              buttonStyle={{
                minWidth: "40px",
                maxWidth: "50px",
                maxHeight: "100px",
                minHeight: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          ) : (
            <Button
              onClick={() => handleRefresh(row)}
              icon="refresh"
              buttonStyle={{
                minWidth: "40px",
                maxWidth: "50px",
                maxHeight: "100px",
                minHeight: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          )}
        </div>
      );
    }
    return row[column.field];
  };

  let currentColor = getComputedStyle(document.documentElement)
    .getPropertyValue("--bg-color")
    .trim();
  let nextColor = "rgba(0, 0, 0, 0.2)";

  return rows.map((row, index) => {
    if (
      ["CR", "ERROR", "UA", "RJ", "SA", "GA", "LN", "EX"].includes(row.status)
    ) {
      let temp = currentColor;
      currentColor = nextColor;
      nextColor = temp;
    }
    return (
      <tr
        key={index}
        className={`row_${index}`}
        style={{ backgroundColor: currentColor }}
      >
        {columns.map((column, colIndex) => (
          <td
            key={column.field}
            style={{ textAlign: column.align }}
            className={`cell_${colIndex}`}
          >
            {renderCell(column, row)}
          </td>
        ))}
      </tr>
    );
  });
};

BankTableBody.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  setupInfo: PropTypes.object.isRequired,
  setSetupInfo: PropTypes.func.isRequired,
  deleteBank: PropTypes.func.isRequired,
  updateAccount: PropTypes.func.isRequired,
};

export default BankTableBody;
