import React from "react";
import { useNavigate } from "react-router-dom";
import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import PropTypes from "prop-types";
import ExecutiveCard from "./ExecutiveCard";
import "./Executives.css";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const calculateLayout = (cards) => {
  return cards.map((card, index) => ({
    i: card.id,
    x: index % 3,
    y: Math.floor(index / 3),
    w: 1,
    h: 1,
    isResizable: false,
  }));
};

const ExecutivesLayout = ({ data = { executives: [] }, frame, view, setView }) => {
  const navigate = useNavigate();

  if (!data.executives || data.executives.length === 0) {
    return <div>No hay elementos a mostrar</div>;
  }

  return (
    <div className="executives">
      <ResponsiveReactGridLayout
        className="grid-layout"
        breakpoints={{ xl: 1920, lg: 1200, md: 768, sm: 480, xs: 200 }}
        cols={{ xl: 3, lg: 3, md: 2, sm: 1, xs: 1 }}
        rowHeight={300}
        layouts={{ lg: calculateLayout(data.executives) }}
        draggableHandle=".executive_card-drag-handle"
      >
        {data.executives.map((card) => (
          <div key={card.id} className="grid-item">
            <ExecutiveCard
              cardData={card}
              frame={frame}
              handleClick={(url) => navigate(url)}
              view={view}
              setView={setView}
            />
          </div>
        ))}
      </ResponsiveReactGridLayout>
    </div>
  );
};

ExecutivesLayout.propTypes = {
  data: PropTypes.shape({
    executives: PropTypes.array.isRequired,
  }),
  frame: PropTypes.shape({
    elements: PropTypes.array,
  }),
};

export default ExecutivesLayout;
