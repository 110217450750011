import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider.js";
import { HttpMethods } from "./HttpMethods.js";

export class Resume {
  static async list(first = 0, pagination = 100) {
    let response = await HttpMethods.request_get(`/v1/resume/list?format=flat`);
    if (!response) {
      addErrorMessageGlobal("Error al obtener los resumes");
      response = [];
    }
    return response;
  }

  static async graph(code) {
    const response = await HttpMethods.request_get(
      `/v1/resume/graph/${code}?format=text`,
      "text"
    );
    return response;
  }

  static async read(id) {
    const response = await HttpMethods.request_get(
      `/v1/resume/read/${id}`
    );
    return response;
  }

  static async crupdate(resume) {
    const response = await HttpMethods.request_post(
      `/v1/resume/crupdate`, resume
    );
    return response;
  }

  static async delete(id) {
    const response = await HttpMethods.request_delete(
      `/v1/resume/delete/${id}`
    );
    return response;
  }
}
