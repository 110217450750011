import React from "react";
import { Icon } from "@mui/material";
import "../Input.css";

const UrlInput = ({ ...inputProps }) => (
  <>
    <input {...inputProps} type="url" />
    <Icon
      onClick={() => window.open(inputProps.value ?? inputProps.defaultValue, "_blank")}
      className="eye"
      fontSize="small"
      size={12}
    >
      open_in_new
    </Icon>
  </>
);
export default UrlInput;
