import dragAndDropCanva from "src/routes/Setup/Grupos/MenuCanva/dragAndDropCanva";
import menuCanvaActionTypes from "../actionTypes/menuCanvaActionTypes";
import { generateStructureMenu } from "src/routes/Setup/Grupos/MenuCanva/helpers";

export const loadMenuData = (
  dispatch,
  setupMenu,
  hasBackupMenuDataBeenLoadedOnce
) => {
  dispatch({
    type: menuCanvaActionTypes.SET_LOADING_MENU_CANVA,
    payload: true,
  });
  const newMenu = generateStructureMenu(setupMenu);
  dispatch({
    type: menuCanvaActionTypes.SET_MENU_DATA,
    payload: newMenu,
  });
  if (!hasBackupMenuDataBeenLoadedOnce) {
    dispatch({
      type: menuCanvaActionTypes.SET_BACKUP_MENU_DATA,
      payload: JSON.parse(JSON.stringify(newMenu)),
    });
  }
  dispatch({
    type: menuCanvaActionTypes.SET_LOADING_MENU_CANVA,
    payload: false,
  });
  dispatch({
    type: menuCanvaActionTypes.SET_BACKUP_LOAD_ONCE,
    payload: true,
  });
};

export const loadRoles = (dispatch, roles) => {
  let rolesOptions = roles.map((role) => {
    return { value: role.name, label: role.name };
  });
  //   const defaultProperties = [
  //     { value: "superadmin", label: "superadmin" },
  //     { value: "user", label: "user" },
  //     { value: "developer", label: "developer" },
  //   ];
  //   rolesOptions = rolesOptions.concat(defaultProperties);
  dispatch({
    type: menuCanvaActionTypes.SET_ROLES_OPTIONS,
    payload: rolesOptions,
  });
};
export const resetMenuData = (dispatch, backupMenuData) => {
  updateMenuData(dispatch, backupMenuData);
};

export const setVariableModal = (dispatch, variable) => {
  dispatch({
    type: menuCanvaActionTypes.SET_MODAL_VARIABLE,
    payload: variable,
  });
};

export const addMenuListToMenu = (dispatch, listData, menuData) => {
  const newMenuData = [...menuData, listData];
  updateMenuData(dispatch, newMenuData);
};

export const modifyMenuListAtMenu = (dispatch, listData, menuData) => {
  const indexListToOverwrite = menuData.findIndex(
    (menu) => menu.id === listData.id
  );
  const menuDataCopy = [...menuData];
  menuDataCopy[indexListToOverwrite] = listData;

  updateMenuData(dispatch, menuDataCopy);
};

export const deleteMenuListAtMenu = (dispatch, listData, menuData) => {
  const indexListToDelete = menuData.findIndex(
    (menu) => menu.id === listData.id
  );
  const menuDataCopy = [...menuData];
  menuDataCopy.splice(indexListToDelete, 1);
  updateMenuData(dispatch, menuDataCopy);
};

export const setMenuListData = (dispatch, listData) => {
  dispatch({
    type: menuCanvaActionTypes.SET_LIST_DATA,
    payload: listData,
  });
};

export const deleteMenuCardAtList = (
  dispatch,
  menuData,
  idMenuList,
  idMenuCard
) => {
  const menuDataCopy = [...menuData];
  const menuListTargetIndex = menuDataCopy.findIndex(
    (el) => el.id === idMenuList
  );
  const newSubmenus = menuDataCopy[menuListTargetIndex].submenus.filter(
    (el) => el.id !== idMenuCard
  );
  menuDataCopy[menuListTargetIndex].submenus = newSubmenus;
  updateMenuData(dispatch, menuDataCopy);
};

export const setMenuCardData = (dispatch, cardData) => {
  dispatch({
    type: menuCanvaActionTypes.SET_CARD_DATA,
    payload: cardData,
  });
};

export const addCardToMenuList = (dispatch, menuData, idMenuList, cardData) => {
  const menuDataCopy = [...menuData];
  const menuListTargetIndex = menuDataCopy.findIndex(
    (el) => el.id === idMenuList
  );
  menuDataCopy[menuListTargetIndex].submenus.push(cardData);

  updateMenuData(dispatch, menuDataCopy);
};

export const modifyCardToMenuList = (
  dispatch,
  menuData,
  idMenuList,
  cardData
) => {
  const menuDataCopy = [...menuData];
  const menuListTargetIndex = menuDataCopy.findIndex(
    (el) => el.id === idMenuList
  );
  const menuCardTargetIndex = menuDataCopy[
    menuListTargetIndex
  ].submenus.findIndex((el) => el.id === cardData.id);
  menuDataCopy[menuListTargetIndex].submenus[menuCardTargetIndex] = cardData;
  updateMenuData(dispatch, menuDataCopy);
};

export const updateMenuData = (dispatch, newMenuData) => {
  dispatch({
    type: menuCanvaActionTypes.SET_MENU_DATA,
    payload: newMenuData,
  });
  setUpdateSetupVariable(dispatch, true);
};
/**
 *
 * @param {*} dispatch
 * @param {boolean} updateSetup
 */
export const setUpdateSetupVariable = (dispatch, updateSetup) => {
  dispatch({
    type: menuCanvaActionTypes.UPDATE_MENU_TO_SETUP,
    payload: updateSetup,
  });
};

export const setupDragAndDropCards = async (dispatch, menuData) => {
  try {
    const pos = await dragAndDropCanva();

    const dragIndex = pos.drag.index;
    const dropIndex = pos.drop.index;
    const dropList = pos.drop.list.split(" ").pop();

    const dragList = pos.drag.object.split("-")[0];
    // const dropList = pos.drop.object.split("-")[0];

    const cardId = pos.drag.object.split("-")[1];

    const updatedMenuData = [...menuData];

    const sourceList = updatedMenuData.find((list) => list.id === dragList);
    const targetList = updatedMenuData.find((list) => list.id === dropList);

    const draggedCard = sourceList.submenus.find(
      (submenu) => submenu.id === cardId
    );
    //Update the pathmenu of the dragged card
    draggedCard.pathmenu = targetList.pathmenu + "/" + draggedCard.id;

    sourceList.submenus.splice(dragIndex, 1);

    targetList.submenus.splice(dropIndex, 0, draggedCard);
    updateMenuData(dispatch, updatedMenuData);
  } catch (error) {
    console.error(error);
  }
};

export const setupDragAndDropLists = async (dispatch, menuData) => {
  try {
    const pos = await dragAndDropCanva();

    const dragIndex = parseInt(pos.drag.index, 10);
    const dropListId = pos.drop.object.split("-")[0];
    const dropListIndex = menuData.findIndex((el) => el.id === dropListId);

    const updatedMenuData = [...menuData];

    const [draggedList] = updatedMenuData.splice(dragIndex, 1);

    updatedMenuData.splice(dropListIndex, 0, draggedList);
    updateMenuData(dispatch, updatedMenuData);
  } catch (error) {
    console.error(error);
  }
};
