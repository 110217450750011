import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "src/modules/components/common/Button/Button";
import Input from "src/modules/components/common/Input/Input";
import FormContainer from "src/modules/components/Containers/FormContainer/FormContainer";
import { Executive as ExecutiveAPI, Agent as AgentAPI } from "src/lib/api";
import { addErrorMessageGlobal } from "src/modules/contexts/MessageContextProvider";
import Select from "src/modules/components/common/Select/Select";
import { useAppContext } from "src/modules/contexts/AppContextProvider";
import "./ExecutiveManagement.css";
const generateRandomId = () => {
  return `exec-${Math.random().toString(36).substr(2, 9)}`;
};

const executiveDefaultValues = {
  id: "",
  name: "",
  agent: "",
  description: "",
  imgurl: "",
  executiveprompt: "",
  documents: [],
  urls: [],
  public: false,
};

const ExecutiveManagement = () => {
  const { executiveid } = useParams();
  const { setNavBarTitle } = useAppContext();
  const navigate = useNavigate();
  const [executiveValues, setExecutiveValues] = useState(
    executiveDefaultValues
  );
  const [isCreating, setIsCreating] = useState(!executiveid);
  const [loading, setLoading] = useState(!!executiveid);
  const [agents, setAgents] = useState([]);
  const returnToExecutives = () => setTimeout(() => navigate(-1), 500);

  useEffect(() => {
    if (!executiveid) {
      setIsCreating(true);
      setExecutiveValues((prevState) => ({
        ...prevState,
        id: generateRandomId(), // Genera un ID aleatorio solo al crear un nuevo ejecutivo
      }));

      return;
    }
    ExecutiveAPI.read(executiveid)
      .then((data) => {
        if (data) {
          setExecutiveValues(data.executive);
          setLoading(false);
          setNavBarTitle(data.executive.name);
        } else {
          throw new Error("Ejecutivo no encontrado");
        }
      })
      .catch(() => {
        addErrorMessageGlobal("Ejecutivo no encontrado");
        returnToExecutives();
      });
  }, [executiveid, navigate]);

  useEffect(() => {
    const fetchAgents = async () => {
      const agents = await AgentAPI.list();
      setAgents(
        agents.map((agent) => ({
          value: agent.id,
          label: agent.name,
        }))
      );
    };
    fetchAgents();
  }, []);

  const handleInputChange = (event) => {
    const { name, type, checked, value } = event.target;
    let newValue = type === "checkbox" ? checked : value;

    setExecutiveValues((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  const handleCreateExecutive = async () => {
    await ExecutiveAPI.create(executiveValues).then(() => returnToExecutives());
  };

  const handleDeleteExecutive = async () => {
    {
      await ExecutiveAPI.delete(executiveValues.id).then(() => {
        returnToExecutives();
      });
    }
  };

  const handleAgentChange = (agent) => {
    setExecutiveValues((prevState) => ({
      ...prevState,
      agent: agent.value,
    }));
  };

  if (loading) return null;

  return (
    <FormContainer style={{ margin: "0 auto", maxWidth: "60%" }}>
      <div className="css-175oi2c" style={{ alignItems: "center" }}>
        <Button onClick={returnToExecutives} icon="arrow_back" />
        {isCreating ? (
          <Button onClick={handleCreateExecutive} icon="save" />
        ) : (
          <>
            <Button onClick={handleDeleteExecutive} icon="delete" />
            <Button onClick={handleCreateExecutive} icon="save" />
          </>
        )}
      </div>

      <Input
        name="name"
        type="text"
        placeholder="Nombre del Ejecutivo"
        value={executiveValues.name}
        onChange={handleInputChange}
        label="Nombre"
      />
      <Input
        name="description"
        type="text"
        placeholder="Descripción"
        value={executiveValues.description}
        onChange={handleInputChange}
        label="Descripción"
      />
      <Select
        isSearchable
        isClearable={false}
        name="agent"
        options={agents}
        placeholder="Seleccione un agente..."
        onChange={handleAgentChange}
        value={agents?.find((agent) => agent.value === executiveValues.agent)}
        label="Agente"
      />
      <Input
        name="imgurl"
        type="text"
        placeholder="URL de la imagen"
        value={executiveValues.imgurl}
        onChange={handleInputChange}
        label="Imagen"
      />
      <Input
        name="public"
        type="checkbox"
        checked={executiveValues.public}
        onChange={handleInputChange}
        label="Es público"
      />
      {!isCreating && (
        <div className="input-container">
          <div className="input-container-executive-title">
            Editar ejecutivo
          </div>
          <Button
            onClick={() => navigate(`/executiveedit/${executiveValues.id}`)}
            icon={"EditIcon"}
          />
        </div>
      )}
    </FormContainer>
  );
};

export default ExecutiveManagement;
